import { Attributes, List, Model, NeoModel, Utils } from '@singularsystems/neo-core';
import TradeRequestTrancheCommand from './TradeRequestTrancheCommand';
import TradeRequestCommandBase from './TradeRequestCommandBase';
import { TradeRequestSaveResult } from '../Lookups/TradeLinkSaveResult';
import { PreventionType } from '../../../../Common/Models/PreventionType';
import CalculationTranche from '../../Portfolio/Calculation/CalculationTranche';
import CalculationGroup from '../../Portfolio/Calculation/CalculationGroup';
import { TradeType } from '../../Trading/TradeType';

@NeoModel
export default class TradeRequestCommand extends TradeRequestCommandBase {

    @Attributes.Float()
    public sellQuantity: number = 0;

    @Attributes.Nullable()
    @Attributes.Float()
    public limitPrice: number | null = null;

    @Attributes.Float()
    public buyQuantity: number = 0;

    public tradeType: TradeType | null = null;

    public agreementText: string = "";

    public tradeRequestTranches = new List(TradeRequestTrancheCommand);

    // Client only properties / methods

    @Attributes.NoTracking()
    @Attributes.Nullable()
    public tradeRequestId: number | null = 0;

    @Attributes.NoTracking()
    public maxExpiryDate: Date = new Date();

    @Attributes.NoTracking()
    public calcObject!: CalculationGroup;

    @Attributes.NoTracking()
    public saveError: string = "";

    public get requiresExpiryDate() {
        return this.limitPrice !== null;
    }

    public get requiresBrokerDetails() {
        return this.buyQuantity > 0;
    }

    public get requiresAdditionalDetails() {
        return this.requiresExpiryDate || this.allowForexCover;
    }

    public get failedDocumentChecks() {
        return this.calcObject.transactionsScheme?.failedDocumentChecks.filter(c => c.tradingAction !== PreventionType.None);
    }

    public calcAllowForexCover(baseCurrencyId: number) {
        /*
Should only be allowed where the settlement currency is be different to the participant currency,
but getting the participants currency is not very accurate, so this check has been commented out.
// let tradeCurrencyId = this.calcObject.incentiveScheme.settlementCurrencyId ?? this.calcObject.currencyId;
*/
        const allowedByScheme = this.calcObject.transactionsScheme?.allowForexCover ?? this.calcObject.brokerAccount.supportsForexCover;
        this.allowForexCover = allowedByScheme && this.sellQuantity > 0 && !this.calcObject.trancheBalance.incentiveScheme.isCashSettled /*&& tradeCurrencyId !== baseCurrencyId*/;
    }

    public static fromCalculationObject(calc: CalculationGroup) {
        const tradeRequest = Utils.createInstance(TradeRequestCommand);

        tradeRequest.calcObject = calc;
        tradeRequest.buyQuantity = calc.buyQuantity;
        tradeRequest.sellQuantity = calc.sellQuantity;
        tradeRequest.tradeType = calc.lastTradeType;

        if (calc.details[0].limitPrice !== null && tradeRequest.sellQuantity > 0) {
            tradeRequest.limitPrice = calc.details[0].limitPrice;
            // TODO: expiry date should be x days, or when the earliest tranche expires?

            tradeRequest.maxExpiryDate = calc.brokerAccount.maxExpiryDate;
        }

        tradeRequest.expiryDate = tradeRequest.requiresExpiryDate ? new Date(tradeRequest.maxExpiryDate) : null;

        // isSellToBuy was removed from the TradeRequest table because of confusion about the difference between sellToBuy and a trade with sell and buy quantity.
        // calc.isSellToBuy means that the user chose the sell to buy drop down, and didn't type in the quantities manually. Left here in case needed in future.
        // tradeRequest.isSellToBuy = calc.isSellToBuy;

        tradeRequest.addTranches(calc.details);

        return tradeRequest;
    }

    public updateFromSaveResult(result: Model.PlainObject<TradeRequestSaveResult>) {
        this.tradeRequestId = result.tradeRequestId;
    }

    public updateFromErrorResult(errorText: string) {
        this.saveError = errorText;
    }

    public clearLimitPrice() {
        this.limitPrice = null;
        this.expiryDate = null;
        this.calcObject.isLimitPrice = false;
    }

    private addTranches(tranches: CalculationTranche[]) {
        for (let calcTranche of tranches) {
            const tranche = this.tradeRequestTranches.addNew();
            tranche.buyQuantity = calcTranche.buyQuantity;
            tranche.sellQuantity = calcTranche.sellQuantity;
            tranche.trancheId = calcTranche.trancheBalance.trancheId;
        }
    }

    public toString(): string {
        return "Trade Request Command";
    }
}