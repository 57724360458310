import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../ParticipantsTypes';
import ParticipantGrade from '../Models/ParticipantGrade';

export interface IParticipantsAppCatalogueApiClient {

    /** 
     * Gets a list of participant grades.
     * @returns List.
     */
    getParticipantGrades(): AxiosPromise<Array<Model.PlainTrackedObject<ParticipantGrade>>>;

    // Client only properties / methods
}

@injectable()
export default class ParticipantsAppCatalogueApiClient extends Data.ApiClientBase implements IParticipantsAppCatalogueApiClient {

    constructor (config = AppService.get(Types.Shared.Config)) {
        super(`${config.ParticipantsApi.ApiPath}/participants-app-catalogue`);
    }

    public getParticipantGrades(): AxiosPromise<Array<Model.PlainTrackedObject<ParticipantGrade>>> {
        return this.axios.get(`${this.apiPath}/participant-grades`);
    }

    // Client only properties / methods
}