import { List } from "@singularsystems/neo-core";
import { Views } from "@singularsystems/neo-react";
import { Awards, Clients, Transactions } from "../../../../App";
import { ScreenSize } from "../../../../App/Services/AppLayout";
import ParticipantAwardLookupBase from "../../../Common/Models/Base/ParticipantAwardLookupBase";
import MyAwardInfoLookup from "../../Models/ParticipantOffers/Queries/MyAwards/MyAwardInfoLookup";
import AwardModuleConfig from "../../../Common/Models/Config/Module/AwardModuleConfig";
import { InstrumentType } from "../../../Common/Models/Enums/InstrumentType";

import { AppService, Types } from '../../AwardsTypes';
import { AwardSelector } from "./AwardSelector";

export default abstract class AwardsVMBase<TLookup extends ParticipantAwardLookupBase> extends Views.ViewModelBase {

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        public cache = AppService.get(Types.Awards.Services.AwardsDataCache),
        private mainCurrenciesApiClient = AppService.get(Types.Clients.ApiClients.MainCurrenciesApiClient),
        private exchangeRatesService = AppService.get(Types.Transactions.Services.ExchangeRateService),
        private pricingService = AppService.get(Types.Transactions.Services.PricingService),
        private awardUtils = AppService.get(Types.Awards.Services.AwardUtils),
        public notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        public appLayout = AppService.get(Types.Shared.Services.AppLayout)) {

        super(taskRunner);
    }

    public awardModuleConfig = new AwardModuleConfig();
    public defaultCurrency = new Clients.MainCurrencyLookup();
    public initialising = true;
    public ratesUsed = new List(Transactions.ExchangeRateLookup);
    public ratesCurrentlyUsed = new List(Transactions.ExchangeRateLookup);
    public incentiveSchemes = new List(Awards.IncentiveSchemeLookup);
    public myAwardsInfoLookup = new MyAwardInfoLookup();

    public pageAwardSelector = new AwardSelector<TLookup>();

    public get activeAwardSelector() {
        return this.pageAwardSelector;
    }

    public getPagerNoOfButtons(): number {
        return this.appLayout.currentScreenSize <= ScreenSize.Large ?
            (this.appLayout.currentScreenSize <= ScreenSize.Medium ? 2 : 5) : 9;
    }

    public selectAll(data: TLookup[]) {
        this.activeAwardSelector.selectAll(data);
    }

    public itemSelected(item: TLookup, data: TLookup[]) {
        this.activeAwardSelector.selectItem(item, data);
    }

    public async initialise() {
        this.initialiseModuleData();

        const defaultCurrencyResult = await this.mainCurrenciesApiClient.getDefaultCurrency();
        this.defaultCurrency = Clients.MainCurrencyLookup.fromJSObject<Clients.MainCurrencyLookup>(defaultCurrencyResult.data);

        this.initialising = false;
    }

    protected async initialiseModuleData() {
        var result = await this.cache.awardModuleConfig.getDataAsync();
        this.awardModuleConfig.set(result);

        var incentiveSchemesResult = await this.cache.incentiveSchemes.getDataAsync();
        this.incentiveSchemes.set(incentiveSchemesResult);
    }

    protected async setupData(data: TLookup[]) {

        if (!this.initialising) {
            await Promise.all([
                this.loadExchangeRates(data),
                this.loadPrices(data),
                this.loadAdditionalData(data),
            ]);

            let previousLookup: TLookup | null = null;
            for (const lookup of data) {
                this.setupLookup(lookup, previousLookup, data);
                previousLookup = lookup;
            }
        }

        this.setupRatesCurrentlyUsed(data);
    }

    private setupRatesCurrentlyUsed(data: TLookup[]) {
        const ratesCurrentlyUsed = this.ratesUsed.filter(r => this.rateCurrentlyUsed(data, r));
        this.ratesCurrentlyUsed = new List(Transactions.ExchangeRateLookup);
        for (const r of ratesCurrentlyUsed) {
            this.ratesCurrentlyUsed.push(r);
        }
    }

    private rateCurrentlyUsed(data: TLookup[], rate: Transactions.ExchangeRateLookup) {
        var usedRate = data.find(c => c.usesRate(rate));
        return !!usedRate;
    }

    protected async loadAdditionalData(data: TLookup[]) {
    }

    private async loadExchangeRates(data: TLookup[]) {
        const optionToOfferedRates = data.map(c => { return { fromCode: c.optionCurrencyCode, toCode: c.offeredCurrencyCode, rateDate: c.awardDate } });
        const offeredToDefaultRates = data.map(c => { return { fromCode: c.offeredCurrencyCode, toCode: this.defaultCurrency.currencyCode, rateDate: c.awardDate } })
        const allRates = optionToOfferedRates.concat(offeredToDefaultRates);

        // no need to worry about duplicates, the ExchangeRatesService will take care of these for us.
        await this.exchangeRatesService.loadExchangeRates(allRates)
    }

    private async loadPrices(data: TLookup[]) {
        const fetchPriceCodes = Array.from(
            new Set(
                data.filter(c => c.optionInstrumentType !== InstrumentType.Cash).map(c => c.optionInstrumentCode)
                    .concat(data.filter(c => c.offeredInstrumentType !== InstrumentType.Cash).map(c => c.offeredInstrumentCode))));

        await this.pricingService.loadPrices(fetchPriceCodes);
    }

    private getPrice(instrumentCode: string, instrumentType: InstrumentType | null) {
        if (instrumentType && instrumentType === InstrumentType.Cash) {
            // price is always 1 for cash
            const price = new Transactions.InstrumentPriceLookup();
            price.price = 1;
            return price;
        } else {
            return this.pricingService.loadedPrices.find(p => p.instrumentCode === instrumentCode);
        }
    }

    protected setupLookup(lookup: TLookup, previousLookup: TLookup | null, data: TLookup[]) {
        const offeredToDefaultExchangeRate = this.exchangeRatesService.getExchangeRate(lookup.offeredCurrencyCode, this.defaultCurrency.currencyCode, lookup.awardDate);
        const optionToAwardedExchangeRate = this.exchangeRatesService.getExchangeRate(lookup.optionCurrencyCode, lookup.offeredCurrencyCode, lookup.awardDate);
        const optionInstrumentCurrentPrice = this.getPrice(lookup.optionInstrumentCode, lookup.optionInstrumentType);
        const offeredInstrumentCurrentPrice = this.getPrice(lookup.offeredInstrumentCode, lookup.offeredInstrumentType);

        lookup.setup(
            previousLookup,
            this.defaultCurrency.currencyCode,
            offeredToDefaultExchangeRate,
            optionToAwardedExchangeRate,
            optionInstrumentCurrentPrice,
            offeredInstrumentCurrentPrice);

        if (offeredToDefaultExchangeRate && offeredToDefaultExchangeRate.rate !== 1) {
            const existingRate = this.ratesUsed.find(c => c.fromCurrencyCode === offeredToDefaultExchangeRate.fromCurrencyCode
                && c.toCurrencyCode === offeredToDefaultExchangeRate.toCurrencyCode
                && c.rateDate.toDateString() === offeredToDefaultExchangeRate.rateDate.toDateString());
            if (!existingRate) {
                this.ratesUsed.push(offeredToDefaultExchangeRate);
            }
        }
        if (optionToAwardedExchangeRate && optionToAwardedExchangeRate.rate !== 1) {
            const existingRate = this.ratesUsed.find(c => c.fromCurrencyCode === optionToAwardedExchangeRate.fromCurrencyCode
                && c.toCurrencyCode === optionToAwardedExchangeRate.toCurrencyCode
                && c.rateDate.toDateString() === optionToAwardedExchangeRate.rateDate.toDateString());
            if (!existingRate) {
                this.ratesUsed.push(optionToAwardedExchangeRate);
            }
        }

        if (this.activeAwardSelector.awardShouldBeSelected(lookup, this.myAwardsInfoLookup) && !lookup.isSelected) {
            // select this item as well
            this.activeAwardSelector.selectItem(lookup, data);
        }
    }

    protected getAwardCommandText(action: string, noOfAwards: number) {
        return `${action} ${this.awardUtils.pluralize("award", noOfAwards)}`;
    }
}