import { Attributes, LookupBase } from '@singularsystems/neo-core';

export default class InterestAccrualLookup extends LookupBase {

    constructor() {
        super();
        this.makeBindable();
    }

    public interestAccrualId: number = 0;

    @Attributes.Nullable()
    public paymentCycleDateId: number | null = null;

    @Attributes.Nullable()
    @Attributes.Float()
    public accrualPaidAmount: number | null = null;

    @Attributes.Date()
    public startDate: Date = new Date();

    @Attributes.Date()
    public endDate: Date = new Date();

    @Attributes.Integer()
    public noOfDays: number = 0;

    @Attributes.Float()
    public linkedRate: number = 0;

    @Attributes.Float()
    public ownRate: number = 0;

    @Attributes.Float()
    public compoundedBalance: number = 0;

    @Attributes.Float()
    public openingAccrued: number = 0;

    @Attributes.Float()
    public accruedForPeriod: number = 0;

    @Attributes.Float()
    public closingAccrued: number = 0;

    // Client only properties / methods
}