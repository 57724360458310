import { Attributes, NeoModel, Rules, Validation, ValueObject } from '@singularsystems/neo-core';
import FileDescriptor from './Files/FileDescriptor';
import { MediaObjectType } from './Enums/MediaObjectType';

@NeoModel
export default class AllocationManagerMediaObject extends ValueObject {

    @Rules.StringLength(100)
    public fileDescription: string = "";

    public fileDescriptorId: string | null = null;

    @Attributes.ChildObject(FileDescriptor)
    public fileDescriptor: FileDescriptor | null = null;

    public mediaObjectTypeId: MediaObjectType | null = null;

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        return "Allocation Manager Media Object";
    }
}