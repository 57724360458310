import { Attributes, LookupBase, NeoModel } from '@singularsystems/neo-core';
import CashTransactionLookup from './CashTransactionLookup';

@NeoModel
export default class CashTransactionLookupGrouped extends LookupBase {

    @Attributes.Display("Date")
    @Attributes.Date()
    public effectiveDate: Date = new Date();

    public transactionType: string = "";

    @Attributes.Float()
    public amountCurrency1: number | null = null;
    
    @Attributes.Float()
    public amountCurrency2: number | null = null;

    // Client only

    @Attributes.Observable()
    public isExpanded = false;

    public details: CashTransactionLookup[] = [];
}