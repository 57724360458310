import { Routing } from '@singularsystems/neo-core';
import { IAppMenuItem } from './RouteService';
import { injectable } from 'inversify';
import { AppService, AppTypes } from './AppService';

@injectable()
export class MenuItemProvider extends Routing.MenuItemProvider<IAppMenuItem> {

    constructor(routeSecurityService = AppService.get(AppTypes.Neo.Routing.RouteSecurityService)) {
        super(routeSecurityService);
    }

    protected canShowItem(menuItem: IAppMenuItem) {
        if (super.canShowItem(menuItem)) {
            if (menuItem.showMenuItem) {
                return menuItem.showMenuItem();
            } else {
                return true;
            }
        } else {
            return false;
        }
    }
}