import React from 'react';
import { Neo } from '@singularsystems/neo-react';
import PortfolioVM from '../PortfolioVM';
import { CalculateGroupedGrid } from './CalculateGroupedGrid';
import { observer } from 'mobx-react';
import { DateUtils, EnumHelper, List, Misc, ModalUtils, NumberUtils } from '@singularsystems/neo-core';
import { IncentiveGroupList } from './IncentiveGroupList';
import CalculationGroup from '../../../Models/Portfolio/Calculation/CalculationGroup';
import { TemplateLink } from './TemplateLink';
import AppDataLookup from '../../../Models/Portfolio/AppDataLookup';
import { NotificationDuration } from '../../../../../App/Models/Enums/NotificationDuration';
import SecondmentLookup from '../../../../../Participants/ParticipantsApp/Models/Lookups/SecondmentLookup';
import { AppService, Types } from '../../../../../Clients/Participants/ClientsTypes';
import { VestingType } from '../../../Models/Portfolio/IncentiveGroup';
import { TradeType } from '../../../Models/Trading/TradeType';


interface ICalculateComponentProps {
    viewModel: PortfolioVM;
    backToPortfolio: () => void;
    onBeginTrade: () => void;
}

@observer
export class CalculateComponent extends React.Component<ICalculateComponentProps> {

    private pluralizer = AppService.get(Types.Shared.Services.Pluralizer)
    public appDataLookup = new AppDataLookup
    public messageApiClient = AppService.get(Types.Clients.ApiClients.ParticipantsMessageApiClient)
    public taskRunner = AppService.get(Types.Neo.TaskRunner)
    private notifications = AppService.get(Types.Neo.UI.GlobalNotifications)

    public render() {
        const viewModel = this.props.viewModel;
        const isAdvancedCalculatorMode = viewModel.isAdvancedCalculatorMode;

        return (
            <div>
                {!isAdvancedCalculatorMode &&
                    <div>
                        <h2>Step 1: Calculate and trade awards</h2>
                        <TemplateLink templateTypeKey="TradeProcessExplanation">
                            Click here to find out more about the trade process.
                        </TemplateLink>
                    </div>}

                {isAdvancedCalculatorMode &&
                    <>
                        <h2>Calculator</h2>
                            <div className="d-flex">
                            <Neo.FormGroup className='mr-4' label="Show portfolio by:" bind={viewModel.meta.vestingType} select={{ items: EnumHelper.asList(VestingType) }} editorClassName="highlight"/>
                            <Neo.FormGroup label="Custom Tax Rate: " bind={viewModel.meta.customTaxRate} editorClassName="highlight"
                                numProps={{ formatString: "#,##0.00##%" }}
                                onBlur={() => viewModel.updateCustomTaxRates()} />
                            </div>

                    </>}

                {viewModel.incentiveGroups.some(c => c.groupedList.some(c => c.trancheBalance.hasClosePeriod)) &&
                    <div className="mt-3">
                        <i className="fa fa-ban" /> These schemes are subject to a ban on trade. For further information <TemplateLink templateTypeKey="TradeRestrictionExplanation">click here</TemplateLink> or contact the shares office.
                    </div>}

                {viewModel.portfolioService.showMissingExchangeRateWarning &&
                    <Neo.Alert className="alert-tiny my-3" variant="warning" heading="Missing Exchange rates">
                        Awards that cannot be converted will be shown in the award currency.
                    </Neo.Alert>}

                {viewModel.portfolioService.appData!.settings.missingTaxRate &&
                    <Neo.Alert className="alert-tiny my-3" variant="warning" heading="Missing tax rate">
                        Could not find a tax rate for your country. Calculations will assume a tax rate of zero.
                    </Neo.Alert>}

                <div className="mt-3">
                    <IncentiveGroupList categories={viewModel.incentiveGroups} renderBody={category => (
                        <CalculateGroupedGrid isAdvancedCalculatorMode={isAdvancedCalculatorMode} onCustomPriceChange={(instrument) => this.onCustomPriceChange(instrument)} category={category} showValidation={this.props.viewModel.showCannotTradeReasons} onGroupRemoved={() => this.onGroupRemoved()} />
                    )} />
                </div>

                <div className="my-2">
                    <small className="text-heading my-2" style={{ fontSize: "0.85rem" }}>
                        *All fees and prices are estimates. Indicative tax is for illustration only and is based on the maximum marginal tax rate for your country. Benefits earned on the vesting of LTI are subject to the tax laws and regulations applicable in your country. In most cases, through payroll in your business, the appropriate regulatory tax deductions for your country will be made. All payment is subject to the available payroll run.
                    </small>
                </div>

                <div className="flex-container justify-content-between mt-2">
                    <Neo.Button className="btn-150" variant="secondary" onClick={() => this.props.backToPortfolio()}>Back</Neo.Button>
                    <Neo.Button hidden={isAdvancedCalculatorMode} className="btn-150" onClick={() => this.beginGoToTrading()}>Continue</Neo.Button>
                </div>

                <Neo.Modal title="Confirm limit price" bindModel={viewModel.meta.limitPriceConfirmations}>
                    {(confirmations: CalculationGroup[]) => (
                        <div>
                            {confirmations.map(item => (
                                <div className="limit-price-confirm-box">
                                    <strong>{item.incentiveSchemeName} - {DateUtils.format(item.awardDate)}</strong>
                                    <p>
                                        You have changed the current/unit price
                                    </p>
                                    <p className="font-light">
                                        Do you want to set {NumberUtils.format(item.details[0].limitPrice!, Misc.NumberFormat.CurrencyDecimals, item.trancheBalance.currencySymbol)} as a new limit price per unit?
                                    </p>
                                    <Neo.Button size="sm" onClick={() => this.confirmLimitPrice(item, false)}>
                                        Set {NumberUtils.format(item.details[0].limitPrice!, Misc.NumberFormat.CurrencyDecimals, item.trancheBalance.currencySymbol)} per unit price as your limit to trade
                                    </Neo.Button>
                                    <p className="font-light">
                                        Or do you want to perform the trade at current market price per unit of {NumberUtils.format(item.trancheBalance.instrumentPrice, Misc.NumberFormat.CurrencyDecimals, item.trancheBalance.currencySymbol)}?
                                    </p>
                                    <Neo.Button size="sm" onClick={() => this.confirmLimitPrice(item, true)}>
                                        Reset {NumberUtils.format(item.trancheBalance.instrumentPrice, Misc.NumberFormat.CurrencyDecimals, item.trancheBalance.currencySymbol)} per unit price
                                    </Neo.Button>
                                </div>
                            ))}
                        </div>
                    )}

                </Neo.Modal>
            </div>
        )
    }

    public onCustomPriceChange(instrumentId: number) {
        const viewModel = this.props.viewModel;
        viewModel.reCalcSellToBuys(instrumentId);
    }

    public async checkSecondmentDuringAwardPeriod() {

        if (!this.props.viewModel.portfolioService.appData!.settings.secondmentConfirmation) {
            this.startTrades();
        } else {
            let secondments = this.props.viewModel.portfolioData?.participantSecondments
            let secondmentsDuringAward = new List(SecondmentLookup);
            if (secondments) {
                secondments.forEach(secondment => {
                    this.props.viewModel.validSelections.forEach(validSelection => {
                        let hasSecondmentDuringAward: boolean | null = false;
                        hasSecondmentDuringAward = (
                            //left during vesting period may not have returned 
                            (secondment.startDate >= validSelection.awardDate && secondment.startDate <= validSelection.vestingDate)
                            //returned during vesting period
                            || (secondment.endDate && (secondment.endDate >= validSelection.awardDate && secondment.endDate <= validSelection.vestingDate))
                            //award and vesting happened while out of country
                            || (secondment.endDate && (secondment.startDate <= validSelection.awardDate && secondment.endDate >= validSelection.vestingDate))
                            //left before award date and have not returned after vesting
                            || (!secondment.endDate && (secondment.startDate <= validSelection.awardDate))
                        )
                        if (hasSecondmentDuringAward && !secondmentsDuringAward.includes(secondment)) {
                            secondmentsDuringAward.push(secondment);
                        }
                    }
                    )
                })
            }
            if (secondmentsDuringAward.length === 0) {
                this.startTrades();
            } else {
                ModalUtils.showModal("Secondment Confirmation",
                    <div>
                        <p>Please confirm the below secondment data:</p>
                        {secondmentsDuringAward!.sortBy('startDate', "asc").map((secondment, index) => (
                            <ul key={index}>
                                <li>Country: {secondment.countryName}</li>
                                <li>Start Date: {DateUtils.format(secondment.startDate, "dd MMM yyyy")}</li>
                                <li>End Date: {secondment.endDate ? DateUtils.format(secondment.endDate, "dd MMM yyyy") : ""}</li>
                            </ul>
                        ))}
                        <div>
                            <p>Please choose the country in which you wish to receive payment(s):</p>
                            <Neo.DropDown
                                bind={this.props.viewModel.meta.paymentCountryId}
                                select={{ items: this.props.viewModel.portfolioData?.paymentCountryList, displayMember: "countryName", valueMember: "countryId" }}
                            >
                            </Neo.DropDown>
                        </div>
                        <br></br>
                        <p>Please note that you are unable to trade until the above has been confirmed.</p>
                        <p>Should you decline, an email will be sent to the administrators on your behalf to check your secondment history.</p>
                    </div>,
                    {
                        acceptButton: { text: "Accept", onClick: () => this.startTrades() },
                        closeButton: { text: "Decline", tooltip: "Declining will send an email to an administrator", onClick: () => this.sendEmailToAdmin() }
                    });
            }
        }
    }

    public async sendEmailToAdmin() {
        await this.taskRunner.run(async () => {
            await this.messageApiClient.sendQueryMessage("Please check my secondment history");
            this.notifications.addSuccess("Enquiry sent", "You enquiry has been sent to an administrator.", NotificationDuration.Standard);
        });
    }

    private beginGoToTrading() {
        const viewModel = this.props.viewModel;
        viewModel.showCannotTradeReasons = true;
        let invalidCount = viewModel.invalidSelections.length;
        let validCount = viewModel.validSelections.sum(c => 1 + c.linkedGroups.length);

        if (invalidCount > 0) {
            let validationMessagePrefix: string;
            let validationMessageSuffix: string;

            if (viewModel.validSelections.length > 0) {
                validationMessagePrefix = `Please note, there ${this.pluralizer.isAreNo(invalidCount)} ${this.pluralizer.numberPluralize(invalidCount, "award")} which cannot be traded due to validation errors:`;
                validationMessageSuffix = `Click 'continue' to trade the remaining ${this.pluralizer.numberPluralize(validCount, "award")}, or 'close' to remove or change selections.`;
            } else {
                validationMessagePrefix = "Unfortunately none of the awards you selected can be traded due to validation errors:";
                validationMessageSuffix = "Please select different awards, or adjust your selections.";
            }

            ModalUtils.showModal("Trade",
                <div>
                    <p>{validationMessagePrefix}</p>
                    <ul>
                        {viewModel.invalidSelections.map(g => (
                            <li className="mt-2">
                                <strong>{g.groupName}</strong><br />
                                <span className="text-danger">{g.cannotTradeReason}</span>
                            </li>
                        ))}
                    </ul>
                    <p>{validationMessageSuffix}</p>
                </div>,
                {
                    acceptButton: viewModel.validSelections.length > 0 && { text: "Continue", onClick: () => this.startTrades() }
                });
        }
        else {
            this.checkSecondmentDuringAwardPeriod();
        }
    }

    private startTrades() {
        this.props.viewModel.startTrades();
        if (this.props.viewModel.tradeVM) {
            this.props.onBeginTrade();
        }
    }

    private confirmLimitPrice(group: CalculationGroup, resetToMarket: boolean) {
        if (this.props.viewModel.confirmLimitPrice(group, resetToMarket)) {
            this.props.onBeginTrade();
        }
    }

    private onGroupRemoved() {
        if (!this.props.viewModel.incentiveGroups.some(c => c.hasDetail)) {
            this.props.backToPortfolio();
        }
    }
}