import React from 'react';
import { observer } from 'mobx-react';
import { Transactions } from "../../../../App";
import { DateUtils, NumberUtils } from '@singularsystems/neo-core';

interface IExchangeRateFooterProps {
    rates: Transactions.ExchangeRateLookup[];
    children: React.ReactNode;
}

@observer
export default class ExchangeRateFooter extends React.Component<IExchangeRateFooterProps> {
    public render() {
        return (<div className="footer-banner header-main-background">
            {React.Children.count(this.props.children) && <div className="content-holder">{this.props.children}</div>}
            
            <div className="message-holder">
                <div className="message">The exchange rate of the grant date was used for calculations:</div>
                {this.props.rates.map(rate =>
                    <div className="message" key={rate.entityIdentifier}>
                        - {DateUtils.format(rate.rateDate, "dd/MM/yy")} - {NumberUtils.format(1 / rate.rate, "#,##0.######")} {rate.fromCurrencyCode} <strong>=</strong> 1 {rate.toCurrencyCode}
                    </div>)}
            </div>
        </div>);
    }
}