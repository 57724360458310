import React from "react";
import { Neo } from '@singularsystems/neo-react';
import { ITaskRunner } from '@singularsystems/neo-core';

import { AppService, AppTypes } from '../../../Services/AppService';

import STUser from '../../Models/Security/STUser';

export default class UserStatus extends React.Component<{ taskRunner: ITaskRunner }> {

    protected findParticipantsHandler?: () => void;

    private authService = AppService.get(AppTypes.Neo.Security.AuthenticationService);
    protected proxySessionService = AppService.get(AppTypes.Identity.Services.ProxySessionService);
    protected startProxySessionModal = AppService.get(AppTypes.Identity.Components.ProxyAsParticipantModalContainer);
    private utils = AppService.get(AppTypes.Shared.Services.STUtils);
    private config = AppService.get(AppTypes.Shared.Config);
    protected taskRunner: ITaskRunner;

    private statusDiv = React.createRef<HTMLDivElement>();
    private cardDiv = React.createRef<HTMLDivElement>();

    constructor(props: any) {
        super(props);

        this.taskRunner = this.props.taskRunner;

        this.onMouseEnter = this.onMouseEnter.bind(this);
        this.onMouseLeave = this.onMouseLeave.bind(this);
    }

    private logoutClicked(e: React.MouseEvent) {
        e.preventDefault();

        this.authService.beginSignOut();
    }

    public additionalComponent(): any {
        return undefined;
    }

    protected startParticipantProxySession() {
        this.startProxySessionModal.viewModel.show();
    }

    protected endProxySession() {
        this.proxySessionService.endProxySession();
    }

    protected proxyLabelClass() {
        return "text-primary";
    }

    protected proxyLabel(user: STUser) {
        return <span className={this.proxyLabelClass()}>(as {this.utils.getInitials(user.proxyParticipant)})</span>;
    }

    protected userIcon(user: STUser) {
        return <i className={`fa fa-${user.isProxy ? "mask" : "user"} ${user.isProxy ? "proxy" : ""}`} />;
    }

    private onMouseEnter() {
        if (this.statusDiv.current) {
            this.statusDiv.current.classList.add("hover");
            this.cardDiv.current!.style.display = "block";
        }
    }

    private onMouseLeave(e: React.MouseEvent) {
        if (this.statusDiv.current && this.cardDiv.current) {
            const clientRect = this.cardDiv.current.getBoundingClientRect();

            if (e.clientX <= clientRect.left || e.clientX >= clientRect.right || e.clientY >= clientRect.bottom || e.clientY <= clientRect.top) {
                this.hidePopup();
            }
        }
    }

    private hidePopup() {
        this.statusDiv.current!.classList.remove("hover");
        this.cardDiv.current!.style.display = "none";
    }

    public render() {
        let user = this.authService.user as STUser;

        return (
            user && <>
                <div className="app-user-status" ref={this.statusDiv} onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave} onClick={() => this.hidePopup()}>
                    <span className="app-user-name">{user.displayName} {user.isProxy && this.proxyLabel(user)}</span>
                    {this.userIcon(user)}

                    <div className="app-user-card" ref={this.cardDiv}>
                        <div className="card-arrow"></div>
                        <div className="card ">
                            <div className="card-body bg-dark text-light">
                                <h5 className="mb-0">{user.displayName}</h5>
                                <p className="card-text">
                                    {user.userName}
                                </p>
                                {user.isProxy &&
                                    <div className="text-warning">
                                        <span className="card-text font-italic">proxying as:</span>
                                        <h5 className="mb-0">{user.proxyParticipant}</h5>
                                    </div>}
                                {user.canProxy && !user.isProxy && <div className="text-info" >Proxy</div>}
                                {user.canProxy &&
                                    <div className="text-info mt-2">
                                        {user.canProxyFind && this.findParticipantsHandler &&
                                            <Neo.Button variant="success" icon="search" size="sm"
                                                data-tip={`Find ${user.isProxy ? "New" : "Proxy"} Participant`}
                                                onClick={() => this.findParticipantsHandler!()}>
                                                {user.isProxy ? "" : "Find"}
                                            </Neo.Button>}

                                        <Neo.Button variant="primary" icon="user-ninja" size="sm"
                                            data-tip={`Select ${user.isProxy ? "New" : "Proxy"} Participant`}
                                            onClick={() => this.startParticipantProxySession()}>
                                            {user.isProxy ? "" : "Select"}
                                        </Neo.Button>

                                        {user.isProxy &&
                                            <Neo.Button variant="danger" icon="user-times" size="sm"
                                                data-tip="End Session"
                                                onClick={() => this.endProxySession()}>
                                            </Neo.Button>}
                                    </div>}
                                {!user.mfaEnabled && user.canEnableMfa &&
                                    <Neo.Button icon="shield-alt" variant="link" className="mt-2 text-info"
                                            data-tip="Enhance your account security by enabling Two-Factor Authentication"
                                            onClick={() => {
                                                const returnUrl = window.location.href;
                                                const enableMfaUrl = `${this.config.IdentityServerApi.BasePath}/Identity/Account/EnableAuthenticator?returnUrl=${encodeURIComponent(returnUrl)}`;
                                                window.location.href = enableMfaUrl;
                                            }}>
                                        Enable 2FA
                                    </Neo.Button>}
                            </div>
                            <ul className="list-group list-group-flush">
                                {/* Uncomment if you have a profile page. */}
                                {/* <li className="list-group-item"><a href="#">Profile</a></li> */}
                                <li className="list-group-item"><a href="/" onClick={this.logoutClicked.bind(this)}>Logout</a></li>
                            </ul>
                        </div>
                    </div>


                </div>
                {this.startProxySessionModal.component}
                {this.additionalComponent()}
            </>
        )
    }
}