import React from 'react';
import { Neo, NeoGrid, Views } from '@singularsystems/neo-react';
import AllocationManagerVM from './AllocationManagerVM';
import { observer } from 'mobx-react';
import AllocationInformationView from './AllocationInformationView';
import { PageBlock } from '../../../../App/Components/Application/PageBlock';
import AllocationGridComponent from './AllocationGridComponent';
import AllocationManagerLinkedManagerLookup from '../Models/Queries/AllocationManagerLinkedManagerLookup';
import { DateUtils, FileUtils, Misc, NumberUtils } from '@singularsystems/neo-core';
import { Collapsable } from './Components/CollapseComponent';
import { AllocationManagerType } from '../Models/Enums/AllocationManagerType';

@observer
export default class AllocationManagerView extends Views.ViewBase<AllocationManagerVM> {

    constructor(props: unknown) {
        super("Allocation Manager", AllocationManagerVM, props);
    }

    public get headerTitle() {
        return "Allocator";
    }

    public get approveAllocationsHeading() {
        if (this.viewModel.currentlyLoggedInManagerDetails.isHrAssist) {
            return "HR Assist"
        }
        if (this.viewModel.currentlyLoggedInManagerDetails.isHrAssistManager) {
            return "HR Assistant Manager"
        }
        else {
            return "Approve Allocations"
        }
    }

    public navigateToSupportAndGuidelines() {
        this.navigation.navigateToView(AllocationInformationView, { allocationPlanTypeId: this.viewModel.welcomeSection.allocationPlanTypeId });
    }

    private async saveLinkedManagersExcelFile() {
        const blobData = await this.viewModel.getAllocationParticipantExtract();
        if (blobData) {
            FileUtils.showSaveFile(blobData);
        }
    }

    public render() {
        const allocationCurrencySymbol = this.viewModel.allocationCurrency.symbol;
        const currentManager = this.viewModel.currentlyLoggedInManagerDetails;
        return (
            <div>
                {/* Welcome Section to be visible across all manager types */}
                <PageBlock className="pt-3">
                    <div>
                        <h2>Welcome!</h2>
                        <p className='show-white-space'>{this.viewModel.welcomeSection.sectionText}</p>
                        <Neo.Button className="btn-150 mt-3" onClick={() => this.navigateToSupportAndGuidelines()}>
                            View guidelines
                        </Neo.Button>
                    </div>
                    <div>
                        <h2>Key Dates:</h2>
                        <p>Proposed allocations must be completed: <span className="date-color">{DateUtils.format(currentManager.endDate, "dd MMMM yyyy")}</span> </p>
                        <p>Manager approvals must be completed: <span className="date-color">{DateUtils.format(currentManager.checkTeamDate, "dd MMMM yyyy")}</span> </p>
                        <p>Last participant data update: <span className="date-color">{this.viewModel.fusionInformation.fusionImportId == 0 ? "No fusion import has occurred yet." :
                            DateUtils.format(this.viewModel.fusionInformation.createdOn, "HH:mm dd MMMM yyyy")}</span> </p>
                    </div>
                </PageBlock>

                {this.viewModel.hasDirectReports &&
                    <>
                        <section onClick={() => this.viewModel.openProposeAllocationAccordian()}>
                            <PageBlock alt>
                                <div>
                                    <h2><span className={!this.viewModel.proposeAllocationExpanded ? "fa fa-angle-right arrow-right" : "fa fa-angle-down arrow-down"} />
                                        Propose allocations<span className="can-edit-wording">{currentManager.isHistoricView ? "" : currentManager.canEdit ? "" : " - Work in progress"}</span></h2>
                                    <p>Due date: <span className="date-color">{DateUtils.format(currentManager.endDate, "dd MMMM yyyy")}</span> </p>
                                    <p>Make LTI allocation proposals for your team.</p>
                                </div>
                                <div>
                                    <p>Last update: <span className="date-color">{currentManager.lastEditedInfoLookup.lastEditedBy + " " + DateUtils.format(currentManager.lastEditedInfoLookup.lastEditedOn, "HH:mm dd MMMM yyyy")}</span></p>
                                    <p className="text-color-positive"> {currentManager.checkedDate !== null ? "All allocations complete" : currentManager.allocationTotals.allocationsComplete.toString() + " Allocations complete"} </p>
                                    <p className="text-color-negative"> {currentManager.checkedDate !== null ? "" : currentManager.allocationTotals.allocationsOutstanding.toString() + " Allocations outstanding"}</p>
                                </div>
                            </PageBlock>
                            <PageBlock alt columns={1} alignment="end" className="special-page-block-alt">
                                <div>
                                    <p className='show-white-space'>{this.viewModel.proposeAllocationsSection.sectionText}</p>
                                </div>
                            </PageBlock>
                        </section>

                        <div className="mx-n3">
                            <Collapsable show={this.viewModel.proposeAllocationExpanded}>
                                <AllocationGridComponent taskRunner={this.taskRunner}
                                    pageManager={this.viewModel.directReportsPageManager}
                                    currentAllocationManager={currentManager}
                                    allocationManagerViewModel={this.viewModel}
                                    allocationGridColumnConfig={this.viewModel.allocationGridColumnConfig}
                                    participantGradeIds={this.viewModel.participantGradeIds}
                                    allocationCurrency={this.viewModel.allocationCurrency} />
                            </Collapsable>
                        </div>
                    </>}
                {currentManager.managerTypeId !== AllocationManagerType.Level1Manager &&
                    <>
                        <section onClick={() => this.viewModel.openApproveAllocationAccordian()}>
                            <PageBlock className='accordion-page-block mt-3' alignment='end'>
                                <div>
                                    <h2><span className={!this.viewModel.approveAllocationExpanded ? "fa fa-angle-right arrow-right" : "fa fa-angle-down arrow-down"} />{this.approveAllocationsHeading}
                                        <span className="can-edit-wording">{currentManager.isHistoricView ? "" : currentManager.canEdit ? "" : " - Work in progress"}</span></h2>
                                    <p>Due date: <span className="date-color">{DateUtils.format(currentManager.levelTwoManagerEndDate, "dd MMMM yyyy")}</span> </p>
                                </div>
                                <div>
                                    <p>Last update: <span className="date-color">{currentManager.lastEditedInfoLookup.lastEditedBy + " " + DateUtils.format(currentManager.lastEditedInfoLookup.lastEditedOn, "HH:mm dd MMMM yyyy")}</span></p>
                                    <p className="text-color-positive"> {currentManager.allocationTotals.linkedManagersComplete} managers completed their draft allocations</p>
                                    <p className="text-color-negative"> {currentManager.allocationTotals.linkedManagersStillToComplete} managers still to complete their draft allocations</p>
                                </div>
                            </PageBlock>
                            {((this.viewModel.currentlyLoggedInManagerDetails.isHrAssist && this.viewModel.hRAssistSection.sectionText !== '')
                                || (!this.viewModel.currentlyLoggedInManagerDetails.isHrAssist && this.viewModel.approveAllocationsSection.sectionText !== '')) &&
                                <PageBlock alignment='end' columns={1} className="accordion-page-block">
                                    <div>
                                        <p className='show-white-space'>{this.viewModel.currentlyLoggedInManagerDetails.isHrAssist ? this.viewModel.hRAssistSection.sectionText : this.viewModel.approveAllocationsSection.sectionText}</p>
                                    </div>
                                </PageBlock>
                            }

                            <PageBlock columns={2} alignment="end" className="accordion-page-block">
                                <div></div>
                                <div className="row ">
                                    <div style={{ marginLeft: "16px" }}>
                                        <div className='budget-table-header'></div>
                                        <br />
                                        <div>
                                            Budget Allocated:
                                        </div>
                                        <div>
                                            Budget Remaining:
                                        </div>
                                    </div>
                                    <div className="row col-6 col-md-5" style={{ marginLeft: "16px" }}>
                                        {this.viewModel.incentiveGroupList.map(incentiveGroup => {
                                            return <div>
                                                <div className='budget-table-header budget-margin'>
                                                    {incentiveGroup.incentiveGroupName}</div>
                                                {currentManager.remainingIncentiveGroupBudgets.map(budget => {
                                                    return <div className="budget-table-column">
                                                        {budget.incentiveGroupId === incentiveGroup.incentiveGroupId &&
                                                            [
                                                                <div className='budget-margin'>
                                                                    {NumberUtils.format(this.viewModel.getTotalBudgets(incentiveGroup.incentiveGroupId) as number, Misc.NumberFormat.CurrencyNoDecimals, allocationCurrencySymbol)}
                                                                </div>
                                                                ,
                                                                <div className='budget-margin'>
                                                                    {NumberUtils.format(this.viewModel.getRemainingBudgets(incentiveGroup.incentiveGroupId) as number, Misc.NumberFormat.CurrencyNoDecimals, allocationCurrencySymbol)}
                                                                </div>]}
                                                    </div>
                                                })
                                                }
                                            </div>
                                        })}
                                    </div>
                                </div>
                            </PageBlock>
                        </section>

                        <div className="mx-n3">
                            <Collapsable show={this.viewModel.approveAllocationExpanded}>
                                {this.viewModel.linkedManagerPageManager.data.length > 0 &&
                                    <Neo.Pager className="am-linked-managers" pageManager={this.viewModel.linkedManagerPageManager}
                                        pageControlProps={{
                                            firstText: "",
                                            prevText: "Prev",
                                            nextText: "Next",
                                            lastText: "",
                                            alignment: "center",
                                            autoHide: false,
                                            pageSizeLabel: "Show"
                                        }}>
                                        <div>
                                            <NeoGrid.Grid<AllocationManagerLinkedManagerLookup> className="allocation-linked-managers">
                                                {(manager) => (
                                                    <NeoGrid.RowGroup expandProperty={manager.meta.isExpanded}>
                                                        <NeoGrid.Row showInHeader={false} className={"am-linked-manager-bg"}>
                                                            <NeoGrid.Column display={manager.meta.participantName} />
                                                            <NeoGrid.Column display={manager.meta.totalMembersLabel} />
                                                            <NeoGrid.Column>
                                                                Budgets Used
                                                            </NeoGrid.Column>
                                                            <NeoGrid.Column>
                                                                <table className="layout-table">
                                                                    {manager.remainingIncentiveGroupBudgets.map(remainingBudgetInfo => (
                                                                        <tr>
                                                                            <td>{remainingBudgetInfo.incentiveGroup}:</td>
                                                                            <td className="pl-2 text-right">
                                                                                {NumberUtils.format(remainingBudgetInfo.budgetUsed, Misc.NumberFormat.CurrencyNoDecimals, allocationCurrencySymbol)}
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                </table>
                                                            </NeoGrid.Column>
                                                            <NeoGrid.ButtonColumn>
                                                                <Neo.Button className="btn-150"
                                                                    size="sm"
                                                                    text={manager.meta.lastApprovalDate.value !== null ? "Team approved" : "Approve the team"}
                                                                    onClick={() => { this.viewModel.approveTeam(manager) }}
                                                                    hidden={currentManager.isHrAssist || currentManager.isHrAssistManager} />
                                                            </NeoGrid.ButtonColumn>
                                                        </NeoGrid.Row>
                                                        <NeoGrid.ChildRow>
                                                            <AllocationGridComponent taskRunner={this.taskRunner}
                                                                pageManager={manager.directReportsPageManager}
                                                                currentAllocationManager={currentManager}
                                                                allocationManagerViewModel={this.viewModel}
                                                                linkedManager={manager}
                                                                allocationGridColumnConfig={this.viewModel.allocationGridColumnConfig}
                                                                participantGradeIds={manager.participantGradeIds}
                                                                allocationCurrency={this.viewModel.allocationCurrency} />
                                                        </NeoGrid.ChildRow>
                                                    </NeoGrid.RowGroup>
                                                )}
                                            </NeoGrid.Grid>

                                            <div className="container">
                                                <div className="row budget-table-row">
                                                    <div className="col-xl-9 col-lg-9 col-md-8 budget-table-header">
                                                        Your remaining face value budget is:
                                                    </div>
                                                    <div className="col-xl-2 col-lg-2 col-md-3 ">
                                                        <table className="layout-table" style={{ width: "auto", float: "right", marginRight: "10%" }}>
                                                            {this.viewModel.incentiveGroupList.map(incentiveGroup => (
                                                                <tr>
                                                                    <td>{incentiveGroup.incentiveGroupName}</td>
                                                                    <td className="text-right pl-2">
                                                                        {NumberUtils.format(this.viewModel.getRemainingBudgets(incentiveGroup.incentiveGroupId), Misc.NumberFormat.CurrencyNoDecimals, this.viewModel.allocationCurrency.symbol)}
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </table>
                                                    </div>
                                                    <div className="col-xl-1 col-lg-1 col-md-2" />
                                                </div>
                                            </div>
                                            <div className="text-right mt-3 mx-3">
                                                <Neo.Button className="btn-150" onClick={() => this.viewModel.approveAllocationsQuestion()}
                                                    hidden={currentManager.isHrAssist || currentManager.isHrAssistManager}                     >
                                                    Approve Allocations
                                                </Neo.Button>
                                            </div>
                                            <div className="text-right mt-3 mx-3">
                                                <Neo.Button className="btn-150"
                                                    hidden={!(currentManager.isHrAssist || currentManager.isHrAssistManager)}
                                                    onClick={() => this.saveLinkedManagersExcelFile()}>
                                                    Export to Excel
                                                </Neo.Button>
                                            </div>
                                        </div>
                                    </Neo.Pager>
                                }
                            </Collapsable>
                        </div>
                    </>}
            </div>
        )
    }
}