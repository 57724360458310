import { Attributes, LookupBase, Misc, NeoModel } from '@singularsystems/neo-core';

@NeoModel
export default class AllocationIncentiveGroupBudgetTotalLookup extends LookupBase {

    @Attributes.Serialisation(Misc.SerialiseType.NotNull)
    public incentiveGroupId: number = 0;

    public allocationManagerId: string | null = null;

    public incentiveGroup: string = "";

    @Attributes.Float()
    public budgetUsed: number = 0;

    @Attributes.Float()
    public budgetRemaining: number = 0;

    @Attributes.Float()
    public budgetAmount: number = 0;

    // Client only properties / methods
}