import React from 'react';
import { Neo } from '@singularsystems/neo-react';
import { observer } from 'mobx-react';

import { AppService, Types } from '../../../AwardsTypes';

import '../../../Styles/approvals.scss';

import ApprovalAwardSummaryComponent from './ApprovalAwardSummaryComponent';

import { ApproverApprovalSummaryLookup, ApproverAwardApprovalSummaryLookup } from '../../../../Common/Models/Approvals/Queries/ApproverApprovalSummaryLookup';
import { variant } from '@singularsystems/neo-core/dist/Components';
import { Verbosity } from '../../../../Common/Models/Enums/Verbosity';

interface IApprovalSummariesComponentProps {
    approvalSummary: ApproverApprovalSummaryLookup;
    awardSelected: ((award: ApproverAwardApprovalSummaryLookup) => void);
}

@observer
export default class ApprovalSummariesComponent extends React.Component<IApprovalSummariesComponentProps> {

    private pluralizer = AppService.get(Types.Shared.Services.Pluralizer);

    private getAlertVariant(approval: ApproverAwardApprovalSummaryLookup): variant {
        if (approval.completedPercentage === 1) {
            return "success";
        } else if (approval.daysRemaining <= 3) {
            return "danger";
        } else if (approval.daysRemaining <= 7) {
            return "warning";
        }

        return "info";
    }

    public render() {
        return (
            <Neo.GridLayout lg={2} gutterSize={1} >
                {this.props.approvalSummary.awards.map(awardApproval =>
                    <ApprovalAwardSummaryComponent key={awardApproval.award.awardPrepId} 
                            awardApproval={awardApproval} verbosity={Verbosity.Detailed}
                            awardSelected={() => this.props.awardSelected(awardApproval)} />) }
            </Neo.GridLayout>
        )
    }
}