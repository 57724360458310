import React from 'react';
import { Neo, Views } from '@singularsystems/neo-react';
import { observer } from 'mobx-react';

import { AppService, Types } from '../CommonIdentityTypes';
import { HtmlView } from '@singularsystems/neo-notifications';
import { LargeCheckBox } from '../../../App/Common/Components/Application/LargeCheckBox';

import '../Styles/CommonIdentityStyles.scss';

@observer
export default class TermsAndConditionsView extends Views.ViewBase {

    private termsAndConditionsService = AppService.get(Types.Identity.Services.TermsAndConditionsService);

    constructor(props: unknown) {
        super("Terms & Conditions", Views.EmptyViewModel, props);
    }

    public initialise() {
        this.termsAndConditionsService.ensureTermsAndConditionsLoaded();
    }

    public render() {
        return (
            <div className="row mt-4">
                <div className="hidden-sm col-md-1 col-lg-2 col-xxl-3" />
                
                {this.termsAndConditionsService.status &&
                    <div className="col-md-10 col-lg-8 col-xxl-6">
                        {this.termsAndConditionsService.termsAndConditions &&
                            <>
                                <HtmlView html={this.termsAndConditionsService.termsAndConditions.termsAndConditionsHtml} />

                                <hr />

                                {!this.termsAndConditionsService.status.userHasAccepted &&
                                    <>
                                        <div className="row accept-terms-and-conditions">
                                            <div className="col-lg-8 col-xl-10 d-flex">
                                                <LargeCheckBox checked={this.termsAndConditionsService.meta.acceptingTermsAndConditions} />
                                                <label className="ml-1">{this.termsAndConditionsService.termsAndConditions.acceptLabelText}</label>
                                            </div>
                                            <div className="col-lg-4 col-xl-2 right-tools">
                                                <Neo.Button task={this.termsAndConditionsService.taskRunner} variant="primary"
                                                    className="button-fw"
                                                    disabled={!this.termsAndConditionsService.acceptingTermsAndConditions}
                                                    onClick={() => this.termsAndConditionsService.acceptTermsAndConditions()}>Submit</Neo.Button>
                                            </div>
                                        </div>

                                        <hr />
                                    </>}
                            </>}
                    </div>}
                <div className="hidden-sm col-md-1 col-lg-2 col-xxl-3" />
            </div>
        );
    }
}