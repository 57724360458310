import { Data, Model, Utils } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../../CommonIdentityTypes';
import ProxySessionLookupCriteria from '../../../Models/ProxyUsers/Queries/ProxySessionLookupCriteria';
import ProxySessionLookup from '../../../Models/ProxyUsers/Queries/ProxySessionLookup';
import MyProxyParticipantLookupCriteria from '../../../Models/ProxyUsers/Queries/MyProxyParticipantLookupCriteria';
import ProxyUserParticipantLookup from '../../../Models/ProxyUsers/Queries/ProxyUserParticipantLookup';

export interface IProxySessionQueryApiClient {

    /**
     * Will retrieve a list of proxy user participants
     * @param request The request
     * @returns A task awaiting the retrieve operation
     */
    sessionLookup(request: Model.PartialPlainObject<Data.PageRequest<ProxySessionLookupCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<ProxySessionLookup>>>;

    /**
     * Will retrieve a list of proxy participants for the current user
     * @param request The request
     * @returns A task awaiting the retrieve operation
     */
    myParticipantLookup(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<MyProxyParticipantLookupCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<ProxyUserParticipantLookup>>>;

    /** 
     * Will return a value indicating whether the user can proxy as the requested participant id
     * @param participantId The participant id
     * @returns A task awaiting the retrieve operation
     */
    canProxyAsParticipant(participantId: string): AxiosPromise<boolean>;

    // Client only properties / methods
}

@injectable()
export default class ProxySessionQueryApiClient extends Data.ApiClientBase implements IProxySessionQueryApiClient {

    constructor (config = AppService.get(Types.Shared.Config)) {
        super(`${config.IdentityServerApi.ApiPath}/ProxySessionQuery`);
    }

    public sessionLookup(request: Model.PartialPlainObject<Data.PageRequest<ProxySessionLookupCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<ProxySessionLookup>>> {
        var result = this.axios.get(`${this.apiPath}/lookup?${Utils.getQueryString(request)}`);
        return result;
    }

    public myParticipantLookup(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<MyProxyParticipantLookupCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<ProxyUserParticipantLookup>>> {
        return this.axios.get(`${this.apiPath}/myParticipantLookup?${Utils.getQueryString(request)}`);
    }

    public canProxyAsParticipant(participantId: string): AxiosPromise<boolean> {
        return this.axios.get(`${this.apiPath}/canProxyAsParticipant/${encodeURIComponent(participantId)}`);
    }

    // Client only properties / methods
}