import { Data, Model, Utils } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../AllocationManagerTypes';
import AllocationManagerStartupData from '../Models/Queries/AllocationManagerStartupData';
import AllocationManagerLinkedManagerLookupCriteria from '../Models/Queries/AllocationManagerLinkedManagerLookupCriteria';
import AllocationManagerLinkedManagerLookup from '../Models/Queries/AllocationManagerLinkedManagerLookup';
import AllocationManagerLookup from '../Models/Queries/AllocationManagerLookup';
import AllocationIncentiveGroupBudgetTotalLookup from '../Models/Queries/AllocationIncentiveGroupBudgetTotalLookup';
import AllocationPlanIncentiveSchemeInfo from '../Models/AllocationPlanIncentiveSchemeInfo';
import AllocationManagerConfig from '../../Common/Models/AllocationManagerConfig';
import AllocationLastEditedInfoLookup from '../Models/Queries/AllocationLastEditedInfoLookup';

export interface IAllocationManagerQueryParticipantApiClient {

    /**
     * Gets a boolean indicating whether to render the Allocation Manager menu item.
     * @returns A boolean indicating whether to show the Allocation Manager menu item.
     */
    displayAllocationManagerMenuItem(): AxiosPromise<boolean>;

    /**
     * Gets startup data required by the participants app allocation manager screen.
     * @returns Startup data.
     */
    getStartupData(): AxiosPromise<Model.PlainObject<AllocationManagerStartupData>>;

    /**
     * Will get the allocation manager linked managers lookup filtered by the criteria provided.
     * @param request request
     * @returns A  representing the result of the asynchronous operation.
     */
    getAllocationManagerLinkedManagerLookups(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<AllocationManagerLinkedManagerLookupCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<AllocationManagerLinkedManagerLookup>>>;

    /**
     * Get Current Allocation Manager by ParticipantId
     * @returns The Allocation Manager
     */
    getCurrentAllocationManager(): AxiosPromise<Model.PlainObject<AllocationManagerLookup>>;

    /**
     * Get the details of a specific allocation manager
     * @param allocationPlanId The allocation plan id to search under.
     * @returns an Allocation Manager Lookup
     */
    getAllocationManagerRemainingBudgetsAsync(allocationPlanId: number): AxiosPromise<Array<Model.PlainObject<AllocationIncentiveGroupBudgetTotalLookup>>>;

    /**
     * Gets a list of Incentive Schemes Information for a specific allocation manager.
     * @param allocationManagerId The allocation manager id
     * @returns List of Allocation Plan Incentive Scheme info lookup
     */
    getAMIncentiveSchemesInfoLookups(allocationManagerId: number): AxiosPromise<Array<Model.PlainTrackedObject<AllocationPlanIncentiveSchemeInfo>>>;

    /**
     * Gets the Allocation Manager config
     * @returns Allocation Manager config
     */
    getAMChangeControlConfig(): AxiosPromise<Model.PlainTrackedObject<AllocationManagerConfig>>;

    /** 
     * Gets the Allocation Last Edited Info
     * @param allocationManagerParticipantId allocationManagerParticipantId
     * @returns Allocation Last Edited Info Lookup
     */
    getLastEditByManagerInformation(allocationManagerParticipantId: string): AxiosPromise<Model.PlainObject<AllocationLastEditedInfoLookup>>;


    // Client only properties / methods
}

@injectable()
export default class AllocationManagerQueryParticipantApiClient extends Data.ApiClientBase implements IAllocationManagerQueryParticipantApiClient {

    constructor(config = AppService.get(Types.Shared.Config)) {
        super(`${config.AwardsApi.ApiPath}/allocation-manager-query-participant`);
    }

    public displayAllocationManagerMenuItem(): AxiosPromise<boolean> {
        return this.axios.get(`${this.apiPath}/display-allocation-manager-menu`);
    }

    public getStartupData(): AxiosPromise<Model.PlainObject<AllocationManagerStartupData>> {
        return this.axios.get(`${this.apiPath}/startup-data`);
    }

    public getAllocationManagerLinkedManagerLookups(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<AllocationManagerLinkedManagerLookupCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<AllocationManagerLinkedManagerLookup>>> {
        return this.axios.get(`${this.apiPath}/linkedManagers-paged?${Utils.getQueryString(request)}`);
    }

    public getCurrentAllocationManager(): AxiosPromise<Model.PlainObject<AllocationManagerLookup>> {
        return this.axios.get(`${this.apiPath}/current-allocation-manager`);
    }

    public getAllocationManagerRemainingBudgetsAsync(allocationPlanId: number): AxiosPromise<Array<Model.PlainObject<AllocationIncentiveGroupBudgetTotalLookup>>> {
        return this.axios.get(`${this.apiPath}/remaining-budgets/${allocationPlanId}`);
    }

    public getAMIncentiveSchemesInfoLookups(allocationManagerId: number): AxiosPromise<Array<Model.PlainTrackedObject<AllocationPlanIncentiveSchemeInfo>>> {
        return this.axios.get(`${this.apiPath}/allocation-manager-schemes?allocationManagerId=${allocationManagerId}`);
    }

    public getAMChangeControlConfig(): AxiosPromise<Model.PlainTrackedObject<AllocationManagerConfig>> {
        return this.axios.get(`${this.apiPath}/config`);
    }

    public getLastEditByManagerInformation(allocationManagerParticipantId: string): AxiosPromise<Model.PlainObject<AllocationLastEditedInfoLookup>> {
        return this.axios.get(`${this.apiPath}/last-edit-info?allocationManagerParticipantId=${encodeURIComponent(allocationManagerParticipantId)}`);
    }

    // Client only properties / methods
}