import { NeoModel, Validation, ValueObject } from '@singularsystems/neo-core';

@NeoModel
export default class SchemeConfig extends ValueObject {


    public allowNotionalInterest: boolean = false;

    public allowReleaseDate: boolean = false;

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        return "Scheme Config";
    }
}