import { Attributes, LookupBase, NeoModel } from '@singularsystems/neo-core';

@NeoModel
export default class AllocationPreviousSplitLookup extends LookupBase {

    @Attributes.Display("Scheme")
    public incentiveSchemeName: string = "";

    @Attributes.Integer()
    public currentFYExpectedValue: number = 0;

    @Attributes.Float()
    public currentFYSplit: number = 0;

    @Attributes.Integer()
    public prior1ExpectedValue: number = 0;

    @Attributes.Float()
    public prior1Split: number = 0;

    @Attributes.Integer()
    public prior2ExpectedValue: number = 0;

    @Attributes.Float()
    public prior2Split: number = 0;

    @Attributes.Integer()
    public prior3ExpectedValue: number = 0;

    @Attributes.Float()
    public prior3Split: number = 0;

    @Attributes.Integer()
    public prior4ExpectedValue: number = 0;

    @Attributes.Float()
    public prior4Split: number = 0;

    // Client only properties / methods
}