export class AdminAppConfig { 
    private silentSignInUrl: string = "";
    private dashboardUrl: string = "";
  
    public get SilentSignInUrl(): string { return this.silentSignInUrl; }
    public get DashboardUrl(): string { return this.dashboardUrl; }
  
    constructor(private silentSignInUrlTemplate: string, private dashboardUrlTemplate: string) {
      const domain = window.location.hostname;
  
      // Naive approach assuming subdomain is on the host, and no 2nd, 3rd etc level subdomains
      // (Improve later to support http://domain.com and http://second.first.domain.com patterns)
      const subdomain = domain.split('.')[0];
      
      // Embed the domain or subdomain for multi-tenancy (Only one of these should be present in the URL template)
      this.silentSignInUrl = silentSignInUrlTemplate.replace("{domain}", domain);
      this.silentSignInUrl = this.silentSignInUrl.replace("{subdomain}", subdomain);

      this.dashboardUrl = dashboardUrlTemplate.replace("{domain}", domain);
      this.dashboardUrl = this.dashboardUrl.replace("{subdomain}", subdomain);
    }
  }
  