import React from 'react';
import { NeoGrid } from '@singularsystems/neo-react';
import { observer } from 'mobx-react';

import ParticipantAwardReleaseLookup from '../../../../Common/Models/Approvals/Queries/ParticipantAwardReleaseLookup';
import AwardsGridBase, { IAwardsGridBaseProps } from '../../Components/AwardsGridBase';
import ReleaseVM from '../ReleaseVM';
import ParticipantAwardReleaseLookupCriteria from '../../../../Common/Models/Approvals/Queries/ParticipantAwardReleaseLookupCriteria';


interface IReleaseAwardsGridProps extends IAwardsGridBaseProps<ReleaseVM, ParticipantAwardReleaseLookupCriteria, ParticipantAwardReleaseLookup>  {
    showTeamMember?: boolean;
    showParticipantInfoButton?: boolean;
    allSelected: boolean;
}

@observer
export default class ReleaseAwardsGrid extends AwardsGridBase<IReleaseAwardsGridProps, ReleaseVM, ParticipantAwardReleaseLookupCriteria, ParticipantAwardReleaseLookup> {

    protected leftColumns(item: ParticipantAwardReleaseLookup) : JSX.Element | undefined {
        return (this.props.showTeamMember ?? true) ? 
                                <NeoGrid.Column label={"Team\nMember"} sort={item.meta.participantName}
                                    rowSpan={item.sameParticipantRowCount}
                                    cellClassName={item.sameParticipant  ? "hidden" : "max-100"}>
                                    {item.sameParticipant  ? "" : item.participantName}
                                </NeoGrid.Column> :
                                undefined;
    }

    protected rightColumns(item: ParticipantAwardReleaseLookup) : JSX.Element | undefined  {
        return (this.props.showParticipantInfoButton ?? true) ?
            <NeoGrid.Column
                rowSpan={item.sameParticipantRowCount}
                cellClassName={item.sameParticipant ? "hidden" : ""}>
                <div data-tip={`View awards for ${item.participantName}`}>
                    <i className="fa fa-ellipsis-v text-primary selectable" onClick={() => this.props.viewModel.showParticipantInfo(item)} />
                </div>
            </NeoGrid.Column> :
            undefined;
    }
}