import React from 'react';
import { observer } from 'mobx-react';
import { NeoModel } from '@singularsystems/neo-core';
import { Neo, Views } from '@singularsystems/neo-react';
import { AppService, Types } from '../../../AwardsTypes';
import ParticipantOfferAwardDocumentLookup from '../../../../Common/Models/AwardDocuments/Queries/ParticipantOfferAwardDocumentLookup';
import AwardDocumentComponent, { AwardDocumentComponentVM } from '../../Acceptance/Components/Steps/Documents/AwardDocumentComponent';
import MyAwardDocumentLookup from '../../../Models/ParticipantOffers/Queries/MyAwards/MyAwardDocumentLookup';
import { NotificationDuration } from '../../../../../App/Models/Enums/NotificationDuration';
import { AwardDocumentDeliveryType } from '../../../../Common/Models/AwardDocuments/AwardDocumentDeliveryType.enum';

interface IMyAwardDocumentComponentProps {
    viewModel: MyAwardDocumentComponentVM;
    documentCompleted?: (document: ParticipantOfferAwardDocumentLookup) => void;
    readOnly: boolean;
    isAcceptedAwardSummary: boolean;
}

@NeoModel
export class MyAwardDocumentComponentVM extends Views.ViewModelBase {

    public awardDocumentComponentVM: AwardDocumentComponentVM;

    public awardDocument = new MyAwardDocumentLookup();

    public get document() {
        return this.awardDocumentComponentVM.document;
  }

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private awardDocumentsApiClient = AppService.get(Types.Awards.ApiClients.AwardDocumentsQueryApiClient),
        public notifications = AppService.get(Types.Neo.UI.GlobalNotifications)) {

        super(taskRunner);

        this.awardDocumentComponentVM = new AwardDocumentComponentVM(this.taskRunner, this.documentCompleted.bind(this));
    }

    public documentCompletedHandler?: (document: ParticipantOfferAwardDocumentLookup) => void;


    public async loadParticipantAwardDocument(participantOfferId: number, participantOfferAwardDocumentId: number, incentiveSchemeDocumentId: number | null) {
        if (incentiveSchemeDocumentId) {
            const result = await this.taskRunner.waitFor(this.awardDocumentsApiClient.incentiveSchemeDocumentLookup(incentiveSchemeDocumentId));
            const document = ParticipantOfferAwardDocumentLookup.fromJSObject<ParticipantOfferAwardDocumentLookup>(result.data);

            return this.setupDocument(document);
        }
        else {
            const result = await this.taskRunner.waitFor(this.awardDocumentsApiClient.participantDocumentLookup(participantOfferId, participantOfferAwardDocumentId));
            const document = ParticipantOfferAwardDocumentLookup.fromJSObject<ParticipantOfferAwardDocumentLookup>(result.data);
            return this.setupDocument(document);
        }
    }

    public async generateAcceptedAwardsSummary(participantOfferId: number, awardPrepId: number)
    {
        const result = await this.taskRunner.waitFor(this.awardDocumentsApiClient.generateAcceptedAwardsSummaryAsync(participantOfferId, awardPrepId));
        const document = ParticipantOfferAwardDocumentLookup.fromJSObject<ParticipantOfferAwardDocumentLookup>(result.data);
        this.awardDocumentComponentVM.isAcceptedAwardSummary = true;
        return this.setupDocument(document);
    }

    private setupDocument(document: ParticipantOfferAwardDocumentLookup) {
        this.awardDocumentComponentVM.disposeReactions();
        this.awardDocumentComponentVM.document = document;
        this.awardDocumentComponentVM.setup();

        return document;
    }

    private documentCompleted(document: ParticipantOfferAwardDocumentLookup) {
        this.awardDocument.acceptedTermsAndConditions = document.acceptedTermsAndConditions;
        this.awardDocument.respondedOn = new Date();
        this.notifications.addSuccess("Response saved", null, NotificationDuration.Standard);

        if (this.documentCompletedHandler) {
            this.documentCompletedHandler(document);
        }
    }

    public async downloadHtmlDocument() {
        const token = await this.taskRunner.waitFor(
            this.awardDocumentsApiClient.getHtmlDocumentPdfDownloadToken(this.document.participantOfferId, this.document.participantOfferAwardDocumentId, this.awardDocumentComponentVM.isAcceptedAwardSummary));

            const downloadUrl = this.awardDocumentsApiClient.getDownloadHtmlDocumentPdfUrl(this.document.participantOfferId, this.document.participantOfferAwardDocumentId, token.data);
        window.location.href = downloadUrl;
    }
}

@observer
export default class MyAwardDocumentComponent extends React.Component<IMyAwardDocumentComponentProps> {
    public render() {
        const viewModel = this.props.viewModel;
        return (<div className="my-award-document">
            <div className="award-document-header page-block-alt p-3">
                <Neo.GridLayout md={2}>
                    <h2>{viewModel.document.documentName}</h2>
                    {viewModel.document.deliveryType === AwardDocumentDeliveryType.HtmlTemplate &&
                        <div className="right-tools">
                            <Neo.Button icon="download" isOutline data-tip={`Download a pdf version of this ${viewModel.document.documentName}`}
                                onClick={viewModel.downloadHtmlDocument.bind(viewModel)} />
                        </div>}
                </Neo.GridLayout>
            </div>

            <AwardDocumentComponent
                viewModel={viewModel.awardDocumentComponentVM}
                document={viewModel.document}
                mainTaskRunner={viewModel.taskRunner}
                readOnly={this.props.readOnly}
                documentCompleted={(d) => { }}
                hideHeader
                alignAcceptTsAndCsLeft
                isAcceptedAwardSummary={this.props.isAcceptedAwardSummary}/>
        </div>);
    }
}