import { Attributes, List, Model, NeoModel } from '@singularsystems/neo-core';
import { TradeLinkSaveResult } from '../Lookups/TradeLinkSaveResult';
import TradeRequestCommand from './TradeRequestCommand';
import TradeRequestCommandBase from './TradeRequestCommandBase';

@NeoModel
export default class LinkedTradeRequestsCommand extends TradeRequestCommandBase {

    public awardLinkId: number = 0;

    public tradeRequests = new List(TradeRequestCommand);

    // Client only properties / methods

    @Attributes.NoTracking()
    public awardLinkDescription: string = ""

    @Attributes.NoTracking()
    public hasSaveError: boolean = false;

    /**
    * Gets the min (max expiry) of all child trades.
    */
   public get maxExpiryDate() {
       let maxExpiryDate: Date | null = null;
       this.tradeRequests.forEach(t => {
           if (t.requiresExpiryDate && (!maxExpiryDate || t.maxExpiryDate.getTime() < maxExpiryDate.getTime())) {
               maxExpiryDate = t.maxExpiryDate;
           }
       });
       return maxExpiryDate ?? new Date();
   }

    public get requiresExpiryDate() {
        return this.tradeRequests.some(c => c.requiresExpiryDate);
    }

    public get requiresBrokerDetails() {
        return this.tradeRequests.some(c => c.requiresBrokerDetails);
    }

    public get requiresAdditionalDetails() {
        return this.tradeRequests.some(c => c.requiresAdditionalDetails);
    }

    public get failedDocumentChecks() {
        let allFailedChecks = [];
        for (let tradeRequest of this.tradeRequests) {
            const failedChecks = tradeRequest.failedDocumentChecks;
            if (failedChecks) {
                allFailedChecks.push(...failedChecks);
            }
        }
        if (allFailedChecks.length > 0) {
            return allFailedChecks.groupBy(c => c.documentRequirementName, (k, i) => i);
        } else {
            return undefined;
        }
    }

    public calcAllowForexCover(baseCurrencyId: number): void {
        this.tradeRequests.forEach(tr => tr.calcAllowForexCover(baseCurrencyId));
        this.allowForexCover = this.tradeRequests.some(c => c.allowForexCover);
    }

    public updateFromSaveResult(result: Model.PlainObject<TradeLinkSaveResult>) {
        if (result.tradeRequestLinkId === -1) {
            // Trade was merged.
            this.tradeRequests[0].tradeRequestId = result.tradeRequests[0].tradeRequestId;
        } else {
            for (let trade of result.tradeRequests) {
                let thisTrade = this.tradeRequests.find(c => c.entityIdentifier === trade.entityIdentifier);
                if (thisTrade) {
                    thisTrade.tradeRequestId = trade.tradeRequestId
                }
            }
        }
    }

    public updateFromErrorResult(errorText: string) {
        this.hasSaveError = true;
        for (let trade of this.tradeRequests) {
            trade.updateFromErrorResult(errorText);
        }
    }

    public finaliseSetup() {
        if (this.requiresExpiryDate) {
            this.expiryDate = new Date(this.maxExpiryDate);
        }
    }

    public clearLimitPrice() {
        this.tradeRequests.forEach(tr => tr.clearLimitPrice());
    }
}