import { NeoModel, Rules, Validation, ValueObject } from '@singularsystems/neo-core';

@NeoModel
export default class AllocationManagerSenderConfig extends ValueObject {

    @Rules.EmailAddress()
    public emailAddress: string = "";

    public displayName: string = "";

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew) {
            return "New Allocation Manager Sender Config";
        } else {
            return "Allocation Manager Sender Config";
        }
    }
}