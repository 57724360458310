import { Attributes, LookupBase, NeoModel } from '@singularsystems/neo-core';

@NeoModel
export default class TradeRequestDocumentLookup extends LookupBase {

    constructor() {
        super();
    }

    public readonly tradeRequestDocumentId: number = 0;

    public readonly tradeRequestId: number = 0;

    public readonly fileDescriptorId: number = 0;

    @Attributes.Date()
    public readonly documentDate: Date = new Date();

    public readonly documentType: string = "";

    public readonly documentName: string = "";

    // Client only properties / methods
    
    @Attributes.NoTracking()
    public downloadUrl : string | null = null;

    @Attributes.NoTracking()
    public preparingDownload = false;

    @Attributes.Observable()
    public isLoading = false;

    public get findId() {
        if (this.tradeRequestId) {
            return "tr-" + this.tradeRequestId;
        }
        return "";
    }
}