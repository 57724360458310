import { Attributes, DateUtils, List, ModelBase, NeoModel, Rules } from '@singularsystems/neo-core';
import { AwardStatus } from '../Enums/AwardStatus';

@NeoModel
export default class AwardPrepLookup extends ModelBase {

    public awardPrepId: number = 0;

    @Rules.Required()
    @Attributes.Date()
    public awardDate: Date | null = null;

    public status: AwardStatus | null = null;

    public options = new List(AwardOptionLookup);

    // Client only properties / methods

    public matchesFilter(filter: string) {
        if (!filter) { return true; }
        else {
            filter = filter.toLowerCase();
            if (this.awardDateString.toLowerCase().indexOf(filter) !== -1) {
                return true;
            }
            for (const o of this.options) {
                if (o.toString().toLowerCase().indexOf(filter) !== -1) {
                    return true;
                }
            }
        }
        return false;
    }

    public get awardDateString() {
        return DateUtils.format(this.awardDate!, "dd-MMM-yy")
    }

    public toString() {
        let optionsString = "";
        if (this.options.length === 1) {
            optionsString = this.options[0].toString();
        } else {
            optionsString = `${this.options.length} Options`;
        }
        return `${DateUtils.format(this.awardDate!)} - ${optionsString}`;
    }

    public get description() {
        return this.options.length > 0 ? this.options[0].toShortString() : DateUtils.format(this.awardDate!, "dd MMM yy") + " - no options";
    }
}

@NeoModel
export class AwardOptionLookup extends ModelBase {

    @Attributes.Nullable()
    public awardOptionId: number | null = null;

    public awardName: string = "";

    @Attributes.Nullable()
    @Attributes.Integer()
    public awardNumber: number | null = null;

    // Client only properties / methods

    public toString() {
        return `#${this.awardNumber} - ${this.awardName}`;
    }

    public toShortString() {
        return `#${this.awardNumber}`;
    }
}