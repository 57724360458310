import { Attributes, NeoModel, Validation, ValueObject } from '@singularsystems/neo-core';

@NeoModel
export default class AllocationManagerMiscConfig extends ValueObject {

    @Attributes.Integer()
    public vestingYears: number = 0;

    public hideHistory: boolean = false;

    public previousAllocationToolTip: string = "";

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        return "Allocation Manager Misc Config";
    }
}