import { Attributes, Components, EnumHelper, ModelBase, NeoModel, StringUtils, Utils, Validation } from '@singularsystems/neo-core';
import { ApprovalStatus } from '../../../../Common/Models/Enums/ApprovalStatus';
import AwardPrepApprovalConfig from '../../Config/AwardPrepApprovalConfig';
import StatusOptions from './StatusOptions';
import { IOfferRecord } from '../../../Services/OfferTotalsService';

@NeoModel
export default class ApprovalLookup extends ModelBase implements IOfferRecord {

    public awardPrepId: number = 0;

    public awardApprovalId: number = 0;

    public participantOfferId: number = 0;

    public participantId: string = Utils.emptyGuid();

    public participantCode: string = "";

    public participantName: string = "";

    @Attributes.Display("Email")
    public correspondenceEmail: string = "";

    @Attributes.Float()
    @Attributes.Display("Offered")
    public offeredUnits: number = 0;

    public offeredInstrumentId: number = 0;
    
    public offeredInstrumentCode: string = "";

    public offeredInstrument: string = "";

    public offeredSymbol: string = "";

    @Attributes.Date()
    public approvedOn: Date | null = null;

    @Attributes.Date()
    public declinedOn: Date | null = null;

    @Attributes.Display("Reason")
    public declinedReason: string = "";

    @Attributes.Date()
    public submittedOn: Date | null = null;

    // Client only properties / methods

    public get offeredInstrumentDisplay() { return this.offeredSymbol; }

    public onDeserialiseCompleted() {
        this.savedStatus = this.getApprovalStatus();
        this.priorStatus = this.getApprovalStatus();
    }

    @Attributes.NoTracking()
    @Attributes.Float()
    public originalOfferedUnits: number = 0;

    @Attributes.NoTracking()
    @Attributes.Nullable()
    @Attributes.Float()
    public newOfferedUnits: number | null = 0;

    @Attributes.NoTracking()
    public _isSaving = false;

    public get isSaving() {
        return this._isSaving;
    }

    public saveStarting() {
        this._isSaving = true;
    }

    public saveCompleted() {
        this._isSaving = false;
        this.savedStatus = this.approvalStatus;
    }

    @Attributes.NoTracking()
    public canEditOfferedUnits = false;

    @Attributes.Float()
    @Attributes.Display("Offered Units")
    public get offeredUnitsValue() {
        if (this.newOfferedUnits !== 0) {
            return this.newOfferedUnits;
        } else {
            return this.originalOfferedUnits;
        }
    }

    public get originalOffer() {
        return this.newOfferedUnits !== 0 && this.originalOfferedUnits !== this.newOfferedUnits;
    }

    public get offeredUnitsHaveChanged() {
        return this.offeredUnits !== this.newOfferedUnits;
    }

    public addBusinessRules(rules: Validation.Rules<this>) {
        rules.warnWhen(a => a.declinedOn !== null && StringUtils.isNullOrWhitespace(a.declinedReason), "Declined reason is required prior to submitting")
            .onProperties(a => [a.declinedOn, a.declinedReason]);
    }

    public get isDeclined() {
        return this.approvalStatus === ApprovalStatus.Declined || this.approvalStatus === ApprovalStatus.DeclinedPending;
    }

    public get approvalStatus() {
        return this.getApprovalStatus();
    }

    @Attributes.Display("Status")
    public get approvalStatusString() {
        return EnumHelper.getItemMetadata(ApprovalStatus, this.getApprovalStatus()).display;
    }

    @Attributes.NoTracking()
    public priorStatus = ApprovalStatus.Pending;

    @Attributes.NoTracking()
    public savedStatus = ApprovalStatus.Pending;

    public getRealApprovalStatus(config: AwardPrepApprovalConfig) {
        return this.getApprovalStatus(
            {
                reasonRequiredForDecline: config.reasonRequiredForDecline,
                ignoreSubmitted: false
        });
    }

    private getApprovalStatus(options: StatusOptions | null = null) {
        if (!options) {
            options = new StatusOptions();
        }
        if (!this.approvedOn && !this.declinedOn) {
            return ApprovalStatus.Pending;
        } else if (!options.ignoreSubmitted && this.submittedOn) {
            return ApprovalStatus.Submitted;
        } else if (this.approvedOn) {
            return ApprovalStatus.Approved;
        } else if (this.declinedOn) {
            if (options.reasonRequiredForDecline && StringUtils.isNullOrWhitespace(this.declinedReason)) {
                return ApprovalStatus.DeclinedPending;
            } else {
                return ApprovalStatus.Declined;
            }
        }
        return ApprovalStatus.Pending;
    }

    public getIcon(options: StatusOptions | null = null) {
        switch (this.getApprovalStatus(options)) {
            case ApprovalStatus.Pending:
                return "hand-pointer";
            case ApprovalStatus.Approved:
                return "thumbs-up";
            case ApprovalStatus.Declined:
            case ApprovalStatus.DeclinedPending:
                return "thumbs-down";
            case ApprovalStatus.Submitted:
                return "check";
        };
        return "";
    }

    public getVariant(options: StatusOptions | null = null): Components.variant {
        switch (this.getApprovalStatus(options)) {
            case ApprovalStatus.Pending:
                return "primary";
            case ApprovalStatus.Approved:
                return "success";
            case ApprovalStatus.Declined:
                return "dark"
            case ApprovalStatus.DeclinedPending:
                return "danger";
            case ApprovalStatus.Submitted:
                return "success";
        };
        return "info";
    }

    public changeApproval() {
        if (!this.submittedOn) {
            this.priorStatus = this.approvalStatus;
            switch (this.approvalStatus) {
                case ApprovalStatus.Pending:
                    this.approve();
                    break;
                case ApprovalStatus.Approved:
                    this.decline();
                    break;
                case ApprovalStatus.Declined:
                case ApprovalStatus.DeclinedPending:
                    this.clear();
                    break
            }
        }
    }

    public approve() {
        this.approvedOn = new Date();
        this.declinedOn = null;
    }

    public decline() {
        this.approvedOn = null;
        this.declinedOn = new Date();
    }

    public clear() {
        this.approvedOn = null;
        this.declinedOn = null;
    }

    public submit(config: AwardPrepApprovalConfig) {
        if (this.readyToSubmit(config)) {
            this.submittedOn = new Date();
        }
    }

    public readyToSubmit(config: AwardPrepApprovalConfig) {
        var status = this.getRealApprovalStatus(config);
        return [ApprovalStatus.Approved, ApprovalStatus.Declined].indexOf(status) > -1;
    }

    public cannotSubmitReason(config: AwardPrepApprovalConfig) {
        if (this.readyToSubmit(config)) {
            return "";
        } else {
            var status = this.getRealApprovalStatus(config);
            if (status === ApprovalStatus.DeclinedPending) {
                return "Declined awards require a declined reason.";
            } else if (status === ApprovalStatus.Submitted) {
                return ApprovalLookup.alreadySubmittedText;
            } else {
                return "Approvals must be approved or declined.";
            }
        }
    }

    public static alreadySubmittedText = "Already Submitted.";
}