import React from 'react';
import { observer } from 'mobx-react';

import QuestionnaireAnsweredLookup from '../../../../../Models/Questionnaires/Queries/QuestionnaireAnsweredLookup';
import QuestionComponent from './QuestionComponent';

interface IQuestion {
    questionnaireAnswered: QuestionnaireAnsweredLookup | null;
    disabled: boolean
}

@observer
export default class QuestionsComponent extends React.Component<IQuestion> {

    public render() {
        const answers = this.props.questionnaireAnswered!.answers.sortBy("questionOrder");
        return (
            <div>
                {answers.map((answer, index) => <QuestionComponent key={answer.entityIdentifier}
                    questionNumber={index + 1}
                    questionAnswer={answer}
                    questionAnswered={this.props.questionnaireAnswered}
                    disabled={this.props.disabled} />)}
            </div>
        )
    }
}
