import { Attributes, EnumHelper, ModelBase, NeoModel } from '@singularsystems/neo-core';
import { IncentiveGroupLookup } from '../../../../Awards/Common/CommonSharedExports';
import { TradeType } from '../Trading/TradeType';
import CalculationGroup from './Calculation/CalculationGroup';
import CalculationTranche from './Calculation/CalculationTranche';
import { IPortfolioGrouping, portfolioGroupings, PortfolioGroupType } from './Calculation/GroupHelper';
import GroupLink from './Calculation/GroupLink';
import { TrancheLink } from './Calculation/TrancheLink';
import { GroupMode } from './PortfolioBalanceLookup';

export enum VestingType {
    AllAwards = 1,
    VestedOnly = 2,
    UnvestedOnly = 3,
}

EnumHelper.decorateEnum(VestingType, d => {
    d.describe(VestingType.AllAwards, "All awards");
    d.describe(VestingType.VestedOnly, "All vested awards");
    d.describe(VestingType.UnvestedOnly, "All unvested awards");
});

export interface ITradeTypeRules {
    allowSell: boolean;
    allowBuy: boolean;
    allowSellToCover: boolean;
}

export interface ITradeQuantitySummary {
    sellQuantity: number;
    buyQuantity: number;
    isSellToBuy: boolean;
    availableBalance: number;
}

@NeoModel
export default class IncentiveGroup extends ModelBase {

    private _groupType: PortfolioGroupType = PortfolioGroupType.ByAward;
    private groupMode: GroupMode = GroupMode.portfolio;

    constructor(public info: IncentiveGroupLookup) {
        super();
    }

    /** Unfiltered tranche balances */
    public allTrancheBalances: CalculationTranche[] = [];

    /** Tranche balances currently being used. Will be the same as allTrancheBalances in portfolio mode, only tranches with available balance in calculator mode. */
    public currentTrancheBalances: CalculationTranche[] = [];

    @Attributes.OnChanged<IncentiveGroup>(c => c.portfolioReGroup)
    public vestingType: VestingType = VestingType.AllAwards;

    /** Tranche balance list filtered by vesting type. */
    public get filteredTrancheBalanceList() {
        return this.vestingType === VestingType.AllAwards ?
            this.currentTrancheBalances :
            this.currentTrancheBalances.filter(c => c.trancheBalance.isVested === (this.vestingType === VestingType.VestedOnly));
    }

    public get groupType() {
        return this._groupType;
    }
    public set groupType(value: PortfolioGroupType) {
        // TODO: If grouping type is allowed to change, reset trade types etc on each tranche to avoid a mixture of types in the new groupings.
        //this._groupType = value;
        throw Error("Changes to Group type not currently supported.");
    }

    public get flattened() {
        return this.groupedList.reduce((p, c) => p.concat(...(c.groupLink?.linkedGroups ?? [c])), [] as CalculationGroup[]);
    }

    public get hasDetail() {
        return this.groupedList.length > 0;
    }

    public groupedList: CalculationGroup[] = [];

    /** Gets the grouped balances based on the groupType property value. */
    private getGroupedList(tranches: CalculationTranche[], mode: GroupMode, groupType: PortfolioGroupType = this.groupType) {
        const grouping: IPortfolioGrouping = portfolioGroupings[groupType];

        let groupList = tranches.groupBy(c => grouping.getId(c.trancheBalance) + c.trancheBalance.getGroupSeparator(mode), (key, item, details) => {
            const group = new CalculationGroup(item.trancheBalance);
            group.groupKey = key;
            group.groupName = grouping.getName(item.trancheBalance);
            group.totalValue = details.sum(c => c.trancheBalance.currentValue);
            group.details = details;
            group.finalise();
            return group;
        });

        // This must be outside the below if statement, since it sets up the linked groups.
        const parentsOnly = groupList.sortBy(c => c.details[0].trancheBalance.awardNumber).groupBy(c => c.trancheBalance.awardLinkId ?? ("G" + c.groupKey), (k, group, groups) => {
            if (group.trancheBalance.awardLinkId) {
                const groupLink = new GroupLink(group.trancheBalance.awardLinkId, group.trancheBalance.awardLinkDescription);
                groupLink.linkedGroups = groups;
                groups.forEach(c => c.setGroupLink(groupLink));
            }
            return group;
        });

        if (mode === GroupMode.portfolio) {
            return grouping.sort(groupList);
        } else {
            // Try sort calculator and trades the same even though they are grouped differently.
            return parentsOnly.sortBy(c => c.details[0].trancheBalance.awardDate);
        }
    }

    public finalise() {
        this.currentTrancheBalances = this.allTrancheBalances;
        this.setupTrancheLinks();
        this.portfolioReGroup();
    }

    public hasSelectedGroups() {
        return this.groupedList.find(c => c.isSelected) !== undefined;
    }

    public hasMultipleInstruments() {
        return this.groupedList.some((outer, outerIndex) =>
            this.groupedList.some((inner, innerIndex) =>
                outerIndex !== innerIndex &&
                outer.instrument.instrumentCode !== inner.instrument.instrumentCode
            ));
    }

    public enterCalculationMode(forTrading: boolean) {
        this.currentTrancheBalances = forTrading ? this.getTranchesOfSelectedGroups() : this.getTranchesWithBalance();
        this.groupMode = forTrading ? GroupMode.tradeCalculator : GroupMode.portfolioCalculator;
        this.groupedList = this.getGroupedList(this.currentTrancheBalances, this.groupMode);
        for (let group of this.groupedList) {
            if (group.trancheBalance.incentiveScheme.allowSell) {
                group.setTradeType(TradeType.Sell);
            } else if (group.trancheBalance.incentiveScheme.allowBuy) {
                group.setTradeType(TradeType.Buy);
            }
        }
    }

    public clearCalculationMode() {
        this.currentTrancheBalances = this.allTrancheBalances;
        this.groupMode = GroupMode.portfolio;
        this.portfolioReGroup();
    }

    private portfolioReGroup() {
        this.groupedList = this.getGroupedList(this.filteredTrancheBalanceList, this.groupMode);
    }

    public getTranchesOfSelectedGroups() {
        let selectedTranches: CalculationTranche[] = [];

        for (let group of this.groupedList) {
            if (group.isSelected) {
                selectedTranches.push(...group.details.filter(c => c.availableBalance > 0 && c.trancheBalance.isVested));
            }
        }

        return selectedTranches;
    }

    public getTranchesWithBalance() {
        return this.allTrancheBalances.filter(c => c.availableBalance > 0);
    }

    public removeGroup(group: CalculationGroup) {
        if (this.groupedList.indexOf(group) >= 0) {
            this.groupedList.remove(group);
            for (let tranche of group.details) {
                this.currentTrancheBalances.remove(tranche);
            }
            for (let linked of group.linkedGroups) {
                this.removeGroup(linked);
            }
        }
    }

    /** Gets the tranches with trade quantity selected, grouped by scheme. */
    public getGroupsToTrade() {
        const tranchesToTrade = this.flattened.reduce((p, c) => p.concat(c.details.filter(c => c.hasQuantity && c.canTrade)), [] as CalculationTranche[]);
        return this.getGroupedList(tranchesToTrade, GroupMode.trade, PortfolioGroupType.ByScheme);
    }

    private setupTrancheLinks() {
        this.allTrancheBalances.filter(c => c.trancheBalance.awardLinkId !== null)
            .groupBy(t => t.trancheBalance.awardLinkId!.toString() + t.trancheBalance.vestingDate.getTime().toString(), (key: string, item: CalculationTranche, details: CalculationTranche[]) => {
                const trancheLink = new TrancheLink();
                trancheLink.awardLinkId = item.trancheBalance.awardLinkId!;
                trancheLink.awardLinkDescription = item.trancheBalance.awardLinkDescription;
                trancheLink.vestingDate = item.trancheBalance.vestingDate;
                trancheLink.linkedTranches = details;

                for (let tranche of details) {
                    tranche.trancheLink = trancheLink;
                    tranche.linkedTranches = details.filter(c => c !== tranche);
                }
            });
    }
}