import { NeoModel, Validation } from '@singularsystems/neo-core';
import ChangeApprovalsCommandBase from './ChangeApprovalsCommandBase';

@NeoModel
export default class ClearApprovalsCommand extends ChangeApprovalsCommandBase {

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew) {
            return "New clear approvals command";
        } else {
            return "Clear Approvals Command";
        }
    }
}