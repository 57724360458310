import { AppServices, Routing } from '@singularsystems/neo-core';

import { Types } from './TransactionsTypes';
import PortfolioApiClient from './ApiClients/PortfolioApiClient';
import FeesApiClient from './ApiClients/FeesApiClient';
import TransactionsDataCache from './Services/TransactionsDataCache';
import TradeApiClient from './ApiClients/TradeApiClient';
import PortfolioView from './Views/Portfolio/PortfolioView';
import TradeRequestView from './Views/TradeRequestView';
import PortfolioService from './Services/PortfolioService';
import PricingApiClient from './ApiClients/PricingApiClient';
import TradeHistoryView from './Views/TradeHistoryView';
import BrokerDetailsView from './Views/BrokerDetailsView';
import ExchangeRateService from './Services/ExchangeRateService';
import PricingService from './Services/PricingService';

// Modules
const transactionsAppModule = new AppServices.Module("Transactions", container => {
    const TransactionsTypes = Types.Transactions;

    // Api Clients
    container.bind(TransactionsTypes.ApiClients.FeesApiClient).to(FeesApiClient).inSingletonScope();
    container.bind(TransactionsTypes.ApiClients.PortfolioApiClient).to(PortfolioApiClient).inSingletonScope();
    container.bind(TransactionsTypes.ApiClients.PricingApiClient).to(PricingApiClient).inSingletonScope();
    container.bind(TransactionsTypes.ApiClients.TradeApiClient).to(TradeApiClient).inSingletonScope();

    // Services
    container.bind(TransactionsTypes.Services.DataCache).to(TransactionsDataCache).inSingletonScope();
    container.bind(TransactionsTypes.Services.PortfolioService).to(PortfolioService).inSingletonScope();
    container.bind(TransactionsTypes.Services.ExchangeRateService).to(ExchangeRateService).inSingletonScope();
    container.bind(TransactionsTypes.Services.PricingService).to(PricingService).inSingletonScope();
});

// Test 
const transactionsTestModule = new AppServices.Module("Transactions", container => {
    // bind test types
});

// Routing
const TradeRequestViewRoute = { path: "/tradeRequest", component: TradeRequestView };
const PortfolioViewRoute = { path: "/portfolio", component: PortfolioView };
const TradeHistoryRoute = { path: "/tradehistory", component: TradeHistoryView, routeChildren: [TradeRequestViewRoute] };
const BrokerDetailsRoute = { path: "/brokerDetails", component: BrokerDetailsView };
const PureRoutes = [TradeRequestViewRoute] as Routing.IRoute[];

export { 
    transactionsAppModule,
    transactionsTestModule,
    PortfolioViewRoute,
    TradeHistoryRoute,
    BrokerDetailsRoute,
    PureRoutes 
}