import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../ClientsTypes';
import MainCurrency from '../Models/MainCurrency';
import MainCurrencyLookup from '../Models/MainCurrencyLookup';

export interface IMainCurrenciesApiClient {

    /**
     * Will get the list of main currencies for the tenant.
     * @returns The list of main currencies.
     */
    getMainCurrencies(): AxiosPromise<Array<Model.PlainTrackedObject<MainCurrency>>>;

    /**
     * Will get the list of main currencies for the provided tenantId.
     * @param tenantId The tenant id.
     * @returns The list of main currencies.
     */
    getMainCurrenciesForTenant(tenantId: number): AxiosPromise<Array<Model.PlainTrackedObject<MainCurrency>>>;

    /** 
     * Will get the default currency for the current tenant.
     * @returns The default currency for the current tenant.
     */
    getDefaultCurrency(): AxiosPromise<Model.PlainObject<MainCurrencyLookup>>;

    /**
     * Will update the main currencies with the items provided.
     * @param mainCurrencies The update list of main currencies.
     * @returns A task awaiting the update operation.
     */
    updateMainCurrencies(mainCurrencies: Array<Model.PartialPlainObject<MainCurrency>>): AxiosPromise<Array<Model.PlainTrackedObject<MainCurrency>>>;

    /**
     * Will update the main currencies with the items provided.
     * @param tenantId The tenant id to update with
     * @param mainCurrencies The update list of main currencies.
     * @returns A task awaiting the update operation.
     */
    updateMainCurrenciesForTenant(tenantId: number, mainCurrencies: Array<Model.PartialPlainObject<MainCurrency>>): AxiosPromise<Array<Model.PlainTrackedObject<MainCurrency>>>;

    // Client only properties / methods
}

@injectable()
export default class MainCurrenciesApiClient extends Data.ApiClientBase implements IMainCurrenciesApiClient {

    constructor (config = AppService.get(Types.Shared.Config)) {
        super(`${config.ClientsApi.ApiPath}/main-currencies`);
    }

    public getMainCurrencies(): AxiosPromise<Array<Model.PlainTrackedObject<MainCurrency>>> {
        return this.axios.get(`${this.apiPath}`);
    }

    public getMainCurrenciesForTenant(tenantId: number): AxiosPromise<Array<Model.PlainTrackedObject<MainCurrency>>> {
        return this.axios.get(`${this.apiPath}/for-tenant/${tenantId}`);
    }

    public getDefaultCurrency(): AxiosPromise<Model.PlainObject<MainCurrencyLookup>> {
        return this.axios.get(`${this.apiPath}/default`);
    }

    public updateMainCurrencies(mainCurrencies: Array<Model.PartialPlainObject<MainCurrency>>): AxiosPromise<Array<Model.PlainTrackedObject<MainCurrency>>> {
        return this.axios.post(`${this.apiPath}`, mainCurrencies);
    }

    public updateMainCurrenciesForTenant(tenantId: number, mainCurrencies: Array<Model.PartialPlainObject<MainCurrency>>): AxiosPromise<Array<Model.PlainTrackedObject<MainCurrency>>> {
        return this.axios.post(`${this.apiPath}/for-tenant/${tenantId}?tenantId=${tenantId}`, mainCurrencies);
    }

    // Client only properties / methods
}