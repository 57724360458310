import { ModelBase, NeoModel } from '@singularsystems/neo-core';
import CalculationGroup from './CalculationGroup';

@NeoModel
export default class GroupLink extends ModelBase {

    constructor(public awardLinkId: number, public awardLinkDescription: string) {
        super();
    }

    public linkedGroups: CalculationGroup[] = [];

    public isSelected: boolean = false;

    public isHovered: boolean = false;

    public setQuantityOnLinkedGroups(group: CalculationGroup) {
        if (group.availableBalance > 0) {
            let sellRatio = group.sellQuantity / group.availableBalance;
            let buyRatio = group.buyQuantity / group.availableBalance;
    
            for (let linkedGroup of this.linkedGroups) {
                if (linkedGroup !== group) {
                    linkedGroup.setSellQuantity(Math.round(sellRatio * linkedGroup.availableBalance));
                    linkedGroup.setBuyQuantity(Math.round(buyRatio * linkedGroup.availableBalance));
                }
            }
        }  
    }
}