import { Attributes, LookupBase, NeoModel } from '@singularsystems/neo-core';

@NeoModel
export default class FusionImportLookup extends LookupBase {

    public fusionImportId: number = 0;

    @Attributes.Date()
    public createdOn: Date = new Date();

    @Attributes.Integer()
    public totalCount: number = 0;

    @Attributes.Integer()
    public unModifiedCount: number = 0;

    @Attributes.Integer()
    public insertedCount: number = 0;

    @Attributes.Integer()
    public updatedCount: number = 0;

    // Client only properties / methods
}