import React from 'react';
import { Neo, Views } from '@singularsystems/neo-react';
import { observer } from 'mobx-react';
import ParticipantsAppUserStatus from './ParticipantsAppUserStatus';
import { AppService, AppTypes } from '../../Services/AppService';
import MainMenu from './MainMenu';
import BreadCrumb from './BreadCrumb';
import { IParticipantsAppView } from './IParticipantsAppView';
import { PlatformImageName } from '../../../Clients/Common/Models/Enums/PlatformImageName';

@observer
export default class HeaderPanel extends React.Component {

    private appLayout = AppService.get(AppTypes.Shared.Services.AppLayout);
    private styleService = AppService.get(AppTypes.Clients.Services.StyleService);
    private currentView: IParticipantsAppView | null = null;

    public render() {
        this.currentView = Views.ViewBase.currentView as unknown as IParticipantsAppView;

        const headerLogoImage = this.styleService.getImageUrl(PlatformImageName.ParticipantsAppHeader),
            headerBackgroundImage = this.styleService.getImageUrl(PlatformImageName.ParticipantsAppSubHeader);

        const showHeaderText = this.styleService.getStyleValue("suppress-header-text") === "false";

        let showBreadcrumb = false;
        if (this.currentView && this.currentView.showBreadcrumb !== undefined) {
            showBreadcrumb = this.currentView.showBreadcrumb();
        }

        const layout = this.appLayout;
        const globalTask = Views.ViewBase.currentView ?
            Views.ViewBase.currentView.taskRunner :
            AppService.get(AppTypes.Neo.TaskRunner);

        let headerClassName = "app-header-panel";

        if (!layout.isScrollTop) {
            headerClassName += " scrolled";
        }

        return (
            <div className={headerClassName} id="header-panel">

                {globalTask && globalTask.isBusy &&
                    <Neo.ProgressBar className="page-progress" progressState={globalTask.progressState} variant={globalTask.options.variant} />}

                <div className="app-header-main container">

                    <div style={{display: "flex", alignItems: "flex-end"}}>
                        
                        <div id="menu-anchor" className="app-hamburger-container" onClick={() => this.appLayout.toggleMenu()}>
                            <div className="app-hamburger">
                                <i className="fa fa-bars"></i>
                            </div>
                        </div>
                        <img src={headerLogoImage} alt="logo" className="logo-image"/>

                        <MainMenu />
                    </div>
        
                    <div>
                        {/* <ConnectionStatus /> */}
                        <ParticipantsAppUserStatus taskRunner={globalTask} />
                    </div>

                </div>
                <div className="app-header-sub container" style={showHeaderText ? { backgroundImage: `url("${headerBackgroundImage}")`} : undefined}>
                    {!showHeaderText && <img src={headerBackgroundImage} alt="header" style={{width: "100%"}} />}
                    {showHeaderText &&
                        <span className="page-title">
                            {this.currentView?.headerTitle ?? "Awards Portal"} 
                        </span>}
                </div>

                {showBreadcrumb &&
                <div className="app-breadcrumb container">
                    <BreadCrumb rootItem={{ label: "Home", link: "/" }} /> {" "}
                </div>}

            </div>
        )
    }
}