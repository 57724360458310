import React from 'react';
import { observer } from 'mobx-react';
import { Data, NeoModel } from '@singularsystems/neo-core';

import { AppService, Types } from '../../../AwardsTypes';

import MyAwardLookupCriteria from '../../../Models/ParticipantOffers/Queries/MyAwards/MyAwardLookupCriteria';
import MyAwardLookup from '../../../Models/ParticipantOffers/Queries/MyAwards/MyAwardLookup';
import MyAwardsVMBase from '../MyAwardsVMBase';
import MyAwardsGrid from './MyAwardsGrid';
import MyAwardLookupIncludeOptions from '../../../Models/ParticipantOffers/Queries/MyAwards/MyAwardLookupIncludeOptions';
import MyAwardDocumentLookup from '../../../Models/ParticipantOffers/Queries/MyAwards/MyAwardDocumentLookup';

interface IMyAwardAcceptSuccessComponentProps {
    viewModel: MyAwardAcceptSuccessComponentVM;
    viewQuestionnaireHandler: (item: MyAwardLookup) => void;
    viewDocumentHandler: (award: MyAwardLookup, doc: MyAwardDocumentLookup) => void;
    viewEntityHandler: (item: MyAwardLookup) => void;
}

@NeoModel
export class MyAwardAcceptSuccessComponentVM extends MyAwardsVMBase {

    constructor(
        private baseSetupData: (data: MyAwardLookup[]) => void,
        taskRunner = AppService.get(Types.Neo.TaskRunner)) {

        super(taskRunner);
    }

    private criteria = new MyAwardLookupCriteria();

    public pageManager = new Data.PageManager(this.criteria, MyAwardLookup, (request) => this.myAwardsApiClient.getAcceptedAwards(request, this.acceptedAwardsIncludes), {
        pageSize: 100,
        initialTaskRunner: this.taskRunner,
        sortBy: "awardDate",
        sortAscending: true,
        afterFetch: (data) => this.baseSetupData(data),
    });

    protected get acceptedAwardsIncludes() {
        return { includeDocuments: true, includeQuestionnaires: true, includeAwardConfig: false } as MyAwardLookupIncludeOptions;
    }

    private initialised = false;

    public async setupAwards(participantOfferIds: number[]) {
        if (!this.initialised) {
            await this.initialise();
            this.initialised = true;
        }

        // need to load the full list of this participant offer, just in case the offer is split over multiple pages
        this.criteria.participantOfferIds = participantOfferIds.filter((x, y) => participantOfferIds.indexOf(x) === y);
        await this.pageManager.refreshData();
    }
}

@observer
export default class MyAwardAcceptSuccessComponent extends React.Component<IMyAwardAcceptSuccessComponentProps> {
    public render() {
        const viewModel = this.props.viewModel;
        return (<div>
            <div className="page-block-alt p-3">
                <h2>Success</h2>
            </div>
            <div className="page-block p-3">
                <MyAwardsGrid
                    pageManager={viewModel.pageManager}
                    config={viewModel.awardModuleConfig.myAwards.grid} 
                    viewModel={viewModel}
                    hideFaceValue={!viewModel.pageManager.data.some(c => c.useExpectedValue)}
                    showSelection={false}
                    allowSort={false}
                    showQuestionnaireButton={true}
                    viewQuestionnaireHandler={this.props.viewQuestionnaireHandler}
                    viewDocumentHandler={this.props.viewDocumentHandler}
                    viewEntityHandler={this.props.viewEntityHandler}
                    showAllDocuments={true}
                    showEntityButton={false} />
            </div>
        </div>);
    }
}