import { Attributes, ModelBase, NeoModel, Validation } from '@singularsystems/neo-core';

@NeoModel
export default class AllocationManagerIncentiveGroupBudget extends ModelBase {

    public allocationManagerIncentiveGroupBudgetId: number = 0;

    public allocationManagerId: number = 0;

    public incentiveGroupId: number = 0;

    @Attributes.Float()
    public budgetAmount: number = 0;

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew) {
            return "New allocation manager incentive group budget";
        } else {
            return "Allocation Manager Incentive Group Budget";
        }
    }
}