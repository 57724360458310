import { List, ModelBase, NeoModel, Validation } from '@singularsystems/neo-core';
import UpdateParticipantCommentCommand from './UpdateParticipantCommentCommand';

@NeoModel
export default class UpdateParticipantAllocationCommentCommand extends ModelBase {

    public allocationParticipantId: number = 0;

    public participantComments = new List(UpdateParticipantCommentCommand);

    // Client only properties / methods

    public commentRequired: boolean = false;

    public overTheMaxCommentRequired: boolean = false;

    public overTheMaxCommentTypeId: number = 0;

    public static commentRequiredRule: Validation.IRule | null = null;

    public static overTheMaxCommentRequiredRule: Validation.IRule | null = null;

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);

        UpdateParticipantAllocationCommentCommand.commentRequiredRule = rules.add(this.checkCommentRequired);
        UpdateParticipantAllocationCommentCommand.overTheMaxCommentRequiredRule = rules.add(this.checkOverTheMaxCommentRequired);
    }

    private checkCommentRequired(context: Validation.IRuleContext) {
        if (this.commentRequired && this.participantComments.length === 0) { context.addError(`Comment required for short round allocation`) }
        else if (this.commentRequired && this.participantComments.length > 0){
            if(this.participantComments[0].allocationCommentTypeId === 0 || this.participantComments[0].allocationCommentTypeOptionId === 0){
                context.addError(`Comment required for short round allocation`)
            }
        }
    }

    private checkOverTheMaxCommentRequired(context: Validation.IRuleContext) {
        if (this.overTheMaxCommentRequired && this.participantComments.length === 0) { context.addError(`Over The Max Comment Required`) }
        else if (this.overTheMaxCommentRequired && this.participantComments.length > 0){
            let overTheMaxComment = false;
            this.participantComments.forEach(pc => {
                if (pc.allocationCommentTypeId === this.overTheMaxCommentTypeId) {
                    overTheMaxComment = true;
                }
            });
            if (!overTheMaxComment) {
                context.addError(`Over The Max Comment Required`)
            }
        }
    }

    public toString(): string {
        if (this.isNew) {
            return "New update participant allocation comment command";
        } else {
            return "Update Participant Allocation Comment Command";
        }
    }
}