import { List } from "@singularsystems/neo-core";
import { injectable } from "inversify";
import { AppService, Types } from '../TransactionsTypes';

import { InstrumentPriceLookup } from "../TransactionsExports";

export interface IPricingService {
    loadedPrices: List<InstrumentPriceLookup>;
    loadPrices: (instrumentCodes: string[]) => Promise<void>
}

@injectable()
export default class PricingService implements IPricingService {
    
    constructor(private pricingApiClient = AppService.get(Types.Transactions.ApiClients.PricingApiClient)) {
    }

    public loadedPrices = new List(InstrumentPriceLookup);

    /* 
     * Will load prices for the list provided if they do not exist in loadedPrices.
     */
    public async loadPrices(instrumentCodes: string[]) {
        for (let i = instrumentCodes.length - 1; i >= 0; i--) {
            if (this.loadedPrices.find(price => price.instrumentCode === instrumentCodes[i])) {
                instrumentCodes.remove(instrumentCodes[i]);
            }
        }

        if (instrumentCodes.length > 0) {
            const pricesResult = await this.pricingApiClient.getPrices(instrumentCodes);
            this.loadedPrices.update(pricesResult.data);
        }
    }
}