import { Attributes, Misc, ModelBase, NeoModel, Rules } from '@singularsystems/neo-core';

@NeoModel
export default class AllocationSchemeSplitLookup extends ModelBase {

    public allocationSchemeSplitId: number = 0;

    public allocationParticipantId: number = 0;

    @Attributes.Serialisation(Misc.SerialiseType.NotNull)
    public incentiveSchemeId: number | null = null;

    public incentiveSchemeName: string = "";

    @Attributes.Float()
    public percentageSplit: number = 0;

    @Attributes.Float()
    public expectedValuePercentage: number = 0;

    @Rules.StringLength(250)
    public description: string = "";

    public readableSchemeName: string = "";

    // Client only properties / methods
}