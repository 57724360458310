import { Data, NeoModel } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import TradeRequestCriteria from '../Models/Trading/Criteria/TradeRequestCriteria';
import TradeRequestLookup from '../Models/Trading/Lookups/TradeRequestLookup';
import { AppService, Types } from '../TransactionsTypes';
import { PendingTradeRequestsComponentModel } from './Components/TradeRequests/PendingTradeRequestsComponentModel';

@NeoModel
export default class TradeHistoryVM extends Views.ViewModelBase {

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        private portfolioService = AppService.get(Types.Transactions.Services.PortfolioService),
        private apiClient = AppService.get(Types.Transactions.ApiClients.PortfolioApiClient),
        public awardsDataCache = AppService.get(Types.Awards.Services.AwardsDataCache),) {

        super(taskRunner);

        this.criteria.activeTrades = false;

        this.autoDispose(this.criteria.onAnyPropertyChanged(() => this.pageManager.refreshData()));
    }

    public criteria = new TradeRequestCriteria();
    public pageManager = new Data.PageManager(this.criteria, TradeRequestLookup, this.apiClient.getTradeRequestsPagedAsync, { 
        initialTaskRunner: this.taskRunner,
        pageSize: 10, 
        sortBy: "tradeRequestId",
        sortAscending: false,
        afterFetch: this.afterFetch.bind(this) 
    });

    public tradeRequestVM = new PendingTradeRequestsComponentModel({ taskRunner: this.taskRunner });

    public async initialise() {
        await this.portfolioService.ensureLoaded(this.taskRunner, { fetchPendingTrades: true });
        
        this.pageManager.refreshData();
    }

    public get showTradeDetailsButton() {
        return this.portfolioService.appData?.settings.showParticipantTradeDetailsButton;
    }

    private afterFetch(trades: TradeRequestLookup[]) {
        if (this.portfolioService.appData) {
            this.portfolioService.appData.populateTradeRequests(trades);
        }
    }
}