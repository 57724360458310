import { Attributes, LookupBase, NeoModel, Utils } from '@singularsystems/neo-core';

@NeoModel
export default class SecondmentLookup extends LookupBase {

    public participantId: string = Utils.emptyGuid();

    public countryCode: string = "";

    public countryName: string = "";

    public countryRegionCode: string = "";
    
    @Attributes.Date()
    public startDate: Date = new Date();

    @Attributes.Date()
    public endDate: Date | null = null;

    public countryId: number = 0;
    // Client only properties / methods
}