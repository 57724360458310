import { AppServices } from '@singularsystems/neo-core';
import { AppService, AppTypes } from '../../../App/Services/AppService';
import { IAllocationPlanInformationQueryApiClient } from './ApiClients/AllocationPlanInformationQueryApiClient';
import { IAllocationPlanQueryApiClient } from './ApiClients/AllocationPlanQueryApiClient';
import { IAllocationParticipantQueryApiClient } from './ApiClients/AllocationParticipantQueryApiClient';
import { IAllocationManagerQueryParticipantApiClient } from './ApiClients/AllocationManagerQueryParticipantApiClient';
import { IAllocationParticipantApiClient } from './ApiClients/AllocationParticipantApiClient';
import { IAllocationCommentTypeApiClient } from './ApiClients/AllocationCommentTypeApiClient';
import { AllocationManagerSharedTypes } from './AllocationManagerSharedTypes';
import { IAllocationManagerApiClient } from './ApiClients/AllocationManagerApiClient';
import { IAllocationReportingApiClient } from './ApiClients/AllocationReportingApiClient';
import { IGridColumnConfigApiClient } from './ApiClients/GridColumnConfigApiClient';

const Types = {
    ...AppTypes,
    AllocationManager: {
        ApiClients: {
            AllocationPlanQueryApiClient: new AppServices.ServiceIdentifier<IAllocationPlanQueryApiClient>("AllocationManager.AllocationPlanQueryApiClient"),
            AllocationPlanInformationQueryApiClient: new AppServices.ServiceIdentifier<IAllocationPlanInformationQueryApiClient>("AllocationManager.AllocationPlanInformationQueryApiClient"),
            AllocationParticipantQueryApiClient: new AppServices.ServiceIdentifier<IAllocationParticipantQueryApiClient>("AllocationManager.AllocationParticipantQueryApiClient"),
            AllocationParticipantApiClient: new AppServices.ServiceIdentifier<IAllocationParticipantApiClient>("AllocationManager.AllocationParticipantApiClient"),
            AllocationManagerQueryApiClient: new AppServices.ServiceIdentifier<IAllocationManagerQueryParticipantApiClient>("AllocationManager.AllocationManagerQueryApiClient"), 
            AllocationCommentTypeApiClient: new AppServices.ServiceIdentifier<IAllocationCommentTypeApiClient>("AllocationManager.AllocationCommentTypeApiClient"),
            AllocationManagerApiClient: new AppServices.ServiceIdentifier<IAllocationManagerApiClient>("AllocationManager.AllocationManagerApiClient"),
            AllocationReportingApiClient: new AppServices.ServiceIdentifier<IAllocationReportingApiClient>("AllocationManager.AllocationReportingApiClient"), 
            GridColumnConfigApiClient: new AppServices.ServiceIdentifier<IGridColumnConfigApiClient>("AllocationManager.GridColumnConfigApiClient"), 
        },
        Services: {
            AllocationManagerService: AllocationManagerSharedTypes.Services.AllocationManagerService,
        }
    }
}

export { AppService, Types };