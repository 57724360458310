import { Attributes, Misc, NeoModel, ValueObject } from '@singularsystems/neo-core';


@NeoModel
export class ExchangeRateLookupHistoryCriteriaDetail extends ValueObject {

    public fromCode = "";

    public toCode = "";
    
    @Attributes.Date(Misc.TimeZoneFormat.None)
    public rateDate = new Date();
}
