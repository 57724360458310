import React from 'react';
import { observer } from 'mobx-react';

import AwardsGridBase, { IAwardsGridBaseProps } from '../../Components/AwardsGridBase';
import { Misc, ValueObject } from '@singularsystems/neo-core';
import MyAwardLookup from '../../../Models/ParticipantOffers/Queries/MyAwards/MyAwardLookup';
import MyAwardsVMBase from '../MyAwardsVMBase';
import { NeoGrid } from '@singularsystems/neo-react';
import MyAwardDocumentLookup from '../../../Models/ParticipantOffers/Queries/MyAwards/MyAwardDocumentLookup';
import { OptionSelectionType } from '../../../../Common/Models/Enums/OptionSelectionType';
import { ScreenSize } from '../../../../../App/Services/AppLayout';

interface IMyAwardsSelectionGridProps extends IAwardsGridBaseProps<MyAwardsVMBase, ValueObject, MyAwardLookup> {
    viewQuestionnaireHandler?: (item: MyAwardLookup) => void;
    viewSelectionHandler?: (item: MyAwardLookup) => void;
    viewDocumentHandler?: (document: MyAwardDocumentLookup) => void;
    viewEntityHandler?: (item: MyAwardLookup) => void;
}

@observer
export default class MyAwardsSelectionGrid extends AwardsGridBase<IMyAwardsSelectionGridProps, MyAwardsVMBase, ValueObject, MyAwardLookup> {

    protected leftColumns(item: MyAwardLookup): JSX.Element | undefined {
        return undefined;
    }

    protected rightColumns(item: MyAwardLookup): JSX.Element | undefined {
        return <>
            {(item.awardPrepId === 0 || item.selectionType !== OptionSelectionType.PartialAcceptOrDecline) &&
                <NeoGrid.Column
                    bind={item.meta.selectedPercent}
                    label={"Select\npercent"}
                    className="select-percent"
                    width={this.appLayout.currentScreenSize > ScreenSize.Small ? "18%" : "25%"}
                    numProps={{ format: Misc.NumberFormat.PercentNoDecimals }} />}
        </>;
    }
}