import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, AppTypes } from 'st-app/Services/AppService';
import IncentiveGroupLookup from '../Models/IncentiveSchemes/IncentiveGroupLookup';

export interface IIncentiveGroupsQueryApiClient {

    /** 
     * Gets the lookup list.
     * @returns Lookup list.
     */
    getLookupList(): AxiosPromise<Array<Model.PlainObject<IncentiveGroupLookup>>>;

    // Client only properties / methods
}

@injectable()
export default class IncentiveGroupsQueryApiClient extends Data.ApiClientBase implements IIncentiveGroupsQueryApiClient {

    constructor (config = AppService.get(AppTypes.Shared.Config)) {
        super(`${config.AwardsApi.ApiPath}/IncentiveGroupsQuery`);
    }

    public getLookupList(): AxiosPromise<Array<Model.PlainObject<IncentiveGroupLookup>>> {
        return this.axios.get(`${this.apiPath}/lookup-list`);
    }

    // Client only properties / methods
}