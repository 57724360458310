import { List, ModelBase, NeoModel } from '@singularsystems/neo-core';
import ParticipantAwardLookupBase from '../../../Common/Models/Base/ParticipantAwardLookupBase';
import MyAwardInfoLookup from '../../Models/ParticipantOffers/Queries/MyAwards/MyAwardInfoLookup';

@NeoModel
export class AwardSelector<TLookup extends ParticipantAwardLookupBase> extends ModelBase {

    public selectedAwards = new List(ParticipantAwardLookupBase);
    
    public unSelectedAwards = new List(ParticipantAwardLookupBase);

    public findSelected(participantOfferId: number) {
        return this.selectedAwards.find(c => c.participantOfferId === participantOfferId);
    }

    public getSelected(filterPredicate?: (item: TLookup) => boolean) {
        return this.selectedAwards.filter(c => c.isSelected && (!filterPredicate || filterPredicate(c as TLookup))) as TLookup[];
    }

    public inAllSelectedMode = false;

    private _allSelected = false;

    public get allSelected() {
        return this._allSelected;
    }

    public awardShouldBeSelected(award: TLookup, myAwardsInfoLookup: MyAwardInfoLookup) {
        if (this.inAllSelectedMode) {
            // should be selected unless in unselected list
            if (this.unSelectedAwards.find(a => a.participantOfferId === award.participantOfferId)) {
                return false;
            } else {
                return true;
            }
        }
        if (myAwardsInfoLookup.acceptTotalAwards === 1)
        {
            return true;
        }
        else {
            // should only be selected if in the selectedAwards
            return !!this.selectedAwards.find(a => a.participantOfferId === award.participantOfferId)
        }
    }

    public selectedCount(awardCount: number) {
        if (this.inAllSelectedMode) {
            // return total less unselected
            const participantOfferIds = this.unSelectedAwards.map(a => a.participantOfferId);
            return awardCount - participantOfferIds.filter((x, y) => participantOfferIds.indexOf(x) === y).length;
        } else {
            const participantOfferIds = this.selectedAwards.map(a => a.participantOfferId);
            return participantOfferIds.filter((x, y) => participantOfferIds.indexOf(x) === y).length;
        }
    }

    public reset() {
        this._allSelected = false;
        // set to false so that it is turned on when we Select All.
        this.inAllSelectedMode = false;
        this.selectedAwards = new List(ParticipantAwardLookupBase);
        this.unSelectedAwards = new List(ParticipantAwardLookupBase);
    }

    public resetToSelected(awards: TLookup[]) {
        this._allSelected = false;
        // set to false so that it is turned on when we Select All.
        this.inAllSelectedMode = false;
        this.selectAll(awards);
    }

    public selectItem(item: TLookup, activeData: TLookup[]) {
        item.isSelected = !item.isSelected;

        const linkedOthers = activeData.filter(c => c.participantOfferId === item.participantOfferId && c !== item);

        for (const linkedItem of linkedOthers) {
            linkedItem.isSelected = item.isSelected;
        }

        if (!this.inAllSelectedMode) {
            if (item.isSelected) {
                // need to record which are selected
                this.selectedAwards.push(item);
                for (const linkedItem of linkedOthers) {
                    this.selectedAwards.push(linkedItem);
                }
            } else {
                // need to clear any that are selected for this same approval
                for (let i = this.selectedAwards.length - 1; i >= 0; i--) {
                    if (this.selectedAwards[i].participantOfferId === item.participantOfferId) {
                        this.selectedAwards.remove(this.selectedAwards[i]);
                    }
                }
            }
        }
        else {
            // in all selected mode, so keep track of unselected
            if (!item.isSelected) {
                // need to record which are selected
                this.unSelectedAwards.push(item);
                for (const linkedItem of linkedOthers) {
                    this.unSelectedAwards.push(linkedItem);
                }
            } else {
                // need to clear any that are selected for this same approval
                for (let i = this.unSelectedAwards.length - 1; i >= 0; i--) {
                    if (this.unSelectedAwards[i].participantOfferId === item.participantOfferId) {
                        this.unSelectedAwards.remove(this.unSelectedAwards[i]);
                    }
                }
            }
        }

        if (this.inAllSelectedMode) {
            this._allSelected = this.unSelectedAwards.length === activeData.length;
        }
    }

    public selectAll(data: TLookup[]) {
        this.inAllSelectedMode = !this.inAllSelectedMode;

        this._allSelected = this.inAllSelectedMode;

        this.selectedAwards = new List(ParticipantAwardLookupBase);
        this.unSelectedAwards = new List(ParticipantAwardLookupBase);

        for (const item of data) {
            item.isSelected = this.inAllSelectedMode;

            if (this.allSelected) {
                this.selectedAwards.push(item);
            }
        }
    }
}
