import React from 'react';
import { Views, Neo, NeoGrid } from '@singularsystems/neo-react';
import { observer } from 'mobx-react';

import { AppService, Types } from '../../AwardsTypes';

import '../../Styles/approvals.scss';
import '../../../Common/Styles/CommonApprovals.scss';

import ApprovalSummariesComponent from './Components/ApprovalSummariesComponent';
import ApprovalHistoryComponent from '../../../Common/Views/Approvals/Components/ApprovalHistoryComponent';
import ApprovalAwardSummaryComponent from './Components/ApprovalAwardSummaryComponent';

import ApprovalsVM from './ApprovalsVM';
import { ApproverAwardApprovalSummaryLookup } from '../../../Common/Models/Approvals/Queries/ApproverApprovalSummaryLookup';
import ApprovalLookup from '../../../Common/Models/Approvals/Queries/ApprovalLookup';
import { ScreenSize } from '../../../../App/Services/AppLayout';
import { IButtonDropDownItem } from '@singularsystems/neo-core/dist/Components/ButtonOptions';
import Loader from '@singularsystems/neo-react/dist/ReactComponents/Loader';
import { ModalUtils, Misc, Components, Model } from '@singularsystems/neo-core';
import { ApprovalStatus } from '../../../Common/Models/Enums/ApprovalStatus';
import DeclineApprovalsCommand from '../../Models/Approvals/Commands/DeclineApprovalsCommand';
import ChangeApprovalsCommandBase from '../../Models/Approvals/Commands/ChangeApprovalsCommandBase';
import { NotificationDuration } from '../../../../App/Models/Enums/NotificationDuration';
import { Verbosity } from '../../../Common/Models/Enums/Verbosity';

class ApprovalsViewParams {
	public award = {};
}

interface IApprovalAction {
	status: ApprovalStatus;
	actionName: string;
	action: (command?: ChangeApprovalsCommandBase) => void;
}

@observer
export default class ApprovalsView extends Views.ViewBase<ApprovalsVM, ApprovalsViewParams> {

	public static params = new ApprovalsViewParams();

	private appLayout = AppService.get(Types.Shared.Services.AppLayout);
	private notifications = AppService.get(Types.Neo.UI.GlobalNotifications)
	private utils = AppService.get(Types.Awards.Services.AwardUtils)

	constructor(props: unknown) {
		super("Awards Release", ApprovalsVM, props);
		this.viewModel.awardLoadedCallback = this.setAwardViewParam.bind(this);
	}

	public async viewParamsUpdated() {
		if (this.viewParams.award.value) {
			const awardPrepId = this.viewParams.award.asNullableInt();
			if (awardPrepId && (!this.viewModel.approverAward || this.viewModel.approverAward.award.awardPrepId !== awardPrepId)) {
				await this.viewModel.loadAward(awardPrepId);
			}
		} else {
			this.viewModel.approverAward = null;
		}
	}

	private setAwardViewParam() {
		if (this.viewModel.approverAward && this.viewParams.award.value) {
			this.viewParams.award.description = this.viewModel.approverAward.toString();
		} else {
			this.viewParams.award.value = null;
			if (this.viewModel.changesMade) {
				this.viewModel.loadSummary();
			}
		}
	}

	protected async initialise() {
		await this.viewModel.initialise();
	}

	private selectAward(awardApproval: ApproverAwardApprovalSummaryLookup) {
		this.viewParams.award.value = awardApproval.award.awardPrepId;
	}

	private approveActions: IApprovalAction[] = [
		{ status: ApprovalStatus.Approved, actionName: "Approve Page", action: this.viewModel.approvePage.bind(this.viewModel) },
		{ status: ApprovalStatus.Approved, actionName: "Approve All", action: this.viewModel.approveAll.bind(this.viewModel) }];

	private declineActions: IApprovalAction[] = [
		{ status: ApprovalStatus.Declined, actionName: "Decline Page", action: this.viewModel.declinePage.bind(this.viewModel) },
		{ status: ApprovalStatus.Declined, actionName: "Decline All", action: this.viewModel.declineAll.bind(this.viewModel) }];

	private clearActions: IApprovalAction[] = [
		{ status: ApprovalStatus.Pending, actionName: "Clear Page", action: this.viewModel.clearPage.bind(this.viewModel) },
		{ status: ApprovalStatus.Pending, actionName: "Clear All", action: this.viewModel.clearAll.bind(this.viewModel) }];

	private submitActions: IApprovalAction[] = [
		{ status: ApprovalStatus.Submitted, actionName: "Submit Page", action: this.viewModel.submitPage.bind(this.viewModel) },
		{ status: ApprovalStatus.Submitted, actionName: "Submit All", action: this.viewModel.submitAll.bind(this.viewModel) }];

	private allApprovalActions: IApprovalAction[] =
		this.approveActions.concat(
			this.declineActions.concat(
				this.clearActions.concat(
					this.submitActions)));

	private getActionButtonVariant(status: ApprovalStatus, actionName: string): "primary" | "secondary" | "info" | "success" | "warning" | "danger" | "light" | "dark" {
		switch (status) {
			case ApprovalStatus.Pending:
				return "warning";
			case ApprovalStatus.Approved:
				return "success";
			case ApprovalStatus.Declined:
			case ApprovalStatus.DeclinedPending:
				return "danger";
			case ApprovalStatus.Submitted:
				return "primary";
		}
	}

	private getActionButton(action: IApprovalAction): React.ReactNode {
		return <Neo.Button key={action.actionName}
			icon={this.getIcon(action.status)}
			disabled={this.viewModel.pageManager.hasFetched !== true}
			isOutline
			variant={this.getActionButtonVariant(action.status, action.actionName)}
			onClick={() => this.confirmAction(action.actionName, action.action)}>
			{action.actionName}
		</Neo.Button>;
	}

	private getActionsControls() {

		return <div>
			{/* SMALLER SCREEN - 1 DROP DOWN BUTTON */}
			{this.appLayout.currentScreenSize < ScreenSize.Large &&
				<Neo.Button icon="tasks" isOutline
					disabled={!this.viewModel.actionButtonsEnabled}
					menuItems={this.getConfigMenuItems(this.allApprovalActions)}>
					Approve/Decline
				</Neo.Button>}
			{/* MEDIUM SCREEN - 4 DROP DOWN BUTTON */}
			{this.appLayout.currentScreenSize >= ScreenSize.Large &&
				<div>
					{!this.viewModel.approvalConfig.isNew && this.viewModel.approvalConfig.approversCanChangeStatus &&
						<div className="d-inline">
							<Neo.Button disabled={!this.viewModel.actionButtonsEnabled}
								icon={this.getIcon(ApprovalStatus.Approved)}
								variant={this.getActionButtonVariant(ApprovalStatus.Approved, "")} isOutline
								menuItems={this.getConfigMenuItems(this.approveActions)}>
								Approve
							</Neo.Button>
							<Neo.Button disabled={!this.viewModel.actionButtonsEnabled}
								icon={this.getIcon(ApprovalStatus.Declined)}
								variant={this.getActionButtonVariant(ApprovalStatus.Declined, "")} isOutline
								menuItems={this.getConfigMenuItems(this.declineActions)} >
								Decline
							</Neo.Button>
							<Neo.Button disabled={!this.viewModel.actionButtonsEnabled}
								icon={this.getIcon(ApprovalStatus.Pending)}
								variant={this.getActionButtonVariant(ApprovalStatus.Pending, "")} isOutline
								menuItems={this.getConfigMenuItems(this.clearActions)}>
								Clear
							</Neo.Button>
						</div>}
					<Neo.Button disabled={!this.viewModel.actionButtonsEnabled}
						icon={this.getIcon(ApprovalStatus.Submitted)}
						variant={this.getActionButtonVariant(ApprovalStatus.Submitted, "")} isOutline
						menuItems={this.getConfigMenuItems(this.submitActions)}>
						Submit
					</Neo.Button>
				</div>}
		</div>;
	}

	private getConfigMenuItems(actions: IApprovalAction[]): IButtonDropDownItem[] {
		// ensure we can do each action
		const allowedActions = actions.filter(action => action.status === ApprovalStatus.Submitted || this.viewModel.approvalConfig.approversCanChangeStatus);
		return allowedActions.map(buttonType => this.getConfigMenuItem(buttonType.status, buttonType.actionName, buttonType.action));
	}

	private getConfigMenuItem(status: ApprovalStatus, actionName: string, action: () => void) {
		return { icon: this.getIcon(status), text: actionName, onClick: () => this.confirmAction(actionName, action) } as IButtonDropDownItem;
	}

	private getIcon(status: ApprovalStatus) {
		switch (status) {
			case ApprovalStatus.Pending:
				return "eraser";
			case ApprovalStatus.Approved:
				return "thumbs-up";
			case ApprovalStatus.Declined:
			case ApprovalStatus.DeclinedPending:
				return "thumbs-down";
			case ApprovalStatus.Submitted:
				return "check-double";
		};
		return "";
	}

	private async confirmAction(actionName: string, action: (command?: ChangeApprovalsCommandBase) => void) {
		const questionMessage = `Are you sure you want to ${actionName.replace("Page", "the Approvals on this Page")}?`;

		// DECLINE approvals - needs reason
		if (actionName.indexOf("Decline") > -1 && this.viewModel.approvalConfig.reasonRequiredForDecline) {
			var command = new DeclineApprovalsCommand();
			if (await ModalUtils.showYesNo(
				actionName + "?",
				<div>
					<p>{questionMessage}</p>
					<Neo.FormGroup bind={command.meta.declinedReason} />
				</div>) === Misc.ModalResult.Yes) {

				action(command);
			}
		}
		// SUBMIT approvals - needs extra check and warning
		else if (actionName.indexOf("Submit") > -1) {
			// check that items are ready for approval
			let hasApprovalsToSubmit = false;
			const defaultReason = "Approve or decline some approvals first.";
			let reason: string = defaultReason;
			if (actionName.indexOf("Page") > -1) {
				hasApprovalsToSubmit = this.viewModel.pageManager.getItems().some(
					item => item.readyToSubmit(this.viewModel.approvalConfig));
				if (!hasApprovalsToSubmit) {
					// find the first one with a reason		
					reason = this.viewModel.pageManager.getItems().find(
						item => ["", ApprovalLookup.alreadySubmittedText].indexOf(item.cannotSubmitReason(this.viewModel.approvalConfig)) === -1)
						?.cannotSubmitReason(this.viewModel.approvalConfig) ?? reason;
				}
			} else {
				hasApprovalsToSubmit = this.viewModel.approverAward!.hasApprovalsToSubmit;
			}
			if (hasApprovalsToSubmit) {
				if (await ModalUtils.showYesNo(
					actionName + "?",
					<div>
						<p>{questionMessage}</p>
						<p><i className="fa fa-info-circle text-info" /> Only approved/declined records will be submitted.</p>
						<p><i className="fa fa-exclamation-triangle text-warning" /> Once approvals are submitted, they can no longer be changed.</p>
					</div>) === Misc.ModalResult.Yes) {

					action();
				}
			} else {
				if (reason !== defaultReason) {
					this.notifications.addWarning("No approvals to submit", reason, NotificationDuration.Standard);
				} else {
					this.notifications.addInfo("No approvals to submit", reason, NotificationDuration.Standard);
				}
			}
		}
		// ALL other cases, just ask the message
		else {
			if (await ModalUtils.showYesNo(actionName + "?", questionMessage) === Misc.ModalResult.Yes) {

				action();
			}
		}
	}

	private getFilterButtonTip(filterName: string, isSelected: boolean) {
		return `${isSelected ? "Un-select" : "Select"} to ${isSelected ? "exclude" : "include"} ${filterName} approvals`;
	}

	private getPagerNoOfButtons(): number {
		return this.appLayout.currentScreenSize <= ScreenSize.Large ? 5 : 9;
	}

	private getFilterButton(icon: string, variant: Components.variant, verbPast: string, prop: Model.IPropertyInstance, inverse = false) {
		return <Neo.Button size="sm" tabIndex={-1} icon={icon} variant={variant} data-tip={this.getFilterButtonTip(verbPast, inverse ? prop.value : !prop.value)} isOutline={inverse ? !prop.value : prop.value}
			onClick={() => {
				prop.value = !prop.value;
				this.viewModel.filterChanged();
			}} />;
	}

	public render() {
		const filterButtons =
			<div className="filter-buttons">
				{this.utils.getFilterButton(() => this.viewModel.filterChanged(), "thumbs-up", "success", "approved", this.viewModel.criteria.meta.excludeApproved)}
				{this.utils.getFilterButton(() => this.viewModel.filterChanged(), "thumbs-down", "danger", "declined", this.viewModel.criteria.meta.excludeDeclined)}
				{this.utils.getFilterButton(() => this.viewModel.filterChanged(), "eraser", "warning", "pending", this.viewModel.criteria.meta.excludePending)}
				{this.utils.getFilterButton(() => this.viewModel.filterChanged(), "check-double", "primary", "submitted", this.viewModel.criteria.meta.includeSubmitted, true)}
				{this.viewModel.pageManager.totalRecords >= 0 && this.viewModel.approverAward && this.viewModel.pageManager.totalRecords !== this.viewModel.approverAward!.approvals &&
					<div className="d-inline filter-stats">
						Filter includes <strong>{this.viewModel.pageManager.totalRecords}</strong> of <strong>{this.viewModel.approverAward!.approvals}</strong>
					</div>}
			</div>;
		return (
			<div>
				<h2>Awards Release</h2>

				{/* APPROVAL LIST VIEW  */}
				{this.viewModel.approverAward === null &&
					<ApprovalSummariesComponent approvalSummary={this.viewModel.approvalSummary} awardSelected={(awardApproval) => this.selectAward(awardApproval)} />}

				{/* APPROVAL VIEW */}
				{this.viewModel.approverAward !== null &&
					<div className="approvals">
						<Neo.GridLayout md={2}>
							<div className="text-left">
								<Neo.Button variant="secondary" isOutline icon="arrow-left" onClick={() => this.viewParams.award.value = null} >Back</Neo.Button>
							</div>
							<div className="right-tools">
							</div>
						</Neo.GridLayout>
						<div className="mt-4">
							<ApprovalAwardSummaryComponent awardApproval={this.viewModel.approverAward}
								offerTotals={this.viewModel.offerTotals} verbosity={Verbosity.Summary} />

							{this.viewModel.actionButtonsEnabled &&
								<div className="right-tools">
									{this.getActionsControls()}
								</div>}
							<Neo.GridLayout lg={3} className="mt-4" >
								<div>
									<Neo.FormGroupInline md={2} bind={this.viewModel.criteria.meta.participantName}
										editorProps={{
											onKeyPress: key => {
												if (key.charCode === 13) {
													this.viewModel.pageManager.refreshData();
													key.preventDefault();
												}
											}
										}}
										append={() =>
											<Neo.Button size="sm" tabIndex={-1} icon="search"
												onClick={() => this.viewModel.pageManager.refreshData()} />} />
								</div>
								{filterButtons}
								<div className="right-tools">
									<Neo.PagerControlsBasic pageManager={this.viewModel.pageManager} noOfButtons={this.getPagerNoOfButtons()} />
								</div>
							</Neo.GridLayout>
							<Neo.Pager pageManager={this.viewModel.pageManager} pageControls="bottom" pageControlProps={{ noOfButtons: this.getPagerNoOfButtons() }} >
								<NeoGrid.Grid<ApprovalLookup> className="approvals-grid">
									{(item, meta) => (
										<NeoGrid.RowGroup>
											<NeoGrid.Row>
												<NeoGrid.ButtonColumn width={"5%"} label="Approve" className="approve-col" >
													{item.approvalStatus !== ApprovalStatus.Submitted &&
														<Neo.Button icon={item.getIcon()} isOutline size="sm"
															disabled={item.isSaving || !this.viewModel.approvalConfig.approversCanChangeStatus}
															variant={item.getVariant()}
															onClick={() => this.viewModel.changeApproval(item)} />
													}
													{item.approvalStatus === ApprovalStatus.Submitted &&
														<div>
															<i className={`fa fa-${item.getIcon({ ignoreSubmitted: true })} text-${item.getVariant({ ignoreSubmitted: true })}`} />
															{" "}
															<i className="fa fa-check text-success" />
														</div>}
												</NeoGrid.ButtonColumn>
												<NeoGrid.Column label="P. Code" display={meta.participantCode} width={"10%"} hideBelow={"md"} />
												<NeoGrid.Column label="Participant" sort={meta.participantName} width={"40%"} >
													<Loader size="small" show={item.isSaving}>
														{item.participantName}
													</Loader>
												</NeoGrid.Column>
												<NeoGrid.Column label="Email" display={meta.correspondenceEmail} width={"15%"} hideBelow={"lg"} />
												<NeoGrid.Column bind={meta.offeredUnits} width={"35%"}
													numProps={{
														format:
															this.viewModel.instrumentsSet[item.offeredInstrumentCode]?.allowedDecimals ? Misc.NumberFormat.Decimals : Misc.NumberFormat.NoDecimals
													}}
													editorProps={{
														disabled: !item.canEditOfferedUnits
													}}
													onClick={() => this.viewModel.allowEditOfferedUnits(item)}
													className={this.viewModel.canSubmit(item) ? "selectable" : ""}
													onBlur={() => this.viewModel.changeOfferedUnits(item)}
													appendText={this.appLayout.currentScreenSize < ScreenSize.Small ? undefined : item.offeredSymbol}
												/>
												<NeoGrid.ButtonColumn width={"2%"} >
													<Neo.Button size="sm" isOutline variant="info" icon="history"
														onClick={() => this.viewModel.approvalHistoryComponentVM.loadApprovalHistory(item)} />
												</NeoGrid.ButtonColumn>
											</NeoGrid.Row>
											{item.isDeclined && this.viewModel.approvalConfig.reasonRequiredForDecline &&
												<NeoGrid.Row showInHeader={false} >
													<NeoGrid.Column colSpan={6} >
														<Neo.FormGroupFloating className="declined-reason"
															bind={meta.declinedReason} onBlur={() => { this.viewModel.decline([item]); }} />
													</NeoGrid.Column>
												</NeoGrid.Row>}
										</NeoGrid.RowGroup>
									)}
								</NeoGrid.Grid>
							</Neo.Pager>
						</div>
					</div>}

				<ApprovalHistoryComponent viewModel={this.viewModel.approvalHistoryComponentVM} pageButtons={this.getPagerNoOfButtons.bind(this)} />
			</div>
		)
	}
}