import React from 'react';
import { Routing, Utils } from '@singularsystems/neo-core';
import { observer } from 'mobx-react';
import { AppService, AppTypes } from '../../Services/AppService';
import { Neo } from "@singularsystems/neo-react";

@observer
export default class MainMenu extends React.Component {

    private routeService = AppService.get(AppTypes.Shared.Services.RouteService);
    private appLayout = AppService.get(AppTypes.Shared.Services.AppLayout);    

    public render() {
        const menuItemProvider = AppService.get(AppTypes.Neo.Routing.MenuItemProvider);
        const menuItems = menuItemProvider.processRoutes(this.routeService.routes.menuRoutes, { collapseSingle: false, hideWhenEmpty: true });

        return (
            <div className={Utils.joinWithSpaces("main-menu-container", this.appLayout.menuVisible ? "show" : undefined)}>                
                <div className="main-menu">
                    <MenuList items={menuItems} level={1} />
                </div>
                <div className="menu-overlay"></div>
            </div>
        )
    }    
}

@observer
class MenuList extends React.Component<{ items: Routing.IMenuRoute[], level: number }> {

    public render() {
        if (!this.props.items.length) return null;

        if (this.props.level === 2 && !this.props.items[0].children) {
            // There must be 1 level, or 3 levels. In the case of 2, add a placeholder level.
            return <ul className="level-2 placeholder"><li><MenuList items={this.props.items} level={3} /></li></ul>
        } else {

            return (
                <ul className={"level-" + this.props.level}>
                    {this.props.items.map(menuItem => (
                        <MenuItem key={menuItem.name} item={menuItem} level={this.props.level} />
                    ))}
                </ul>
            )
        }
    }
}

@observer
class MenuItem extends React.Component<{ item: Routing.IMenuRoute, level: number }> {

    public render() {
        const item = this.props.item;
        const iconClass = item.icon ? "fa-fw " + Utils.getFontAwesomeClass(item.icon) : (this.props.level === 3 ? "fa fa-fw fa-arrow-right" : "");

        return (
            <li>
                {item.path ? 
                <Neo.Link isNav to={item.path}>{iconClass && <i className={iconClass} />} {item.name}</Neo.Link> : 
                <span>{item.icon && <i className={iconClass} />} {item.name}</span>}

                {item.children && <MenuList items={item.children} level={this.props.level + 1} />}
            </li>
        )
    }
}