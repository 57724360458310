import { Attributes, List, ModelBase, NeoModel, Validation } from '@singularsystems/neo-core';
import { QuestionType } from '../../../../Common/Models/Enums/QuestionType';
import DropDownItem from '../../../../Common/Models/Questionnaires/DropDownItem';

@NeoModel
export default class QuestionnaireQuestionAnswerLookup extends ModelBase {

    public questionnaireQuestionAnswerId: number = 0;

    public questionnaireAnsweredId: number = 0;

    public questionnaireQuestionId: number = 0;

    public question: string = "";

    public questionAnswer: string = "";

    @Attributes.OnChanged<QuestionnaireQuestionAnswerLookup>(c => c.onItemDisplayChanged)
    public questionAnswerDropDownValue: string = "";

    public questionInfoText: string = "";

    public answerPlaceholder: string = "";

    public questionType: QuestionType | null = null;

    @Attributes.Integer()
    public questionOrder: number = 0;

    @Attributes.Display("Required")
    public isRequired: boolean = false;

    public dropDownItems = new List(DropDownItem);

    // Client only properties / methods

    public async onItemDisplayChanged() {
        const item = this.dropDownItems.find(i => i.itemValue === this.questionAnswerDropDownValue);
        this.questionAnswer = item!.itemDisplay;
    }

    protected addBusinessRules(rules: Validation.Rules<this>) {
        rules.failWhen(c => c.isRequired === true
            && !c.questionAnswer && c.questionType !== QuestionType.DropDown,
            "Answer must be specified.")
            .onProperties(c => c.questionAnswer);

        rules.failWhen(c => c.isRequired === true
            && !c.questionAnswerDropDownValue && c.questionType === QuestionType.DropDown,
            "Please select a value.")
            .onProperties(c => c.questionAnswerDropDownValue);

    }
}