import { Attributes, Misc, ModelBase, NeoModel, Rules, Utils, Validation } from '@singularsystems/neo-core';

@NeoModel
export default class ParticipantBrokerDetail extends ModelBase {

    public participantBrokerDetailId: number = 0;

    public participantId: string = Utils.emptyGuid();

    @Rules.Required()
    @Rules.StringLength(100)
    public brokingFirm: string = "";

    @Rules.Required()
    @Rules.StringLength(40)
    public accountNumber: string = "";

    @Rules.StringLength(100)
    public brokerContactPerson: string = "";

    @Rules.StringLength(20)
    public brokerPhoneNo: string = "";

    @Rules.StringLength(250)
    @Rules.EmailAddress()
    public brokerEmailAddress: string = "";

    @Attributes.Date()
    public endDate: Date | null = null;

    @Attributes.NoTracking(Misc.SerialiseType.FullOnly)
    public hasPendingTrades: boolean = false;

    public requireBrokerContactDetails:  boolean = false;
    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
        rules.failWhen(c => c.requireBrokerContactDetails && !c.brokerPhoneNo && !c.brokerEmailAddress, "Phone no and email address cannot both be blank.").onProperties(c => [c.brokerPhoneNo, c.brokerEmailAddress]);
    }

    public get description() {
        return `${this.brokingFirm}, ${this.brokerContactPerson}, ${(this.brokerEmailAddress ? this.brokerEmailAddress : this.brokerPhoneNo)}, ${this.accountNumber}`;
    }

    public toString(): string {
        if (this.isNew || !this.participantId) {
            return "New participant broker detail";
        } else {
            return this.participantId;
        }
    }
}