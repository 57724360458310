import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../ParticipantsTypes';
import ParticipantContactDetailsLookup from '../Models/Lookups/ParticipantContactDetailsLookup';
import SecondmentLookup from '../Models/Lookups/SecondmentLookup';
import ParticipantDocumentLookup from '../Models/Lookups/ParticipantDocumentLookup';
import UpdateContactDetailsCommand from '../Models/Commands/UpdateContactDetailsCommand';
import FusionImportLookup from '../Models/Import/Queries/FusionImportLookup';
import ParticipantEntity from '../Models/ParticipantEntity';

export interface IParticipantsAppApiClient {

    /**
     * Gets the current participants basic contact details.
     * @returns Contact details.
     */
    getContactDetails(): AxiosPromise<Model.PlainObject<ParticipantContactDetailsLookup>>;

    /**
     * Gets a list of secondments for the current participant.
     * @returns List of secondments.
     */
    getSecondments(): AxiosPromise<Array<Model.PlainObject<SecondmentLookup>>>;

    /**
     * Gets a list of document lookups for a participant.
     * @returns List.
     */
    getDocumentLookupListAsync(): AxiosPromise<Array<Model.PlainObject<ParticipantDocumentLookup>>>;

    /**
     * Gets a download link for a document.
     * @param participantDocumentId The specific participant document id
     * @param fileDescriptorId The file descriptor
     * @returns Status.
     */
    getDocumentDownloadUrl(participantDocumentId: number, fileDescriptorId: number): AxiosPromise<string>;

    /**
     * Updates a participants contact details.
     * @param participantId Participant id to update.
     * @param command Update details.
     * @returns Task.
     */
    updateContactDetails(command: Model.PartialPlainObject<UpdateContactDetailsCommand>): AxiosPromise;

    /** 
     * Gets the Last Fusion Import done.
     * @returns The latest Fusion Import.
     */
    getLastFusionImport(): AxiosPromise<Model.PlainObject<FusionImportLookup>>;

    /** 
     * Gets the Participant Entity details for the provided Participant
     * @param participantId The participant id
     * @returns Participant Entities
     */
    getParticipantEntities(participantId: string): AxiosPromise<Array<Model.PlainTrackedObject<ParticipantEntity>>>;

    // Client only properties / methods
}

@injectable()
export default class ParticipantsAppApiClient extends Data.ApiClientBase implements IParticipantsAppApiClient {

    constructor (config = AppService.get(Types.Shared.Config)) {
        super(`${config.ParticipantsApi.ApiPath}/participants-app`);
    }

    public getContactDetails(): AxiosPromise<Model.PlainObject<ParticipantContactDetailsLookup>> {
        return this.axios.get(`${this.apiPath}/contact-details`);
    }

    public getSecondments(): AxiosPromise<Array<Model.PlainObject<SecondmentLookup>>> {
        return this.axios.get(`${this.apiPath}/secondments`);
    }

    public getDocumentLookupListAsync(): AxiosPromise<Array<Model.PlainObject<ParticipantDocumentLookup>>> {
        return this.axios.get(`${this.apiPath}/documents`);
    }

    public getDocumentDownloadUrl(participantDocumentId: number, fileDescriptorId: number): AxiosPromise<string> {
        return this.axios.post(`${this.apiPath}/documentDownloadUrl/${participantDocumentId}/${fileDescriptorId}`);
    }

    public updateContactDetails(command: Model.PartialPlainObject<UpdateContactDetailsCommand>): AxiosPromise {
        return this.axios.post(`${this.apiPath}/contact-details`, command);
    }

    public getLastFusionImport(): AxiosPromise<Model.PlainObject<FusionImportLookup>> {
        return this.axios.get(`${this.apiPath}/last-fusion-import`);
    }

    public getParticipantEntities(participantId: string): AxiosPromise<Array<Model.PlainTrackedObject<ParticipantEntity>>> {
        return this.axios.get(`${this.apiPath}/ParticipantEntities/${encodeURIComponent(participantId)}`);
    }
    
    // Client only properties / methods
}