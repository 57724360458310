import { List, ModelBase, NeoModel, Rules, Validation } from '@singularsystems/neo-core';
import AllocationCommentTypeOption from '../AllocationCommentTypeOption';

@NeoModel
export default class UpdateParticipantCommentCommand extends ModelBase {

  public allocationCommentId: number = 0;

  @Rules.Required()
  public allocationCommentTypeId: number | null = 0;

  @Rules.Required()
  public allocationCommentTypeOptionId: number | null = 0;

  // Client only properties / methods

  public commentOption: string = "";

  @Rules.StringLength(250, "Maximum 250 Characters Allowed")
  public allocationCommentNotes: string = "";

  get allocationCommentNotesCharsRemaining(): string {
    return (250 - this.allocationCommentNotes.length).toString() + " characters remaining"
  }

  public commentTypeOptions = new List(AllocationCommentTypeOption);

  protected addBusinessRules(rules: Validation.Rules<this>) {
    super.addBusinessRules(rules);
  }

  public toString(): string {
    if (this.isNew) {
      return "New update participant comment command";
    } else {
      return "Update Participant Comment Command";
    }
  }
}