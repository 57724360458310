import { NeoModel, Validation } from '@singularsystems/neo-core';
import ChangeApprovalsCommandBase from './ChangeApprovalsCommandBase';

@NeoModel
export default class SubmitApprovalsCommand extends ChangeApprovalsCommandBase {

    public isRelease: boolean = true;

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew) {
            return "New submit approvals command";
        } else {
            return "Submit Approvals Command";
        }
    }
}