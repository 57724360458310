import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../ClientsTypes';
import { DocumentDisplayLocation } from '../Models/Documents/DocumentDisplayLocation';
import TenantDocumentLookup from '../Models/Documents/TenantDocumentLookup';

export interface ITenantDocumentsQueryApiClient {

    /**
     * Gets the blob download url for a file.
     * @param fileDescriptorId The file descriptor
     * @returns Status.
     */
    getDocumentDownloadUrl(fileDescriptorId: number, inline?: boolean): AxiosPromise<string>;

    /** 
     * Gets a list of tenant document lookups for a display location.
     * @param location Display location.
     * @returns Lookup list.
     */
    getTenantDocumentLookups(location: DocumentDisplayLocation, inline?: boolean): AxiosPromise<Array<Model.PlainObject<TenantDocumentLookup>>>;

    // Client only properties / methods
}

@injectable()
export default class TenantDocumentsQueryApiClient extends Data.ApiClientBase implements ITenantDocumentsQueryApiClient {

    constructor (config = AppService.get(Types.Shared.Config)) {
        super(`${config.ClientsApi.ApiPath}/tenant-documents-query`);
    }

    public getDocumentDownloadUrl(fileDescriptorId: number, inline = false): AxiosPromise<string> {
        return this.axios.get(`${this.apiPath}/download-url/${fileDescriptorId}?inline=${inline}`);
    }

    public getTenantDocumentLookups(location: DocumentDisplayLocation): AxiosPromise<Array<Model.PlainObject<TenantDocumentLookup>>> {
        return this.axios.get(`${this.apiPath}?location=${location}`);
    }

    // Client only properties / methods
}