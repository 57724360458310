import React from 'react';
import { Neo, NeoGrid } from '@singularsystems/neo-react';
import AllocationHistoryComponentVM from './AllocationHistoryComponentVM';
import { observer } from 'mobx-react';
import HistoryChart from './Components/HistoryChart';
import { Misc, NumberUtils } from '@singularsystems/neo-core';

interface IAllocationHistoryProps {
    viewModel: AllocationHistoryComponentVM;
}

@observer
export default class AllocationHistoryComponent extends React.Component<IAllocationHistoryProps> {

    public render() {
        const participantHistory = this.props.viewModel.participantHistory;
        const allocationCurrencySymbol = this.props.viewModel.participantHistory?.currencySymbol
        return (
            <>
                <p className="mt-2 mb-4 custom-blue"><strong>View the history of LTI allocations for the past 4 years (vested and unvested in Fair Value).</strong></p>
                <NeoGrid.Grid items={participantHistory.previousSplits!} className="am-history-table">
                    {(ps) => (
                        <NeoGrid.Row >
                            <NeoGrid.Column display={ps.meta.incentiveSchemeName} headerClassName="bold" footerContent={() => "Total"} />
                            <NeoGrid.Column display={ps.meta.currentFYExpectedValue}
                                headerClassName="bold"
                                numProps={{ zeroText: "-", currencySymbol: allocationCurrencySymbol }}
                                label={this.props.viewModel.getPriorHeader(0)}
                                sum />
                            <NeoGrid.Column display={ps.meta.prior1ExpectedValue}
                                headerClassName="bold"
                                numProps={{ zeroText: "-", currencySymbol: allocationCurrencySymbol }}
                                label={this.props.viewModel.getPriorHeader(1)}
                                sum />
                            <NeoGrid.Column display={ps.meta.prior2ExpectedValue}
                                headerClassName="bold"
                                numProps={{ zeroText: "-", currencySymbol: allocationCurrencySymbol }}
                                label={this.props.viewModel.getPriorHeader(2)}
                                sum />
                            <NeoGrid.Column display={ps.meta.prior3ExpectedValue}
                                headerClassName="bold"
                                numProps={{ zeroText: "-", currencySymbol: allocationCurrencySymbol }}
                                label={this.props.viewModel.getPriorHeader(3)}
                                sum />
                            <NeoGrid.Column display={ps.meta.prior4ExpectedValue}
                                headerClassName="bold"
                                numProps={{ zeroText: "-", currencySymbol: allocationCurrencySymbol }}
                                label={this.props.viewModel.getPriorHeader(4)}
                                sum />
                        </NeoGrid.Row>
                    )}
                </NeoGrid.Grid>

                <p className="mt-4 mb-4 custom-blue"><strong>View the Fair Value of the current portfolio of awards vesting (excluding new proposals).</strong>
                    <Neo.Button className="btn-150" text="View Vesting Schedule Graph" variant="secondary" onClick={() => this.props.viewModel.showParticipantHistoryGraph()} style={{ float: 'right' }} /></p>
                <NeoGrid.Grid items={participantHistory.expectedValues!} className="am-history-table"
                    footerContent={() =>
                        <tr className="am-perc-of-salary">
                            <td style={{ textAlign: "left" }}>{"% of salary (current)"}</td>
                            <td>{NumberUtils.format(participantHistory.percOfSalaryByYear(1), Misc.NumberFormat.PercentDecimals)}</td>
                            <td>{NumberUtils.format(participantHistory.percOfSalaryByYear(2), Misc.NumberFormat.PercentDecimals)}</td>
                            <td>{NumberUtils.format(participantHistory.percOfSalaryByYear(3), Misc.NumberFormat.PercentDecimals)}</td>
                            <td>{NumberUtils.format(participantHistory.percOfSalaryByYear(4), Misc.NumberFormat.PercentDecimals)}</td>
                            <td>{NumberUtils.format(participantHistory.percOfSalaryByYear(5), Misc.NumberFormat.PercentDecimals)}</td>
                            <td>{NumberUtils.format(participantHistory.percOfSalaryTotal(), Misc.NumberFormat.PercentDecimals)}</td>
                        </tr>}>
                    {(ev) => (
                        <NeoGrid.Row>
                            <NeoGrid.Column display={ev.meta.incentiveSchemeName} headerClassName="bold" footerContent={() => "Grand Total"} />
                            <NeoGrid.Column display={ev.meta.plus1ExpectedValue}
                                headerClassName="bold"
                                numProps={{ zeroText: "-", currencySymbol: allocationCurrencySymbol }}
                                label={this.props.viewModel.getEVHeader(0)}
                                sum />
                            <NeoGrid.Column display={ev.meta.plus2ExpectedValue}
                                headerClassName="bold"
                                numProps={{ zeroText: "-", currencySymbol: allocationCurrencySymbol }}
                                label={this.props.viewModel.getEVHeader(1)}
                                sum />
                            <NeoGrid.Column display={ev.meta.plus3ExpectedValue}
                                headerClassName="bold"
                                numProps={{ zeroText: "-", currencySymbol: allocationCurrencySymbol }}
                                label={this.props.viewModel.getEVHeader(2)}
                                sum />
                            <NeoGrid.Column display={ev.meta.plus4ExpectedValue}
                                headerClassName="bold"
                                numProps={{ zeroText: "-", currencySymbol: allocationCurrencySymbol }}
                                label={this.props.viewModel.getEVHeader(3)}
                                sum />
                            <NeoGrid.Column display={ev.meta.plus5ExpectedValue}
                                headerClassName="bold"
                                numProps={{ zeroText: "-", currencySymbol: allocationCurrencySymbol }}
                                label={this.props.viewModel.getEVHeader(4)}
                                sum />
                            <NeoGrid.Column display={ev.meta.totalQuantity}
                                headerClassName="bold"
                                numProps={{ zeroText: "-", currencySymbol: allocationCurrencySymbol }}
                                sum />
                        </NeoGrid.Row>
                    )}
                </NeoGrid.Grid>
                <Neo.Modal title={"Vesting Schedule Graph"} size="xl" bindModel={this.props.viewModel.meta.historyChartVM}>
                    {() => (
                        this.props.viewModel.historyChartVM && <HistoryChart viewModel={this.props.viewModel.historyChartVM} />
                    )}
                </Neo.Modal>
            </>
        );
    }
}