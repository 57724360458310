import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../AllocationManagerTypes';
import AllocationPlanInformationLookup from '../Models/Queries/AllocationPlanInformationLookup';
import AllocationPlanInformation from '../Models/Queries/AllocationPlanInformation';

export interface IAllocationPlanInformationQueryApiClient {

    /**
     * Get Allocation Plan Information Lookup Endpoint
     * @returns A list of Allocation Plan Information Lookup from database based.
     */
    getAllocationPlanInformationLookup(): AxiosPromise<Array<Model.PlainObject<AllocationPlanInformationLookup>>>;

    /**
     * Get Allocation Plan Information Endpoint
     * @param allocationPlanInformationId allocationPlanInfomationId
     * @returns A list of Allocation Plan Information from database based.
     */
    getAllocationPlanInformation(allocationPlanInformationId: number): AxiosPromise<Array<Model.PlainTrackedObject<AllocationPlanInformation>>>;

    /**
     * Get Allocation Plan Information Endpoint
     * @param allocationPlanTypeId allocationPlanInfomationId
     * @returns A list of Allocation Plan Information from database based on Allocation Plan type.
     */
    getAllocationPlanInfoList(allocationPlanTypeId?: number): AxiosPromise<Array<Model.PlainTrackedObject<AllocationPlanInformation>>>;

    /** 
     * Get Allocation Plan Information Endpoint
     * @param allocationPlanTypeId allocationPlanInfomationId
     * @returns A list of Allocation Plan Information from database based on Allocation Plan type.
     */
    getAllocationPlanInfoTrainingList(allocationPlanTypeId: number): AxiosPromise<Array<Model.PlainTrackedObject<AllocationPlanInformation>>>;

    /**
     * Retrieves dowload url for saved resource in azure blob storage
     * @param fileDescriptorId File Descriptor Id.
     * @returns A download url will be returned .
     */
    getMediaURL(fileDescriptorId: string): AxiosPromise<string>;

    // Client only properties / methods
}

@injectable()
export default class AllocationPlanInformationQueryApiClient extends Data.ApiClientBase implements IAllocationPlanInformationQueryApiClient {

    constructor (config = AppService.get(Types.Shared.Config)) {
        super(`${config.AwardsApi.ApiPath}/allocation-plan-info/query`);
    }

    public getAllocationPlanInformationLookup(): AxiosPromise<Array<Model.PlainObject<AllocationPlanInformationLookup>>> {
        return this.axios.get(`${this.apiPath}/lookup`);
    }

    public getAllocationPlanInformation(allocationPlanInformationId: number): AxiosPromise<Array<Model.PlainTrackedObject<AllocationPlanInformation>>> {
        return this.axios.get(`${this.apiPath}/detail/${allocationPlanInformationId}`);
    }

    public getAllocationPlanInfoList(allocationPlanTypeId?: number): AxiosPromise<Array<Model.PlainTrackedObject<AllocationPlanInformation>>> {
        return this.axios.get(`${this.apiPath}/info?allocationPlanTypeId=${allocationPlanTypeId}`);
    }

    public getAllocationPlanInfoTrainingList(allocationPlanTypeId: number): AxiosPromise<Array<Model.PlainTrackedObject<AllocationPlanInformation>>> {
        return this.axios.get(`${this.apiPath}/info-training/${allocationPlanTypeId}`);
    }

    public getMediaURL(fileDescriptorId: string): AxiosPromise<string> {
        return this.axios.get(`${this.apiPath}/media/${encodeURIComponent(fileDescriptorId)}`);
    }

    // Client only properties / methods
}