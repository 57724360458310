import { NeoModel, Utils, Validation, ValueObject } from '@singularsystems/neo-core';

@NeoModel
export default class AllocationManagerLookupCriteria extends ValueObject {

    public allocationManagerId: number = 0;

    public allocationPlanId: number = 0;

    public participantName: string = "";

    public participantId: string = Utils.emptyGuid();

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        return "Allocation Manager Lookup Criteria";
    }
}