import { observable, action, makeObservable } from 'mobx';
import { Utils } from '@singularsystems/neo-core';
import { injectable } from 'inversify';

export enum ScreenSize {
    ExtraSmall = 1,
    Small = 2,
    Medium = 3,
    Large = 4,
    ExtraLarge = 5,
    Huge = 6
}

export interface IAppLayout {
    menuVisible: boolean;
    currentScreenSize: ScreenSize;
    isScrollTop: boolean;
    toggleMenu(): void;
    setup(): void;
    setHeaderHeight(): void;
}

/**
 * Contains logic about the layout of the app. E.g. screensize, theme etc.
 * Use AppLayout.current for the singleton instance.
 */
@injectable()
export default class AppLayout implements IAppLayout {

    @observable.ref
    public pinHeader = true;

    @observable.ref
    public menuVisible = false;

    @observable.ref
    public currentScreenSize = ScreenSize.Medium;

    @observable.ref
    public isScrollTop = true;

    constructor() {
        window.addEventListener("resize", this.onWindowResize.bind(this));
        window.addEventListener("click", (e) => {

            // Hide the menu on click.
            const anchor = document.getElementById("menu-anchor");
            if (!this.isChildOf(e.target! as Element, anchor!)) {
                this.menuVisible = false;
            }
            
        });
        
        document.addEventListener("scroll", () => {
            // This event is fired a lot, which is why we are using peek().
            if ((window.scrollY === 0) !== Utils.peek(this, "isScrollTop")) {
                this.isScrollTop = window.scrollY === 0;
            }
        });

        this.onWindowResize();

        makeObservable(this);
    }

    @action
    private onWindowResize() {
        this.menuVisible = false;
        
        if (window.innerWidth < 576) {
            this.currentScreenSize = ScreenSize.ExtraSmall;
        } else if (window.innerWidth < 768) {
            this.currentScreenSize = ScreenSize.Small;
        } else if (window.innerWidth < 992) {
            this.currentScreenSize = ScreenSize.Medium;
        } else if (window.innerWidth < 1260) {
            this.currentScreenSize = ScreenSize.Large;
        } else if (window.innerWidth < 1800) {
            this.currentScreenSize = ScreenSize.ExtraLarge;
        } else {
            this.currentScreenSize = ScreenSize.Huge;
        }

        this.setHeaderHeight();
    }

    public setHeaderHeight() {
        if (this.header) {
            this.contentPanel!.style.minHeight = (window.innerHeight - this.header.clientHeight - this.footer!.clientHeight) + "px";
        }
    }

    private header?: HTMLDivElement;
    private footer?: HTMLDivElement;
    private contentPanel?: HTMLDivElement;

    public setup() {
        this.header = document.getElementById("header-panel") as HTMLDivElement;
        this.footer = document.getElementById("footer-panel") as HTMLDivElement;
        this.contentPanel = document.getElementById("content-panel") as HTMLDivElement;

        this.onWindowResize();
    }

    public toggleMenu() {
        this.menuVisible = !this.menuVisible;
    }

    private isChildOf(element : Element, parent : Element) {
        while (true) {
            if (element === parent) {
                return true;
            }
            if (element.parentElement) {
                element = element.parentElement;
            } else {
                return false;
            }
        }
    }
}