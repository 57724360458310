export enum DocumentDisplayLocation {
    ParticipantDocuments = 1,
    ParticipantDashboardWelcome = 50,
    AdminGeneral = 100,
}

// TODO: remove this once ParticipantDocuments is supported in participants-app.

export enum DocumentDisplayLocation_TEMP {
    ParticipantDashboardWelcome = DocumentDisplayLocation.ParticipantDashboardWelcome,
    AdminGeneral = DocumentDisplayLocation.AdminGeneral,
}