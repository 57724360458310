import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../AllocationManagerTypes';
import GridColumnConfig from '../../Common/Models/GridColumnConfig';

export interface IGridColumnConfigApiClient {

    /** 
     * The GET grid column config Endpoint.
     * @returns A list of grid column config from the database.
     */
    getGridColumnConfig(): AxiosPromise<Array<Model.PlainTrackedObject<GridColumnConfig>>>;

    /** 
     * End Point save grid column configuration.
     * @param gridColumnConfig The allocation manager grid column config list to Save
     * @returns A List of Grid Column Config/returns>
     */
    saveGridColumnConfig(gridColumnConfig: Array<Model.PartialPlainObject<GridColumnConfig>>): AxiosPromise<Array<Model.PlainTrackedObject<GridColumnConfig>>>;

    // Client only properties / methods
}

@injectable()
export default class GridColumnConfigApiClient extends Data.ApiClientBase implements IGridColumnConfigApiClient {

    constructor (config = AppService.get(Types.Shared.Config)) {
        super(`${config.AwardsApi.ApiPath}/grid-column-config`);
    }

    public getGridColumnConfig(): AxiosPromise<Array<Model.PlainTrackedObject<GridColumnConfig>>> {
        return this.axios.get(`${this.apiPath}`);
    }

    public saveGridColumnConfig(gridColumnConfig: Array<Model.PartialPlainObject<GridColumnConfig>>): AxiosPromise<Array<Model.PlainTrackedObject<GridColumnConfig>>> {
        return this.axios.post(`${this.apiPath}`, gridColumnConfig);
    }

    // Client only properties / methods
}