import { AppServices } from '@singularsystems/neo-core';

import ProxySessionService from './Services/ProxySessionService';
import { ProxyAsParticipantModalVM } from './Components/ProxyAsParticipantModal';

import TermsAndConditionsService from './Services/TermsAndConditionsService';

import { IAcceptTermsAndConditionsApiClient } from './ApiClients/Custom/TermsAndConditions/AcceptTermsAndConditionsApiClient';
import { ITermsAndConditionsDisplayOptionsApiClient } from './ApiClients/Custom/TermsAndConditions/TermsAndConditionsDisplayOptionsApiClient';
import { IProxySessionQueryApiClient } from './ApiClients/Custom/ProxyUsers/ProxySessionQueryApiClient';
import { IProxySessionApiClient } from './ApiClients/Custom/ProxyUsers/ProxySessionApiClient';
import { IAccountApiClient } from './ApiClients/AccountApiClient';
import IComponentContainer from '../../App/Common/Components/Domain/IComponentContainer';
import { TsAndCsDisplayLocation } from './Models/TsAndCs/Display/TsAndCsDisplayLocation.enum';

// Symbols to expose outside of this module
export const IdentitySharedTypes = {
    ApiClients: {
        AccountApiClient: new AppServices.ServiceIdentifier<IAccountApiClient>("Identity.ApiClients.AccountApiClient"),
        ProxySessionQueryApiClient: new AppServices.ServiceIdentifier<IProxySessionQueryApiClient>("Identity.ApiClients.ProxySessionQueryApiClient"),
        ProxySessionApiClient: new AppServices.ServiceIdentifier<IProxySessionApiClient>("Identity.ApiClients.ProxySessionApiClient"),
        AcceptTermsAndConditionsApiClient: new AppServices.ServiceIdentifier<IAcceptTermsAndConditionsApiClient>("Identity.ApiClients.AcceptTermsAndConditionsApiClient"),
        TermsAndConditionsDisplayOptionsApiClient: new AppServices.ServiceIdentifier<ITermsAndConditionsDisplayOptionsApiClient>("Identity.ApiClients.TermsAndConditionsDisplayOptionsApiClient"),
    },
    Services: {
        ProxySessionService: new AppServices.ServiceIdentifier<ProxySessionService>("Identity.Services.ProxySessionService"),
        TermsAndConditionsService: new AppServices.ServiceIdentifier<TermsAndConditionsService>("Identity.Services.TermsAndConditionsService"),
    },
    ViewModels: {
        ProxyAsParticipantModalVM: new AppServices.ServiceIdentifier<ProxyAsParticipantModalVM>("Identity.Components.ProxyAsParticipantModalVM")
    },
    Components: {
        ProxyAsParticipantModalContainer:  new AppServices.ServiceIdentifier<IComponentContainer<ProxyAsParticipantModalVM>>("Identity.Components.ProxyAsParticipantModalContainer")
    },
    Types: {
        TsAndCsDisplayLocation
    }
}