import { NeoModel } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import TradeRequestLookup from '../Models/Trading/Lookups/TradeRequestLookup';
import { AppService, Types } from '../TransactionsTypes';

@NeoModel
export default class TradeRequestVM extends Views.ViewModelBase {

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        private tradeApiClient = AppService.get(Types.Transactions.ApiClients.TradeApiClient)) {

        super(taskRunner);
    }

    public tradeRequest: TradeRequestLookup | null = null;

    public async initialise() {

    }

    public async loadTradeRequest(tradeRequestId: number) {

        const result = await this.taskRunner.waitFor(this.tradeApiClient.getTradeRequest(tradeRequestId));
        this.tradeRequest = TradeRequestLookup.fromJSObject<TradeRequestLookup>(result.data);
    }
}