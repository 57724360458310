import { Attributes, LookupBase, NeoModel, Utils } from '@singularsystems/neo-core';

@NeoModel
export default class ProxyUserParticipantLookup extends LookupBase {

    public applicationUserParticipantId: number = 0;

    public applicationUserId: string = "";

    public participantId: string = Utils.emptyGuid();

    public participantName: string = "";

    @Attributes.Integer()
    public proxySessions: number = 0;

    // Client only properties / methods
}