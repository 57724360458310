import { List, NeoModel } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import { AppService, Types } from '../AllocationManagerTypes';
import { AllocationPlanSection } from '../Models/Enums/AllocationPlanSection';
import { MediaObjectType } from '../Models/Enums/MediaObjectType';
import AllocationPlanInformation from '../Models/Queries/AllocationPlanInformation';

@NeoModel
export default class AllocationInformationVM extends Views.ViewModelBase {

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private allocationPlanInformationQueryApiClient = AppService.get(Types.AllocationManager.ApiClients.AllocationPlanInformationQueryApiClient),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications)) {

        super(taskRunner);
    }

    public allocationPlanInformationList = new List(AllocationPlanInformation);
    public supportAndTrainingSectionInfo = new AllocationPlanInformation();
    public supportAndTrainingSectionImage = "";
    public supportAndTrainingGuidesSectionInfo = new List(AllocationPlanInformation);
    public shortGuidesRight = new List(AllocationPlanInformation);
    public shortGuidesLeft = new List(AllocationPlanInformation);

    public async openDocument(documentURL: string) {
        window.open(documentURL, '_blank');
    }

    public async getAllocationPlanInformation(informationPlanTypeId: number) {
        const response = await this.taskRunner.waitFor(this.allocationPlanInformationQueryApiClient.getAllocationPlanInfoTrainingList(informationPlanTypeId));
        this.allocationPlanInformationList.set(response.data);
        this.setSupportAndTrainingGuidesSection();
        this.setSupportAndTrainingSection();
    }

    public setSupportAndTrainingSection() {
        this.supportAndTrainingSectionInfo = this.allocationPlanInformationList.find(c => c.allocationPlanSectionId === AllocationPlanSection.SupportAndTraining) ?? new AllocationPlanInformation();
        this.supportAndTrainingSectionInfo.allocationPlanInformationSupportMedia.forEach(async element => {
            if (element.allocationManagerMediaObject.mediaObjectTypeId === MediaObjectType.Image) {
                this.supportAndTrainingSectionImage = (await this.allocationPlanInformationQueryApiClient.getMediaURL(element.allocationManagerMediaObject.fileDescriptorId!)).data;
                //if its an image, we dont want to be able to download it like we do the documents. So we want to remove it from the list so it doesnt get mapped.
                this.supportAndTrainingSectionInfo.allocationPlanInformationSupportMedia.remove(
                    this.supportAndTrainingSectionInfo.allocationPlanInformationSupportMedia.find(c => c.allocationPlanInformationSupportMediaId === element.allocationPlanInformationSupportMediaId)!);
            }
            else {
                element.documentURL = (await this.allocationPlanInformationQueryApiClient.getMediaURL(element.allocationManagerMediaObject.fileDescriptorId!)).data;
            }
        });
    }

    public setSupportAndTrainingGuidesSection() {
        this.allocationPlanInformationList.forEach(element => {
            if (element.allocationPlanSectionId === AllocationPlanSection.SupportAndTrainingShortGuides) {
                this.supportAndTrainingGuidesSectionInfo.push(element);
            }
        });
        var counter = 0;
        this.supportAndTrainingGuidesSectionInfo.forEach(async element => {
            counter++;
            element.allocationPlanInformationSupportMedia.forEach(async media => {
                if (media.allocationManagerMediaObject.mediaObjectTypeId === MediaObjectType.Image) {
                    media.imageURL = (await this.allocationPlanInformationQueryApiClient.getMediaURL(media.allocationManagerMediaObject.fileDescriptorId!)).data;
                    element.allocationPlanInformationSupportMediaImages.push(media);
                }
                else {
                    media.documentURL = (await this.allocationPlanInformationQueryApiClient.getMediaURL(media.allocationManagerMediaObject.fileDescriptorId!)).data;
                    element.allocationPlanInformationSupportMediaDocuments.push(media);
                }
            });
            // we split the list in 2 to build up the page
            if (counter % 2 === 0) {
                this.shortGuidesRight.push(element);
            } else {
                this.shortGuidesLeft.push(element);
            }
        });
    }
}