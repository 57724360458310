import { Attributes, List, LookupBase, ModelBase, NeoModel } from '@singularsystems/neo-core';
import { TradeAuthorisationStatus, TradeBuyStatus, TradeSellStatus } from '../../../../Common/Models/Enums/TradeStatus';
import { TaxDirectiveStatus } from '../../../../Common/Models/Enums/TaxDirectiveStatus';
import { ICancellableTradeRequestLookup } from '../../../../Common/Models/ITradeRequestLookup';
import { TradeStatusHelper } from '../../../../Common/Services/TradeStatusHelper';
import ParticipantBrokerDetail from '../../../../Common/Models/ParticipantBrokerDetail';
import CashTransactionLookup from '../../../../Common/Models/CashTransactionLookup';
import PortfolioBalanceLookup from '../../Portfolio/PortfolioBalanceLookup';
import UpdateParticipantBrokerDetailCommand from '../../../../Common/Models/UpdateParticipantBrokerDetailCommand';
import TradeRequestAdminExtraDetailsLookup from '../../../../Common/Models/Trading/TradeRequestAdminExtraDetailsLookup';

@NeoModel
export default class TradeRequestLookup extends ModelBase implements ICancellableTradeRequestLookup {

    public tradeRequestId: number = 0;

    @Attributes.Nullable()
    public tradeRequestLinkId: number | null = null;

    public instrumentId: number = 0;

    public instrumentCode: string = "";

    public settlementInstrumentCode: string | null = null;

    public incentiveSchemeId: number = 0;

    @Attributes.Float()
    public sellQuantity: number = 0;

    @Attributes.Float()
    public buyQuantity: number = 0;

    @Attributes.Nullable()
    @Attributes.Float()
    public limitPrice: number | null = null;

    public authorisationStatus: TradeAuthorisationStatus | null = null;

    public sellStatus: TradeSellStatus | null = null;

    public buyStatus: TradeBuyStatus | null = null;

    @Attributes.Nullable()
    public buyBatchId: number | null = null;

    @Attributes.Float()
    public soldQuantity: number = 0;

    @Attributes.Date()
    public createdOn: Date = new Date();

    @Attributes.Date()
    public tradeDate: Date = new Date();

    @Attributes.Nullable()
    public participantBrokerDetailId: number | null = null;

    public taxDirectiveStatus: TaxDirectiveStatus = TaxDirectiveStatus.None;

    public isComplete: boolean = false;

    @Attributes.Nullable()
    @Attributes.Float()
    public costTransfered: number | null = null;

    @Attributes.Nullable()
    @Attributes.Float()
    public proceeds: number | null = null;

    @Attributes.Nullable()
    @Attributes.Float()
    public buyProceeds: number | null = null;

    @Attributes.Date()
    public tradeInstructionCloseDate: Date | null = null;

    @Attributes.Nullable()
    public tradeAgreementId: number | null = null;

    public cashTransactions = new List(CashTransactionLookup);

    public tranches = new List(TradeRequestTrancheLookup);

    // Client only properties / methods

    @Attributes.NoTracking()
    public extraDetails: TradeRequestAdminExtraDetailsLookup | null = null;

    @Attributes.NoTracking()
    public isExpanded = false;

    @Attributes.NoTracking()
    public incentiveSchemeName: string = "";

    @Attributes.NoTracking()
    public currencySymbol: string = "";

    @Attributes.NoTracking()
    public hasLoadedCashTransactions = false;

    /**
     * Indicates that the tranches list is populated, and that this is still a pending trade.
     * The value and costs are summed by tranche since some tranches may have traded but not others.
     */
    @Attributes.NoTracking()
    public hasTrancheData = false;

    @Attributes.NoTracking()
    public editingBrokerDetails: UpdateParticipantBrokerDetailCommand | null = null;

    @Attributes.NoTracking()
    public existingBrokerDetails: ParticipantBrokerDetail | null = null;

    @Attributes.NoTracking()
    public settlementRequiresTaxDirective: boolean = false;

    @Attributes.Display("Quantity")
    @Attributes.Integer()
    public get totalQuantity() {
        return this.sellQuantity + this.buyQuantity;
    }

    public get type() {
        if (this.sellQuantity > 0 && this.buyQuantity === 0) {
            return "Sell";
        } else if (this.buyQuantity > 0 && this.sellQuantity === 0) {
            return "Transfer";
        } else {
            return "Sell / Transfer";
        }
    }

    @Attributes.Float()
    public get price() {
        if (this.hasTrancheData) {
            return this.customInstrumentPrice;
        } else {
            if (this.sellQuantity) {
                return (this.proceeds ?? 0) / this.sellQuantity;
            } else if (this.buyQuantity) {
                return (this.buyProceeds ?? 0) / this.buyQuantity;
            }
            return 0;
        }
    }

    @Attributes.Float()
    public get customInstrumentPrice() {
        return this.tranches[0].trancheBalance!.fixedPrice ??
            (this.tranches[0].trancheBalance!.trackingInstrument ?
                this.tranches[0].trancheBalance!.trackingInstrument.customPrice ?? this.tranches[0].trancheBalance!.trackingInstrumentPrice :
                this.tranches[0].trancheBalance!.instrument.customPrice ?? this.tranches[0].trancheBalance!.currentInstrumentPrice);
    }

    @Attributes.Float()
    public get buyPrice() {
        return this.buyQuantity > 0 ? (this.buyProceeds ?? 0) / this.buyQuantity : 0;
    }

    @Attributes.Float()
    public get grossValue() {
        if (this.hasTrancheData) {
            return (this.sellQuantity + this.buyQuantity) * (this.price / (this.tranches[0].trancheBalance!.trackingInstrument ? this.tranches[0].trancheBalance!.awardPrice : 1));
        } else {
            return (this.proceeds ?? 0) + (this.buyProceeds ?? 0);
        }
    }

    @Attributes.Float()
    public get costs() {
        if (this.hasTrancheData) {
            return -this.tranches.sum(tt => tt.trancheBalance!.unitBalance === 0 ?
                -(tt.costTransfered ?? 0) :
                tt.trancheBalance!.costPerUnit * (tt.sellQuantity + tt.buyQuantity));
        } else {
            return this.costTransfered ?? 0;
        }
    }

    @Attributes.Float()
    public get profit() {
        return this.grossValue + this.costs;
    }

    private get requiresTaxDirective() {
        return (this.buyStatus !== TradeBuyStatus.Cancelled && this.buyQuantity > 0) || this.soldQuantity > 0;
    }

    @Attributes.Display("Status")
    public get statusText() {
        return TradeStatusHelper.getSimpleStatusText(this);
    }

    public get canCancel(): boolean {
        return TradeStatusHelper.canCancel(this, false);
    }

    public get canUpdateBrokerDetails(): boolean {
        return this.buyQuantity > 0 && this.buyStatus === TradeBuyStatus.Pending && !this.buyBatchId;
    }
}

@NeoModel
export class TradeRequestTrancheLookup extends LookupBase {

    public trancheId: number = 0;

    @Attributes.Float()
    public sellQuantity: number = 0;

    @Attributes.Float()
    public buyQuantity: number = 0;

    @Attributes.Nullable()
    @Attributes.Float()
    public costTransfered: number | null = null;

    // Client only properties / methods

    @Attributes.NoTracking()
    public trancheBalance: PortfolioBalanceLookup | null = null;
}