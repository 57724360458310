import React from 'react';
import { Neo, NeoGrid, Views } from '@singularsystems/neo-react';
import { observer } from 'mobx-react';

import { AppService, AppTypes } from 'st-app/Services/AppService';

import '../../../Styles/CommonApprovals.scss';

import ApprovalLookup from '../../../Models/Approvals/Queries/ApprovalLookup';
import { Data, NeoModel, DateUtils } from '@singularsystems/neo-core';
import AuditLookupCriteria from 'st-app/Common/Models/Audit/AuditLookupCriteria';
import ApprovalAuditLookup from '../../../Models/Approvals/Queries/ApprovalAuditLookup';
import { ScreenSize } from 'st-app/Common/Models/Enums/ScreenSize';
import { Types } from '../../../CommonAwardsTypes';

interface IApprovalHistoryComponentProps {
    viewModel: ApprovalHistoryComponentVM;
    pageButtons: () => number;
}

@NeoModel
export class ApprovalHistoryComponentVM extends Views.ViewModelBase {

    public showHistoryFor: ApprovalLookup | null = null;

    private approvalLookupCriteria = new AuditLookupCriteria();

    public pageManager = new Data.PageManager(this.approvalLookupCriteria, ApprovalAuditLookup, this.queryClient.getApprovalHistory, {
        pageSize: 10,
        initialTaskRunner: this.taskRunner,
        beforeFetch: request => request.criteria!.key = this.showHistoryFor!.awardApprovalId
    });

    constructor(
        taskRunner = AppService.get(AppTypes.Neo.TaskRunner),
        public queryClient = AppService.get(Types.ApiClients.ApprovalsQueryApiClient)) {

        super(taskRunner);
    }

    public async loadApprovalHistory(item: ApprovalLookup) {
        this.showHistoryFor = item;

        this.approvalLookupCriteria.key = item.awardApprovalId;

        this.pageManager.reset();
        this.pageManager.refreshData();
    }
}

@observer
export default class ApprovalHistoryComponent extends React.Component<IApprovalHistoryComponentProps> {

    private appLayout = AppService.get(AppTypes.Shared.Services.AppLayout);

    public render() {

        const viewModel = this.props.viewModel;

        return (
            <Neo.Modal size="lg" title="Approval History" show={viewModel.showHistoryFor !== null} onClose={() => viewModel.showHistoryFor = null}>
                <div className="approval-history">
                {viewModel.showHistoryFor &&
                        <div>
                            <p>{viewModel.showHistoryFor.participantName}</p>

                            <Neo.Pager pageManager={viewModel.pageManager}
                                pageControls="top" pageControlProps={{ noOfButtons: this.props.pageButtons() }} >
                                <NeoGrid.Grid<ApprovalAuditLookup> className="approvals-grid" >
                                    {(item, meta) =>
                                        <NeoGrid.Row>
                                            <NeoGrid.Column hideBelow="sm"
                                                display={meta.changedOn} dateProps={{ formatString: "dd MMM yy HH:mm" }} />
                                            <NeoGrid.Column hideBelow="sm"
                                                display={meta.changedBy} />
                                            <NeoGrid.Column hideAbove="sm"
                                                label="Changed On">
                                                <span>
                                                    {DateUtils.format(item.changedOn, "dd MMM HH:mm")} by {item.changedBy}
                                                </span>
                                            </NeoGrid.Column>
                                            <NeoGrid.Column label="Status">
                                                <div className={`text-${item.entity.getVariant()}`}>
                                                    <i className={`fa fa-${item.entity.getIcon()}`} />
                                                    {this.appLayout.currentScreenSize >= ScreenSize.Small &&
                                                    <span>
                                                        {" "}{item.entity.approvalStatusString}
                                                    </span>}
                                                </div>
                                            </NeoGrid.Column>
                                            <NeoGrid.Column hideBelow="sm"
                                                display={item.entity.meta.declinedReason} />
                                            <NeoGrid.Column hideBelow="sm" label="Changed Offer" className={item.entity.originalOffer ? "changed" : ""}
                                                display={item.entity.meta.offeredUnitsValue} />
                                        </NeoGrid.Row>}
                                </NeoGrid.Grid>
                            </Neo.Pager>
                        </div>}
                </div>
            </Neo.Modal>
        )
    }
}