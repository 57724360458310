import { EnumHelper } from '@singularsystems/neo-core';

export enum AllowedExercisePriceType {
    NoLimitAllowed = 1,
    AllowLimitPrice = 2,
}

EnumHelper.decorateEnum(AllowedExercisePriceType, decorator => {
    decorator.describe(AllowedExercisePriceType.NoLimitAllowed, "No limit price");
    decorator.describe(AllowedExercisePriceType.AllowLimitPrice, "Allow limit price");
});