import { Attributes, LookupBase, ModelBase, NeoModel, Rules, Utils, Validation } from '@singularsystems/neo-core';

@NeoModel
export default class ParticipantEntityLookup extends LookupBase {

    public participantEntityId: number = 0;

    public entityName: string = "";

    // Client only properties / methods
}