import React from 'react';
import { observer } from 'mobx-react';
import { NeoModel, Validation } from '@singularsystems/neo-core';
import { IQuestionnaireComponentProps, QuestionnaireComponentVMBase } from '../../Acceptance/Components/Steps/Questionnaire/QuestionnaireComponent';
import QuestionsComponent from '../../Acceptance/Components/Steps/Questionnaire/QuestionsComponent';
import UpdateQuestionnaireAnswerCommand from '../../../Models/Questionnaires/Commands/UpdateQuestionnaireAnswerCommand';
import { Neo } from '@singularsystems/neo-react';

interface IMyAwardQuestionnaireComponentProps extends IQuestionnaireComponentProps<MyAwardQuestionnaireComponentComponentVM> {
}

@NeoModel
export class MyAwardQuestionnaireComponentComponentVM extends QuestionnaireComponentVMBase {

    public onAnswersSaved?: () => void;
    
    public validationDisplayMode: Validation.DisplayMode | undefined;

    protected afterSave(command: UpdateQuestionnaireAnswerCommand) {
        super.afterSave(command);

        if (this.onAnswersSaved) {
            this.onAnswersSaved();
        }
    }
}

@observer
export default class MyAwardQuestionnaireComponent extends React.Component<IMyAwardQuestionnaireComponentProps> {
    public render() {
        const viewModel = this.props.viewModel;
        return (<div>
            <div className="page-block-alt p-3">
                <h2>{viewModel.questionnaireAnsweredLookup.questionnaireName}</h2>
            </div>
            <div className="page-block p-3">
                <Neo.Form model={viewModel.updateQuestionnaireAnswerCommand.questionnaireAnswered} validationDisplayMode={viewModel.validationDisplayMode}>
                    <QuestionsComponent questionnaireAnswered={viewModel.updateQuestionnaireAnswerCommand.questionnaireAnswered}
                        disabled={this.props.disabled} />
                </Neo.Form>
            </div>
        </div>);
    }
}