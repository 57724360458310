import React from 'react';
import TradeRequestLookup from '../../../Models/Trading/Lookups/TradeRequestLookup';
import { DateUtils, Misc, NumberUtils } from '@singularsystems/neo-core';

interface ITradeRequestHiddenFieldsProps {
    tradeRequest: TradeRequestLookup
}

export class TradeRequestHiddenFields extends React.Component<ITradeRequestHiddenFieldsProps> {

    public render() {
        const tradeRequest = this.props.tradeRequest;

        return (
            <table className="hidden-fields">
                <tr><th>Placed</th><td>{DateUtils.format(tradeRequest.tradeDate)}</td></tr>
                <tr><th>Instrument</th><td>{tradeRequest.instrumentCode}</td></tr>
                <tr><th>Scheme</th><td>{tradeRequest.incentiveSchemeName}</td></tr>
                <tr><th>Type</th><td>{tradeRequest.type}</td></tr>
                <tr><th>Units</th><td>{NumberUtils.format(tradeRequest.totalQuantity, Misc.NumberFormat.NoDecimals)}</td></tr>
                <tr><th>Status</th><td>{tradeRequest.statusText}</td></tr>
            </table>
        )
    }
}