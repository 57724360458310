import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../Common/ClientsTypes';
import TenantLookup from '../Models/TenantLookup';
import TicketProviderUrlLookup from '../Models/TicketProviders/TicketProviderUrlLookup';

export interface IClientsApiClient {

    /**
     * Gets the current tenant info.
     * @returns The current tenant info.
     */
    getTenantLookup(): AxiosPromise<Model.PlainObject<TenantLookup>>;

    /**
     * Gets the Ticket Provider Redirect Url if the User exists
     * @returns A Task
     */
     getTicketProviderUrl(primaryUrl: boolean): AxiosPromise<Model.PlainObject<TicketProviderUrlLookup>>;

    // Client only properties / methods
}

@injectable()
export default class ClientsApiClient extends Data.ApiClientBase implements IClientsApiClient {

    constructor (config = AppService.get(Types.Shared.Config)) {
        super(`${config.ClientsApi.ApiPath}/Clients`);
    }

    public getTenantLookup(): AxiosPromise<Model.PlainObject<TenantLookup>> {
        return this.axios.get(`${this.apiPath}/tenant-lookup`);
    }

    public getTicketProviderUrl(primaryUrl: boolean): AxiosPromise<Model.PlainObject<TicketProviderUrlLookup>> {
        return this.axios.get(`${this.apiPath}/ticket-provider-url?primaryUrl=${primaryUrl}`);
    }

    // Client only properties / methods
}