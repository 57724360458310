import { Attributes, ModelBase, NeoModel } from '@singularsystems/neo-core';

@NeoModel
export default class CurrentTermsAndConditionsLookup extends ModelBase {

    public termsAndConditionsId: number = 0;

    @Attributes.Date()
    public effectiveDate: Date = new Date();

    public acceptLabelText: string = "";

    public termsAndConditionsHtml: string = "";

    public isUpdatedTermsAndConditions: boolean = false;

    // Client only properties / methods
}