import SharedTypes from './SharedTypes';
import { ClientsSharedTypes } from '../../Clients/Common/ClientsSharedTypes';
import { AwardsSharedTypes } from '../../Awards/ParticipantsApp/AwardsSharedTypes';
import { AllocationManagerSharedTypes } from '../../Awards/AllocationManager/ParticipantsApp/AllocationManagerSharedTypes';
import { AppServices } from '@singularsystems/neo-core';
import { NeoReactTypes } from '@singularsystems/neo-react';
import { IdentitySharedTypes } from '../../Identity/Common/IdentitySharedTypes';
import { TransactionsSharedTypes } from '../../Transactions/ParticipantsApp/TransactionsSharedTypes';
import { ParticipantsSharedTypes } from '../../Participants/ParticipantsApp/ParticipantsSharedTypes';
import { ReportingTypes } from '@singularsystems/neo-reporting';

// All shared type symbols for the app
const Types = {
    Neo: {
        ...AppServices.NeoTypes,
        ...NeoReactTypes
    },
    Shared: SharedTypes,
    Clients: ClientsSharedTypes,
    Awards: AwardsSharedTypes,
    Identity: IdentitySharedTypes,
    Transactions: TransactionsSharedTypes,
    Participants: ParticipantsSharedTypes,
    // Reporting: ReportingTypes,
    AllocationManager: AllocationManagerSharedTypes,
}

export default Types