import React from 'react';
import { Neo, Views } from '@singularsystems/neo-react';
import { Components } from '@singularsystems/neo-core';
import { observer } from 'mobx-react';

import ReleaseVM from './ReleaseVM';
import { ScreenSize } from '../../../../App/Services/AppLayout';
import { ModalUtils } from '@singularsystems/neo-core';

import DeclineApprovalsCommand from '../../Models/Approvals/Commands/DeclineApprovalsCommand';
import PagerTextFilter from '../../../../App/Common/Components/Application/PagerTextFilter';
import ParticipantAwardReleaseInfo from '../../../Common/Models/Approvals/Queries/ParticipantAwardReleaseInfo';

import ReleaseAwardsGrid from './Components/ReleaseAwardsGrid';
import ExchangeRateFooter from '../Components/ExchangeRateFooter';

import MyAwardsView from '../MyAwards/MyAwardsView';

@observer
export default class ReleaseView extends Views.ViewBase<ReleaseVM> {

    constructor(props: unknown) {
        super("Release", ReleaseVM, props);
    }

    private async declineAwards() {
        const declineCommand = new DeclineApprovalsCommand();

        if (this.viewModel.declineReasonRequired()) {
            this.checkActionModal(
                "Decline",
                this.viewModel.declineAwardsText,
                async () => {
                    if (declineCommand.declinedReason.length === 0) {
                        await ModalUtils.showMessage("Reason Required", "A reason is required for declined awards.");
                        this.declineAwards();
                    } else {
                        this.viewModel.declineAwards(declineCommand);
                    }
                },
                <div>
                    <p>Provide a reason for declining:</p>
                    <Neo.FormGroup bind={declineCommand.meta.declinedReason} suppressLabel />
                </div>);
        }
        else {
            this.checkActionModal("Decline", this.viewModel.declineAwardsText, () => this.viewModel.declineAwards(declineCommand));
        }
    }

    private approveAwards() {
        this.viewModel.approveAwards();
    }

    private releaseAwards() {
        this.checkActionModal("Release", this.viewModel.releaseAwardsText, () => this.viewModel.releaseAwards());
    }

    private cancelAwards() {
        this.checkActionModal("Cancel", this.viewModel.cancelAwardsText, () => this.viewModel.cancelAwards());
    }

    private checkActionModal(action: string, actionDetail: string, successAction: () => void, inputs?: any) {
        ModalUtils.showYesNoDismissible(
            `${action} Awards?`,
            <div>
                {inputs}
                <span>Are you sure you want to <strong>{actionDetail.toLocaleLowerCase()}</strong>?</span>
            </div>,
            successAction);
    }
    
    private back() {
        this.navigation.navigateToView(MyAwardsView);
    }

    public render() {
        return (
            <div className="award-release">
                <div className="row">
                    <div className="col">
                        <h2>{this.viewModel.awardModuleConfig.awardRelease.header}</h2>
                        <p>
                            {this.viewModel.awardModuleConfig.awardRelease.subHeader}
                        </p>
                    </div>
                </div>


                <Neo.Loader task={this.taskRunner}>
                    {/* Award Release */}
                    {this.taskRunner.isBusy &&
                        <Neo.Alert variant="info">Loading...</Neo.Alert>}

                    {!this.taskRunner.isBusy &&
                        <div>
                            <div className="row grid-tools">
                                {this.viewModel.hasData &&
                                    <div className="col">
                                        <PagerTextFilter filterProperty={this.viewModel.criteria.meta.filterString}
                                            label="Filter"
                                            pageManager={this.viewModel.pageManager}
                                            taskRunner={this.viewModel.pageManager.taskRunner} />
                                    </div>}

                                {this.viewModel.pageManager.totalPages > 1 && this.viewModel.pageManager.data.length > 0 &&
                                    <div className="col pager-holder right-tools">
                                        <Neo.PagerControlsBasic pageManager={this.viewModel.pageManager} noOfButtons={this.viewModel.getPagerNoOfButtons()} />
                                    </div>}
                            </div>

                            {this.viewModel.pageManager.data.length > 0 &&
                                <div>
                                    All Selected Mode: {this.viewModel.activeAwardSelector.inAllSelectedMode ? "Yes" : "No"}
                                    <ReleaseAwardsGrid
                                        pageManager={this.viewModel.pageManager}
                                        viewModel={this.viewModel}
                                        allSelected={this.viewModel.activeAwardSelector.inAllSelectedMode}
                                        showAwardDate 
                                        config={this.viewModel.awardModuleConfig.myAwards.grid} />

                                    <ExchangeRateFooter rates={this.viewModel.ratesCurrentlyUsed} >
                                        <span>{this.viewModel.selectedCount > 0 ? `Selected ${this.viewModel.selectedCount} of ${this.viewModel.pluralizer.numberPluralize(this.viewModel.awardCount, "award")}` : this.viewModel.pluralizer.numberPluralize(this.viewModel.awardCount, "award")}</span>
                                    </ExchangeRateFooter>

                                    {!this.viewModel.selectedParticipant &&
                                        <div className="release-buttons page-block-alt">
                                            <div>
                                                <Neo.Button variant="secondary" onClick={() => this.back()}>Back</Neo.Button>
                                            </div>

                                            <div className="right-tools">
                                                {this.getActionButtons()}
                                            </div>
                                        </div>}
                                </div>}
                        </div>}

                    {!this.taskRunner.isBusy && this.viewModel.pageManager.data.length === 0 &&
                        <Neo.Alert variant="primary">{this.viewModel.criteria.filterString.length > 0 ? "No awards can be found matching the filter string." : "There are no awards to release."}</Neo.Alert>}

                    <Neo.Modal
                        title={this.viewModel.awardModuleConfig.awardRelease.participantInfoTitle}
                        className="participant-awards-modal"
                        size="xl"
                        containerClassName="backdrop-blurred"
                        showCancelButton={false}
                        bindModel={this.viewModel.meta.selectedParticipant}
                        buttons={[
                            { text: "Close", variant: "secondary", onClick: () => this.viewModel.selectedParticipant = null },
                            ...this.getActionButtonOptions()
                        ]}>
                        {(participantInfo: ParticipantAwardReleaseInfo) => <div>
                            {participantInfo.awards.length > 0 &&
                                <div className="mb-2">
                                    <h4 className="text-heading">{participantInfo.awards[0].participantName}</h4>
                                    <div>
                                        <div className="d-inline">
                                            <strong>Code:</strong> {participantInfo.awards[0].participantCode}
                                        </div>
                                        <div className="d-inline ml-3">
                                            <strong>Email:</strong> {participantInfo.awards[0].correspondenceEmail}
                                        </div>
                                    </div>
                                </div>}
                            <p>{this.viewModel.awardModuleConfig.awardRelease.participantInfoText}</p>
                            <ReleaseAwardsGrid
                                pageManager={this.viewModel.selectedParticipantPageManager}
                                viewModel={this.viewModel}
                                allSelected={this.viewModel.participantAwardSelector.allSelected}
                                showTeamMember={false}
                                showSelection={false}
                                showParticipantInfoButton={false} 
                                config={this.viewModel.awardModuleConfig.myAwards.grid} />
                        </div>}
                    </Neo.Modal>
                </Neo.Loader>

            </div >
        );
    }

    private getActionButtons() {
        return this.getActionButtonOptions().map((options, index) => <Neo.Button key={`button-${index}-${options.variant}`} {...options} />)
    }

    private getActionButtonOptions(): Components.IButtonOptions[] {
        let buttons: Components.IButtonOptions[] = [];
        if (this.viewModel.declineAwardCount > 0) {
            buttons.push({ variant: "danger", isOutline: true, onClick: () => this.declineAwards(), text: this.viewModel.declineAwardsText });
        }
        if (this.viewModel.approveAwardCount > 0) {
            buttons.push({ variant: "success", isOutline: true, onClick: () => this.approveAwards(), text: this.viewModel.approveAwardsText });
        }
        if (this.viewModel.releaseAwardCount > 0) {
            buttons.push({ variant: "primary", onClick: () => this.releaseAwards(), text: this.viewModel.releaseAwardsText });
        }
        if (this.viewModel.cancelAwardCount > 0) {
            buttons.push({ variant: "warning", onClick: () => this.cancelAwards(), text: this.viewModel.cancelAwardsText });
        }

        if (buttons.length > 3 && this.viewModel.appLayout.currentScreenSize <= ScreenSize.Medium) {
            // make 1 drop down button
            for (const btn of buttons) {
                btn.icon = `minus text-${btn.variant}`;
            }
            buttons = [
                { variant: "primary", text: "Action", menuItems: buttons }
            ];
        }

        return buttons;
    }
}