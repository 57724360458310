import { AppServices } from '@singularsystems/neo-core';
import { AppService, AppTypes } from '../../App/Services/AppService';
import { ParticipantsSharedTypes } from './ParticipantsSharedTypes';
import { IParticipantsAppCatalogueApiClient } from './ApiClients/ParticipantsAppCatalogueApiClient';

const Types = {
    ...AppTypes,
    Participants: {
        ApiClients: {
            MainApiClient: ParticipantsSharedTypes.ApiClients.MainApiClient,
            CatalogueApiClient: new AppServices.ServiceIdentifier<IParticipantsAppCatalogueApiClient>("Participants.ApiClients")
        },
        Services: {
            ...ParticipantsSharedTypes.Services
        }
    } 
}

export { AppService, Types }