import { NeoModel, Rules, Validation, ValueObject } from '@singularsystems/neo-core';

@NeoModel
export default class UpdateContactDetailsCommand extends ValueObject {
    
    @Rules.Required()
    @Rules.EmailAddress()
    public correspondenceEmail: string = "";

    @Rules.Required()
    @Rules.RegExp(new RegExp("^(\\+\\d{1,2}\\s)?\\(?\\d{3}\\)?[\\s-]?\\d{3}[\\s-]?\\d{4}$"), "Invalid mobile number.")
    public correspondenceMobileNumber: string = "";

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        return "Update Contact Details Command";
    }
}