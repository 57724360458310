import { Attributes, NeoModel, Utils, Validation, ModelBase } from '@singularsystems/neo-core';

@NeoModel
export default class ChangeApprovalParticipantOfferCommand extends ModelBase {

    public approverParticipantId: string = Utils.emptyGuid();

    public awardApprovalId: number = 0;

    public participantOfferId: number = 0;

    @Attributes.Float()
    public newOfferedUnits: number = 0;

    // Client only properties / methods

    public awardPrepId: number = 0;
    
    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew || !this.approverParticipantId) {
            return "New change approval participant offer command";
        } else {
            return this.approverParticipantId;
        }
    }
}