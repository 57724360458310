import { injectable } from 'inversify';
import { Misc, NumberUtils } from '@singularsystems/neo-core';

export interface IPluralizer {
    /**
     * Pluralizes the word. E.g. (item) => items
     */
    pluralize(word: string): string;

    /**
     * Pluralizes the word if the value of the number provided is not 1.
     */
    pluralizeIfMany(number: number, word: string): string;

    /**
     * Returns the number and the conditionally pluralised word. E.g. (1, item) => 1 item or (2, item) => 2 items
     */
    numberPluralize(number: number, word: string): string; 

    /**
     * Returns a prefix for a number. Use in conjunction with numberPluralize(). E.g. no items, is 1 item, are 2 items.
     */
    isAreNo(number: number): string;

    /**
     * Returns this item / these items depending on the value of the provided number.
     */
    thisThese(number: number, word: string): string;
}

@injectable()
export default class Pluralizer implements IPluralizer {

    public pluralize(word: string): string {
        return word + "s";
    }

    public pluralizeIfMany(number: number, word: string) {
        if (Math.abs(number) !== 1) {
            return this.pluralize(word);
        } else {
            return word;
        }
    }

    public numberPluralize(number: number, word: string)  {
        if (number === undefined) {
            return `No ${this.pluralizeIfMany(2, word)}`;
        } else {
            const numberString = NumberUtils.format(number, Misc.NumberFormat.NoDecimals);
            return `${numberString} ${this.pluralizeIfMany(number, word)}`;
        }
    }

    public isAreNo(number: number)  {
        return number === 0 ? "no" : (number === 1 ? "is" : "are");
    }

    public thisThese(number: number, word: string): string {
        return `${number === 1 ? "this" : "these"} ${this.pluralizeIfMany(number, word)}`;
    }
}