import { Attributes, NeoModel, Validation, ValueObject } from '@singularsystems/neo-core';
import { ExcludeClosePeriodType } from '../Enums/ExcludeClosePeriodType';

@NeoModel
export default class IncentiveSchemeSeparationConfig extends ValueObject {

    @Attributes.Integer()
    public daysToExercise: number = 0;

    @Attributes.Integer()
    public accelerateVestingDays: number = 0;

    public accelerateVesting: boolean = false;

    public takeUpOnVesting: boolean = false;

    public apportionAwardUnits: boolean = false;

    public apportionNextVesting: boolean = false;

    public retainVestedAwardUnits: boolean = false;

    public selectTranches: boolean = false;

    public appliesToGoodLeavers: boolean = false;

    public enableSeparationCalculations: boolean = false;

    public excludeClosePeriodType: ExcludeClosePeriodType = ExcludeClosePeriodType.None;

    @Attributes.Integer()
    public daysToExerciseAfterClosePeriod: number = 0;

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);

        rules.failWhen(c => c.daysToExercise < 0, "Days To Exercise (Before Options Forfeit) must be >= 0");
        rules.failWhen(c => c.daysToExerciseAfterClosePeriod < 0, "Days To Exercise After Close Period (Before Options Forfeit) must be >= 0");

        // TODO: double check this rule, not sure where it came from
        // rules.failWhen(c => c.daysToExercise > 0 && c.accelerateVestingDays > 0,
        //     "Days To Exercise (Before Options Forfeit) and Accelerate Vesting Days cannot both be > 0")
        //     .onProperties(c => [c.daysToExercise, c.accelerateVestingDays]);

        // TODO: check this, UAT data for tenant 1 seems to violate this rule
        rules.failWhen(c => c.accelerateVesting && c.accelerateVestingDays > 0,
            "Accelerate Vesting Days cannot be > 0 when Accelerate Vesting is selected")
            .onProperties(c => [c.accelerateVesting, c.accelerateVestingDays]);

        rules.failWhen(
            c => c.selectTranches && (c.takeUpOnVesting || c.daysToExercise > 0 || c.accelerateVesting || c.accelerateVestingDays > 0),
            "Incentive Scheme Separation Conditions must be left empty when associated Separation Condition has Select Tranches selected")
            .onProperties(c => [c.selectTranches, c.takeUpOnVesting, c.daysToExercise, c.accelerateVesting, c.accelerateVestingDays]);

        rules.failWhen(c => c.takeUpOnVesting && (c.apportionAwardUnits || c.apportionNextVesting),
            "Cannot select Take Up On Vesting when Apportion Award Units or Apportion Next Vesting are selected.")
            .onProperties(c => [c.takeUpOnVesting, c.apportionAwardUnits, c.apportionNextVesting]);

        rules.failWhen(c => c.takeUpOnVesting && (c.accelerateVesting || c.accelerateVestingDays > 0),
            "Cannot select Accelerate Vesting or specify Accelerate Vesting Days when Take Up On Vesting is selected")
            .onProperties(c => [c.takeUpOnVesting, c.accelerateVesting, c.accelerateVestingDays]);

        rules.failWhen(c => c.daysToExercise === 0 && c.excludeClosePeriodType === ExcludeClosePeriodType.ExcludeClosePeriods,
            "Cannot select Exclude Close Periods if Days to Exercise is 0.")
            .onProperties(c => [c.daysToExercise, c.excludeClosePeriodType]);

        rules.failWhen(c => c.daysToExerciseAfterClosePeriod === 0 && c.excludeClosePeriodType === ExcludeClosePeriodType.AddToClosePeriod,
            "Cannot select Add to Close Period if Days to Exercise After Close Period is 0.")
            .onProperties(c => [c.daysToExerciseAfterClosePeriod, c.excludeClosePeriodType]);

        rules.failWhen(c => c.daysToExerciseAfterClosePeriod !== 0 && c.excludeClosePeriodType !== ExcludeClosePeriodType.AddToClosePeriod,
            "Cannot have Days to Exercise After Close Period > 0 if Exclude Close Period Type is not Add to Close Period.")
            .onProperties(c => [c.daysToExerciseAfterClosePeriod, c.excludeClosePeriodType]);

        rules.failWhen(c => c.apportionAwardUnits && c.apportionNextVesting,
            "Cannot select Apportion Award Units when Apportion Next Vesting is selected.")
            .onProperties(c => [c.apportionAwardUnits, c.apportionNextVesting]);

        rules.failWhen(c => c.enableSeparationCalculations && (c.accelerateVesting || c.selectTranches || c.retainVestedAwardUnits || c.takeUpOnVesting || c.apportionAwardUnits || c.apportionNextVesting),
            "Cannot select Enable Separation Calculations when Accelerate Vesting, Select Tranches, Take up on Vesting, Apportion Award Units or Apportion Next Vesting is selected.")
            .onProperties(c => [c.enableSeparationCalculations]);
    }

    public toString(): string {
        return "Incentive Scheme Separation Config";
    }
}