import * as React from 'react';
import { Neo } from '@singularsystems/neo-react';
import { Components, Utils } from '@singularsystems/neo-core';

export interface IPageBlockProps {
    alt?: boolean;
    columns?: Components.columnCount;
    alignment?: Components.flexItemAlign;
    className?: string;
    children?: React.ReactNode;
}

export class PageBlock extends React.Component<IPageBlockProps> {
    public render() {
        return (
            <div className={Utils.joinWithSpaces("row page-block", this.props.alt ? "page-block-alt" : undefined, this.props.className)}>
                <div className="col-12">
                    <Neo.GridLayout md={this.props.columns ?? 2} withGaps alignItems={this.props.alignment}>
                        {this.props.children}
                    </Neo.GridLayout>
                </div>
            </div>);
    }
}
