import { Attributes, List, LookupBase, NeoModel, Utils } from '@singularsystems/neo-core';
import { AllocationManagerType } from '../Enums/AllocationManagerType';
import AllocationManagerIncentiveGroupBudget from './AllocationManagerIncentiveGroupBudget';
import AllocationIncentiveGroupBudgetTotalLookup from './AllocationIncentiveGroupBudgetTotalLookup';
import AllocationManagerAllocationTotalsLookup from './AllocationManagerAllocationTotalsLookup';
import AllocationLastEditedInfoLookup from './AllocationLastEditedInfoLookup';

@NeoModel
export default class AllocationManagerLookup extends LookupBase {

    public allocationManagerId: number = 0;

    public allocationPlanId: number = 0;

    public allocationPlanTypeId: number = 0;

    public managerTypeId: AllocationManagerType | null = null;

    public reportingAllocationManagerId: number = 0;

    public isUnlocked: boolean = false;

    @Attributes.Observable()
    @Attributes.Date()
    public checkedDate: Date | null = null;

    @Attributes.Date()
    public lastApprovalDate: Date | null = null;

    public participantId: string = Utils.emptyGuid();

    public participantCode: string = "";

    public participantName: string = "";

    public correspondenceEmail: string = "";

    public allocationTotals: AllocationManagerAllocationTotalsLookup = new AllocationManagerAllocationTotalsLookup();

    @Attributes.Observable()
    public lastEditedInfoLookup: AllocationLastEditedInfoLookup = new AllocationLastEditedInfoLookup();

    public incentiveGroupBudgets = new List(AllocationManagerIncentiveGroupBudget);

    public remainingIncentiveGroupBudgets = new List(AllocationIncentiveGroupBudgetTotalLookup);

    @Attributes.Date()
    public startDate: Date = new Date();

    @Attributes.Date()
    public endDate: Date = new Date();

    @Attributes.Date()
    public allocationPlanEndDate: Date = new Date();

    @Attributes.Date()
    public allocationPlanStartDate: Date = new Date();

    @Attributes.Date()
    public checkTeamDate: Date = new Date();

    @Attributes.Date()
    public levelTwoManagerEndDate: Date = new Date();

    // Client only properties / methods

    public canEdit: boolean = false;

    public get isLevelOneManager() {
        return this.managerTypeId === AllocationManagerType.Level1Manager;
    }

    public get isHrAssist() {
        return this.managerTypeId === AllocationManagerType.HRAssistant;
    }

    public get isHrAssistManager() {
        return this.managerTypeId === AllocationManagerType.HRAssistantManager;
    }

    public get isHistoricView() {
        var currentDate = new Date();
        var endDate = new Date(this.allocationPlanEndDate);
        var startDate = new Date(this.allocationPlanStartDate);
        return endDate > currentDate && startDate < currentDate;
    }
}