import { LookupBase, NeoModel } from '@singularsystems/neo-core';

@NeoModel
export default class InstrumentOptionLookup extends LookupBase {

    public instrumentOptionId: number = 0;

    public instrumentId: number = 0;

    // Client only properties / methods
}