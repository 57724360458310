import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../AwardsTypes';
import StartQuestionnaireAnswerCommand from '../../Models/Questionnaires/Commands/StartQuestionnaireAnswerCommand';
import UpdateQuestionnaireAnswerCommand from '../../Models/Questionnaires/Commands/UpdateQuestionnaireAnswerCommand';

export interface IAnswerQuestionnaireCommandApiClient {

    /**
     * Will update the participant's selection.
     * @param command The update participant selection command
     * @returns A task awaiting the completion of the command
     */
    startQuestionnaireAnswersAsync(command: Model.PartialPlainObject<StartQuestionnaireAnswerCommand>): AxiosPromise<any>;

    /** 
     * Will accept the participant's current selection.
     * @param command The update participant selection command
     * @returns A task awaiting the completion of the command
     */
    updateQuestionnaireAnswersAsync(command: Model.PartialPlainObject<UpdateQuestionnaireAnswerCommand>): AxiosPromise<any>;

    // Client only properties / methods
}

@injectable()
export default class AnswerQuestionnaireCommandApiClient extends Data.ApiClientBase implements IAnswerQuestionnaireCommandApiClient {

    constructor (config = AppService.get(Types.Shared.Config)) {
        super(`${config.AwardsApi.ApiPath}/AnswerQuestionnaireCommand`);
    }

    public startQuestionnaireAnswersAsync(command: Model.PartialPlainObject<StartQuestionnaireAnswerCommand>): AxiosPromise<any> {
        return this.axios.post(`${this.apiPath}/create`, command);
    }

    public updateQuestionnaireAnswersAsync(command: Model.PartialPlainObject<UpdateQuestionnaireAnswerCommand >): AxiosPromise<any> {
        return this.axios.post(`${this.apiPath}/update`, command);
    }

    // Client only properties / methods
}