import { Attributes, List, ModelBase, NeoModel, StringUtils } from '@singularsystems/neo-core';
import QuestionnaireQuestionAnswerLookup from './QuestionnaireQuestionAnswerLookup';

@NeoModel
export default class QuestionnaireAnsweredLookup extends ModelBase {

    public questionnaireAnsweredId: number = 0;

    public questionnaireId: number = 0;

    public questionnaireName: string = "";

    @Attributes.Date()
    public completedOn: Date | null = null;

    public answers = new List(QuestionnaireQuestionAnswerLookup);

    // Client only properties / methods

    public get requiredQuestions() {
        return this.answers.filter(a => a.isRequired).length;
    }

    public get answeredQuestions() {
        return this.answers.filter(a => !StringUtils.isNullOrWhitespace(a.questionAnswer)).length;
    }

    public isCompleted() {
        return this.answeredQuestions >= this.requiredQuestions;
    }
}