import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../AwardsTypes';
import AwardOfferLookup from '../../Models/ParticipantOffers/Queries/AwardOfferLookup';
import { OfferLookup } from '../../Models/ParticipantOffers/Queries/OfferLookup';
import AwardParticipantOfferTotalLookup from '../../../Common/Models/ParticipantOffers/Queries/AwardParticipantOfferTotalLookup';

export interface IParticipantOffersQueryApiClient {

    /**
     * Will get the approval summary for the current logged in participant
     * @returns The approval summary
     */
    getAwardOffersAsync(): AxiosPromise<Array<Model.PlainObject<AwardOfferLookup>>>;

    /**
     * Will get the approval summary for the current logged in participant
     * @param participantOfferId The participant offer id
     * @returns The approval summary
     */
    getOfferedAwardDetailAsync(participantOfferId: number): AxiosPromise<Model.PlainObject<OfferLookup>>;

    /** 
     * Will get the participant summary for the provided award prep id
     * @param awardPrepId The award prep id
     * @returns The participant summary for the provided award prep id
     */
    getParticipantOfferTotalsLookup(awardPrepId: number): AxiosPromise<Array<Model.PlainObject<AwardParticipantOfferTotalLookup>>>;

    // Client only properties / methods
}

@injectable()
export default class ParticipantOffersQueryApiClient extends Data.ApiClientBase implements IParticipantOffersQueryApiClient {

    constructor (config = AppService.get(Types.Shared.Config)) {
        super(`${config.AwardsApi.ApiPath}/ParticipantOffersQuery`);
    }

    public getAwardOffersAsync(): AxiosPromise<Array<Model.PlainObject<AwardOfferLookup>>> {
        return this.axios.get(`${this.apiPath}/awardOffers`);
    }

    public getOfferedAwardDetailAsync(participantOfferId: number): AxiosPromise<Model.PlainObject<OfferLookup>> {
        return this.axios.get(`${this.apiPath}/awardOffers/${participantOfferId}`);
    }

    public getParticipantOfferTotalsLookup(awardPrepId: number): AxiosPromise<Array<Model.PlainObject<AwardParticipantOfferTotalLookup>>> {
        return this.axios.get(`${this.apiPath}/awardTotals/${awardPrepId}`);
    }

    // Client only properties / methods
}