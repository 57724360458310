import { Attributes, Data, List, LookupBase, NeoModel, Utils } from '@singularsystems/neo-core';
import { AppService, Types } from '../../AllocationManagerTypes';
import AllocationParticipantLookup from './AllocationParticipantLookup';
import AllocationParticipantLookupCriteria from './AllocationParticipantLookupCriteria';
import AllocationManagerIncentiveGroupBudget from './AllocationManagerIncentiveGroupBudget';
import AllocationIncentiveGroupBudgetTotalLookup from './AllocationIncentiveGroupBudgetTotalLookup';

@NeoModel
export default class AllocationManagerLinkedManagerLookup extends LookupBase {

    constructor() {
        super();

        this.directReportCriteria.onAnyPropertyChanged(() => this.directReportsPageManager.refreshData());
    }

    public allocationManagerLinkedManagerId: number = 0;

    public allocationManagerId: number = 0;

    public participantId: string = Utils.emptyGuid();

    public participantName: string = "";

    public participantCode: string = "";

    public correspondenceEmail: string = "";

    public incentiveGroupBudgets = new List(AllocationManagerIncentiveGroupBudget);

    public remainingIncentiveGroupBudgets = new List(AllocationIncentiveGroupBudgetTotalLookup);

    public allocationsComplete: number = 0;
    
    @Attributes.Observable()
    public allocationsOutstanding: number = 0;

    @Attributes.Date()
    public lastApprovalDate: Date | null = null;

    @Attributes.Integer()
    public totalTeamMembers: number = 0;

    // Client only properties / methods

    public prevHasCompleted = false;

    public participantGradeIds: number[] = [];
    /*
    This is used to calculate whether or not there was a change in the completed status of this linked manager.
        If there was a change then the new status is stored in prevHasCompleted and the resulting change value is returned.
        If there is no change in status then a zero value is returned.

    The result is used to modify the allocation total labels on the allocation manager view.
    */
    public getHasCompletedChangedValue() {
        const newHasCompleted = this.hasCompleted;
        if (newHasCompleted && !this.prevHasCompleted) {
            this.prevHasCompleted = true;
            return 1;
        } else if (!newHasCompleted && this.prevHasCompleted) {
            this.prevHasCompleted = false;
            return -1;
        }
        return 0;
    }

    private queryClient = AppService.get(Types.AllocationManager.ApiClients.AllocationParticipantQueryApiClient);
    @Attributes.Observable()
    @Attributes.NoTracking()
    private _isExpanded = false;

    public get isExpanded() {
        return this._isExpanded;
        }
    public set isExpanded(value: boolean) {
        if (value && !this.directReportsPageManager.hasFetched) {
            this.directReportsPageManager.refreshData();
            this.setParticipantGradeIds();
        }
        this._isExpanded = value;
    }

    public async setParticipantGradeIds(){        
        const response = await this.queryClient.getParticipantGradeIds(this.directReportCriteria.allocationManagerParticipantId);
        this.participantGradeIds = response.data;
    }
    
    @Attributes.NoTracking()
    public directReportCriteria = new AllocationParticipantLookupCriteria();

    @Attributes.NoTracking()
    public directReportsPageManager = new Data.PageManager(
    this.directReportCriteria,
    AllocationParticipantLookup,
    this.queryClient.getAllocationParticipantsPaged,
    {
        sortBy: "participantName",
        pageSize: 10,
        fetchInitial: false,
    })

    private get hasCompleted() {
        return this.allocationsOutstanding === 0;
    }

    protected onDeserialiseCompleted() {
        this.directReportCriteria.allocationManagerParticipantId = this.participantId;
        this.prevHasCompleted = this.hasCompleted;
    }

    public get totalMembersLabel() {
        return this.totalTeamMembers + " team members";
    }
}