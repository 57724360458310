import { List, LookupBase, NeoModel } from '@singularsystems/neo-core';
import GridColumnConfig from '../../../Common/Models/GridColumnConfig';
import AllocationManagerLookup from './AllocationManagerLookup';
import AllocationPlanInformation from './AllocationPlanInformation';

@NeoModel
export default class AllocationManagerStartupData extends LookupBase {

    public allocationManager: AllocationManagerLookup = new AllocationManagerLookup();

    public allocationPlanInformation = new List(AllocationPlanInformation);

    public hasDirectReports: boolean = false;

    public gridColumnConfig = new List(GridColumnConfig);

    public participantGradeIds: number[] = [];

    public allocationCurrencyCode: string = "";

    // Client only properties / methods
}