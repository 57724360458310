import { Attributes, ModelBase, NeoModel, Validation } from '@singularsystems/neo-core';
import QuestionnaireAnsweredLookup from '../Queries/QuestionnaireAnsweredLookup';

@NeoModel
export default class UpdateQuestionnaireAnswerCommand extends ModelBase {

    public questionnaireAnsweredId: number | null = 0;

    @Attributes.ChildObject(QuestionnaireAnsweredLookup)
    public questionnaireAnswered = new QuestionnaireAnsweredLookup();

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew) {
            return "New update questionnaire answer command";
        } else {
            return "Update Questionnaire Answer Command";
        }
    }
}