import { LookupBase, NeoModel } from '@singularsystems/neo-core';

@NeoModel
export default class CurrencyLookup extends LookupBase {

    public currencyId: number = 0;

    public currencyCode: string = "";

    public currencyName: string = "";

    public symbol: string = "";

    public isMain: boolean = false;

    // Client only properties / methods
}