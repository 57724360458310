import { LookupBase, NeoModel } from '@singularsystems/neo-core';

@NeoModel
export default class TicketProviderUrlLookup extends LookupBase {

    public foundUser: boolean = false;

    public redirectUrl: string = "";

    public redirectMessage: string = "";

    // Client only properties / methods
}