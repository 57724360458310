import React from 'react';
import { observer } from 'mobx-react';
import { NeoModel } from '@singularsystems/neo-core';
import { AppService, Types } from '../../../../../AwardsTypes';

import QuestionnaireAnsweredLookup from '../../../../../Models/Questionnaires/Queries/QuestionnaireAnsweredLookup';
import UpdateQuestionnaireAnswerCommand from '../../../../../Models/Questionnaires/Commands/UpdateQuestionnaireAnswerCommand';
import StartQuestionnaireAnswerCommand from '../../../../../Models/Questionnaires/Commands/StartQuestionnaireAnswerCommand';
import QuestionsComponent from './QuestionsComponent';
import { Views } from '@singularsystems/neo-react';

export interface IQuestionnaireComponentProps<TQuestionnaireVM extends QuestionnaireComponentVM> {
    viewModel: TQuestionnaireVM;
    disabled: boolean;
}

export class QuestionnaireComponentVMBase extends Views.ViewModelBase {

    public questionnaireAnsweredLookup = new QuestionnaireAnsweredLookup();
    public updateQuestionnaireAnswerCommand = new UpdateQuestionnaireAnswerCommand();

    protected loadedQuestionnaireAnsweredId = 0;

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private queryQuestionnaireClient = AppService.get(Types.Awards.ApiClients.AnswerQuestionnaireQueryApiClient),
        private commandQuestionnaireClient = AppService.get(Types.Awards.ApiClients.AnswerQuestionnaireCommandApiClient)) {

        super(taskRunner);
    }

    // Questionnaire Answers 
    public async loadQuestionnaireAnswers(participantOfferId: number, questionnaireId: number | null, questionnaireAnsweredId: number | null) {
        if (questionnaireId) {
            await this.taskRunner.run(async () => {
                if (questionnaireAnsweredId === null) {
                    questionnaireAnsweredId = await this.startQuestionnaireAnswersAsync(participantOfferId, questionnaireId);
                }
                await this.loadQuestionnairesAnsweredAsync(questionnaireAnsweredId!);
            });
        }
        return questionnaireAnsweredId;
    }

    public async startQuestionnaireAnswersAsync(participantOfferId: number, questionnaireId: number | null) {
        var startQuestionnaireAnswerCommand = new StartQuestionnaireAnswerCommand();
        startQuestionnaireAnswerCommand.questionnaireId = questionnaireId;
        startQuestionnaireAnswerCommand.participantOfferId = participantOfferId;
        var result = await this.commandQuestionnaireClient.startQuestionnaireAnswersAsync(startQuestionnaireAnswerCommand.toJSObject());
        return result.data;
    }

    public async loadQuestionnairesAnsweredAsync(questionnaireAnsweredId: number) {
        if (questionnaireAnsweredId && this.loadedQuestionnaireAnsweredId !== questionnaireAnsweredId) {
            this.updateQuestionnaireAnswerCommand.questionnaireAnsweredId = questionnaireAnsweredId;
            const resultCommand = await this.queryQuestionnaireClient.getQuestionnairesAnsweredAsync(questionnaireAnsweredId);
            this.questionnaireAnsweredLookup.set(resultCommand.data as any);
            this.updateQuestionnaireAnswerCommand.questionnaireAnswered = this.questionnaireAnsweredLookup;
            this.updateQuestionnaireAnswerCommand.markOld();
            this.updateQuestionnaireAnswerCommand.validator.forceShowErrors = false;

            this.loadedQuestionnaireAnsweredId = questionnaireAnsweredId
        }
    }

    public saveQuestionnaireAnswers() {
        if (this.updateQuestionnaireAnswerCommand.isDirty) {
            this.taskRunner.run(async () => {
                const cmd = this.updateQuestionnaireAnswerCommand.toJSObject({ includeClean: true });
                await this.commandQuestionnaireClient.updateQuestionnaireAnswersAsync(cmd);
                this.updateQuestionnaireAnswerCommand.markOld();

                this.afterSave(this.updateQuestionnaireAnswerCommand);
            });
        }
    }

    protected afterSave(command: UpdateQuestionnaireAnswerCommand) {
         if (command.questionnaireAnswered.isCompleted()) {
             command.questionnaireAnswered.completedOn = new Date();
         }
    }
}

@NeoModel
export class QuestionnaireComponentVM extends QuestionnaireComponentVMBase {

}

export abstract class QuestionnaireComponentBase<TQuestionnaireComponentProps extends IQuestionnaireComponentProps<TQuestionnaireVM>,
    TQuestionnaireVM extends QuestionnaireComponentVM>
    extends React.Component<TQuestionnaireComponentProps> {

}

@observer
export default class QuestionnaireComponent extends QuestionnaireComponentBase<IQuestionnaireComponentProps<QuestionnaireComponentVM>, QuestionnaireComponentVM> {

    public render() {
        const viewModel = this.props.viewModel;
        return (
            <QuestionsComponent questionnaireAnswered={viewModel.updateQuestionnaireAnswerCommand.questionnaireAnswered}
                disabled={this.props.disabled} />
        );
    }
}