import { ModelBase, NeoModel, Validation } from '@singularsystems/neo-core';

@NeoModel
export default class AllocationParticipantAddRequest extends ModelBase {

    public participantName: string = "";

    public additionalNotes: string = "";

    public changeRequestedByFullName: string = "";

    public allocationManagerFullName: string = "";

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew || !this.participantName) {
            return "New allocation participant add request";
        } else {
            return this.participantName;
        }
    }
}