import { ModelBase, NeoModel, Utils, Validation } from '@singularsystems/neo-core';

@NeoModel
export default class UpdateAllocationManagerCommand extends ModelBase {

    public participantId: string = Utils.emptyGuid();

    public approved: boolean = false;

    public readyForReview: boolean = false;

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew) {
            return "New update allocation manager command";
        } else {
            return "Update Allocation Manager Command";
        }
    }
}