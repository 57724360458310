import { Attributes, NeoModel, Validation, ValueObject } from '@singularsystems/neo-core';
import AllocationManagerRecipientConfig from './AllocationManagerRecipientConfig';
import AllocationManagerSenderConfig from './AllocationManagerSenderConfig';
import AllocationManagerChangeControlConfig from './AllocationManagerChangeControlConfig';
import AllocationManagerMiscConfig from './AllocationManagerMiscConfig'

@NeoModel
export default class AllocationManagerConfig extends ValueObject {

    @Attributes.ChildObject(AllocationManagerRecipientConfig)
    public recipientConfig: AllocationManagerRecipientConfig = new AllocationManagerRecipientConfig();

    @Attributes.ChildObject(AllocationManagerSenderConfig)
    public senderConfig: AllocationManagerSenderConfig = new AllocationManagerSenderConfig();

    @Attributes.ChildObject(AllocationManagerChangeControlConfig)
    public changeControlConfig: AllocationManagerChangeControlConfig = new AllocationManagerChangeControlConfig();

    @Attributes.ChildObject(AllocationManagerMiscConfig)
    public miscConfig: AllocationManagerMiscConfig = new AllocationManagerMiscConfig();
    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        return "Allocation Manager Config";
    }
}