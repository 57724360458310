import { ModelBase, NeoModel, Rules, Validation } from '@singularsystems/neo-core';
import AllocationManagerMediaObject from '../AllocationManagerMediaObject';

@NeoModel
export default class AllocationPlanInformationSupportMedia extends ModelBase {

    public allocationPlanInformationSupportMediaId: number = 0;

    public allocationManagerMediaObject: AllocationManagerMediaObject = new AllocationManagerMediaObject();

    public showDocument: boolean = false;

    @Rules.Required()
    public allocationPlanInformationId: number | null = null;

    // Client only properties / methods
    
    public imageURL: string = "";

    public documentURL: string = "";

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew) {
            return "New allocation plan information support media";
        } else {
            return "Allocation Plan Information Support Media";
        }
    }
}