import 'core-js/stable';
import * as React from 'react';
import ReactDOM from 'react-dom/client';
import './App/AppSetup'
import App from './App/App';
import { unregister } from './registerServiceWorker';
import { AppService, AppTypes } from './App/Services/AppService';
import { App as AppType } from './Clients/Common/Models/Enums/App';
import { UserManager } from 'oidc-client-ts';
import { Config } from './App/Models/Config/Config';
import { OidcAuthenticationService } from './App/Services/OidcAuthenticationService';

(async function init() {

    if (OidcAuthenticationService.isSilentSignIn(Config.silentSignInRoute)) {
        return;
    }

    let renderComponent = <App />;

    try {
        await AppService.get(AppTypes.Neo.Config.ConfigService).loadConfig();
        const config = AppService.get(AppTypes.Shared.Config);

        const authService = AppService.get(AppTypes.Neo.Security.AuthenticationService);
        if (config.isDevelopment) {
            await authService.loadUserFromSession();
        }

        if (window.location.href.indexOf("SingleSignOn") >= 0) {
            if (!authService.isAuthenticated) {
                
                let userManager = new UserManager(config.userManagerSettings);
                let st_sso = new URLSearchParams(window.location.search).get("st_sso");
                userManager.signinRedirect({ state: "/", extraQueryParams: { st_sso: st_sso! } });
                return;
            } else {
                window.location.href = "/";
                return;
            }
        }

        if (window.location.href.toLowerCase().indexOf("redirect") < 0 && !authService.isAuthenticated) {
            authService.beginSignIn(window.location.pathname);
            return;
        }

        const styleService = AppService.get(AppTypes.Clients.Services.StyleService);
        await styleService.initialise(AppType.Participants);
    } catch (e) {
        console.log(e);
        renderComponent = <div>The app failed to load.</div>;
    }

    // React init
    const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
    root.render(renderComponent);

})();

// Service Worker disabled. 
// We have multiple applications running off the same base URL (E.g. /identityserver, /clients, /admin, etc),
// and the service worker is causing problems by always returning a cached copy of index.html for any page 
// requested after the initial load of participants app. Redirects to identityserver fail because instead of
// calling the IDS service, a call to /identityserver/??? results in the app's index.html being returned. 
// While it is possible to add the URLs to a blacklist, you need to have ejected create-react-app and also
// need to edit configuration inside the webpack.config.js file to add the various URLs to an 'exclude' list,
// which is then something we'd have to remember to maintain in the case of additional services being added.
unregister();