import { ModelBase, NeoModel } from '@singularsystems/neo-core';

@NeoModel
export default class AllocationManagerAllocationTotalsLookup extends ModelBase {

    public allocationsComplete: number = 0;
    
    public allocationsOutstanding: number = 0;

    public linkedManagersComplete: number = 0;
    
    public linkedManagersStillToComplete: number = 0;

    // Client only properties / methods
}