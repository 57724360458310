import React from 'react';
import { AwardStatus } from '../../Common/Models/Enums/AwardStatus';
import { injectable } from 'inversify';
import { NumberUtils, Misc, DateUtils, Components, Model } from '@singularsystems/neo-core';
import { Verbosity } from '../Models/Enums/Verbosity';

import { AppService, AppTypes as Types } from '../../../App/Services/AppService';
import ApprovalProgressBaseLookup from '../Models/Approvals/Queries/ApprovalProgressBaseLookup';
import { IProgressSegment } from '../../../App/Common/Components/Application/SegmentedProgressBar';
import { Neo } from '@singularsystems/neo-react';
import AcceptanceProgressLookup from '../Models/AwardPreparation/Queries/AcceptanceProgressLookup';

@injectable()
export default class AwardUtils {

    constructor(private pluralizer = AppService.get(Types.Shared.Services.Pluralizer)) {
    }

    public getStatusIcon(status: AwardStatus) {
        switch(status) {
            case AwardStatus.Preparation: return "user-circle";
            case AwardStatus.ReleasingForApproval: return "users-cog";
            case AwardStatus.Approval: return "check-circle";
            case AwardStatus.PreRelease: return "spinner";
            case AwardStatus.PreparingForProcessing: 
            case AwardStatus.ReleasingForAcceptance: return "spinner";
            case AwardStatus.Acceptance: return "play-circle";
            case AwardStatus.Processing: return "circle-notch";
            case AwardStatus.Active: return "universal-access";
            case AwardStatus.Rejected: return "times";
            default: return "cogs";
        }
    }

    public pluralize(word: string, quantity: number) {
        if (quantity === 1) { return `1 ${word}`; }
        else { return `${NumberUtils.format(quantity, Misc.NumberFormat.NoDecimals)} ${this.pluralizeWord(word)}` };
    }

    public pluralizeHtml(word: string, quantity: number): React.ReactNode {
		if (quantity === 1) { return <span><strong>1 </strong> {word}</span> }
		else return <span><strong>{NumberUtils.format(quantity, Misc.NumberFormat.NoDecimals)} </strong> {this.pluralizeWord(word)}</span>
	}

	private pluralizeWord(word: string) {
		// only deals with "participant" for now 
		return word + 's';
    }
    
    public getClosingHtml(verbosity: Verbosity, daysRemaining: number, actionBy: Date | null, closingText: string = "Closing in", closedText: string = "Closed"): React.ReactNode {
        return <span>{daysRemaining > 0 ? closingText + " " : closedText + " "}<span className={daysRemaining <= 0 ? "danger" : ""}><strong>{this.pluralizer.numberPluralize(Math.abs(daysRemaining), "day")}{daysRemaining >= 0 ? "" : " ago"}</strong></span>
                {(verbosity === Verbosity.Detailed ? <span>{" "}on the <strong>{DateUtils.format(actionBy!, DateUtils.defaultFormatString)}</strong></span> : true)}
                </span>;
    }
    
    public getAcceptanceSelectedProgressSegments(progress: AcceptanceProgressLookup): IProgressSegment[] {
        return [
            { name: "Approval Declined", value: progress.approvalDeclined, variant: "dark" },
            { name: "Declined", value: progress.selectedDeclined + progress.submittedDeclined, variant: "warning" },
            { name: "Accepted partial", value: progress.selectedAcceptedPartial + progress.submittedAcceptedPartial, variant: "primary" },
            { name: "Accepted", value: progress.selectedAcceptedAll + progress.submittedAcceptedAll, variant: "success" },
        ];
    }
    
    public getAcceptanceSubmittedProgressSegments(progress: AcceptanceProgressLookup): IProgressSegment[] {
        return [
            { name: "Approval Declined", value: progress.approvalDeclined, variant: "dark" },
            { name: "Declined", value: progress.submittedDeclined, variant: "warning" },
            { name: "Accepted partial", value: progress.submittedAcceptedPartial, variant: "primary" },
            { name: "Accepted", value: progress.submittedAcceptedAll, variant: "success" },
        ];
    }
    
    public getApprovalProgressSegments(progress: ApprovalProgressBaseLookup): IProgressSegment[] {
        return [
            { name: "Declined", value: progress.declined, variant: "warning" },
            { name: "Approved", value: progress.approved, variant: "success" },
        ];
    }
    
    public getApprovalProgressText(progress: ApprovalProgressBaseLookup) {
        return <span>
                    <strong>{NumberUtils.format(progress.completedPercent, Misc.NumberFormat.PercentNoDecimals)} ({NumberUtils.format(progress.completed, Misc.NumberFormat.NoDecimals)} / {NumberUtils.format(progress.total, Misc.NumberFormat.NoDecimals)})</strong> captured
                </span>;
    }

    public getApprovalCompletionProgressSegments(progress: ApprovalProgressBaseLookup): IProgressSegment[] {
        return [
            { name: "Submitted", value: progress.submitted, variant: "primary" },
            { name: "Not yet submitted", value: progress.completed - progress.submitted, variant: "secondary" },
        ];
    }
    
    public getApprovalCompletionProgressText(progress: ApprovalProgressBaseLookup) {
        return  <span>
                    <strong>{NumberUtils.format(progress.submittedPercent, Misc.NumberFormat.PercentNoDecimals)} ({NumberUtils.format(progress.submitted, Misc.NumberFormat.NoDecimals)} / {NumberUtils.format(progress.total, Misc.NumberFormat.NoDecimals)}</strong> submitted
                </span>;
    }

    public getFilterButtonTip(filterName: string, isSelected: boolean) {
        return `${isSelected ? "Un-select" : "Select"} to ${isSelected ? "exclude" : "include"} ${filterName} approvals`;
    }

    public getFilterButton(filterChangeHandler: () => void, icon: string, variant: Components.variant, verbPast: string, prop: Model.IPropertyInstance, inverse = false) {
        return <Neo.Button size="sm" tabIndex={-1} icon={icon} variant={variant} data-tip={this.getFilterButtonTip(verbPast, inverse ? prop.value : !prop.value)} isOutline={inverse ? !prop.value : prop.value}
            onClick={() => {
                prop.value = !prop.value;
                filterChangeHandler();
            }} />;
    }
}