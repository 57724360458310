export enum AwardStatus {
    Preparation = 1,
    ReleasingForApproval = 9,
    Approval = 10,
    PreRelease = 20,
    ReleasingForAcceptance = 29,
    Acceptance = 30,
    PreparingForProcessing = 39,
    Processing = 40,
    Active = 100,
    Rejected = -1,
}

export const AwardStatusClassMapping = {} as { [index: number]: string }
AwardStatusClassMapping[AwardStatus.Preparation] = "warning";
AwardStatusClassMapping[AwardStatus.Approval] = "secondary";
AwardStatusClassMapping[AwardStatus.ReleasingForApproval] = "danger";
AwardStatusClassMapping[AwardStatus.PreRelease] = "info";
AwardStatusClassMapping[AwardStatus.ReleasingForAcceptance] = "danger";
AwardStatusClassMapping[AwardStatus.Acceptance] = "alternate";
AwardStatusClassMapping[AwardStatus.PreparingForProcessing] = "danger";
AwardStatusClassMapping[AwardStatus.Processing] = "process";
AwardStatusClassMapping[AwardStatus.Active] = "success";