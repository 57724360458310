import { Attributes, NeoModel, Rules, Validation, ValueObject } from '@singularsystems/neo-core';
import AwardReleaseConfig from './AwardReleaseConfig';
import MyAwardsConfig from './MyAwardsConfig';
import SchemeConfig from './SchemeConfig';

@NeoModel
export default class AwardModuleConfig extends ValueObject {

    @Attributes.ChildObject(AwardReleaseConfig)
    public awardRelease: AwardReleaseConfig = new AwardReleaseConfig();

    @Attributes.ChildObject(MyAwardsConfig)
    public myAwards: MyAwardsConfig = new MyAwardsConfig();

    @Attributes.ChildObject(SchemeConfig, true)
    public schemeConfig = new SchemeConfig();

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        return "Award Module Config";
    }
}