import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../AllocationManagerTypes';
import AllocationCommentType from '../Models/AllocationCommentType';

export interface IAllocationCommentTypeApiClient {

    /**
     * The GET Allocation Comment Type Endpoint
     * @returns A list of Allocation Comment Types from the database.
     */
    getAllocationCommentTypes(): AxiosPromise<Array<Model.PlainTrackedObject<AllocationCommentType>>>;

    /** 
     * The GET Allocation Comment Type Endpoint
     * @returns A list of Allocation Comment Types from the database.
     */
    getActiveAllocationCommentTypes(): AxiosPromise<Array<Model.PlainTrackedObject<AllocationCommentType>>>;

    /**
     * End Point to Save the Allocation Comment Type List
     * @param allocationCommentTypes The Allocation Comment Type List to Save
     * @returns A List of Allocation Comment Types
     */
    saveAllocationCommentTypeListAsync(allocationCommentTypes: Array<Model.PartialPlainObject<AllocationCommentType>>): AxiosPromise<Array<Model.PlainTrackedObject<AllocationCommentType>>>;

    // Client only properties / methods
}

@injectable()
export default class AllocationCommentTypeApiClient extends Data.ApiClientBase implements IAllocationCommentTypeApiClient {

    constructor (config = AppService.get(Types.Shared.Config)) {
        super(`${config.AwardsApi.ApiPath}/allocation-comment-type`);
    }

    public getAllocationCommentTypes(): AxiosPromise<Array<Model.PlainTrackedObject<AllocationCommentType>>> {
        return this.axios.get(`${this.apiPath}`);
    }

    public getActiveAllocationCommentTypes(): AxiosPromise<Array<Model.PlainTrackedObject<AllocationCommentType>>> {
        return this.axios.get(`${this.apiPath}/active`);
    }

    public saveAllocationCommentTypeListAsync(allocationCommentTypes: Array<Model.PartialPlainObject<AllocationCommentType>>): AxiosPromise<Array<Model.PlainTrackedObject<AllocationCommentType>>> {
        return this.axios.post(`${this.apiPath}`, allocationCommentTypes);
    }

    // Client only properties / methods
}