import { AppServices, Misc, Numeric, Security } from '@singularsystems/neo-core';
import { NotificationServiceTypes } from '@singularsystems/neo-notifications';

import Types from './Types/AppTypes';

import Axios from 'axios';
import AuthorisationService from './Services/AuthorisationService';
import { Config } from './Models/Config/Config';
import { OidcAuthenticationService } from './Services/OidcAuthenticationService';
import { AppDataService } from './Services/AppDataService';
import { RouteService } from './Services/RouteService';
import { MenuItemProvider } from './Services/MenuItemProvider';
import AppLayout from './Services/AppLayout';
import Pluralizer from './Common/Services/Pluralizer';
import STUtils from './Common/Services/STUtils';
import AsyncSelect from 'react-select/async'
import ReactSelect from 'react-select';
import Decimal from 'decimal.js-light';
// import { ReportingTypes } from '@singularsystems/neo-reporting';

const appModule = new AppServices.Module("App", container => {
    
    // Neo Types
    container.bind(Types.Neo.Components.AsyncSelect).toConstantValue(AsyncSelect);
    container.bind(Types.Neo.Components.ReactSelect).toConstantValue(ReactSelect);
    container.bind(Types.Neo.Core.DecimalService).toConstantValue(new Numeric.DecimalService(Decimal, Decimal.ROUND_HALF_UP, Decimal.ROUND_DOWN));

    // Config
    container.bind(Types.Shared.Config).to(Config).inSingletonScope();
    container.bindConfig(NotificationServiceTypes.ConfigModel, (c: Config) => c.notificationServerConfig);
    // container.bindConfig(ReportingTypes.ConfigModel, (c: Config) => c.reportingConfig);
    
    // Security
    container.bind(Types.Neo.Security.AuthenticationService).to(OidcAuthenticationService).inSingletonScope();
    container.bind(Types.Neo.Security.AuthorisationService).to(AuthorisationService).inSingletonScope();
    container.bind(Types.Neo.Security.SecurityService).to(Security.SecurityService).inSingletonScope();

    // Api clients
    container.bind(Types.Neo.Axios).toConstantValue(Axios);

    // Data cache
    container.bind(Types.Shared.Services.AppDataCache).to(AppDataService).inSingletonScope();

    // Services
    container.bind(Types.Shared.Services.RouteService).to(RouteService).inSingletonScope();
    container.bind(Types.Neo.Routing.MenuItemProvider).to(MenuItemProvider).inSingletonScope();
    container.bind(Types.Shared.Services.AppLayout).to(AppLayout).inSingletonScope(); 
    container.bind(Types.Shared.Services.Pluralizer).to(Pluralizer).inSingletonScope(); 
    container.bind(Types.Shared.Services.STUtils).to(STUtils).inSingletonScope(); 

    Misc.Settings.grid.isBordered = false;
    Misc.Settings.grid.useStickyHeaders = true;
   
});

const appTestModule = new AppServices.Module("App", container => {

});

export { appModule, appTestModule };