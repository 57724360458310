import { Attributes, ModelBase, NeoModel } from '@singularsystems/neo-core';

@NeoModel
export class QuestionnaireSummary extends ModelBase {

    public questionnaireId: number = 0;

    @Attributes.Nullable()
    public questionnaireAnsweredId: number | null = null;

    @Attributes.Integer()
    public questionsCount: number = 0;

    @Attributes.Integer()
    public requiredAnswersCount: number = 0;

    @Attributes.Integer()
    public answersCount: number = 0;

    @Attributes.Date()
    public completedOn: Date | null = null;

    // Client only properties / methods
    public get completedQuestionnairePercentage() {
        return this.answersCount / this.questionsCount;
    }

    public get completedQuestions() {
        return this.answersCount === this.questionsCount;
    }
}