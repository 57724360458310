import { ModelBase, NeoModel, Validation } from '@singularsystems/neo-core';
import { GridColumn } from './Enums/GridColumn';

@NeoModel
export default class GridColumnConfig extends ModelBase {

    public gridColumnConfigId: number = 0;

    public gridColumnId: GridColumn | null = null;

    public gridColumnName: string = "";

    public gridColumnTooltip: string = "";

    public isHidden: boolean = false;

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew || !this.gridColumnName) {
            return "New grid column config";
        } else {
            return this.gridColumnName;
        }
    }
}