import { AppServices } from "@singularsystems/neo-core";
import { IPricingApiClient } from "./ApiClients/PricingApiClient";
import { IPortfolioService } from './Services/IPortfolioService';
import { IExchangeRateService } from './Services/ExchangeRateService';
import { IPricingService } from './Services/PricingService';

export const TransactionsSharedTypes = {
    ApiClients: {
        PricingApiClient: new AppServices.ServiceIdentifier<IPricingApiClient>("Transactions.PricingApiClient"),
    },
    Services: {
        PortfolioService: new AppServices.ServiceIdentifier<IPortfolioService>("Transactions.PortfolioService"),
        ExchangeRateService : new AppServices.ServiceIdentifier<IExchangeRateService>("Transactions.ExchangeRateService"),
        PricingService : new AppServices.ServiceIdentifier<IPricingService>("Transactions.PricingService"),
    }
}