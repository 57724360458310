import { Attributes, Misc, ModelBase, Validation } from '@singularsystems/neo-core';
import DocumentRequirementCheckResult from '../../Compliance/DocumentRequirementCheckResult';

export default abstract class TradeRequestCommandBase extends ModelBase {

    @Attributes.Date(Misc.TimeZoneFormat.None)
    public expiryDate: Date | null = null;

    public forexCover: boolean = false;

    @Attributes.Nullable()
    public paymentCountryId: number | null = null;
    
    // Client only properties / methods

    @Attributes.NoTracking()
    public allowForexCover: boolean = false;

    public abstract get maxExpiryDate(): Date;
    public abstract get requiresExpiryDate(): boolean;
    public abstract get requiresBrokerDetails(): boolean;
    public abstract get requiresAdditionalDetails(): boolean;
    public abstract get failedDocumentChecks(): DocumentRequirementCheckResult[] | undefined;
    public abstract calcAllowForexCover(baseCurrencyId: number): void;
    public abstract clearLimitPrice(): void;

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);

        rules.failWhen(c => c.requiresExpiryDate && !c.expiryDate, "Expiry date is required.").onProperties(c => c.expiryDate);
    }
}