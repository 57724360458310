import { Attributes, Validation, ValueObject } from '@singularsystems/neo-core';
import AwardPrepApprovalConfig from './AwardPrepApprovalConfig';
import { AwardProcessType } from '../Enums/AwardProcessType';
import { AwardApprovalType } from '../Enums/AwardApprovalType';

export default class AwardPrepConfig extends ValueObject {

    constructor() {
        super();
        this.makeObservable();
    }

    public awardProcessType: AwardProcessType = AwardProcessType.ManuallyProcessed;

    public approvalType: AwardApprovalType = AwardApprovalType.RequiresApproval;

    public useExpectedValue: boolean = false;

    @Attributes.Nullable()
    public questionnaireId: number | null = null;

    public questionnaireRequiredForDecliningParticipants: boolean = false;

    @Attributes.Nullable()
    public awardConditionId: number | null = null;

    public approvals: AwardPrepApprovalConfig = new AwardPrepApprovalConfig();

    public showAwardClassifications: boolean = false;

    public showRequiredPercentageIncrement: boolean = false;

    public allowSeparatedParticipants: boolean = false;

    public showEnableChargeOuts: boolean = false;

    // Client only properties / methods

    public get requiresApproval() {
        return this.approvalType === AwardApprovalType.RequiresApproval;
    }
    public set requiresApproval(value: boolean) {
        this.approvalType = value ? AwardApprovalType.RequiresApproval : AwardApprovalType.NoApproval;
    }

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        return "Awards Config";
    }
}