import React from 'react';
import { Neo, Views } from '@singularsystems/neo-react';
import ChangePasswordVM from './ChangePasswordVM';
import { observer } from 'mobx-react';

@observer
export default class ChangePasswordView extends Views.ViewBase<ChangePasswordVM> {

    constructor(props: unknown) {
        super("Change Password", ChangePasswordVM, props);
    }

    public render() {
        return (
            <div className="more-padding">
			    <h2>Change Password</h2>

                {this.viewModel.isIDSUser &&
                    <div>
                        <p className="my-4">
                            Please enter your current, and new password below. 
                            Passwords need to be at least 8 characters in length, and have at least one occurrence of each of the following: 
                            Uppercase Characters, Lowercase Characters, Numbers and Special Characters.
                        </p>

                        <div className="row">
                            <div className="col-xl-8 col-xxl-7">
                                <Neo.Form model={this.viewModel.changePasswordCommand} onSubmit={() => this.viewModel.changePassword()}>
                                    <Neo.GridLayout>
                                        <Neo.FormGroup bind={this.viewModel.changePasswordCommand.meta.oldPassword} input={{ type: "password", showPasswordButton: true }} />
                                        <div />
                                        <Neo.FormGroup bind={this.viewModel.changePasswordCommand.meta.newPassword} input={{ type: "password", showPasswordButton: true }} />
                                        <Neo.FormGroup bind={this.viewModel.changePasswordCommand.meta.confirmPassword} input={{ type: "password", showPasswordButton: true }} />
                                    </Neo.GridLayout>
                                    <div className="text-right">
                                        <Neo.Button className="btn-150 mt-4" isSubmit>Change password</Neo.Button>
                                    </div>
                                </Neo.Form>
                                
                            </div>
                        </div>
                    </div>}
                {!this.viewModel.isIDSUser && 
                    <div>
                        You logged in using an external identity provider. Please change your password in the external system.
                    </div>}
            </div>
        );
    }
}