import { List, ModelBase, NeoModel, Rules, Validation } from '@singularsystems/neo-core';
import AcceptAwardCommandDetail from './AcceptAwardCommandDetail';

@NeoModel
export default class AcceptAwardsCommand extends ModelBase {

    public awards = new List(AcceptAwardCommandDetail);

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew) {
            return "New accept awards command";
        } else {
            return "Accept Awards Command";
        }
    }
}