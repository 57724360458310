import React from "react";
import ProxyUserParticipantLookup from "../Models/ProxyUsers/Queries/ProxyUserParticipantLookup";

import { AppService, Types } from '../CommonIdentityTypes';

import styles from './ProxyAs.module.scss';

export default class ProxyAsComponent extends React.Component<{ participant: ProxyUserParticipantLookup, onClick: (participant: ProxyUserParticipantLookup) => void }> {

    private utils = AppService.get(Types.Shared.Services.STUtils);

    public render() {
        var participant = this.props.participant;
        return (
            <div className={styles["proxy-participant"]} key={participant.entityIdentifier} onClick={() => this.props.onClick(participant)}>
                <div className={styles["initials-container"]}>
                    <div className={styles["initials"]}>
                        {this.utils.getInitials(participant.participantName)}
                    </div>
                </div>

                <div className={styles["name-container"]} >
                    <div className={styles["name"]}>
                        {participant.participantName}
                    </div>
                </div>
            </div>);
    }
}