import { LookupBase, NeoModel } from '@singularsystems/neo-core';

@NeoModel
export default class MainCurrencyLookup extends LookupBase {

    public mainCurrencyId: number = 0;

    public currencyCode: string = "";

    public currencyName: string = "";

    public symbol: string = "";

    public isDefault: boolean = false;

    // Client only properties / methods
}