import { ModelBase, NeoModel, Rules, Validation } from '@singularsystems/neo-core';

@NeoModel
export default class ParticipantGrade extends ModelBase {

    public participantGradeId: number = 0;

    @Rules.Required()
    @Rules.StringLength(100)
    public participantGradeName: string = "";

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew || !this.participantGradeName) {
            return "New participant grade";
        } else {
            return this.participantGradeName;
        }
    }
}