import { Attributes, ModelBase, Utils, Validation } from '@singularsystems/neo-core';
export default abstract class ChangeApprovalsCommandBase extends ModelBase {

    public allApproverApprovals: boolean = false;

    public approverParticipantId: string = Utils.emptyGuid();

    public awardApprovalIds: number[] = [];

    public excludeAwardApprovalIds: number[] = [];

    @Attributes.Nullable()
    public awardPrepId: number | null = 0;

    public filterString: string = "";

    public allEligibleAwards: boolean = false;

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }
}