import { Attributes, Model, NeoModel } from '@singularsystems/neo-core';

@NeoModel
export default class FileDescriptor extends Model.FileDescriptorBase {

    @Attributes.Date()
    public createdOn: Date = new Date();

    // Client only properties / methods

    public toString(): string {
        if (this.isNew) {
            return "New file descriptor";
        } else {
            return "File Descriptor";
        }
    }
}