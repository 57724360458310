import React from 'react';
import { Neo } from '@singularsystems/neo-react';
import { observer } from 'mobx-react';
import { Components, Model } from '@singularsystems/neo-core';
import UpdateParticipantBrokerDetailCommand from '../Models/UpdateParticipantBrokerDetailCommand';

interface IBrokerDetailsComponentProps {
    command: UpdateParticipantBrokerDetailCommand;
    readOnly?: boolean;
    columns?: Components.columnCount;
}

@observer
export class BrokerDetailsComponent extends React.Component<IBrokerDetailsComponentProps> {

    render() {
        const command = this.props.command,
            brokerDetails = this.props.command.participantBrokerDetail;
        const isDisabled = this.props.readOnly ?? false;
        return (
            <div>
                <Neo.FormContext allDisabled={isDisabled}>
                    <Neo.GridLayout md={this.props.columns ?? 2}>
                        <Neo.FormGroup bind={brokerDetails.meta.brokingFirm} />
                        <Neo.FormGroup bind={brokerDetails.meta.accountNumber} />
                        <Neo.FormGroup bind={brokerDetails.meta.brokerContactPerson} />
                        <Neo.FormGroup bind={brokerDetails.meta.brokerPhoneNo} appendText="fa-phone" />
                    </Neo.GridLayout>
                    <Neo.FormGroup bind={brokerDetails.meta.brokerEmailAddress} appendText="fa-envelope" />
                    {brokerDetails.hasPendingTrades &&
                        <Neo.FormGroup
                            label={`Update${command.tradeRequestId ? " other" : ""} pending trades?`}
                            bind={command.meta.updatePendingTradeRequests}
                            data-tip="Tick if you want to update all pending trades to use these broker details."
                            data-tip-align="start" />}
                </Neo.FormContext>
            </div>)
    }
}

@observer
export class EditTradeBrokerDetailsModal extends React.Component<{ bind: Model.IPropertyInstance, onSave: () => void, preventSave?: boolean }> {

    render() {
        return (
            <Neo.Modal
                title="Edit broker details"
                bindModel={this.props.bind}
                size="lg"
                closeButton={{ text: "Cancel" }}
                acceptButton={{ text: "Save", variant: "primary", disabled: this.props.preventSave, onClick: this.props.onSave }}>
                {(brokerDetails: UpdateParticipantBrokerDetailCommand) => <div>
                    <BrokerDetailsComponent command={brokerDetails} />
                </div>}
            </Neo.Modal>
        )
    }
}