import { Attributes, ModelBase, NeoModel, Rules, Validation } from '@singularsystems/neo-core';

@NeoModel
export default class AllocationPlanIncentiveSchemeInfo extends ModelBase {

    public allocationPlanIncentiveSchemeInfoId: number = 0;

    @Rules.Required()
    public allocationPlanId: number | null = null;

    public incentiveSchemeId: number = 0;

    @Rules.StringLength(100)
    public readableSchemeName: string = "";

    @Attributes.Float()
    public expectedValuePercentage: number = 0;

    @Attributes.Float()
    public dataGenerationExchangeRate: number = 0;

    @Attributes.Float()
    public reportingExchangeRate: number = 0;

    @Rules.StringLength(250)
    public description: string = "";

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew || !this.readableSchemeName) {
            return "New allocation plan incentive scheme info";
        } else {
            return this.readableSchemeName;
        }
    }
}