import { Attributes, DateUtils, Misc, Validation, ValueObject } from '@singularsystems/neo-core';

export default class AwardCostsCriteria extends ValueObject {

    constructor() {
        super();
        this.makeObservable();
    }

    public trancheId: number = 0;

    @Attributes.Date(Misc.TimeZoneFormat.None)
    public balanceDate: Date = DateUtils.today();

    // Client only properties / methods

    protected static addBusinessRules(rules: Validation.Rules<AwardCostsCriteria>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        return "Award Costs Criteria";
    }
}