import { Attributes, ModelBase, NeoModel } from '@singularsystems/neo-core';
import FileDescriptor from '../../../../../Common/Models/Files/FileDescriptor';
import { AwardDocumentDeliveryType } from '../../../../../Common/Models/AwardDocuments/AwardDocumentDeliveryType.enum';
import { AwardDocumentAcceptanceType } from '../../../../../Common/Models/AwardDocuments/AwardDocumentAcceptanceType.enum';
import ParticipantOfferAwardDocumentLookup from '../../../../../Common/Models/AwardDocuments/Queries/ParticipantOfferAwardDocumentLookup';

@NeoModel
export default class MyAwardDocumentLookup extends ModelBase {

    public participantOfferAwardDocumentId: number = 0;

    @Attributes.Nullable()
    public incentiveSchemeDocumentId: number | null = null;

    public participantOfferId: number = 0;

    public documentName: string = "";

    @Attributes.Integer()
    public orderNum: number = 0;

    @Attributes.ChildObject(FileDescriptor)
    public downloadFileDescriptor: FileDescriptor | null = null;

    public awardDocumentType: string = "";

    public deliveryType: AwardDocumentDeliveryType | null = null;

    public acceptanceType: AwardDocumentAcceptanceType | null = null;

    public acceptedTermsAndConditions: boolean | null = null;

    public uploadedFileName: string = "";

    @Attributes.Date()
    public respondedOn: Date | null = null;

    @Attributes.Date()
    public documentDate: Date = new Date();

    public acceptedAwardSummary: boolean = false;

    // Client only properties / methods

    @Attributes.NoTracking()
    public document: ParticipantOfferAwardDocumentLookup | null = null;

    public get documentViewAction() {
        if (this.acceptanceType !== AwardDocumentAcceptanceType.None || this.deliveryType === AwardDocumentDeliveryType.HtmlTemplate) {
            return "View";
        } else {
            return "Download";
        }
    }

    public get requiresCompletion() {
        return this.acceptanceType !== AwardDocumentAcceptanceType.None;
    }

    public get isCompleted() {
        return this.acceptanceType === AwardDocumentAcceptanceType.None ||
            (this.acceptanceType === AwardDocumentAcceptanceType.AcceptTermsAndConditions && this.acceptedTermsAndConditions === true && this.respondedOn !== null) ||
            (this.acceptanceType === AwardDocumentAcceptanceType.DocumentUpload && this.uploadedFileName && this.uploadedFileName.length > 0 && this.respondedOn !== null);
    }
}