import { ModelBase, NeoModel, Validation } from '@singularsystems/neo-core';
import { ChangeRequestReason } from './Enums/ChangeRequestReason';

@NeoModel
export default class AllocationParticipantChangeRequest extends ModelBase {

    public changeRequestReasonId: ChangeRequestReason | null = null;

    public managerName: string = "";

    public removalReason: string = "";

    public additionalNotes: string = "";

    public participantFullName: string = "";

    public participantCorrespondenceEmail: string = "";

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew || !this.managerName) {
            return "New allocation participant change request";
        } else {
            return this.managerName;
        }
    }
}