import { TradeAuthorisationStatus, TradeBuyStatus, TradeSellStatus } from "../Models/Enums/TradeStatus";
import { TaxDirectiveStatus } from "../Models/Enums/TaxDirectiveStatus";
import { ICancellableTradeRequestLookup, ITradeRequestLookup } from "../Models/ITradeRequestLookup";
import { DateUtils } from "@singularsystems/neo-core";

export class TradeStatusHelper {

    public static getStatusText(tradeRequest: ITradeRequestLookup): string {

        switch (tradeRequest.sellStatus) {
            case TradeSellStatus.PendingCancellation:
                return "Cancelling...";
            case TradeSellStatus.Cancelled:
                return "Cancelled";
            case TradeSellStatus.Expired:
                return "Expired";
        }

        if (tradeRequest.buyStatus !== TradeBuyStatus.Cancelled && tradeRequest.authorisationStatus === TradeAuthorisationStatus.AwaitingAuthorisation) {
            return "Awaiting authorisation";
        }

        switch (tradeRequest.sellStatus) {
            case TradeSellStatus.Default:
            case TradeSellStatus.AwaitingBatching:
                return "Sale waiting to be sent to broker";       
            case TradeSellStatus.AwaitingInternalPrice:
                return "Waiting for sale price";         
            case TradeSellStatus.Batched:
            case TradeSellStatus.SentToExchange:
                return "Sent to broker";
        }

        const requiresTaxDirective = tradeRequest.settlementRequiresTaxDirective && ((tradeRequest.buyStatus === TradeBuyStatus.Cancelled ? 0 : tradeRequest.buyQuantity) > 0 || tradeRequest.soldQuantity > 0);
        
        if (tradeRequest.taxDirectiveStatus !== TaxDirectiveStatus.Complete && tradeRequest.taxDirectiveStatus !== TaxDirectiveStatus.NotApplicable && requiresTaxDirective) {
            return "Waiting for tax directive";
        }
        if (tradeRequest.buyStatus !== TradeBuyStatus.NotApplicable) {
            if (!tradeRequest.participantBrokerDetailId && tradeRequest.buyStatus !== TradeBuyStatus.Cancelled) {
                return "Waiting for broker details";
            }
            if (tradeRequest.sellStatus === TradeSellStatus.Settled && tradeRequest.buyStatus === TradeBuyStatus.Cancelled) {
                return "Sale complete, buy cancelled";
            }
            switch (tradeRequest.buyStatus) {
                case TradeBuyStatus.Pending:
                case TradeBuyStatus.Processed:
                    return "Awaiting transfer authorisation";
                case TradeBuyStatus.Authorised:
                    return "Transfer authorised";
                case TradeBuyStatus.SentToBroker:
                    return "Transfer sent to broker";
                case TradeBuyStatus.Cancelled:
                    return "Cancelled";
            }
        }
        if (tradeRequest.isComplete) {
            return "Complete";
        } else {
            return "Proceeds ready to be paid";
        }
    }

    public static getSimpleStatusText(tradeRequest: ITradeRequestLookup) {

        if (tradeRequest.sellStatus === TradeSellStatus.Cancelled || tradeRequest.buyStatus === TradeBuyStatus.Cancelled) {
            return "Cancelled";
        }
        if (tradeRequest.isComplete) {
            return "Complete";
        } else {
            return "Pending";
        }
    }

    public static canCancel(tradeRequest: ICancellableTradeRequestLookup, isAdmin: boolean, buyPortionOnly = false): boolean {

        if (!(tradeRequest.taxDirectiveStatus === TaxDirectiveStatus.None || tradeRequest.taxDirectiveStatus === TaxDirectiveStatus.NotApplicable)) {
            return false;
        }

        if (!isAdmin) {
            if (tradeRequest.authorisationStatus === TradeAuthorisationStatus.ManuallyAuthorised) {
                return false;
            }
            if (tradeRequest.tradeInstructionCloseDate != null) {
                if (DateUtils.today() > tradeRequest.tradeInstructionCloseDate) {
                    return false;
                }
            }
        }

        if (buyPortionOnly) {
            return this.canCancelBuyPortion(tradeRequest);
        } else {
            return this.canCancelSalePortion(tradeRequest) && 
                (tradeRequest.buyStatus === TradeBuyStatus.NotApplicable || this.canCancelBuyPortion(tradeRequest))
        }
    }

    private static canCancelBuyPortion(tradeRequest: ICancellableTradeRequestLookup): boolean {
        return tradeRequest.buyStatus === TradeBuyStatus.Pending && tradeRequest.buyBatchId === null;
    }

    private static canCancelSalePortion(tradeRequest: ICancellableTradeRequestLookup): boolean {

        if (tradeRequest.sellStatus === TradeSellStatus.AwaitingBatching) {
            return tradeRequest.settlementInstrumentCode === null;
        }

        return tradeRequest.sellStatus === TradeSellStatus.Default ||
            tradeRequest.sellStatus === TradeSellStatus.AwaitingInternalPrice ||
            tradeRequest.sellStatus === TradeSellStatus.NotApplicable;
    }
}