import { injectable } from "inversify";
import { Data } from '@singularsystems/neo-core';
import { AppService, Types } from '../AwardsTypes';
import { LifeTime } from '../../../App/Models/Enums/LifeTime';
import IncentiveGroupLookup from "../../Common/Models/IncentiveSchemes/IncentiveGroupLookup";

@injectable()
export default class AwardsDataCache extends Data.CachedDataService {

    constructor(
        private catalogueApi = AppService.get(Types.Awards.ApiClients.CatalogueQueryApiClient),
        private incentiveSchemesApi = AppService.get(Types.Awards.ApiClients.IncentiveSchemesQueryApiClient),
        private incentiveGroupsApiClient = AppService.get(Types.Awards.ApiClients.IncentiveGroupsQueryApiClient),
        private moduleConfigApiClient = AppService.get(Types.Awards.ApiClients.AwardModuleConfigApiClient)) {
        super();
     }
    
    public instruments = this.register(this.catalogueApi.getInstrumentLookup, LifeTime.Long);
    public incentiveSchemes = this.register(this.incentiveSchemesApi.getLookupAll, LifeTime.Short);
    public particpantIncentiveSchemes = this.register(this.incentiveSchemesApi.getParticipantIncentiveSchemeLookup, LifeTime.Long);
    public incentiveGroupsLookup = this.registerList(IncentiveGroupLookup, this.incentiveGroupsApiClient.getLookupList, LifeTime.Long);
    
    public awardModuleConfig = this.register(this.moduleConfigApiClient.get, LifeTime.Long);
}