import { injectable } from "inversify";
import { Data } from '@singularsystems/neo-core';

import { AppService, Types } from '../TransactionsTypes';

import { LifeTime } from '../../../App/Models/Enums/LifeTime';

@injectable()
export default class TransactionsDataCache extends Data.CachedDataService {

    constructor(
        private feesApi = AppService.get(Types.Transactions.ApiClients.FeesApiClient)) {
        super();

     }
    
    public rates = this.register(this.feesApi.getCurrentRates, LifeTime.Long);


}