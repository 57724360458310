import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../CommonIdentityTypes';
import ChangePasswordCommand from '../Models/ChangePasswordCommand';

export interface IAccountApiClient {

    /** 
     * Changes the password for the current user.
     * @param oldPassword Old password.
     * @param newPassword New password.
     * @returns Task.
     */
    changePassword(command: Model.PartialPlainObject<ChangePasswordCommand>): AxiosPromise;

    // Client only properties / methods
}

@injectable()
export default class AccountApiClient extends Data.ApiClientBase implements IAccountApiClient {

    constructor (config = AppService.get(Types.Shared.Config)) {
        super(`${config.IdentityServerApi.ApiPath}/Account`);
    }

    public changePassword(command: Model.PartialPlainObject<ChangePasswordCommand>): AxiosPromise {
        return this.axios.post(`${this.apiPath}/change-password`, command);
    }

    // Client only properties / methods
}