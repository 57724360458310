import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../AllocationManagerTypes';
import AllocationParticipantLookupCriteria from '../Models/Queries/AllocationParticipantLookupCriteria';

export interface IAllocationReportingApiClient {

    /** 
     * Gets an excel export of Allocation Participant information based on criteria.
     * @param criteria The allocation participant lookup criteria.
     * @returns An Excel File.
     */
    getAllocationParticipantsExcelExportFile(criteria: Model.PlainObject<AllocationParticipantLookupCriteria>): AxiosPromise<Blob>;

    // Client only properties / methods
}

@injectable()
export default class AllocationReportingApiClient extends Data.ApiClientBase implements IAllocationReportingApiClient {

    constructor (config = AppService.get(Types.Shared.Config)) {
        super(`${config.AwardsApi.ApiPath}/allocation-reporting-participants`);
    }

    public getAllocationParticipantsExcelExportFile(criteria: Model.PlainObject<AllocationParticipantLookupCriteria>): AxiosPromise<Blob> {
        return this.axios.post(`${this.apiPath}/allocation-participants-excel-export`, criteria, {responseType: "blob" });
    }
    
    // Client only properties / methods
}