import React from 'react';
import { DateUtils, Misc, NeoModel, NumberUtils } from '@singularsystems/neo-core';
import CalculationGroup from '../../../Models/Portfolio/Calculation/CalculationGroup';
import { observer } from 'mobx-react';
import CalculationTranche from '../../../Models/Portfolio/Calculation/CalculationTranche';
import { Neo, NeoGrid, Views } from '@singularsystems/neo-react';
import AwardCostsLookup from '../../../Models/Portfolio/AwardCostsLookup';
import { AppService, Types } from '../../../TransactionsTypes';
import AwardCostsCriteria from '../../../../Common/Models/AwardCostsCriteria';

interface IBalancesSummaryProps {
    group: CalculationGroup;
}

interface ITrancheDetails {
    trancheId: number,
    vestingDate: Date,
    isVested: boolean,
    hasAcceleration: boolean,
    availableBalance: number,
    currentValueConverted: number,
    remainingAwardDebtConverted: number,
    profitLossConverted: number
}

@NeoModel
class BalancesTrancheSummaryVM extends Views.ViewModelBase {

    public constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private portfolioApiClient = AppService.get(Types.Transactions.ApiClients.PortfolioApiClient),
    ) {
        super(taskRunner);
    }

    public awardCostsLookup: AwardCostsLookup = new AwardCostsLookup();

    public showAwardCostsModal: boolean = false;

    public showVestingSchedule: boolean = false;

    public hasLinkedRates: boolean = false;

    public hasOwnRates: boolean = false;

    public showCashTransactions: boolean = false;

    public showInterestAccruals: boolean = false;

    public vestingDate: Date = new Date();

    public async getAwardCosts(trancheId: number, vestingDate: Date, rate: number | null) {
        var criteria = new AwardCostsCriteria();
        criteria.trancheId = trancheId;

        this.vestingDate = vestingDate;

        const data = await this.taskRunner.waitForData(this.portfolioApiClient.getAwardCostsForTranche(criteria.toQueryObject()));
        this.awardCostsLookup?.set(data);

        this.showCashTransactions = this.awardCostsLookup.cashTransactions.length > 0;
        this.showInterestAccruals = this.awardCostsLookup.interestAccruals.length > 0;

        this.hasLinkedRates = this.awardCostsLookup.interestAccruals.some(c => c.linkedRate !== 1);
        this.hasOwnRates = this.awardCostsLookup.interestAccruals.some(c => c.ownRate !== 1);

        if (rate) {
            await this.setAwardCostsConvertedValue(this.awardCostsLookup, rate);
        }

        this.showAwardCostsModal = true;
    }

    private setAwardCostsConvertedValue(awardCostsLookup: AwardCostsLookup, rate: number) {
        awardCostsLookup.interestAccruals.forEach(interestAccrual => {
            interestAccrual.compoundedBalance *= rate;
            interestAccrual.openingAccrued *= rate;
            interestAccrual.accruedForPeriod *= rate;
            interestAccrual.closingAccrued *= rate;
            interestAccrual.accrualPaidAmount! *= rate;
        })

        awardCostsLookup.cashTransactions.forEach(cashTransaction => {
            cashTransaction.amount *= rate;
        })
    }
}

@observer
export class BalancesTrancheSummary extends React.Component<IBalancesSummaryProps>{

    private viewModel: BalancesTrancheSummaryVM = new BalancesTrancheSummaryVM();

    public render() {

        const group = this.props.group,
            awardName = group.awardName,
            shouldGroup = group.details.length > 5,
            tranches = this.getData(group.details, shouldGroup),
            currencySymbol = group.instrument.displayCurrencySymbol,
            noProfit = group.profitLossConvertedLimited === -1,
            groups = shouldGroup ? tranches as unknown as CalculationGroup[] : [];

        return (
            <>
                <table className="tranches">
                    <tr>
                        {!group.trancheBalance.incentiveScheme.requiresBulkTrading &&
                            <th>Expiry<br />date</th>
                        }
                        {group.trancheBalance.incentiveScheme.requiresBulkTrading &&
                            <th></th>
                        }

                        <th style={{ minWidth: 200 }}></th>
                        <th></th>
                        <th>Traded<br />units</th>
                        {tranches.map((t, index) =>
                            <th key={index} className={t.isVested ? "vested" : ""}>
                                <span>
                                    {t.hasAcceleration && <i className="fa fa-forward" data-tip={shouldGroup ? "Contains accelerated vestings" : "Vesting was accelerated"}></i>} {t.isVested ? "Vested" : "Vests"}<br />
                                    {DateUtils.format(t.vestingDate, shouldGroup ? "yyyy" : "dd MMM yyyy")}
                                </span>
                            </th>)}
                        <th className="vested">Vested<br />awards</th>
                    </tr>
                    {shouldGroup &&
                        <tr>
                            {!group.trancheBalance.incentiveScheme.requiresBulkTrading &&
                                <td>{DateUtils.format(group.trancheBalance.effectiveExpiryDate, "dd MMM yyyy")}</td>
                            }
                            {group.trancheBalance.incentiveScheme.requiresBulkTrading &&
                                <td></td>
                            }
                            <td className="text-heading">Number of vestings </td>
                            <td><button className='fa fa-info-circle info-btn '
                                onClick={() => this.viewModel.showVestingSchedule = true}
                            /></td>
                            <td></td>
                            {groups.map((group: CalculationGroup, index) =>
                                <td key={index} className={group.isVested ? "vested" : ""}>{group.details.length}</td>)}
                            <td>{tranches.sum(c => group.isVested ? group.details.length : 0)}</td>
                        </tr>
                    }
                    <tr>
                        {!shouldGroup && !group.trancheBalance.incentiveScheme.requiresBulkTrading &&
                            <td>{DateUtils.format(group.trancheBalance.effectiveExpiryDate, "dd MMM yyyy")}</td>}
                        {!shouldGroup && group.trancheBalance.incentiveScheme.requiresBulkTrading &&
                            <td></td>}
                        {shouldGroup &&
                            <td></td>}
                        <td className="text-heading">Available units</td>
                        <td></td>
                        <td>{NumberUtils.format(group.tradedUnits, "#,##0.##")}</td>
                        {tranches.map((t, index) =>
                            <td key={index} className={t.isVested ? "vested" : ""}>{NumberUtils.format(t.availableBalance, "#,##0.##")}</td>)}
                        <td className="vested">{NumberUtils.format(group.vestedBalance, "#,##0.##")}</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td className="text-heading">Current gross value</td>
                        <td></td>
                        <td></td>
                        {tranches.map((t, index) =>
                            <td className={t.isVested ? "vested" : ""}>{this.format(t.currentValueConverted, currencySymbol)}</td>)}
                        <td>{this.format(group.vestedCurrentValueConverted, currencySymbol)}</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td className="text-heading">Award costs/income</td>
                        <td></td>
                        <td></td>
                        {tranches.map((t, index) =>
                            <td key={index} className={t.isVested ? "vested" : ""}>
                                {!shouldGroup &&
                                    <Neo.Tooltip
                                        content={'Click to view the award cost breakdown'} position="right" className="background-secondary" inline>
                                        <button className='fa fa-info-circle info-btn '
                                            onClick={() => this.viewModel.getAwardCosts(t.trancheId, t.vestingDate, group.instrument.rate)}
                                        />
                                    </Neo.Tooltip>
                                }
                                {this.format(t.remainingAwardDebtConverted, currencySymbol)}
                            </td>)}
                        <td>{this.format(group.vestedAwardDebtConverted, currencySymbol)}</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td className="text-heading bold">Total potential profit</td>
                        <td></td>
                        <td></td>
                        {tranches.map((t, index) =>
                            <td key={index} className={t.isVested ? "vested" : ""}>{this.format(noProfit ? 0 : t.profitLossConverted, currencySymbol)}</td>)}
                        <td className="vested bold">{this.format(group.vestedProfitLossConvertedLimited, currencySymbol)}</td>
                    </tr>
                </table>
                <Neo.Modal title="Vesting Schedule" size='xl'
                    bind={this.viewModel.meta.showVestingSchedule}>
                    <NeoGrid.Grid items={groups} className="tranches">
                        {(item: CalculationGroup, meta) => (
                            <NeoGrid.RowGroup expandProperty={meta?.isExpanded}>
                                <NeoGrid.Row >
                                    <NeoGrid.Column className={item.isVested ? "vested bold" : ""} display={meta?.vestingDate} dateProps={{ formatString: "yyyy" }} />
                                </NeoGrid.Row>
                                <NeoGrid.ChildRow >
                                    <NeoGrid.Grid items={item.details.sortBy(c => c.vestingDate)}>
                                        {(childItem, childMeta) => (
                                            <NeoGrid.RowGroup>
                                                <NeoGrid.Row>
                                                    <NeoGrid.Column display={childMeta?.vestingDate} dateProps={{ formatString: "dd MMMM yyyy" }}
                                                        label={item.isVested ? "Date Vested" : "Vesting Date"} className={item.isVested ? "text-right vested" : "text-right"} />
                                                    <NeoGrid.Column display={childMeta?.availableBalance} label={"Available Balance"}
                                                        className={item.isVested ? "text-right vested" : "text-right"} />
                                                    <NeoGrid.Column display={childMeta?.remainingAwardDebt} label={"Award Costs"}
                                                        className={item.isVested ? "text-right vested" : "text-right"}
                                                        numProps={{ currencySymbol: currencySymbol, formatString: "#,##0.00##" }}>
                                                    </NeoGrid.Column>
                                                    <NeoGrid.Column>
                                                        <Neo.Tooltip
                                                            content={'Click to view the award cost breakdown'} position="right" className="background-secondary" inline>
                                                            <button className='fa fa-info-circle info-btn '
                                                                onClick={() => this.viewModel.getAwardCosts(childItem.trancheId, childItem.vestingDate, group.instrument.rate)}
                                                            />
                                                        </Neo.Tooltip>
                                                    </NeoGrid.Column>
                                                </NeoGrid.Row>
                                            </NeoGrid.RowGroup>
                                        )}
                                    </NeoGrid.Grid>
                                </NeoGrid.ChildRow>
                            </NeoGrid.RowGroup>
                        )}
                    </NeoGrid.Grid>
                </Neo.Modal>

                <Neo.Modal title={`Award Costs for ${awardName} | Vesting: ${DateUtils.format(this.viewModel.vestingDate, "dd MMM yy")} `} bind={this.viewModel.meta.showAwardCostsModal} size={"xl"}>
                    <div>
                        {this.viewModel.showCashTransactions &&
                            <Neo.Card title={"Cash Transactions"} icon="money-bill">
                                <NeoGrid.Grid items={this.viewModel.awardCostsLookup.cashTransactions}>
                                    {(cashTransaction, cashTransactionMeta) => (
                                        <NeoGrid.Row>
                                            <NeoGrid.Column display={cashTransactionMeta.effectiveDate} label={"Date"} />
                                            <NeoGrid.Column display={cashTransactionMeta.transactionType} label={"Type"} />
                                            <NeoGrid.Column display={cashTransactionMeta.amount} label={`Amount (${currencySymbol})`} numProps={{ currencySymbol: currencySymbol }} sum />
                                        </NeoGrid.Row>
                                    )}
                                </NeoGrid.Grid>
                            </Neo.Card>
                        }

                        {this.viewModel.showInterestAccruals &&
                            <Neo.Card title={"Interest Accruals"} icon="chart-line">
                                <NeoGrid.Grid items={this.viewModel.awardCostsLookup.interestAccruals} allowSort={false}>
                                    {(interestAccrual, interestAccrualMeta) => (
                                        <NeoGrid.Row>
                                            <NeoGrid.Column display={interestAccrualMeta.startDate} />
                                            <NeoGrid.Column display={interestAccrualMeta.endDate} label={"End Date\n(excl.)"} />
                                            <NeoGrid.Column display={interestAccrualMeta.noOfDays} />
                                            {this.viewModel.hasLinkedRates &&
                                                <NeoGrid.Column display={interestAccrualMeta.linkedRate} numProps={{ formatString: "#,##0.00##%" }} />}
                                            {this.viewModel.hasOwnRates &&
                                                <NeoGrid.Column display={interestAccrualMeta.ownRate} numProps={{ formatString: "#,##0.00##%" }} />}
                                            <NeoGrid.Column display={interestAccrualMeta.compoundedBalance} label={`Capital (${currencySymbol})`} />
                                            <NeoGrid.Column display={interestAccrualMeta.openingAccrued} label={`Opening accrued (${currencySymbol})`} />
                                            <NeoGrid.Column display={interestAccrualMeta.accrualPaidAmount} label={`Accrued paid amount (${currencySymbol})`} />
                                            <NeoGrid.Column display={interestAccrualMeta.accruedForPeriod} label={`Accrued for period (${currencySymbol})`} />
                                            <NeoGrid.Column display={interestAccrualMeta.closingAccrued} label={`Closing accrued (${currencySymbol})`} />
                                        </NeoGrid.Row>
                                    )}
                                </NeoGrid.Grid>
                            </Neo.Card>
                        }
                    </div>
                </Neo.Modal>
            </>
        )
    }

    private getData(tranches: CalculationTranche[], shouldGroup: boolean): ITrancheDetails[] {

        if (shouldGroup) {
            var trancheGrouping = tranches.groupBy(c => c.vestingDate.getFullYear() + (c.isVested ? "vested" : "unvested")).map(g => {
                const group = new CalculationGroup(g.item.trancheBalance);
                group.details = g.details;
                return group;
            })
            return trancheGrouping;
        } else {
            return tranches;
        }
    }

    private format(value: number, currencySymbol: string) {
        return NumberUtils.format(value, Misc.NumberFormat.CurrencyDecimals, currencySymbol);
    }
}