import React from 'react';
import { observer } from 'mobx-react';
import { Components, Utils } from '@singularsystems/neo-core'

interface IInlineFieldComponentProps extends Components.IColumnWidthBreakpoints, React.HTMLProps<HTMLDivElement> {
    label: any;
    display: any;
    displayClass?: string;
    right?: boolean;
    toolTip?: string;
}

@observer
export class InlineFieldComponent extends React.Component<IInlineFieldComponentProps> {

    public render() {
        let { label, display, displayClass, right, toolTip, ...domProps } = this.props;

        const classes = Components.BootstrapHelpers.getInverseBreakpointClasses(this.props);
        if (!classes.cols) {
            classes.cols = "col-6";
            classes.inverseCols = "col-6";
        }

        domProps.className = Utils.joinWithSpaces(domProps.className, "row inline-field");
        displayClass = Utils.joinWithSpaces(classes.inverseCols, displayClass, right ? "text-right" : "");

        return <div {...domProps}>
            <div className={classes.cols}><label data-tip={toolTip}>{label}</label></div>
            <div className={displayClass}>{display}</div>
        </div>
    }
}