
// Component for Direct Vesting Schedule edit controls
import React from 'react';
import { injectable } from 'inversify';
import { observer } from 'mobx-react';
import { NeoModel, Data } from '@singularsystems/neo-core';
import { Neo, Views } from '@singularsystems/neo-react';

import { AppService, Types } from '../CommonIdentityTypes';
import ProxyUserParticipantLookup from '../Models/ProxyUsers/Queries/ProxyUserParticipantLookup';
import MyProxyParticipantLookupCriteria from '../Models/ProxyUsers/Queries/MyProxyParticipantLookupCriteria';

import ProxyAsComponent from './ProxyAsComponent';
import PagerTextFilter from '../../../App/Common/Components/Application/PagerTextFilter';
import { ScreenSize } from '../../../App/Common/Models/Enums/ScreenSize';

interface IProxyAsParticipantModalProps {
    viewModel: ProxyAsParticipantModalVM;
}

@injectable()
@NeoModel
export class ProxyAsParticipantModalVM extends Views.ViewModelBase {

    public pageSize: number = 12;

    public showModal = false;

    public criteria = new MyProxyParticipantLookupCriteria();

    public findParticipants?: () => void;

    public pageManager = new Data.PageManager(this.criteria, ProxyUserParticipantLookup, this.sessionQueryClient.myParticipantLookup, {
        pageSize: this.pageSize,
        initialTaskRunner: this.taskRunner,
        fetchInitial: false,
        beforeFetch: request => request.pageSize = this.pageSize,
    });

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        public proxySessionService = AppService.get(Types.Identity.Services.ProxySessionService),
        public sessionQueryClient = AppService.get(Types.Identity.ApiClients.ProxySessionQueryApiClient)) {

        super(taskRunner);
    }

    public async show() {
        this.showModal = true;

        await this.pageManager.refreshData();
    }

    public proxyAsParticipant(participant: ProxyUserParticipantLookup) {
        this.showModal = false
        this.taskRunner.run(async () => {
            await this.proxySessionService.startProxySession(participant.participantId, participant.participantName);
        });
    }
}

@observer
export default class ProxyAsParticipantModal extends React.Component<IProxyAsParticipantModalProps> {

    private appLayout = AppService.get(Types.Shared.Services.AppLayout);

    private findParticipants() {
        this.props.viewModel.showModal = false;
        this.props.viewModel.findParticipants!();
    }

    public modalSize(): "xl" | "lg" {
        if (this.props.viewModel.pageManager.getItems().length <= 4) {
            return "lg";
        } else {
            return "xl";
        }
    }

    public render() {        

        const xlTiles = this.modalSize() === "lg" ? 4 : 6;
        const lgTiles = 4;
        const mdTiles = 3;

        const viewModel = this.props.viewModel;

        if (this.appLayout.currentScreenSize > ScreenSize.Large) {
            viewModel.pageSize = 3 * xlTiles; // 3 rows
        } else {
            viewModel.pageSize = 12;
        }

        return (
            <div>
                {viewModel &&
                    <Neo.Modal size={this.modalSize()}
                        title={`Select Participant to proxy for`}
                        show={viewModel.showModal}
                        onClose={() => { viewModel.showModal = false; }}
                        closeButton={{
                            text: "Cancel", variant: "secondary", icon: "times"
                        }} >

                        <div>
                            {viewModel.proxySessionService.user && viewModel.pageManager.getItems().length > 0 &&
                                <div>
                                    <Neo.GridLayout md={2}>
                                        <PagerTextFilter filterProperty={viewModel.criteria.meta.participantName}
                                            pageManager={viewModel.pageManager}
                                            taskRunner={viewModel.taskRunner} />
                                        <Neo.PagerControlsBasic pageManager={viewModel.pageManager} />
                                    </Neo.GridLayout>
                                    <Neo.Pager className="proxy-as-participants" pageControls="none"
                                        pageManager={viewModel.pageManager} >
                                        {data => (
                                            <Neo.GridLayout sm={1} md={mdTiles} lg={lgTiles} xl={xlTiles}>
                                                {data.map(item => (
                                                    item.participantName && <ProxyAsComponent participant={item} onClick={(p) => viewModel.proxyAsParticipant(p)} />
                                                ))}
                                            </Neo.GridLayout>
                                        )}
                                    </Neo.Pager>
                                </div>}

                            {this.appLayout.currentScreenSize <= ScreenSize.Medium &&
                                <Neo.PagerControlsBasic pageManager={viewModel.pageManager} />}

                            {/* ALERTS */}
                            {viewModel.pageManager.getItems().length === 0 && !viewModel.criteria.participantName &&
                                <Neo.Alert variant="info">
                                    <p>
                                        You have no participants configured.
                                    </p>
                                    {viewModel.proxySessionService.user && viewModel.proxySessionService.user.canProxyFind && viewModel.findParticipants &&
                                        <Neo.Button icon="search" onClick={() => this.findParticipants()}>
                                            Find participants
                                        </Neo.Button>}
                                    {viewModel.proxySessionService.user && !viewModel.proxySessionService.user.canProxyFind &&
                                        "Ask your administrator to add participants for you to proxy on behalf of"}
                                </Neo.Alert>}
                        </div>
                    </Neo.Modal>
                }
            </div>
        )
    }
}