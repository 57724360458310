import { Attributes, LookupBase, NeoModel } from '@singularsystems/neo-core';

@NeoModel
export default class TenantDocumentLookup extends LookupBase {

    public tenantDocumentId: number = 0;

    @Attributes.Nullable()
    public fileDescriptorId: number | null = 0;

    public documentUrl: string = "";

    public category: string = "";

    public displayName: string = "";

    // Client only properties / methods
}