import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../AwardsTypes';
import UpdateParticipantSelectionCommand from '../../Models/ParticipantOffers/Commands/UpdateParticipantSelectionCommand';
import AcceptSelectionCommand from '../../Models/ParticipantOffers/Commands/AcceptSelectionCommand';
import AcceptAwardsCommand from '../../Models/ParticipantOffers/Commands/AcceptAwardsCommand';
import DeclineAwardsCommand from '../../Models/Approvals/Commands/DeclineAwardsCommand';

export interface IParticipantAcceptanceCommandApiClient {

    /** 
     * Will update the participant's selection.
     * @param command The update participant selection command
     * @returns A task awaiting the completion of the command
     */
    updateParticipantSelection(command: Model.PartialPlainObject<UpdateParticipantSelectionCommand>): AxiosPromise<any>;

    /** 
     * Will accept the participant's current selection.
     * @param command The update participant selection command
     * @returns A task awaiting the completion of the command
     */
    acceptSelection(command: Model.PartialPlainObject<AcceptSelectionCommand>): AxiosPromise<any>;

    /** 
     * Will accept the specified awards.
     * @param command The accept awards command
     * @returns A task awaiting the acceptance of the awards
     */
    acceptAwards(command: Model.PartialPlainObject<AcceptAwardsCommand>): AxiosPromise<any>;

    /** 
     * Will decline the specified awards.
     * @param command The decline awards command
     * @returns A task awaiting the declineance of the awards
     */
    declineAwards(command: Model.PartialPlainObject<DeclineAwardsCommand>): AxiosPromise<any>;

    // Client only properties / methods
}

@injectable()
export default class ParticipantAcceptanceCommandApiClient extends Data.ApiClientBase implements IParticipantAcceptanceCommandApiClient {

    constructor (config = AppService.get(Types.Shared.Config)) {
        super(`${config.AwardsApi.ApiPath}/awards/accept`);
    }

    public updateParticipantSelection(command: Model.PartialPlainObject<UpdateParticipantSelectionCommand>): AxiosPromise<any> {
        return this.axios.post(`${this.apiPath}/selection/update`, command);
    }

    public acceptSelection(command: Model.PartialPlainObject<AcceptSelectionCommand>): AxiosPromise<any> {
        return this.axios.post(`${this.apiPath}/selection/accept`, command);
    }

    public acceptAwards(command: Model.PartialPlainObject<AcceptAwardsCommand>): AxiosPromise<any> {
        return this.axios.post(`${this.apiPath}/accept`, command);
    }

    public declineAwards(command: Model.PartialPlainObject<DeclineAwardsCommand>): AxiosPromise<any> {
        return this.axios.post(`${this.apiPath}/decline`, command);
    }

    // Client only properties / methods
}