class StyleUtilsClass {

    private graphColors: string[] | null = null;

    public getGraphColors() {
        if (this.graphColors === null) {
            this.graphColors = [];

            const div = document.createElement("div");
            document.body.appendChild(div);

            div.className = "chart-color-1";
            this.graphColors.push(window.getComputedStyle(div).color);
            div.className = "chart-color-2";
            this.graphColors.push(window.getComputedStyle(div).color);
            div.className = "chart-color-3";
            this.graphColors.push(window.getComputedStyle(div).color);

            div.remove();
        }
        return this.graphColors;
    } 
}

export const StyleUtils = new StyleUtilsClass();