import { Attributes, LookupBase, NeoModel } from '@singularsystems/neo-core';

@NeoModel
export default class AMIncentiveGroupUsedBudgetLookup extends LookupBase {

    public incentiveGroupId: number = 0;

    @Attributes.Integer()
    public budgetUsedFaceValue: number = 0;

    // Client only properties / methods
}