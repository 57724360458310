import { EnumHelper } from '@singularsystems/neo-core';

export enum ExcludeClosePeriodType {
    None = 1,
    ExcludeClosePeriods = 10,
    AddToClosePeriod = 20,
}

EnumHelper.decorateEnum(ExcludeClosePeriodType, decorator => {
    decorator.describe(ExcludeClosePeriodType.None, "None");
    decorator.describe(ExcludeClosePeriodType.ExcludeClosePeriods, "Exclude Days to exercise during closed periods");
    decorator.describe(ExcludeClosePeriodType.AddToClosePeriod, "Add days to exercise after close period");
});