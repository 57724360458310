import React from 'react';

export class PortfolioHelpers {

    /**
     * Executes a callback, passing in an up / down icon component.
     * The component returned is wrapped in a div with a profit / loss class depending on the value.
     */
    public static getProfitLoss(value: number, callback: (value: number, icon: string) => any) {
        let className = "";
        let icon: any;

        if (value === 0) {
            className = "";
            icon = <span>-</span>;
        } else if (value > 0) {
            className = "profit";
            icon = <i className="fa fa-caret-up" />;
        } else {
            className = "loss";
            icon = <i className="fa fa-caret-down" />;
        }

        return <div className={"profit-loss " + className}>
            {callback(value, icon)}
        </div>;
    }
}