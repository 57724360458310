import React from 'react';
import { Routing } from '@singularsystems/neo-core';
import SecurityConfigView from './Views/SecurityConfigView';
import TermsAndConditionsView from './Views/TermsAndConditionsView';
import { IAppMenuItem } from '../../App/Services/RouteService';
import { AppService, Types } from './CommonIdentityTypes';
import { TsAndCsDisplayLocation } from './Models/TsAndCs/Display/TsAndCsDisplayLocation.enum';

export const SecurityConfigRoute: Routing.IMenuRoute = {
    name: "Security Config", path: '/security/config', component: SecurityConfigView
}

export const TermsAndConditionsRoute: IAppMenuItem = {
    name: "Terms & Conditions", path: '/terms-and-conditions', component: TermsAndConditionsView,
    showMenuItem: () => 
    {
        const termsAndConditionsService = AppService.get(Types.Identity.Services.TermsAndConditionsService);
        return !!termsAndConditionsService?.status?.companyHasTermsAndConditions && termsAndConditionsService.displayOptions.location === TsAndCsDisplayLocation.ProfileMenu;
    }
}

export const ProxyRefreshRoute : Routing.IRoute = {
    path: "/proxyrefresh",
    component: () => <div>Reloading...</div>
}

export const IdentityPureRoutes = [SecurityConfigRoute, ProxyRefreshRoute];
export const IdentityCommonRoutes = { TermsAndConditionsRoute };