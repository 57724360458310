import React from 'react';
import { observer } from 'mobx-react';
import { Neo } from '@singularsystems/neo-react';
import QuestionnaireQuestionAnswerLookup from '../../../../../../Models/Questionnaires/Queries/QuestionnaireQuestionAnswerLookup';
import { QuestionType } from '../../../../../../../Common/Models/Enums/QuestionType';
import QuestionnaireAnsweredLookup from '../../../../../../Models/Questionnaires/Queries/QuestionnaireAnsweredLookup';

interface IQuestionTextComponentProps {
    questionAnswered: QuestionnaireAnsweredLookup | null;
    questionNumber: number;
    question: QuestionnaireQuestionAnswerLookup;
    disabled: boolean;
}

@observer
export default class QuestionTextComponent extends React.Component<IQuestionTextComponentProps> {

    public render() {
        const question = this.props.question;
        const questionLabel = this.props.questionNumber + ". " + question.question;
        return (
            <div>
                {this.props.question &&
                    <Neo.FormGroup bind={question.meta.questionAnswer}
                        label={questionLabel}
                        placeholder={question.answerPlaceholder}
                        editorProps={{
                            "data-tip": question.questionInfoText,
                            disabled:  this.props.disabled
                        } as any}
                        input={{ rows: question.questionType === QuestionType.ShortText ? 0 : 4 }} />
                }
            </div>
        )
    }
}