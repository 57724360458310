import React from 'react';
import { Neo, NeoGrid, Views } from '@singularsystems/neo-react';
import TradeHistoryVM from './TradeHistoryVM';
import { observer } from 'mobx-react';
import { PendingTradeRequestsComponent } from './Components/TradeRequests/PendingTradeRequestsComponent';
import { TradeRequestHiddenFields } from './Components/TradeRequests/TradeRequestHiddenFields';
import TradeRequestLookup from '../Models/Trading/Lookups/TradeRequestLookup';
import { AppService, Types } from '../TransactionsTypes';
import { ScreenSize } from '../../../App/Services/AppLayout';

@observer
export default class TradeHistoryView extends Views.ViewBase<TradeHistoryVM> {

    constructor(props: unknown) {
        super("Trade History", TradeHistoryVM, props);
    }

    private layout = AppService.get(Types.Shared.Services.AppLayout);

    public render() {
        const hideExpand = this.layout.currentScreenSize >= ScreenSize.Medium;

        return (
            <div>
                <h2 className="mt-2 ml-3">My Trade History</h2>

                <div className="mt-5">
                    <PendingTradeRequestsComponent viewModel={this.viewModel.tradeRequestVM} taskRunner={this.taskRunner} />
                </div>

                <div className="mt-5">
                    <div className="portfolio-group-container">
                        <div className="portfolio-group-header">
                            <div className="row">
                                <div className="col-xl-4 col-xxl-5">
                                    <div className="portfolio-group-title">
                                        Trade history
                                    </div>
                                </div>
                                <div className="col-md-6 col-xl-4 col-xxl-3">
                                    <Neo.FormGroup bind={this.viewModel.criteria.meta.incentiveSchemeId} select={{ itemSource: this.viewModel.awardsDataCache.particpantIncentiveSchemes, nullText: "All schemes" }} />
                                </div>
                                <div className="col-6 col-md-3 col-xl-2 col-xxl-2">
                                    <Neo.FormGroup bind={this.viewModel.criteria.meta.fromDate} />
                                </div>
                                <div className="col-6 col-md-3 col-xl-2 col-xxl-2">
                                    <Neo.FormGroup bind={this.viewModel.criteria.meta.toDate} />
                                </div>
                            </div>


                        </div>
                        <Neo.Pager pageManager={this.viewModel.pageManager} pageControls="none">
                            <NeoGrid.Grid<TradeRequestLookup> collapseAll={hideExpand} className={hideExpand ? "pad-left-cells" : ""}>
                                {(item, meta) => (
                                    <NeoGrid.RowGroup expandProperty={meta.isExpanded}>
                                        <NeoGrid.Row>
                                            <NeoGrid.Column display={meta.tradeRequestId} label="Ref No.">
                                                {item.tradeRequestLinkId && <i className="fa fa-link fa-sm ml-1" data-tip="This is a linked trade."></i>}
                                            </NeoGrid.Column>
                                            <NeoGrid.Column label="Trade Date" display={meta.tradeDate} hideBelow="xl" />
                                            <NeoGrid.Column label="Scheme" display={meta.incentiveSchemeName} className="text-heading" sort={false} />
                                            <NeoGrid.Column display={meta.statusText} className="grid-highlight" hideBelow="xxl" />
                                            <NeoGrid.Column label="Trade Instruction" display={meta.type} hideBelow="xl" />
                                            <NeoGrid.Column label="Units" display={meta.totalQuantity} hideBelow="md" />
                                            <NeoGrid.Column label={"Trade\nprice"} display={meta.price} numProps={{ formatString: `${item.currencySymbol} #,##0.00######;(${item.currencySymbol}#,##0.00######)` }} hideBelow="lg" />
                                            <NeoGrid.Column label={"Gross\nvalue"} display={meta.grossValue} numProps={{ currencySymbol: item.currencySymbol }} hideBelow="md" />
                                            <NeoGrid.Column label={"Award\ncosts/income"} display={meta.costs} numProps={{ currencySymbol: item.currencySymbol }} hideBelow="md" />
                                            <NeoGrid.Column label={"Profit\nbefore tax"} display={meta.profit} numProps={{ currencySymbol: item.currencySymbol }} hideBelow="md" className="grid-highlight" cellClassName="bold" />
                                            {this.viewModel.showTradeDetailsButton &&
                                                <NeoGrid.ButtonColumn>
                                                    <Neo.Button size="sm" variant="info" icon="info" onClick={() => this.viewModel.tradeRequestVM.showTradeRequest(item)} />
                                                </NeoGrid.ButtonColumn>}
                                        </NeoGrid.Row>
                                        <NeoGrid.ChildRow>
                                            {item.totalQuantity && <TradeRequestHiddenFields tradeRequest={item} />}
                                        </NeoGrid.ChildRow>
                                    </NeoGrid.RowGroup>
                                )}
                            </NeoGrid.Grid>
                        </Neo.Pager>
                        <div className="portfolio-group-footer">
                            <Neo.PagerControlsBasic pageManager={this.viewModel.pageManager} pageSizeLabel="No of trades" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}