import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../AllocationManagerTypes';
import UpdateParticipantAllocationCommand from '../Models/Commands/UpdateParticipantAllocationCommand';
import AllocationParticipantChangeRequest from '../Models/AllocationParticipantChangeRequest';
import AllocationParticipantRequestResult from '../Models/AllocationParticipantRequestResult';
import AllocationParticipantAddRequest from '../Models/AllocationParticipantAddRequest';
import UpdateParticipantAllocationCommentCommand from '../Models/Commands/UpdateParticipantAllocationCommentCommand';

export interface IAllocationParticipantApiClient {

    /**
     * Request change for a specific participant. Send email to reporting manager or admin team if HR assist
     * @param allocationParticipantChangeRequest AllocationParticipantChangeRequest
     * @returns Task.
     */
    sendParticipantChangeRequest(allocationParticipantChangeRequest: Model.PartialPlainObject<AllocationParticipantChangeRequest>): AxiosPromise<Model.PlainTrackedObject<AllocationParticipantRequestResult>>;

    /**
     * Request that a specific participant be added to a managers team. Send email HR team.
     * @param allocationParticipantAddRequest AllocationParticipantAddRequest
     * @returns Task.
     */
    sendParticipantAddRequest(allocationParticipantAddRequest: Model.PartialPlainObject<AllocationParticipantAddRequest>): AxiosPromise<Model.PlainTrackedObject<AllocationParticipantRequestResult>>;

    /** 
     * Update a participants comments.
     * @param updateParticipantAllocationCommentCommand UpdateParticipantAllocationCommentCommand
     * @returns Task.
     */
    updateParticipantComment(updateParticipantAllocationCommentCommand: Model.PartialPlainObject<UpdateParticipantAllocationCommentCommand>): AxiosPromise;

    /**
     * Approve Allocations sets the LastApproveDate od the participant of the manager to the current date.
     * @returns Task.
     */
    approveAllocations(): AxiosPromise;


    /** 
     * Update a participants allocation per incentive group.
     * @param updateParticipantAllocationCommand UpdateParticipantAllocationCommand
     * @returns Task.
     */
    updateParticipantIncentiveGroupAllocation(updateParticipantAllocationCommand: Model.PartialPlainObject<UpdateParticipantAllocationCommand>): AxiosPromise;

    // Client only properties / methods
}

@injectable()
export default class AllocationParticipantApiClient extends Data.ApiClientBase implements IAllocationParticipantApiClient {

    constructor (config = AppService.get(Types.Shared.Config)) {
        super(`${config.AwardsApi.ApiPath}/allocation-participant`);
    }

    public sendParticipantChangeRequest(allocationParticipantChangeRequest: Model.PartialPlainObject<AllocationParticipantChangeRequest>): AxiosPromise<Model.PlainTrackedObject<AllocationParticipantRequestResult>> {
        return this.axios.post(`${this.apiPath}/request-change`, allocationParticipantChangeRequest);
    }

    public sendParticipantAddRequest(allocationParticipantAddRequest: Model.PartialPlainObject<AllocationParticipantAddRequest>): AxiosPromise<Model.PlainTrackedObject<AllocationParticipantRequestResult>> {
        return this.axios.post(`${this.apiPath}/request-add`, allocationParticipantAddRequest);
    }

    public updateParticipantComment(updateParticipantAllocationCommentCommand: Model.PartialPlainObject<UpdateParticipantAllocationCommentCommand>): AxiosPromise {
        return this.axios.put(`${this.apiPath}/update-comment`, updateParticipantAllocationCommentCommand);
    }

    public approveAllocations(): AxiosPromise {
        return this.axios.patch(`${this.apiPath}/approve-allocations`);
    }

    public updateParticipantIncentiveGroupAllocation(updateParticipantAllocationCommand: Model.PartialPlainObject<UpdateParticipantAllocationCommand>): AxiosPromise {
        return this.axios.put(`${this.apiPath}/update-allocation`, updateParticipantAllocationCommand);
    }

    // Client only properties / methods
}