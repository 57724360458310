import { AppServices, Routing } from '@singularsystems/neo-core';

import { Types } from './ClientsTypes';
import ImagesQueryApiClient from '../Common/ApiClients/ImagesQueryApiClient';
import StylesQueryApiClient from '../Common/ApiClients/StylesQueryApiClient';
import StyleService from '../Common/Services/StyleService';

import ContactUsView from './Views/ContactUsView';
import ParticipantsMessageApiClient from './ApiClients/ParticipantsMessageApiClient';
import MainCurrenciesApiClient from '../Common/ApiClients/MainCurrenciesApiClient';
import { IAppRoute } from '../../App/Services/RouteService';
import TenantDocumentsQueryApiClient from '../Common/ApiClients/TenantDocumentsQueryApiClient';
import ClientsApiClient from '../Common/ApiClients/ClientsApiClient';

// Modules
const clientsAppModule = new AppServices.Module("Clients", container => {
    // Api Clients
    container.bind(Types.Clients.ApiClients.ImagesQueryApiClient).to(ImagesQueryApiClient).inSingletonScope();
    container.bind(Types.Clients.ApiClients.AdminStylesQueryApiClient).to(StylesQueryApiClient).inSingletonScope();
    container.bind(Types.Clients.ApiClients.TenantDocumentsQueryApiClient).to(TenantDocumentsQueryApiClient).inSingletonScope();
    container.bind(Types.Clients.ApiClients.ParticipantsMessageApiClient).to(ParticipantsMessageApiClient).inSingletonScope();
    container.bind(Types.Clients.ApiClients.MainCurrenciesApiClient).to(MainCurrenciesApiClient).inSingletonScope();
    container.bind(Types.Clients.ApiClients.ClientsApiClient).to(ClientsApiClient).inSingletonScope();

    // Services
    container.bind(Types.Clients.Services.StyleService).to(StyleService).inSingletonScope();
});

// Test
const clientsTestModule = new AppServices.Module("Clients", container => {
    // bind test types
});

// Routing
const ContactUsRoute: IAppRoute = { path: "/contact-us", component: ContactUsView, requiresParticipant: false };

const PureRoutes = [] as Routing.IRoute[];

export { clientsAppModule,
         clientsTestModule,
         ContactUsRoute,
         PureRoutes }