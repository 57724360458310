import { UserManager, User, OidcClient } from 'oidc-client-ts';
import { NeoModel, Security } from '@singularsystems/neo-core';
import { NotificationServiceTypes } from '@singularsystems/neo-notifications';
import { injectable } from 'inversify';
import { AppService, AppTypes } from '../../../Services/AppService';
import STUser from '../../Models/Security/STUser';

export interface ISTAuthenticationService extends Security.OidcAuthService {
    readonly user: STUser | null;
    redirectToRegistration(emailAddress: string): void;
}

export class OidcAuthenticationServiceBase extends Security.OidcAuthService<STUser> implements ISTAuthenticationService {

    constructor(
        axios = AppService.get(AppTypes.Neo.Axios),
        private config = AppService.get(AppTypes.Shared.Config),
        private routeService = AppService.get(AppTypes.Shared.Services.RouteService)
    ) {
        super(
            new UserManager(config.userManagerSettings),
            axios);
    }

    protected createUser(user: User): STUser {
        return new STUser(user);
    }

    protected async afterUserLoaded() {
        AppService.get(NotificationServiceTypes.Services.NotificationService).initialise();
        await AppService.get(AppTypes.Identity.Services.TermsAndConditionsService).initialize();
    }

    public async redirectToRegistration(emailAddress: string) {
        // Create the sign in request to send to IDS.
        const client = new OidcClient(this.config.userManagerSettings);
        const request = await client.createSigninRequest({ state: this.routeService.registerRoute.path + "/complete" });

        // We only care about the query string from the above request.
        const returnUrl = "/connect/authorize/callback" + decodeURIComponent(request.url.substr(request.url.indexOf("?")));

        // Redirect to the register page on the identity portal, with its redirect url set to IDS' authorise callback.
        // On authorization, IDS will redirect back to our app (to the register page).
        const registerUrl = `${this.config.IdentityServerApi.BasePath}/identity/account/register?email=${encodeURIComponent(emailAddress)}&ReturnUrl=${encodeURIComponent(returnUrl)}`;

        (window.location as any) = registerUrl;
    }
}

