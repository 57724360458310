import { LookupBase, NeoModel } from '@singularsystems/neo-core';
import { TicketProviderType } from './TicketProviders/TicketProviderType.enum';

@NeoModel
export default class TenantLookup extends LookupBase {

    public tenantId: number = 0;

    public tenantName: string = "";

    public tenantDisplayName: string = "";

    public ticketProviderType: TicketProviderType | null = null;

    public urls: string[] | null = null;

    // Client only properties / methods
}