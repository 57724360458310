import { Attributes, DateUtils, List, LookupBase, Misc, NeoModel, NumberUtils, Utils } from '@singularsystems/neo-core';
import AllocationIncentiveGroupAllocationLookup from './AllocationIncentiveGroupAllocationLookup';
import AllocationSchemeSplitLookup from './AllocationSchemeSplitLookup';
import AllocationCommentLookup from './AllocationCommentLookup';
import { Awards } from '../../../../../App';

@NeoModel
export default class AllocationParticipantLookup extends LookupBase {

    public allocationParticipantId: number = 0;

    public allocationPlanId: number = 0;

    public participantId: string = Utils.emptyGuid();

    public allocationManagerId: string | null = null;

    @Attributes.Float()
    public unvestedCostNow: number = 0;

    public isFinalised: boolean = false;

    @Attributes.Date()
    public lastApprovalDate: Date | null = null;

    public participantName: string = "";

    public participantCode: string = "";

    public correspondenceEmail: string = "";

    public jobTitle: string = "";

    public participantGrade: string = "";

    public performanceRating: string = "";

    @Attributes.Nullable()
    @Attributes.Float()
    public annualTCTC: number | null = null;

    public modifiedById: number | null = null;

    public modifiedByName: string = "";

    @Attributes.Date()
    public modifiedOn: Date | null = null;

    public incentiveGroupAllocations = new List(AllocationIncentiveGroupAllocationLookup);

    public schemeSplits = new List(AllocationSchemeSplitLookup);

    public allocationComments = new List(AllocationCommentLookup);

    public gradeRange = new Awards.IncentiveSchemeTypeGradeRangeLookup();

    public readonly isParticipantException: boolean = false;

    public vestingYears: number = 0;

    public isPreviousAllocation: boolean = false;

    // Client only properties / methods

    @Attributes.Float()
    public annualValueAsPercSalary: number = 0;

    public prevHasAllocations = false;

    /*
    This is used to calculate whether or not there was a change in the status of this allocation participant.
        If there was a change then the new status is stored in prevHasAllocations and the resulting change value is returned.
        If there is no change in status then a zero value is returned.

    The result is used to modify the allocation total labels on the allocation manager view.
    */
    public getAllocationCompletedChangeValue() {
        const newHasAllocationsValue = this.hasAllocations;
        if (newHasAllocationsValue && !this.prevHasAllocations) {
            this.prevHasAllocations = newHasAllocationsValue;
            return 1; // changed to allocated/completed
        } else if (!newHasAllocationsValue && this.prevHasAllocations) {
            this.prevHasAllocations = newHasAllocationsValue;
            return -1 // changed to unallocated/outstanding
        }
        return 0; // no change
    }

    public get schemeSplitLabelText() {
        var schemeSplitLabelText = "";
        this.schemeSplits.forEach(split => {
            if (schemeSplitLabelText !== "") {
                schemeSplitLabelText += ", "
            }
            schemeSplitLabelText += NumberUtils.format(split.percentageSplit, "##0.########%") + " " + split.readableSchemeName;
        });
        if (schemeSplitLabelText === "") {
            schemeSplitLabelText = "Choose scheme"
        }
        return schemeSplitLabelText;
    }

    public get annualValueAsPercSalaryAmount() {
        return ((this.unvestedCostNow / this.vestingYears) / (this.annualTCTC === 0 ? 1 : this.annualTCTC!));
    }

    public get newAnnualValueAsPercSalaryAmount() {
        let totalAllocation = 0
        this.incentiveGroupAllocations.forEach(allocation => {
            totalAllocation += allocation.proposedAllocationFaceValue
        })
        return totalAllocation / (this.annualTCTC === 0 ? 1 : this.annualTCTC!);
    }

    public allowedValueRange(currencySymbol: string) {
        if (this.gradeRange) {
            return `${NumberUtils.format((this.annualTCTC ?? 0) * this.gradeRange.minPercentage, Misc.NumberFormat.CurrencyNoDecimals, currencySymbol)} - ${NumberUtils.format((this.annualTCTC ?? 0) * this.gradeRange.maxPercentage, Misc.NumberFormat.CurrencyNoDecimals, currencySymbol)}`;
        }
        return 0;
    }

    public get gradeRangeText() {
        if (this.gradeRange) {
            return `${NumberUtils.format(this.gradeRange.minPercentage, Misc.NumberFormat.PercentNoDecimals)} - ${NumberUtils.format(this.gradeRange.maxPercentage, Misc.NumberFormat.PercentNoDecimals)}`;
        }
        return "-";
    }

    public get isOverAllocated() {
        const maxPercent = this.gradeRange?.maxPercentage ?? 0;
        return this.newAnnualValueAsPercSalaryAmount > maxPercent;
    }

    public hasOverTheMaxComment() {
        let hasOverTheMaxComment = false;
        this.allocationComments.forEach(comment => {
            if (comment.isOverTheMaxComment) {
                hasOverTheMaxComment = true;
            }
        });
        return hasOverTheMaxComment;
    }

    public get lastChangedDetails() {
        let lastChanged = this.modifiedByName;
        if (this.modifiedOn) {
            lastChanged += "\n" + DateUtils.format(this.modifiedOn, "hh:mm dd-MMM-yy");
        }
        return lastChanged;
    }

    public get participantGradeLabel() {
        // TODO: need to make more generic. Could possibly add a participant grade description or label to Participant Grade table
        var newLabel = '';
        if (this.participantGrade.includes('N')) {
            newLabel = this.participantGrade.replace('N', ' Non-Tech ')
            return newLabel;
        } else if (this.participantGrade.includes('T')) {
            newLabel = this.participantGrade.replace('T', ' Tech ')
            return newLabel;
        } else {
            return this.participantGrade;
        }
    }

    private get hasAllocations() {
        return this.incentiveGroupAllocations.some(c => c.proposedAllocationExpectedValue > 0 || c.proposedAllocationFaceValue > 0);
    }

    protected onDeserialiseCompleted() {
        this.prevHasAllocations = this.hasAllocations;
    }
}