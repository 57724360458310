import { Data, Model, Utils } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, AppTypes } from 'st-app/Services/AppService';
import { ApproverApprovalSummaryLookup } from '../../Models/Approvals/Queries/ApproverApprovalSummaryLookup';
import ApprovalLookupCriteria from '../../Models/Approvals/Queries/ApprovalLookupCriteria';
import ApprovalLookup from '../../Models/Approvals/Queries/ApprovalLookup';
import AwardPrepApprovalConfig from '../../Models/Config/AwardPrepApprovalConfig';
import AuditLookupCriteria from 'st-app/Common/Models/Audit/AuditLookupCriteria';
import ApprovalAuditLookup from '../../Models/Approvals/Queries/ApprovalAuditLookup';
import ParticipantAwardReleaseLookupCriteria from '../../Models/Approvals/Queries/ParticipantAwardReleaseLookupCriteria';
import ParticipantAwardReleaseLookupPageResult from '../../Models/Approvals/Queries/ParticipantAwardReleaseLookupPageResult';

export interface IApprovalsQueryApiClient {

    /**
     * Will get the participant summary for the provided award prep id
     * @returns The participant summary for the provided award prep id
     */
    getApproverApprovalSummaryAsync(): AxiosPromise<Model.PlainObject<ApproverApprovalSummaryLookup>>;

    /** 
     * Will get a bool value indicating whether the current approver participant has any awards that still need to be released.
     * @returns The approval summary
     */
    participantNextAwardReleaseDate(): AxiosPromise<Date | null>;

    /**
     * Will get the paged participant offer lookups for the current logged in approver participant
     * @param request The approval lookup page request
     * @returns The approval summary
     */
    getParticipantAwardReleaseLookup(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<ParticipantAwardReleaseLookupCriteria>>): AxiosPromise<ParticipantAwardReleaseLookupPageResult>;

    /**
     * Will get the approval summary for the current logged in participant
     * @param request The approval lookup page request
     * @returns The approval summary
     */
    getApprovalLookupAsync(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<ApprovalLookupCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<ApprovalLookup>>>;

    /**
     * Will get the approval summary for the current logged in participant
     * @param request The approval lookup page request
     * @returns The approval summary
     */
    getAdminApprovalLookupAsync(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<ApprovalLookupCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<ApprovalLookup>>>;

    /**
     * Will get the approval config for the specified awardPrepId
     * @param awardPrepId The award prep id
     * @returns The approval config
     */
    getApprovalsConfig(awardPrepId: number): AxiosPromise<Model.PlainTrackedObject<AwardPrepApprovalConfig>>;

    /**
     * Will get the approval config for the specified awardPrepIds
     * @param awardPrepIds The award prep ids
     * @returns The approval config
     */
    getApprovalsConfigs(awardPrepIds: number[]): Promise<Map<number, AwardPrepApprovalConfig>>;

    /**
     * Will get the approval history for the specified request
     * @param request The approval history request
     * @returns The approval history request
     */
    getApprovalHistory(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<AuditLookupCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<ApprovalAuditLookup>>>;


    // Client only properties / methods
}

@injectable()
export default class ApprovalsQueryApiClient extends Data.ApiClientBase implements IApprovalsQueryApiClient {

    constructor (config = AppService.get(AppTypes.Shared.Config)) {
        super(`${config.AwardsApi.ApiPath}/ApprovalsQuery`);
    }

    public getApproverApprovalSummaryAsync(): AxiosPromise<Model.PlainObject<ApproverApprovalSummaryLookup>> {
        return this.axios.get(`${this.apiPath}/summary`);
    }

    public participantNextAwardReleaseDate(): AxiosPromise<Date | null> {
        return this.axios.get(`${this.apiPath}/release/next`);
    }

    public getParticipantAwardReleaseLookup(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<ParticipantAwardReleaseLookupCriteria>>): AxiosPromise<ParticipantAwardReleaseLookupPageResult> {
        return this.axios.get(`${this.apiPath}/release/awards?${Utils.getQueryString(request)}`);
    }

    public getApprovalLookupAsync(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<ApprovalLookupCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<ApprovalLookup>>> {
        return this.axios.get(`${this.apiPath}/approvals?${Utils.getQueryString(request)}`);
    }

    public getAdminApprovalLookupAsync(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<ApprovalLookupCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<ApprovalLookup>>> {
        return this.axios.get(`${this.apiPath}/adminApprovals?${Utils.getQueryString(request)}`);
    }

    public getApprovalsConfig(awardPrepId: number): AxiosPromise<Model.PlainTrackedObject<AwardPrepApprovalConfig>> {
        return this.axios.get(`${this.apiPath}/approvals/${awardPrepId}/config`);
    }

    public async getApprovalsConfigs(awardPrepIds: number[]) {
        const result = await this.axios.get(`${this.apiPath}/approvals/configs?awardPrepIds=${awardPrepIds.join("&awardPrepIds=")}`);

        const configs = new Map<number, AwardPrepApprovalConfig>();
        for (const configProp in result.data) {
            if (configProp !== "$id") {
                const config = AwardPrepApprovalConfig.mapFrom<AwardPrepApprovalConfig>(result.data[configProp]);
                configs.set(+configProp, config);
            }
        }
        return configs;
    }

    public getApprovalHistory(request: Model.PartialPlainNonTrackedObject<Data.PageRequest<AuditLookupCriteria>>):  AxiosPromise<Data.PageResult<Model.PlainObject<ApprovalAuditLookup>>> {
        return this.axios.get(`${this.apiPath}/approvals/history?${Utils.getQueryString(request)}`);
    }

    // Client only properties / methods
}