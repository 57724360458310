import React from 'react';
import { Neo, NeoGrid } from '@singularsystems/neo-react';
import { DateUtils, Misc, ModalUtils, Model, NumberUtils } from '@singularsystems/neo-core';
import { TradeType } from '../../../Models/Trading/TradeType';
import { observer } from 'mobx-react';
import TradeIncentiveGroup from '../../../Models/Trading/TradeIncentiveGroup';
import BindableTradeInfo from '../../../Models/Trading/BindableTradeInfo';
import TradeVM from '../../TradeVM';
import { HtmlView } from '@singularsystems/neo-notifications';

interface ITradeGroupedGridProps {
    category: TradeIncentiveGroup;
    viewModel: TradeVM;
}

@observer
export class TradeGroupedGrid extends React.Component<ITradeGroupedGridProps> {

    public render() {
        const category = this.props.category,
            viewModel = this.props.viewModel;

        const onlyBuys = category.flattened.every(c => c.sellQuantity === 0 && c.buyQuantity > 0);

        return (
            <NeoGrid.Grid items={category.bindableTrades} className="editable compact-editors">
                {(item, meta) => (
                    <>
                        {this.renderMainRow(item, meta, false, onlyBuys)}
                        {item.linkedTrades.map(linked => this.renderMainRow(linked, linked.meta, true, onlyBuys))}
                        {item.trade.requiresAdditionalDetails && !viewModel.startedSavingTrades &&
                            <NeoGrid.Row showInHeader={false} className="trade-group-footer">
                                <NeoGrid.Column colSpan={9} headerTooltip="A limit order is an order to sell units with a restriction on the price to be received (the “limit price”). The units will only be sold if the price reaches the specified limit price on the market. Please note it could take some time for the price to match, if at all, which is why you need to indicate an expiry date. Note that any Limit price trades will be cancelled at the start of a closed period or ban-on trade.">
                                    {item.tradeBase.requiresExpiryDate &&
                                        <div className="row mt-1">

                                            <div className="col-6">
                                                <Neo.Alert variant="light" className="alert-tiny">
                                                    You have selected a limit price for this trade. This means the trade may not match immediately, or may partially match.
                                                    Please enter an expiry date where any remaining quantity will automatically be cancelled.
                                                    {item.calcObject.lastTradeType !== TradeType.SellToCover &&
                                                        <div className="mt-2">
                                                            Or <a href="/" onClick={e => { item.tradeBase.clearLimitPrice(); e.preventDefault() }}>click here </a> to change to trade at market price.
                                                        </div>}
                                                </Neo.Alert>
                                            </div>
                                            <div className="col-3 align-self-center">
                                                <Neo.FormGroup bind={item.tradeBase.meta.expiryDate} dateProps={{ minDate: DateUtils.today(), maxDate: item.tradeBase.maxExpiryDate }} editorProps={{ autoComplete: "off" }} />
                                            </div>
                                        </div>}
                                    {item.trade.allowForexCover &&
                                        <div className="row mt-1">

                                            <div className="col-6">
                                                <Neo.Alert variant="light" className="alert-tiny">
                                                You have the option to select forex cover in USD, EUR, and GBP for this trade, but only if your payroll can accept this currency. This will only be applicable to you if you choose to sell some or all of your shares. The purpose of this is to limit your forex exposure between ZAR (South African Rand) and your payment currency i.e. the currency in which you are paid via your relevant payroll. 
                                                If you are paid in USD, EUR or GBP upon your instruction we can direct our broker to convert your ZAR net cash proceeds into your payroll currency i.e. USD, EUR or GBP. The broker can convert at the applicable rate on trade date plus a fixed percentage margin of 0.17% of the market rate. By doing this you will no longer be exposed to any foreign exchange rate fluctuations between the forex conversion and settlement date. Please note that if you are paid in any other currency other than USD, EUR or GBP, before you choose a forex cover it is important to check if your payroll can accept the selected currency.
                                                </Neo.Alert>
                                            </div>
                                            <div className="col-3 align-self-center">
                                                <Neo.FormGroup label="Add forex cover" bind={item.trade.meta.forexCover} />
                                            </div>
                                        </div>}
                                </NeoGrid.Column>
                            </NeoGrid.Row>}
                    </>
                )}
            </NeoGrid.Grid>
        )
    }

    private renderMainRow(item: BindableTradeInfo, meta: Model.TransformMetaType<BindableTradeInfo>, isLink: boolean, onlyBuys: boolean) {
        const viewModel = this.props.viewModel;
        return (
            <NeoGrid.Row showInHeader={!isLink} className={isLink ? "linked-Row" : ""}>
                {viewModel.startedSavingTrades &&
                    <NeoGrid.Column label={"Reference\nnumber"} bindContext={item.trade.meta.tradeRequestId}>
                        {item.trade.saveError && <i className="fa fa-exclamation-triangle text-danger" data-tip={item.trade.saveError}></i>}
                        {item.trade.tradeRequestId ? NumberUtils.format(item.trade.tradeRequestId, Misc.NumberFormat.NoDecimals) : ""}
                    </NeoGrid.Column>}

                <NeoGrid.Column label="Scheme" bindContext={meta.incentiveSchemeName} className="text-heading" headerClassName="bold">
                    <span>
                        {item.trancheBalance.awardLinkId && <i className="fa fa-link mr-2" data-tip={"Linked award: " + item.trancheBalance.awardLinkDescription} />}
                        {item.incentiveSchemeName}
                    </span>
                </NeoGrid.Column>
                <NeoGrid.Column label={"Units\nsold"} display={meta.sellQuantity} />
                <NeoGrid.Column label={"Units\ntransferred"} display={meta.buyQuantity} />
                <NeoGrid.Column label={"Current\nprice"} display={meta.sellPrice} numProps={{ formatString: "#,##0.00######;(#,##0.00######)" }} />
                {onlyBuys &&
                    <NeoGrid.Column
                        label={"Current gross\nvalue"}
                        display={meta.buyValue}
                    />}
                {!onlyBuys &&
                    <NeoGrid.Column
                        label={"Trade\nproceeds"}
                        display={meta.tradeProceeds}
                    />}
                <NeoGrid.Column label={"Award\ncosts/income"} display={meta.proportionalAwardDebt} />
                <NeoGrid.Column label={"Estimated\ntax & fees"} display={meta.tradingCosts} />
                <NeoGrid.Column
                    label={"Current potential\nprofit after tax"}
                    display={meta.netProceeds}
                    className={(item.netProceeds < 0 ? "grid-loss" : "grid-highlight") + " bold calculator-profit-column"} />
                {!viewModel.startedSavingTrades && <NeoGrid.ButtonColumn>
                    <Neo.Button size="sm" onClick={() => this.showTradeAgreement(item)}>View trade agreement</Neo.Button>
                </NeoGrid.ButtonColumn>}
            </NeoGrid.Row>
        )
    }

    private async showTradeAgreement(trade: BindableTradeInfo) {
        const tradeAgreement = await this.props.viewModel.getTradeAgreement(trade.trade);

        ModalUtils.showModal(
            tradeAgreement.incentiveSchemeName + " Trade Agreement",
            <HtmlView className="trade-terms" html={tradeAgreement.body} suppressIsolation />,
            {
                size: "lg"
            });
            this.props.viewModel.hasViewedTradeAgreementText = true;
    }
}