import { Attributes, ModelBase, NeoModel, Rules } from '@singularsystems/neo-core';

@NeoModel
export default class AcceptAwardCommandDetail extends ModelBase {

    public participantOfferId: number = 0;

    @Rules.Required()
    @Attributes.Date()
    public documentGenerationDate: Date | null = null;

    @Attributes.Nullable()
    public participantEntityId: number | null = null;

    // Client only properties / methods
}