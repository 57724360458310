import { List, ModelBase, NeoModel, NumberUtils, Validation } from '@singularsystems/neo-core';
import UpdateIncentiveGroupAllocationCommand from './UpdateIncentiveGroupAllocationCommand';
import UpdateSchemeSplitCommand from './UpdateSchemeSplitCommand';

@NeoModel
export default class UpdateParticipantAllocationCommand extends ModelBase {

    public allocationParticipantId: number = 0;

    public incentiveGroupAllocations = new List(UpdateIncentiveGroupAllocationCommand);
    
    public schemeSplits = new List(UpdateSchemeSplitCommand);

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);                

        // Incentive schemes must be unique
        UpdateParticipantAllocationCommand.incentiveSchemesUniqueRule = rules.add(this.checkIncentiveSchemesUnique);

        // split percentages must sum to 100%
        UpdateParticipantAllocationCommand.splitPercentagesTotalRule = rules.add(this.checkSplitPercentages);     
        
        UpdateParticipantAllocationCommand.atLeastOneSchemeRule = rules.add(this.checkAtLeastOneScheme);
    }

    private checkIncentiveSchemesUnique(context: Validation.IRuleContext) {        
        const schemeCounts = {};        
        
        for (let split of this.schemeSplits) {                            
            if (split.incentiveSchemeId) {
                if (!schemeCounts[split.incentiveSchemeId]) {
                    schemeCounts[split.incentiveSchemeId] = true;                
                } else {                
                    // there is a duplicate - find the existing item and use its name as the name has not yet been set on the newly selected item.
                    let duplicateScheme = this.schemeSplits.find(x => x.incentiveSchemeId === split.incentiveSchemeId && x.incentiveSchemeName !== "");
                    // Separated out to prevent app crash on brand new duplicates where the incentiveSchemeName has never been set.
                    if (duplicateScheme) {
                        context.addError(`Incentive schemes must be unique, ${duplicateScheme.incentiveSchemeName} has been repeated`);                        
                    }
                    break;
                }            
            }            
        } 
    }

    private checkSplitPercentages(context: Validation.IRuleContext) {
        if (this.schemeSplits.length > 0) {
            let schemeSplitsHaveValues = true;
            this.schemeSplits.forEach(x => {
                if (x.percentageSplit === 0) {
                    schemeSplitsHaveValues = false;
                    context.addError(`The split percentage for a Scheme must be greater than 0%`)
                }
            })

            if (schemeSplitsHaveValues) {
                const sumValue = this.schemeSplits.reduce((sum, current) => sum + current.percentageSplit, 0);
                if (sumValue !== 1) { 
                    context.addError(`The split percentages must add up to 100% (currently ${NumberUtils.format(sumValue, "##0.########%")})`)                 
                }
            }                        
        }        
    }
    
    private checkAtLeastOneScheme(context: Validation.IRuleContext) {                
        let allocationsWithNoAssumedScheme = this.incentiveGroupAllocations.filter(x => !x.assumedIncentiveSchemeId).sum(y => y.proposedAllocationExpectedValue);        
        if (allocationsWithNoAssumedScheme > 0 && this.schemeSplits.length === 0) {
            context.addError(`An allocation has already been made. Set the allocation to 0 before attempting to clear the Scheme Splits.`)
        }
    }

    public static incentiveSchemesUniqueRule: Validation.IRule | null = null;
    public static splitPercentagesTotalRule: Validation.IRule | null = null;    
    public static atLeastOneSchemeRule: Validation.IRule | null = null;

    public toString(): string {
        if (this.isNew) {
            return "New update participant allocation command";
        } else {
            return "Update Participant Allocation Command";
        }
    }
}