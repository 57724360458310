import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, AppTypes } from 'st-app/Services/AppService';
import AwardModuleConfig from '../../Models/Config/Module/AwardModuleConfig';
import UpdateAwardModuleConfigCommand from '../../Models/Config/Commands/UpdateAwardModuleConfigCommand';

export interface IAwardModuleConfigApiClient {

    /** 
     * Gets the award module config.
     * @returns The prep module config.
     */
    get(): AxiosPromise<Model.PlainTrackedObject<AwardModuleConfig>>;

    /** 
     * Updates the award module config.
     * @param command The config update command.
     * @returns A  representing the asynchronous update operation.
     */
    update(command: Model.PartialPlainObject<UpdateAwardModuleConfigCommand>): AxiosPromise;

    // Client only properties / methods
}

@injectable()
export default class AwardModuleConfigApiClient extends Data.ApiClientBase implements IAwardModuleConfigApiClient {

    constructor (config = AppService.get(AppTypes.Shared.Config)) {
        super(`${config.AwardsApi.ApiPath}/config`);
    }

    public get(): AxiosPromise<Model.PlainTrackedObject<AwardModuleConfig>> {
        return this.axios.get(`${this.apiPath}`);
    }

    public update(command: Model.PartialPlainObject<UpdateAwardModuleConfigCommand>): AxiosPromise {
        return this.axios.post(`${this.apiPath}`, command);
    }

    // Client only properties / methods
}