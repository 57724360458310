import React from 'react';
import { Views, Neo } from '@singularsystems/neo-react';
import { observer } from 'mobx-react';
import { AppService, AppTypes } from '../../Services/AppService';
import { NeoModel } from '@singularsystems/neo-core';

class RegisterViewParams {
    email = {};
}

@NeoModel
export class RegisterVM extends Views.ViewModelBase {

    public isComplete = false;
}

@observer
export default class RegisterView extends Views.ViewBase<RegisterVM, RegisterViewParams> {
    public static params = new RegisterViewParams();

    public oidcService = AppService.get(AppTypes.Shared.Services.STAuthenticationService);

	constructor(props: unknown) {
		super("Register", RegisterVM, props);
    }
    
    public viewParamsUpdated() {
        if (this.viewParams.email.value === "complete") {
            this.viewModel.isComplete = true;
        } else {
            this.oidcService.redirectToRegistration(this.viewParams.email.asString());
        }
    }

	public render() {
		return (
			<div className="mt-4">
				<h2>Register</h2>
				
                {this.viewModel.isComplete && 
                <div>
                    <Neo.Alert variant="success" className="mt-5">
                        <h4>Congratulations {this.oidcService.user!.displayName}</h4>
                        <p className="mt-3 mb-2">You have been registered on the Share Trust platform. You are now logged in.</p>
                    </Neo.Alert>
                    
                </div>}
            </div>
        );
	}
}