import { ModelBase, NeoModel } from '@singularsystems/neo-core';
import { IncentiveGroupLookup } from '../../../../Awards/Common/CommonSharedExports';
import TradeRequestCommand from './Commands/TradeRequestCommand';
import BindableTradeInfo from './BindableTradeInfo';
import LinkedTradeRequestsCommand from './Commands/LinkedTradeRequestsCommand';
import CalculationGroup from '../Portfolio/Calculation/CalculationGroup';

@NeoModel
export default class TradeIncentiveGroup extends ModelBase {

    constructor(public info: IncentiveGroupLookup, public trades: (TradeRequestCommand | LinkedTradeRequestsCommand)[]) {
        super();

        this.bindableTrades = trades.map(t => new BindableTradeInfo(t));
    }

    public readonly bindableTrades: BindableTradeInfo[];

    public acceptTradeAgreement: boolean = false;

    /** For CategoryList component. */
    public get flattened() {
        return this.bindableTrades.reduce((p, c) => p.concat(...(c.calcObject.groupLink?.linkedGroups ?? [c.calcObject])), [] as CalculationGroup[]);
    }

    public get hasDetail() {
        return this.trades.length > 0;
    }
}