import { List, ModelBase, NeoModel } from '@singularsystems/neo-core';
import IncentiveSchemeParticipantLookup from '../IncentiveSchemeParticipantLookup';
import BrokerAccountLookup from '../Brokers/Lookups/BrokerAccountLookup';
import { BrokingFeeLookup } from '../Brokers/Lookups/BrokingFeeLookup';
import { IGroupedBalance } from './CalculatorDataRequest';
import { Awards } from '../../../../App';
import RatesLookup from '../RatesLookup';
import CalculationTranche from './Calculation/CalculationTranche';
import SettlementExchangeRateLookup from './SettlementExchangeRateLookup';

interface IGroupedBalanceResult extends IGroupedBalance {
    hasClosePeriod: boolean;

    separationExpiryDate: Date | null;
}

@NeoModel
export default class CalculatorData extends ModelBase {

    public transactionsSchemes = new List(IncentiveSchemeParticipantLookup);

    public brokerAccounts = new List(BrokerAccountLookup);

    public brokingFees = new List(BrokingFeeLookup);

    public groupedBalances: IGroupedBalanceResult[] = [];

    public settlementExchangeRates = new List(SettlementExchangeRateLookup);

    // Client only properties / methods

    public populateTranches(incentiveSchemes: Awards.IncentiveSchemeLookup[], rates: RatesLookup, trancheBalances: CalculationTranche[]) {

        const defaultBrokerAccount = this.brokerAccounts.find(c => c.isDefault)!;
        for (let transactionsScheme of this.transactionsSchemes) {
            transactionsScheme.awardsScheme = incentiveSchemes.find(c => c.incentiveSchemeId === transactionsScheme.incentiveSchemeId)!;
            if (transactionsScheme.brokerAccountId) {
                transactionsScheme.brokerAccount = this.brokerAccounts.find(c => c.brokerAccountId === transactionsScheme.brokerAccountId) ?? null;
            }
        }
        
        for (let tranche of trancheBalances) {
            tranche.transactionsScheme = this.transactionsSchemes.find(c => c.incentiveSchemeId === tranche.trancheBalance.incentiveSchemeId) ?? null;
            tranche.brokerAccount = tranche.transactionsScheme?.brokerAccount ?? defaultBrokerAccount!;
            tranche.rates = rates;

            tranche.trancheBalance.settlementExchangeRate = this.settlementExchangeRates.find(ser => tranche.trancheBalance.instrument.currencyCode === ser.fromCurrencyCode && tranche.trancheBalance.settlementInstrument?.currencyCode === ser.toCurrencyCode)?.settlementExchangeRate ?? null;

            const trancheDetails = tranche.trancheBalance;
            const extraInfo = this.groupedBalances.find(
                c => c.incentiveSchemeId === trancheDetails.incentiveSchemeId && 
                c.instrumentId === trancheDetails.instrumentId && 
                (c.allowedExerciseDays ?? 0) === (trancheDetails.allowedExerciseDays ?? 0));

            if (extraInfo) {
                trancheDetails.hasClosePeriod = extraInfo.hasClosePeriod;
                if (extraInfo.separationExpiryDate) {
                    trancheDetails.separationExpiryDate = new Date(extraInfo.separationExpiryDate);
                }
            }
        };

        for (let brokerAccount of this.brokerAccounts) {
            brokerAccount.vatRate = rates.vatRate;
            brokerAccount.setFeeObjects(this.brokingFees);
        }
    }
}