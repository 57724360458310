import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../ClientsTypes';
import { App } from '../Models/Enums/App';
import AppStyleInfo from '../Models/Styling/AppStyleInfo';

export interface IStylesQueryApiClient {

    /**
     * Gets the file hash of the current css for the admin site.
     * @param app Get the hash for which application?
     * @returns String hash to be passed to GetCss.
     */
    getFileHash(app: App): AxiosPromise<any>;

    /** 
     * Gets the style info for the app.
     * @param app Which application?
     * @returns Style info.
     */
    getStyleInfo(app: App): AxiosPromise<Model.PlainObject<AppStyleInfo>>;

    /**
     * Gets the css file which matches the hash string.
     * Note: This method returns the url of the api endpoint. It performs no logic.
     * @param app Get the file for which application?
     * @param hash Hash string retrieved from GetFileHash.
     * @returns Css file
     */
    getCssUrl(app: App, hash: string): string;

    // Client only properties / methods
}

@injectable()
export default class StylesQueryApiClient extends Data.ApiClientBase implements IStylesQueryApiClient {

    constructor(config = AppService.get(Types.Shared.Config)) {
        super(`${config.ClientsApi.ApiPath}/StylesQuery`);
    }

    public getFileHash(app: App): AxiosPromise<any> {
        return this.axios.get(`${this.apiPath}/GetFileHash?app=${app}`);
    }

    public getStyleInfo(app: App): AxiosPromise<Model.PlainObject<AppStyleInfo>> {
        return this.axios.get(`${this.apiPath}/style-info?app=${app}`);
    }

    public getCssUrl(app: App, hash: string): string {
        return `${this.apiPath}/GetCss?app=${app}&hash=${encodeURIComponent(hash)}`;
    }
    
    // Client only properties / methods
}