import { Data } from "@singularsystems/neo-core";
import { LifeTime } from "../../../App/Services/AppDataService";
import ParticipantGrade from "../Models/ParticipantGrade";
import { AppService, Types } from "../ParticipantsTypes";

export default class ParticipantsDataCache extends Data.CachedDataService {
    constructor(
        private catalogueApiClient = AppService.get(Types.Participants.ApiClients.CatalogueApiClient),
        public participantApiClient = AppService.get(Types.Participants.ApiClients.MainApiClient)
    ) {
        super();
    }

    public participantGrades = this.registerList(ParticipantGrade, this.catalogueApiClient.getParticipantGrades, LifeTime.Long);
    public secondments = this.register(this.participantApiClient.getSecondments, LifeTime.Long)
}