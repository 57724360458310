import React from 'react';
import { Neo } from '@singularsystems/neo-react';
import { observer } from 'mobx-react';
import TradeVM from '../../TradeVM';
import { AppService, Types } from '../../../TransactionsTypes';
import { EditTradeBrokerDetailsModal } from '../../../../Common/Components/BrokerDetails';
import { IncentiveGroupList } from './IncentiveGroupList';
import { TradeGroupedGrid } from './TradeGroupedGrid';

interface ITradeComponentProps {
    viewModel: TradeVM;
    backToPortfolio: () => void;
    backToCalculator: () => void;
}

@observer
export class TradeComponent extends React.Component<ITradeComponentProps> {

    private pluralizer = AppService.get(Types.Shared.Services.Pluralizer);

    public render() {
        const viewModel = this.props.viewModel;
        const tradePlural = this.pluralizer.pluralizeIfMany(viewModel.flattenedTradeRequests.length, "trade");
        return <div className="trade-component">
            {this.getHeader(viewModel)}

            <div className="mt-4">
                {!viewModel.startedSavingTrades &&
                    <p className="mb-3">
                        View trade agreements and ensure your broker information is correct before confirming your {tradePlural}.
                    </p>}

                <IncentiveGroupList categories={viewModel.tradeGroups} renderBody={category =>
                    <TradeGroupedGrid category={category} viewModel={viewModel} />} />
            </div>

            {!viewModel.startedSavingTrades && this.renderPreSaveFooter(viewModel, tradePlural)}
            {viewModel.hasSavedTrades && this.renderPostSaveFooter(viewModel)}

            <EditTradeBrokerDetailsModal
                bind={viewModel.meta.editBrokerDetail}
                onSave={() => viewModel.saveBrokerDetails()} />
        </div>
    }

    private getHeader(viewModel: TradeVM) {
        const tradePlural = this.pluralizer.pluralizeIfMany(viewModel.flattenedTradeRequests.length, "trade");

        if (!viewModel.startedSavingTrades) {
            return <h2>Step 2: Confirm {tradePlural}</h2>;
        } else {
            if (!viewModel.hasSavedTrades) {
                return <h2>Submitting {tradePlural}</h2>;
            } else {
                if (viewModel.savedCount > 0) {
                    if (viewModel.hadSaveErrors) {
                        return <h2>Your {viewModel.savedCount > 1 ? "trades have" : "trade has"} been submitted</h2>
                    } else {
                        return <h2>Success! <span className="nested-heading">Your {viewModel.savedCount > 1 ? "trades have" : "trade has"} been submitted</span></h2>
                    }
                } else {
                    return <h2>Error submitting {tradePlural}</h2>
                }
            }
        }
    }

    private renderPreSaveFooter(viewModel: TradeVM, tradePlural: string) {
        return (
            <div>
                {viewModel.hasBuys &&
                    <div className="portfolio-group-container">
                        <div className="portfolio-group-header">
                            <div className="portfolio-group-sub-title mt-2">
                                Broker details
                            </div>

                            {viewModel.participantBrokerDetail &&
                                <div>
                                    {viewModel.participantBrokerDetail.description} | <a href="/" onClick={e => { viewModel.editBrokerDetails(); e.preventDefault() }}><strong>Edit broker details</strong></a>.
                                </div>}

                            {!viewModel.participantBrokerDetail && !viewModel.taskRunner.isBusy &&
                                <div className="warning-section mt-3">
                                    <i className="fa fa-exclamation-triangle fa-2x"></i>
                                    <div>
                                        You have not provided broking details.<br /> You will need to provide these details before any shares can be transferred to you.
                                        <div className="mt-1">
                                            <a href="/" onClick={e => { viewModel.editBrokerDetails(); e.preventDefault() }}><strong>Click here</strong></a> to add your broker details.
                                        </div>
                                    </div>
                                </div>}
                        </div>
                    </div>}

                <div className="portfolio-group-container">
                    <div className="portfolio-group-header">
                        <div className="text-right mt-3">
                            <Neo.FormGroup bind={viewModel.meta.acceptTerms} disabled={!viewModel.hasViewedTradeAgreementText} label="I accept the trade agreements" className="mb-1" />
                        </div>
                        <div className="flex-container justify-content-between">
                            <Neo.Button className="btn-150" variant="secondary" onClick={() => this.props.backToCalculator()}>Back</Neo.Button>
                            <Neo.Button className="btn-150" variant="primary" disabled={!viewModel.acceptTerms}
                                tooltip={!viewModel.hasViewedTradeAgreementText ? "Please view the trade agreement" : !viewModel.acceptTerms ? "Please accept the trade agreement" : ""}
                                onClick={() => viewModel.submitTrades()}>Confirm {tradePlural}</Neo.Button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    private renderPostSaveFooter(viewModel: TradeVM) {
        return (
            <div className="portfolio-group-container">
                <div className="portfolio-group-header pt-5">
                    {viewModel.hadSaveErrors &&
                        <Neo.Alert variant="warning" className="alert-tiny mb-3">
                            Please note: Errors occurred on some trades.
                        </Neo.Alert>}

                    <div className="text-right">
                        <Neo.Button className="btn-150" variant="primary" onClick={() => this.props.backToPortfolio()}>View portfolio</Neo.Button>
                        {/* <Neo.Button className="btn-150" variant="primary" onClick={() => {}}>View trades</Neo.Button> */}
                    </div>
                </div>
            </div>
        )
    }
}