import { Data } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../ClientsTypes';

export interface IImagesQueryApiClient {

    /** 
     * Gets a flattened list of all images. If there is a tenant, the tenant overrides are copied into the top level.
     * @returns Lookup list
     */
    getImageLookup(): AxiosPromise<any>;

    // Client only properties / methods
}

@injectable()
export default class ImagesQueryApiClient extends Data.ApiClientBase implements IImagesQueryApiClient {

    constructor (config = AppService.get(Types.Shared.Config)) {
        super(`${config.ClientsApi.ApiPath}/ImagesQuery`);
    }

    public getImageLookup(): AxiosPromise<any> {
        return this.axios.get(`${this.apiPath}`);
    }

    // Client only properties / methods
}