import { ModelBase, NeoModel } from '@singularsystems/neo-core';
import CalculationTranche from './CalculationTranche';

@NeoModel
export class TrancheLink extends ModelBase {

    public awardLinkId: number = 0;

    public awardLinkDescription: string = "";

    public vestingDate!: Date;

    public linkedTranches: CalculationTranche[] = [];

    public suppressSynchronisation = false;

    public get hasProfit() {
        let profit = 0;
        for (let tranche of this.linkedTranches) {
            let trancheProfit = (tranche.availableBalance * tranche.effectiveSellPrice) + tranche.remainingAwardDebt + tranche.tradingCosts;

            if (tranche.trancheBalance.awardLinkExchangeRate !== null) {
                trancheProfit = trancheProfit * tranche.trancheBalance.awardLinkExchangeRate;
            }

            profit += trancheProfit;
        }
        return profit > 0;
    }

    public setQuantityOnLinkedTranches(tranche: CalculationTranche) {
        if (!this.suppressSynchronisation && tranche.availableBalance > 0) {
            let sellRatio = tranche.sellQuantity / tranche.availableBalance;
            let buyRatio = tranche.buyQuantity / tranche.availableBalance;
    
            for (let linkedTranche of this.linkedTranches) {
                if (linkedTranche !== tranche) {
                    linkedTranche.setSellQuantity(Math.round(sellRatio * linkedTranche.availableBalance));
                    linkedTranche.setBuyQuantity(Math.round(buyRatio * linkedTranche.availableBalance));
                }
            }
        }  
    }
}