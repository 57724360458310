import { Misc, NumberUtils } from '@singularsystems/neo-core';

export default class AmountWithCurrency {
    
    constructor(
        public amount: number,
        public currencySymbol: string) {

        }

    public toString() {
        return NumberUtils.format(this.amount, Misc.NumberFormat.CurrencyNoDecimals, this.currencySymbol);
    }
}