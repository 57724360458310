import { NeoModel, Validation, ValueObject } from '@singularsystems/neo-core';
import { TsAndCsDisplayLocation } from './TsAndCsDisplayLocation.enum';

@NeoModel
export default class TsAndCsDisplayOptions extends ValueObject {

    public name: string = "Terms & Conditions";

    public location: TsAndCsDisplayLocation | null = null;

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        return "Ts And Cs Display Options";
    }
}