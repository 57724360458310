// Transactions Module
// DI and Route Setup
import { AppServices  } from '@singularsystems/neo-core';
import AllocationManagerView from './Views/AllocationManagerView';
import { AppService, Types } from './AllocationManagerTypes';
import AllocationPlanQueryApiClient from './ApiClients/AllocationPlanQueryApiClient';
import AllocationPlanInformationQueryApiClient from './ApiClients/AllocationPlanInformationQueryApiClient';
import AllocationParticipantQueryApiClient from './ApiClients/AllocationParticipantQueryApiClient';
import AllocationManagerQueryApiClient from './ApiClients/AllocationManagerQueryParticipantApiClient';
import AllocationParticipantApiClient from './ApiClients/AllocationParticipantApiClient';
import AllocationCommentTypeApiClient from './ApiClients/AllocationCommentTypeApiClient';
import { IAppMenuItem } from '../../../App/Services/RouteService';
import AllocationManagerService from './Services/AllocationManagerService';
import AllocationManagerApiClient from './ApiClients/AllocationManagerApiClient';
import AllocationReportingApiClient from './ApiClients/AllocationReportingApiClient';
import GridColumnConfigApiClient from './ApiClients/GridColumnConfigApiClient';

// Modules
const allocationManagerAppModule = new AppServices.Module("AllocationManager", container => {    
    // Api Clients        
    container.bind(Types.AllocationManager.ApiClients.AllocationPlanQueryApiClient).to(AllocationPlanQueryApiClient).inSingletonScope();
    container.bind(Types.AllocationManager.ApiClients.AllocationPlanInformationQueryApiClient).to(AllocationPlanInformationQueryApiClient).inSingletonScope();
    container.bind(Types.AllocationManager.ApiClients.AllocationParticipantQueryApiClient).to(AllocationParticipantQueryApiClient).inSingletonScope();
    container.bind(Types.AllocationManager.ApiClients.AllocationParticipantApiClient).to(AllocationParticipantApiClient).inSingletonScope();
    container.bind(Types.AllocationManager.ApiClients.AllocationManagerQueryApiClient).to(AllocationManagerQueryApiClient).inSingletonScope();
    container.bind(Types.AllocationManager.ApiClients.AllocationCommentTypeApiClient).to(AllocationCommentTypeApiClient).inSingletonScope();
    container.bind(Types.AllocationManager.ApiClients.AllocationManagerApiClient).to(AllocationManagerApiClient).inSingletonScope();
    container.bind(Types.AllocationManager.ApiClients.AllocationReportingApiClient).to(AllocationReportingApiClient).inSingletonScope();
    container.bind(Types.AllocationManager.ApiClients.GridColumnConfigApiClient).to(GridColumnConfigApiClient).inSingletonScope();

    // Services
    container.bind(Types.AllocationManager.Services.AllocationManagerService).to(AllocationManagerService).inSingletonScope();    
});

const allocationManagerTestModule = new AppServices.Module("AllocationManager", container => {
    // bind test types
});

const AllocationManagerRoute: IAppMenuItem = { 
    name: "LTI Allocator", path: "/allocation-manager", component: AllocationManagerView,
    showMenuItem: () => 
    {                                        
        return AppService.get(Types.AllocationManager.Services.AllocationManagerService).displayMenu;
    }
}

// const AllocationPlanPureRoutes = []

export { allocationManagerAppModule, allocationManagerTestModule, AllocationManagerRoute }