import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { Data, Model } from '@singularsystems/neo-core';

import { AppService, AppTypes } from 'st-app/Services/AppService';

import InstrumentLookup from '../Models/Instruments/InstrumentLookup';
import IncentivePlanInstrumentLookup from '../Models/IncentiveSchemes/IncentivePlanInstrumentLookup';
import CurrencyLookup from '../../../Transactions/Common/Models/CurrencyLookup';

export interface ICatalogueQueryApiClient {
    /**
     * Will get a list of instrument records matching the provided criteria
     * @returns A list of instrument records
     */
    getInstrumentLookup(): AxiosPromise<Array<Model.PlainObject<InstrumentLookup>>>;

    /**
     * Will get the Instruments that are related to the given incentivePlanId
     * @param incentivePlanId The IncentivePlanId
     * @returns A List of Instruments that are related to the given incentivePlanId
     */
    getIncentivePlanInstrumentsQuery(incentivePlanId: number): AxiosPromise<Array<Model.PlainObject<IncentivePlanInstrumentLookup>>>;

    /** 
     * Will get the Currency matching the given Currency Code
     * @param currencyCode The Currency Code
     * @returns A Currency for the given code
     */
    getCurrency(currencyCode: string): AxiosPromise<Model.PlainTrackedObject<CurrencyLookup>>;
}

@injectable()
export class CatalogueQueryApiClient extends Data.ApiClientBase implements ICatalogueQueryApiClient {

    constructor(
        config = AppService.get(AppTypes.Shared.Config),
        axios = AppService.get(AppTypes.Neo.Axios)) {

        super(`${config.AwardsApi.ApiPath}/catalogueQuery`, axios);

        this.getInstrumentLookup = this.getInstrumentLookup.bind(this);
        }

    public getInstrumentLookup(): AxiosPromise<Array<Model.PlainObject<InstrumentLookup>>> {
        return this.axios.get(`${this.apiPath}/instruments`);
    }

    public getIncentivePlanInstrumentsQuery(incentivePlanId: number): AxiosPromise<Array<Model.PlainObject<IncentivePlanInstrumentLookup>>> {
        return this.axios.get(`${this.apiPath}/incentivePlanInstruments?incentivePlanId=${incentivePlanId}`);
    }

    public getCurrency(currencyCode: string): AxiosPromise<Model.PlainTrackedObject<CurrencyLookup>> {
        return this.axios.get(`${this.apiPath}/currencyByCode?currencyCode=${encodeURIComponent(currencyCode)}`);
    }
}