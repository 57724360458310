import { NeoModel, Rules, ValueObject } from '@singularsystems/neo-core';

@NeoModel
export default class AwardReleaseConfig extends ValueObject  {

    @Rules.StringLength(50)
    public header: string = "";

    @Rules.StringLength(500)
    public subHeader: string = "";

    @Rules.StringLength(50)
    public participantInfoTitle: string = "";

    @Rules.StringLength(500)
    public participantInfoText: string = "";

    // Client only properties / methods
}