import { NeoModel, Validation } from '@singularsystems/neo-core';
import ChangeApprovalsCommandBase from './ChangeApprovalsCommandBase';

@NeoModel
export default class DeclineApprovalsCommand extends ChangeApprovalsCommandBase {

    public declinedReason: string = "";

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew || !this.declinedReason) {
            return "New decline approvals command";
        } else {
            return this.declinedReason;
        }
    }
}