import { Attributes, NeoModel, Validation, ValueObject } from '@singularsystems/neo-core';
import ParticipantBrokerDetail from './ParticipantBrokerDetail';

@NeoModel
export default class UpdateParticipantBrokerDetailCommand extends ValueObject {

    @Attributes.ChildObject(ParticipantBrokerDetail)
    public participantBrokerDetail!: ParticipantBrokerDetail;

    @Attributes.Nullable()
    public tradeRequestId: number | null = null;

    public updatePendingTradeRequests: boolean = false;

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        return "Update Participant Broker Detail Command";
    }
}