import React from 'react';
import { Neo } from '@singularsystems/neo-react';
import { observer } from 'mobx-react';

import { AppService, Types } from '../../../AwardsTypes';

import '../../../Styles/approvals.scss';

import { Verbosity } from '../../../../Common/Models/Enums/Verbosity';

import { ApproverAwardApprovalSummaryLookup } from '../../../../Common/Models/Approvals/Queries/ApproverApprovalSummaryLookup';
import { Utils, DateUtils, NumberUtils, Misc, List } from '@singularsystems/neo-core';
import AwardParticipantOfferTotalLookup from '../../../../Common/Models/ParticipantOffers/Queries/AwardParticipantOfferTotalLookup';

interface IApprovalAwardSummaryComponentProps {
    awardApproval: ApproverAwardApprovalSummaryLookup;
    offerTotals?: List<AwardParticipantOfferTotalLookup>;
    awardSelected?: ((award: ApproverAwardApprovalSummaryLookup) => void);
    verbosity: Verbosity | undefined;
}


@observer
export default class ApprovalAwardSummaryComponent extends React.Component<IApprovalAwardSummaryComponentProps> {

    private awardUtils = AppService.get(Types.Awards.Services.AwardUtils);

    public render() {
        const awardApproval = this.props.awardApproval;

        let verbosity = this.props.verbosity;
        if (!verbosity) {
            verbosity = Verbosity.Detailed;
        }

        return (
            <Neo.Alert key={awardApproval.award.awardPrepId}
                variant={awardApproval.getAlertVariant()} className={"approval-summary" + (this.props.awardSelected ? " selectable" : "")}
                onClick={() => this.props.awardSelected && this.props.awardSelected(awardApproval)} >

                <div className="info-row">
                    <div>
                        <p>
                            Submitted <strong>{NumberUtils.format(awardApproval.submittedPercentage * 100, Misc.NumberFormat.NoDecimals)}</strong> <i className={Utils.getFontAwesomeClass("percent")} />
                        </p>
                    </div>
                    <div>
                        <p>
                            {this.awardUtils.getClosingHtml(verbosity, this.props.awardApproval.daysRemaining, this.props.awardApproval.actionBy)}
                        </p>
                    </div>
                    {verbosity === Verbosity.Summary &&
                        <div className="options">
                            <div>Options: </div>
                            <ul>
                                {awardApproval.award.options.map(option =>
                                    <li key={option.awardNumber!}>
                                        {option.toString()}
                                    </li>)}
                            </ul>
                        </div>}
                </div>
                {this.props.offerTotals &&
                    <div className="row mb-3 allocation-totals">
                        <div className="col-4">Total Allocation</div>
                        <div className="col-8 row">
                            {this.props.offerTotals.sortBy("offerTotal").map(total =>
                                <div key={total.entityIdentifier} className="col allocation-total">
                                    <span className="symbol">{total.instrumentDisplay}</span> <span className="value">{NumberUtils.format(total.offerTotal, total.getNumberFormat())}</span>
                                </div>)}
                        </div>
                    </div>}

                {verbosity === Verbosity.Detailed &&
                    <div className="info-row">
                        <div>
                            <p>
                                Awarded on <strong>{DateUtils.format(awardApproval.award!.awardDate!, DateUtils.defaultFormatString)}</strong>
                            </p>
                        </div>
                    </div>}
                {verbosity === Verbosity.Detailed &&
                    <div className="info-row">
                        <div className="options">
                            <span>Options: </span>
                            <ul>
                                {awardApproval.award.options.map(option =>
                                    <li key={option.awardNumber!}>
                                        {option.toString()}
                                    </li>)}
                            </ul>
                        </div>
                    </div>}

                <Neo.GridLayout sm={1} lg={3} className="stats" >
                    <div className="approved">
                        <div className="d-block">
                            <span>Approved: <i className="fa fa-check" /></span>
                        </div>
                        <div><strong>{NumberUtils.format(awardApproval.approved, Misc.NumberFormat.NoDecimals)} - ({NumberUtils.format(awardApproval.approvedPercentage, Misc.NumberFormat.PercentNoDecimals)})</strong> </div>
                    </div>
                    <div className="submitted">
                        <div className="d-block">
                            <span>Submitted: <i className="fa fa-check-double" /></span>
                        </div>
                        <div><strong>{NumberUtils.format(awardApproval.submitted, Misc.NumberFormat.NoDecimals)} / {NumberUtils.format(awardApproval.approvals, Misc.NumberFormat.NoDecimals)} ({NumberUtils.format(awardApproval.submittedPercentage, Misc.NumberFormat.PercentNoDecimals)})</strong> </div>
                    </div>
                    <div className="declined">
                        <div className="d-block">
                            <span>Declined: <i className="fa fa-times" /></span>
                        </div>
                        <div><strong>{NumberUtils.format(awardApproval.declined, Misc.NumberFormat.NoDecimals)} - ({NumberUtils.format(awardApproval.declinedPercentage, Misc.NumberFormat.PercentNoDecimals)})</strong> </div>
                    </div>
                </Neo.GridLayout>
            </Neo.Alert>
        )
    }
}