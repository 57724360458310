import { Attributes, List, LookupBase, NeoModel } from '@singularsystems/neo-core';
import AllocationPreviousSplitLookup from './AllocationPreviousSplitLookup';
import AllocationExpectedValueLookup from './AllocationExpectedValueLookup';

@NeoModel
export default class AllocationParticipantHistoryLookup extends LookupBase {

    public allocationParticipantId: number = 0;

    @Attributes.Integer()
    public financialYear: number = 0;

    public vestingYears: number = 0;

    public previousSplits: List<AllocationPreviousSplitLookup> | null = new List(AllocationPreviousSplitLookup);

    public expectedValues: List<AllocationExpectedValueLookup> | null = new List(AllocationExpectedValueLookup);

   // Client only properties / methods

   public currencySymbol: string = "";

   public annualTCTC = 0;

    public get vestedExpectedValueTotal() {
        return this.expectedValues?.sum(ev => ev.currentExpectedValue);
    }

   public get plus1ExpectedValueTotal() {
      return this.expectedValues?.sum(ev => ev.plus1ExpectedValue);
   }

   public get plus2ExpectedValueTotal() {
      return this.expectedValues?.sum(ev => ev.plus2ExpectedValue);
   }

   public get plus3ExpectedValueTotal() {
      return this.expectedValues?.sum(ev => ev.plus3ExpectedValue);
   }

   public get plus4ExpectedValueTotal() {
      return this.expectedValues?.sum(ev => ev.plus4ExpectedValue);
   }

   public get plus5ExpectedValueTotal() {
      return this.expectedValues?.sum(ev => ev.plus5ExpectedValue);
   }

   public get totalExpectedValue() {
       return this.expectedValues?.sum(ev => ev.totalQuantity);
   }

   //labels

   public expectedValueLabel(yearsToAdd: number) {
      return "FY " + (this.financialYear! + yearsToAdd) + "\n Vestings"
   }

    public percOfSalaryByYear(year: number): number {
        let total = 0;
        switch (year) {
            case 0: // the sum of all vested expected values
                total = this.vestedExpectedValueTotal!;
                break;
            case 1:
                total = this.plus1ExpectedValueTotal!;
                break;
            case 2:
                total = this.plus2ExpectedValueTotal!;
                break;
            case 3:
                total = this.plus3ExpectedValueTotal!;
                break;
            case 4:
                total = this.plus4ExpectedValueTotal!;
                break;
            case 5:
                total = this.plus5ExpectedValueTotal!;
                break;
        }
        return total / (this.annualTCTC === 0 || this.annualTCTC === null ? 1 : this.annualTCTC!);
    }

    public percOfSalaryTotal(): number {
        return this.totalExpectedValue! / this.vestingYears / (this.annualTCTC === 0 || this.annualTCTC === null ? 1 : this.annualTCTC!);
    }
}