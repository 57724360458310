import { Attributes, LookupBase } from '@singularsystems/neo-core';

export default class ClosedPeriodLookup extends LookupBase {

    constructor() {
        super();
        this.makeBindable();
    }

    public readonly closedPeriodName: string = "";

    @Attributes.Date()
    public readonly startDate: Date | null = null;

    @Attributes.Date()
    public readonly endDate: Date | null = null;

    // Client only properties / methods
}