import { LookupBase, NeoModel } from '@singularsystems/neo-core';

export class IncentiveSchemeLookupBase extends LookupBase {

    public incentiveSchemeId: number = 0;

    public brokerAccountId: number | null = null;

    public allowForexCover: boolean | null = null;

}

@NeoModel
export class IncentiveSchemeLookup extends IncentiveSchemeLookupBase {

    // Client only properties / methods
}