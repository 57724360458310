import React from 'react';
import { Neo, Views } from '@singularsystems/neo-react';
import AllocationInformationVM from './AllocationInformationVM';
import { observer } from 'mobx-react';
import AllocationManagerView from './AllocationManagerView';
import { PageBlock } from '../../../../App/Components/Application/PageBlock';

class AllocationInformationViewParams {
    public allocationPlanTypeId = { required: true, isQuery: true };
}

@observer
export default class AllocationInformationView extends Views.ViewBase<AllocationInformationVM, AllocationInformationViewParams> {

    constructor(props: unknown) {
        super("Allocation Information", AllocationInformationVM, props);
    }

    public static params = new AllocationInformationViewParams();

    protected async viewParamsUpdated() {
        await this.viewModel.getAllocationPlanInformation(this.viewParams.allocationPlanTypeId.value as number);
    }

    public get headerTitle() {
        return "Support and Training";
    }

    public navigateToAllocations() {
        this.navigation.navigateToView(AllocationManagerView);
    }

    public render() {
        return (
            <div>
                <PageBlock className="pt-3" alt>
                    <div>
                        <Neo.Button className="btn-150 mb-3" isOutline onClick={() => this.navigateToAllocations()}>Back to allocations</Neo.Button>
                        <h2>LTI MANAGER GUIDE</h2>
                        <p>
                            {this.viewModel.supportAndTrainingSectionInfo.sectionText}
                        </p>
                        <div className="mt-3">
                            {this.viewModel.supportAndTrainingSectionInfo.allocationPlanInformationSupportMedia.map(media => (
                                <div key={media.allocationPlanInformationSupportMediaId}>
                                    <Neo.Button className="btn-150" onClick={() => this.viewModel.openDocument(media.documentURL)}>Open</Neo.Button>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div>
                        <img src={this.viewModel.supportAndTrainingSectionImage} className="support-image" alt="..."></img>
                    </div>
                </PageBlock>
                <PageBlock>
                    <div>
                        <h2>SHORT GUIDES</h2>
                        <p>
                            Our series of quick and simple short guides are here to remind you of important information on key matters when making your allocations.
                        </p>
                    </div>
                    <div>

                    </div>
                </PageBlock>
                <PageBlock className="pt-3">
                    <div>
                        <Neo.Loader task={this.viewModel.taskRunner}>
                            <div>
                                {this.viewModel.shortGuidesLeft.map(item => (
                                    <div key={item.allocationPlanInformationId}>
                                        <PageBlock>
                                            <div className="short-guides">
                                                {item.allocationPlanInformationSupportMediaImages.map(media => (
                                                    <div key={media.allocationPlanInformationSupportMediaId}>
                                                        <img src={media.imageURL} className="short-guide-image" alt="..." />
                                                    </div>
                                                ))}
                                            </div>
                                            <div className="short-guides">
                                                {item.allocationPlanInformationSupportMediaDocuments.map(media => (
                                                    <div key={media.allocationPlanInformationSupportMediaId} className="mt-3">
                                                        <p className="header-small">{item.sectionText}</p>
                                                        <Neo.Button className="btn-150 mt-3" onClick={() => this.viewModel.openDocument(media.documentURL)}>Open</Neo.Button>
                                                    </div>
                                                ))}
                                            </div>
                                        </PageBlock>
                                    </div>
                                ))}
                            </div>
                        </Neo.Loader>
                    </div>
                    <div>
                        <Neo.Loader task={this.viewModel.taskRunner}>
                            <div>
                                {this.viewModel.shortGuidesRight.map(item => (
                                    <div key={item.allocationPlanInformationId}>
                                        <PageBlock>
                                            <div className="short-guides">
                                                {item.allocationPlanInformationSupportMediaImages.map(media => (
                                                    <div key={media.allocationPlanInformationSupportMediaId}>
                                                        <img src={media.imageURL} className="short-guide-image" alt="..." />
                                                    </div>
                                                ))}
                                            </div>
                                            <div className="short-guides">
                                                {item.allocationPlanInformationSupportMediaDocuments.map(media => (
                                                    <div key={media.allocationPlanInformationSupportMediaId} className="mt-3">
                                                        <p className="header-small">{item.sectionText}</p>
                                                        <Neo.Button className="btn-150 mt-3" onClick={() => this.viewModel.openDocument(media.documentURL)}>Open</Neo.Button>
                                                    </div>
                                                ))}
                                            </div>
                                        </PageBlock>
                                    </div>
                                ))}
                            </div>
                        </Neo.Loader>
                    </div>
                    <Neo.Button className="btn-150 mb-3" isOutline onClick={() => this.navigateToAllocations()}>Back to allocations</Neo.Button>
                </PageBlock>
            </div>
        );
    }
}