import { AppService, AppTypes } from '../../../App/Services/AppService';

import ProxySessionService from '../../Common/Services/ProxySessionService';
import { SilentSignInRoute } from '../../../App/Services/RouteService';


export default class ParticipantsAppProxySessionService extends ProxySessionService {

    constructor(private config = AppService.get(AppTypes.Shared.Config)) {
        super();
    }

    public async endProxySession() {
        var returnUrl = await super.endProxySession();

        if (this.failMessage === "" && this.user && this.user.isAdmin()) {
            // redirect to participants
            let url = this.config.AdminApp.SilentSignInUrl;
            if (returnUrl) {
                url = url + `?returnUrl=${returnUrl}`;
            }
            window.location.href = url;
            return returnUrl;
        } else {
            this.navigation.navigateInternal(SilentSignInRoute.path);
            return "";
        }
    }

}