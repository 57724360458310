import { NeoModel, Data, Model, Components, Utils } from '@singularsystems/neo-core';
import { AppService, Types } from '../../AwardsTypes';

import { Views } from '@singularsystems/neo-react';
import { ApproverApprovalSummaryLookup, ApproverAwardApprovalSummaryLookup, ApprovalCounts } from '../../../Common/Models/Approvals/Queries/ApproverApprovalSummaryLookup';
import ApprovalLookupCriteria from '../../../Common/Models/Approvals/Queries/ApprovalLookupCriteria';
import ApprovalLookup from '../../../Common/Models/Approvals/Queries/ApprovalLookup';
import InstrumentLookup from '../../../Common/Models/Instruments/InstrumentLookup';
import AwardPrepApprovalConfig from '../../../Common/Models/Config/AwardPrepApprovalConfig';
import ApproveApprovalsCommand from '../../Models/Approvals/Commands/ApproveApprovalsCommand';
import { ApprovalStatus } from '../../../Common/Models/Enums/ApprovalStatus';
import DeclineApprovalsCommand from '../../Models/Approvals/Commands/DeclineApprovalsCommand';
import SubmitApprovalsCommand from '../../Models/Approvals/Commands/SubmitApprovalsCommand';
import ClearApprovalsCommand from '../../Models/Approvals/Commands/ClearApprovalsCommand';
import ChangeApprovalsCommandBase from '../../Models/Approvals/Commands/ChangeApprovalsCommandBase';
import { NotificationDuration } from '../../../../App/Models/Enums/NotificationDuration';
import { AxiosPromise } from 'axios';
import ChangeApprovalParticipantOfferCommand from '../../Models/Approvals/Commands/ChangeApprovalParticipantOfferCommand';
import { ApprovalHistoryComponentVM } from '../../../Common/Views/Approvals/Components/ApprovalHistoryComponent';

interface ICommandQueueItem {
    command: ChangeApprovalsCommandBase;
    items: ApprovalLookup[];
    clientAction: (command: Model.PartialPlainObject<ChangeApprovalsCommandBase>) => AxiosPromise<number>;
    actionMethod?: (item: ApprovalLookup) => void;
}

@NeoModel
export default class ApprovalsVM extends Views.ViewModelBase {

    public approvalSummary = new ApproverApprovalSummaryLookup();

    public instrumentsSet: { [code: string]: InstrumentLookup | undefined } = {};

    private loadAwardPrepId: number = 0;

    public approverAward: ApproverAwardApprovalSummaryLookup | null = null;

    public get offerTotals() { return this.offerTotalsService.getOfferTotals(); }

    public approvalConfig: AwardPrepApprovalConfig = new AwardPrepApprovalConfig();

    public criteria = new ApprovalLookupCriteria();

    public awardLoadedCallback!: () => void;

    public pageManager = new Data.PageManager(this.criteria, ApprovalLookup, this.queryApiClient.getApprovalLookupAsync, {
        pageSize: 20,
        initialTaskRunner: this.taskRunner,
        sortBy: "participantName",
        beforeFetch: request => request.criteria!.awardPrepId = this.approverAward!.award.awardPrepId,
        afterFetch: data => {
            this.setupCounts(data);
            this.offerTotalsService.recalculatePageTotals(data);
        }
    });

    public approvalHistoryComponentVM: ApprovalHistoryComponentVM;
    public actionButtonsEnabled = true;
    public changesMade = false;

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private queryApiClient = AppService.get(Types.Awards.ApiClients.ApprovalsQueryApiClient),
        private commandApiClient = AppService.get(Types.Awards.ApiClients.ApprovalsCommandApiClient),
        private catalogueApiClient = AppService.get(Types.Awards.ApiClients.CatalogueQueryApiClient),
        private participantOffersQueryApiClient = AppService.get(Types.Awards.ApiClients.ParticipantOffersQueryApiClient),
        public commandsTask = AppService.get(Types.Neo.TaskRunner),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        private pluralizer = AppService.get(Types.Shared.Services.Pluralizer),
        private offerTotalsService = AppService.get(Types.Awards.Services.OfferTotalsService)) {

        super(taskRunner);

        this.approvalHistoryComponentVM = new ApprovalHistoryComponentVM(taskRunner, this.queryApiClient);

        // tell the offer totals service to exclude the offer in the total, if the offer is declined
        this.offerTotalsService.includeInTotalPredicate = (offer) => !(offer as ApprovalLookup).isDeclined;
    }

    public async initialise() {
        super.initialise();

        await Promise.all([
            this.loadSummary(),
            this.loadInstruments(),
        ]);
    }

    public async loadSummary() {
        const result = await this.taskRunner.waitFor(this.queryApiClient.getApproverApprovalSummaryAsync());

        if (result) {
            this.approvalSummary.set(result.data);
            this.changesMade = false;

            if (this.loadAwardPrepId !== 0) {
                this.loadAwardRecord(this.loadAwardPrepId);
                this.loadAwardPrepId = 0;
            }
        }
    }

    private async loadInstruments() {
        const result = await this.taskRunner.waitFor(this.catalogueApiClient.getInstrumentLookup());
        result.data.forEach(instrument => {
            this.instrumentsSet[instrument.instrumentCode] = InstrumentLookup.fromJSObject<InstrumentLookup>(instrument);
        })
    }

    private async loadApprovalConfig(awardPrepId: number) {
        const result = await this.taskRunner.waitFor(this.queryApiClient.getApprovalsConfig(awardPrepId));
        this.approvalConfig.set(result.data);
    }

    public async loadAward(awardPrepId: number) {
        if (!this.approvalSummary.awards.length) {
            if (this.taskRunner.isBusy) {
                this.loadAwardPrepId = awardPrepId;
            }
            else {
                await this.loadSummary();
                this.loadAwardRecord(awardPrepId);
            }
        } else {
            this.loadAwardRecord(awardPrepId);
        }
    }

    private async loadAwardRecord(awardPrepId: number) {
        // try get the award from the list
        const approval = this.approvalSummary.awards.find(a => a.award.awardPrepId === awardPrepId);
        if (approval) {
            // now load the first page for this award
            this.approverAward = approval;
            this.pageManager.reset();

            // if the approval is 100% submitted, disable the buttons and activate the submitted filter so that
            // the grid is not empty
            this.criteria.participantName = "";
            this.criteria.includeSubmitted = this.approverAward.submittedPercentage === 1;
            this.actionButtonsEnabled = this.approverAward.submittedPercentage < 1;

            // refresh the data
            await Promise.all([this.pageManager.refreshData(), this.loadOfferTotals()]);
            this.loadApprovalConfig(approval.award.awardPrepId)
        } else {
            this.approverAward = null;
        }
        this.awardLoadedCallback();
    }
   
    private async loadOfferTotals() {
        this.offerTotalsService.reset();
        const result = await this.participantOffersQueryApiClient.getParticipantOfferTotalsLookup(this.approverAward!.award.awardPrepId);
        this.offerTotalsService.setOfferTotals(result.data);
    }

    private changeApprovalQueue: ApprovalLookup[] = [];

    public async changeApproval(item: ApprovalLookup) {
        item.changeApproval()

        var existing = this.changeApprovalQueue.find(queued => queued === item);
        if (!existing) {
            this.changeApprovalQueue.push(item);
        }

        Utils.debounce(this.changeApprovalQueue, async () => {
            if (item.approvalStatus !== item.savedStatus) {
                const approvals = this.changeApprovalQueue.filter(c => c.approvalStatus === ApprovalStatus.Approved);
                const declines = this.changeApprovalQueue.filter(c => c.approvalStatus === ApprovalStatus.Declined || c.approvalStatus === ApprovalStatus.DeclinedPending);
                const clears = this.changeApprovalQueue.filter(c => c.approvalStatus === ApprovalStatus.Pending);
                this.changeApprovalQueue = [];
                if (approvals.length) {
                    await this.approve(approvals);
                }
                if (declines.length) {
                    await this.decline(declines);
                }
                if (clears.length) {
                    await this.clear(clears);
                }
            }
        }, 750);
    }

    public approveAll(): void {
        var command = new ApproveApprovalsCommand();
        this.actionAll(command, item => item.approve(), this.commandApiClient.approveApprovals);
    }

    public approvePage(): void {
        var command = new ApproveApprovalsCommand();
        this.actionPage(command, ApprovalStatus.Approved, item => item.approve(), this.commandApiClient.approveApprovals);
    }

    public declineAll(command?: ChangeApprovalsCommandBase): void {
        if (!command) {
            command = new DeclineApprovalsCommand()
        }
        const cmd = command as DeclineApprovalsCommand;
        this.actionAll(
            command,
            item => {
                item.decline();
                item.declinedReason = cmd.declinedReason;
            },
            this.commandApiClient.declineApprovals);
    }

    public declinePage(command?: ChangeApprovalsCommandBase): void {
        if (!command) {
            command = new DeclineApprovalsCommand()
        }
        const cmd = command as DeclineApprovalsCommand;
        this.actionPage(
            cmd,
            ApprovalStatus.Declined,
            item => {
                item.decline();
                item.declinedReason = cmd.declinedReason;
            },
            this.commandApiClient.declineApprovals);
    }

    public clearAll(): void {
        var command = new ClearApprovalsCommand();
        this.actionAll(command, item => item.clear(), this.commandApiClient.clearApprovals);
    }

    public clearPage(): void {
        var command = new ClearApprovalsCommand();
        this.actionPage(command, ApprovalStatus.Pending, item => item.clear(), this.commandApiClient.clearApprovals);
    }

    public submitAll(): void {
        var command = new SubmitApprovalsCommand();
        this.actionAll(command, item => item.submit(this.approvalConfig), this.commandApiClient.submitApprovals);
    }

    public submitPage(): void {
        var command = new SubmitApprovalsCommand();
        this.actionPage(
            command,
            ApprovalStatus.Submitted,
            item => item.submit(this.approvalConfig),
            this.commandApiClient.submitApprovals,
            (item) => {
                return [ApprovalStatus.Approved, ApprovalStatus.Declined].indexOf(item.getRealApprovalStatus(this.approvalConfig)) > -1;
            });
    }

    private async approve(items: ApprovalLookup[]) {
        var command = new ApproveApprovalsCommand();
        this.runActionCommand(command, items, this.commandApiClient.approveApprovals);
    }

    public async decline(items: ApprovalLookup[]) {
        // group the items by declined reason
        var declineCommands: { command: DeclineApprovalsCommand, items: ApprovalLookup[] }[] = [];
        for (const item of items) {
            const existingCommand = declineCommands.find(cmd => cmd.command.declinedReason === item.declinedReason);
            if (!existingCommand) {
                var command = new DeclineApprovalsCommand();
                command.declinedReason = item.declinedReason;
                declineCommands.push({ command: command, items: [item] });
            } else {
                existingCommand.items.push(item);
            }
        }
        // run each of them
        for (const declineCommand of declineCommands) {
            await this.runActionCommand(declineCommand.command, declineCommand.items, this.commandApiClient.declineApprovals);
        }
    }

    private async clear(items: ApprovalLookup[]) {
        var command = new ClearApprovalsCommand();
        this.runActionCommand(command, items, this.commandApiClient.clearApprovals);
    }

    private actionPage(
        command: ChangeApprovalsCommandBase,
        status: ApprovalStatus,
        actionMethod: (item: ApprovalLookup) => void,
        clientAction: (command: Model.PartialPlainObject<ChangeApprovalsCommandBase>) => AxiosPromise<number>,
        itemSelector?: (item: ApprovalLookup) => boolean) {

        let items: ApprovalLookup[];
        if (itemSelector) {
            items = this.pageManager.getItems().filter(itemSelector);
        } else {
            items = this.pageManager.getItems().filter(item => item.approvalStatus !== status);
        }

        this.actionItems(command, status, actionMethod, clientAction, items);
    }

    private actionItems(
        command: ChangeApprovalsCommandBase,
        status: ApprovalStatus,
        actionMethod: (item: ApprovalLookup) => void,
        clientAction: (command: Model.PartialPlainObject<ChangeApprovalsCommandBase>) => AxiosPromise<number>,
        items: ApprovalLookup[]) {

        items.forEach(actionMethod);
        if (items.length > 0) {
            this.runActionCommand(command, items, clientAction, actionMethod);
        }
    }

    private actionAll(command: ChangeApprovalsCommandBase, actionMethod: (item: ApprovalLookup) => void, clientAction: (command: Model.PartialPlainObject<ChangeApprovalsCommandBase>) => AxiosPromise<number>) {
        command.allApproverApprovals = true;
        const items = this.pageManager.getItems();
        items.forEach(item => actionMethod(item));
        this.runActionCommand(command, items, clientAction, actionMethod);
    }

    private async runActionCommand(
        command: ChangeApprovalsCommandBase,
        items: ApprovalLookup[],
        clientAction: (command: Model.PartialPlainObject<ChangeApprovalsCommandBase>) => AxiosPromise<number>,
        actionMethod?: (item: ApprovalLookup) => void) {

        if (!actionMethod) {
            actionMethod = (i: ApprovalLookup) => { };
        }

        this.setupChangeCommand(command, items);
        this.offerTotalsService.recalculateOfferTotals(this.pageManager.getItems());

        await clientAction(command.toJSObject());
        this.completeChange(items, command, actionMethod!);
    }

    public completeChange(items: ApprovalLookup[], command: ChangeApprovalsCommandBase, actionMethod: (item: ApprovalLookup) => void) {
        for (const item of items) {
            item.saveCompleted();
        }
        if (command.allApproverApprovals) {
            this.pageManager.getItems().forEach(actionMethod);

            if (this.approverAward) {
                var changedToStatus = items[0].approvalStatus;
                switch (changedToStatus) {
                    case ApprovalStatus.Submitted:
                        this.approverAward.updateCountsAllSubmitted();
                        break;
                    case ApprovalStatus.Approved:
                        this.approverAward.updateCountsAllApproved();
                        break;
                    case ApprovalStatus.Declined:
                        this.approverAward.updateCountsAllDeclined();
                        break;
                    case ApprovalStatus.Pending:
                        this.approverAward.updateCountsAllCleared();
                        break;
                }
            }
            this.setupCounts(this.pageManager.getItems())
        } else {
            this.updateCounts(this.pageManager.getItems());
        }
        this.changesMade = true;
        this.addNotification(command, items);
    }

    private addNotification(command: ChangeApprovalsCommandBase, items: ApprovalLookup[]) {
        const message = (command.allApproverApprovals ?
            (command instanceof SubmitApprovalsCommand && this.approverAward!.submitted !== this.approverAward!.approvals ? 
                `${this.pluralizer.numberPluralize(this.approverAward!.submitted, "Participant")} now ` :   
                `All ${this.pluralizer.numberPluralize(this.approverAward!.approvals, "Participant")}`) :
            `${this.pluralizer.numberPluralize(items.length, "Participant")} `)

        const notification = message +
            ApprovalStatus[items[0].approvalStatus]
                .replace("DeclinedPending", "Declined (* pending reason)")
                .replace("Pending", "Cleared");

        this.notifications.add(notification, null, this.getNotificationVariant(items[0].approvalStatus), NotificationDuration.Short);
    }

    private setupCounts(items: ApprovalLookup[]) {
        if (this.approverAward) {
            var pageCounts = this.getPageCounts(items);
            this.approverAward.setupCounts(pageCounts);
        }
    }

    private updateCounts(items: ApprovalLookup[]) {
        if (this.approverAward) {
            var pageCounts = this.getPageCounts(items);
            this.approverAward.updateCounts(pageCounts);
        }
    }

    private getPageCounts(items: ApprovalLookup[]) {
        var pageCounts = new ApprovalCounts();
        for (const approval of items) {
            if (approval.approvedOn) {
                pageCounts.approved++;
            }
            else if (approval.declinedOn) {
                pageCounts.declined++;
            }

            if (approval.approvalStatus === ApprovalStatus.Submitted) {
                pageCounts.submitted++;
            }
        }
        return pageCounts;
    }

    private getNotificationVariant(approvalStatus: ApprovalStatus): Components.variant {
        switch (approvalStatus) {
            case ApprovalStatus.Approved:
                return "success";
            case ApprovalStatus.Declined:
                return "warning";
            case ApprovalStatus.DeclinedPending:
                return "info";
            case ApprovalStatus.Pending:
                return "primary";
            case ApprovalStatus.Submitted:
                return "success";
        }
    }

    private setupChangeCommand(command: ChangeApprovalsCommandBase, items: ApprovalLookup[]) {
        for (const item of items) {
            command.awardApprovalIds.push(item.awardApprovalId);
            item.saveStarting();
        }
        command.awardPrepId = items[0].awardPrepId;
    }

    public filterChanged() {
        Utils.debounce(this.criteria, async () => {
            this.pageManager.refreshData();
        }, 750);
    }

    private previousApprovalLookup = new ApprovalLookup();

    public allowEditOfferedUnits(item: ApprovalLookup) {
        if (this.canSubmit(item)) {
            item.canEditOfferedUnits = true;
            if (item !== this.previousApprovalLookup) {
                this.previousApprovalLookup.canEditOfferedUnits = false;
                this.previousApprovalLookup = item;
            }
        }
    }

    public canSubmit(item: ApprovalLookup) {
        return this.approvalConfig.approversCanEditOfferedUnits && !item.submittedOn
    }

    public async changeOfferedUnits(item: ApprovalLookup) {
        if (item.offeredUnitsHaveChanged && this.canSubmit(item)) {
            const command = new ChangeApprovalParticipantOfferCommand();
            command.awardPrepId = item.awardPrepId;
            command.awardApprovalId = item.awardApprovalId;
            command.participantOfferId = item.participantOfferId;
            command.newOfferedUnits = item.offeredUnits;
            await this.taskRunner.run(async () => await this.commandApiClient.changeOffer(command.toJSObject()));

            const notification = `Participant offer changed from ${item.originalOfferedUnits} to ${item.offeredUnits}`;
            item.newOfferedUnits = item.offeredUnits;

            this.changesMade = true;
            this.notifications.add(notification, null, "info", NotificationDuration.Short);
        }
    }
}