import { Attributes, LookupBase, NeoModel} from '@singularsystems/neo-core';

@NeoModel
export default class AllocationExpectedValueLookup extends LookupBase {

    @Attributes.Display("Scheme")
    public incentiveSchemeName: string = "";

    @Attributes.Integer()
    public currentExpectedValue: number = 0;

    @Attributes.Integer()
    public plus1ExpectedValue: number = 0;

    @Attributes.Integer()
    public plus2ExpectedValue: number = 0;

    @Attributes.Integer()
    public plus3ExpectedValue: number = 0;

    @Attributes.Integer()
    public plus4ExpectedValue: number = 0;

    @Attributes.Integer()
    public plus5ExpectedValue: number = 0;

    // Client only properties / methods

    @Attributes.Display("Total unvested\n (current)")
    @Attributes.Integer()
    public get totalQuantity() {
        return this.plus1ExpectedValue + this.plus2ExpectedValue + this.plus3ExpectedValue + this.plus4ExpectedValue + this.plus5ExpectedValue;
    }
}