import { Attributes, Model, NeoModel, Validation } from '@singularsystems/neo-core';

@NeoModel
export default class FileDescriptor extends Model.FileDescriptorBase<string> {

    @Attributes.Date()
    public createdOn: Date = new Date();

    // Client only properties / methods
    
    @Attributes.NoTracking()
    public preparingDownload = false;

    @Attributes.NoTracking()
    public icon = "";
    
    @Attributes.NoTracking()
    public extension = "";

    public getIconClassName(): string {

        let iconName = "";
        switch (this.extension.toLowerCase()) {
            case ".docx":
            case ".doc":
                iconName = "file-word";
                break;
            case ".pptx":
            case ".ppt":
                iconName = "file-powerpoint";
                break;
            case ".xlsx":
            case ".xls":
                iconName = "file-excel";
                break;
            case ".pdf":
                iconName = "file-pdf";
                break;
            case ".jpg":
            case ".jpeg":
            case ".png":
                iconName = "file-image";
                break;
            case ".mp3":
                iconName = "file-audio";
                break;
            case ".mp4":
                iconName = "file-video";
                break;
            default:
                iconName = "file-alt";
        }
        return `fa fa-${iconName}`
    }

    public onDeserialiseCompleted() {
        this.extension = this.fileName.substring(this.fileName.lastIndexOf("."));
        this.icon = this.getIconClassName();
    }

    public addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString() {
        return super.toString();
    }
}