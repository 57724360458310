import { ModelBase, NeoModel, Validation } from '@singularsystems/neo-core';

@NeoModel
export default class StartQuestionnaireAnswerCommand extends ModelBase {

    public questionnaireId: number | null = 0;

    public participantOfferId: number = 0;

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew) {
            return "New start questionnaire answer command";
        } else {
            return "Start Questionnaire Answer Command";
        }
    }
}