import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../AwardsTypes';
import ApproveApprovalsCommand from '../../Models/Approvals/Commands/ApproveApprovalsCommand';
import DeclineApprovalsCommand from '../../Models/Approvals/Commands/DeclineApprovalsCommand';
import ClearApprovalsCommand from '../../Models/Approvals/Commands/ClearApprovalsCommand';
import SubmitApprovalsCommand from '../../Models/Approvals/Commands/SubmitApprovalsCommand';
import ChangeApprovalParticipantOfferCommand from '../../Models/Approvals/Commands/ChangeApprovalParticipantOfferCommand';

export interface IApprovalsCommandApiClient {

    /**
     * Will approve approvals
     * @param command The approve approvals command
     * @returns An OK result if successful
     */
    approveApprovals(command: Model.PartialPlainObject<ApproveApprovalsCommand>): AxiosPromise;

    /**
     * Will decline approvals
     * @param command The decline approvals command
     * @returns An OK result if successful
     */
    declineApprovals(command: Model.PartialPlainObject<DeclineApprovalsCommand>): AxiosPromise;

    /**
     * Will clear approvals
     * @param command The clear approvals command
     * @returns An OK result if successful
     */
    clearApprovals(command: Model.PartialPlainObject<ClearApprovalsCommand>): AxiosPromise;

    /**
     * Will submit approvals
     * @param command The submit approvals command
     * @returns An OK result if successful
     */
    submitApprovals(command: Model.PartialPlainObject<SubmitApprovalsCommand>): AxiosPromise;

    /** 
     * Will submit approvals
     * @param command The submit approvals command
     * @returns An OK result if successful
     */
    changeOffer(command: Model.PartialPlainObject<ChangeApprovalParticipantOfferCommand>): AxiosPromise;

    // Client only properties / methods
}

@injectable()
export default class ApprovalsCommandApiClient extends Data.ApiClientBase implements IApprovalsCommandApiClient {

    constructor (config = AppService.get(Types.Shared.Config)) {
        super(`${config.AwardsApi.ApiPath}/ApprovalsCommand`);
    }

    public approveApprovals(command: Model.PartialPlainObject<ApproveApprovalsCommand>): AxiosPromise {
        return this.axios.post(`${this.apiPath}/approve`, command);
    }

    public declineApprovals(command: Model.PartialPlainObject<DeclineApprovalsCommand>): AxiosPromise {
        return this.axios.post(`${this.apiPath}/decline`, command);
    }

    public clearApprovals(command: Model.PartialPlainObject<ClearApprovalsCommand>): AxiosPromise {
        return this.axios.post(`${this.apiPath}/clear`, command);
    }

    public submitApprovals(command: Model.PartialPlainObject<SubmitApprovalsCommand>): AxiosPromise {
        return this.axios.post(`${this.apiPath}/submit`, command);
    }

    public changeOffer(command: Model.PartialPlainObject<ChangeApprovalParticipantOfferCommand>): AxiosPromise {
        return this.axios.post(`${this.apiPath}/changeOffer`, command);
    }

    // Client only properties / methods
}