import { AppServices, Routing } from '@singularsystems/neo-core';

import { Types } from './ParticipantsTypes';
import ParticipantsAppApiClient from './ApiClients/ParticipantsAppApiClient';
import ContactDetailsView from './Views/ContactDetailsView';
import SecondmentsView from './Views/SecondmentsView';
import DocumentsView from './Views/DocumentsView';
import ParticipantsAppCatalogueApiClient from './ApiClients/ParticipantsAppCatalogueApiClient';
import ParticipantsDataCache from './Services/ParticipantsDataCache';

// Modules
const participantsAppModule = new AppServices.Module("Participants", container => {
    const ParticipantsTypes = Types.Participants;

    // Api Clients
    container.bind(ParticipantsTypes.ApiClients.MainApiClient).to(ParticipantsAppApiClient).inSingletonScope();
    container.bind(ParticipantsTypes.ApiClients.CatalogueApiClient).to(ParticipantsAppCatalogueApiClient).inSingletonScope();

    // Services
    container.bind(ParticipantsTypes.Services.DataCache).to(ParticipantsDataCache).inSingletonScope();
});

// Test 
const participantsTestModule = new AppServices.Module("Participants", container => {
    // bind test types
});

// Routing
const ContactDetailsRoute = { path: "/contact-details", component: ContactDetailsView };
const SecondmentsRoute = { path: "/global-locations", component: SecondmentsView };
const DocumentsRoute = { path: "/documents", component: DocumentsView };

const PureRoutes = [] as Routing.IRoute[];

export { 
    participantsAppModule,
    participantsTestModule,
    ContactDetailsRoute,
    SecondmentsRoute,
    DocumentsRoute,
    PureRoutes 
}