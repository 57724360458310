import { AppService, AppTypes } from '../../App/Services/AppService';
import { AppServices } from "@singularsystems/neo-core";
import { IPortfolioApiClient } from './ApiClients/PortfolioApiClient';
import { IFeesApiClient } from './ApiClients/FeesApiClient';
import { ITradeApiClient } from './ApiClients/TradeApiClient';
import TransactionsDataCache from './Services/TransactionsDataCache';
import { TransactionsSharedTypes } from './TransactionsSharedTypes';

const Types = {
    ...AppTypes,
    Transactions: {
        ApiClients: {
            ...TransactionsSharedTypes.ApiClients,
            FeesApiClient: new AppServices.ServiceIdentifier<IFeesApiClient>("Transactions.FeesApiClient"),
            PortfolioApiClient: new AppServices.ServiceIdentifier<IPortfolioApiClient>("Transactions.PortfolioApiClient"),
            TradeApiClient: new AppServices.ServiceIdentifier<ITradeApiClient>("Transactions.TradeApiClient")
        },
        Services: {
            ...TransactionsSharedTypes.Services,
            DataCache: new AppServices.ServiceIdentifier<TransactionsDataCache>("Transactions.DataCache")
        }
    } 
}

export { AppService, Types }