import { Model, ModelBase, Attributes } from "@singularsystems/neo-core";
import InstrumentLookup from "../../../../Common/Models/InstrumentLookup";
import RatesLookup from "../../RatesLookup";
import BrokerAccountLookup from '../../Brokers/Lookups/BrokerAccountLookup';
import IncentiveSchemeParticipantLookup from "../../IncentiveSchemeParticipantLookup";
import PortfolioBalanceLookup from "../PortfolioBalanceLookup";
import { ITradeTypeRules } from "../IncentiveGroup";
import { TradeType } from "../../Trading/TradeType";
import { Awards } from "../../../../../App";

export interface ICalculationBase {
    sellQuantity: number;
    buyQuantity: number;
    effectiveSellPrice: number;

    readonly trancheBalance: PortfolioBalanceLookup;
    readonly brokerAccount: BrokerAccountLookup;
    readonly rates: RatesLookup;

    readonly availableBalance: number;
    readonly remainingAwardDebt: number;
    readonly calcTaxAmount: number;
    readonly proportionalAwardDebt: number;
    readonly tradeProceeds: number;
    readonly netProceedsConverted: number;
    readonly buyNetValueConverted: number;

    setSellQuantity(value: number): void;
    setBuyQuantity(value: number): void;
    calcSellToBuy(): void;
}

export interface ITradeableBalance extends ICalculationBase {

    canTrade: boolean;

    readonly instrumentPrice: number;
    readonly awardPrice: number;

    /** Note: do not use instrumentId from this object, it is from the awards module. */
    readonly instrument: InstrumentLookup;

    readonly transactionsScheme: IncentiveSchemeParticipantLookup | null;

    readonly tradeProceeds: number;
    readonly saleBrokerage: number;
    readonly awardDebt: number;
    readonly tradingCosts: number;

    readonly remainingAwardDebtPositive: number;
    readonly proportionalAwardDebt: number;
    readonly netProceeds: number;
    readonly unitsAfterTrade: number;

    /** True if the current date is after the vesting date or trade open date */
    readonly isVested: boolean;
    /** True if isVested, and all trading rules pass. */
    readonly isTradeable: boolean;
    readonly cannotTradeReason: string;
    readonly tradeTypeRules: ITradeTypeRules;

    readonly hasQuantity: boolean;

    /**
     * Sets the trade type.
     * @param type Trade type
     */
    setTradeType(type: TradeType | null): void;

    setSellQuantity(value: number): void;
    setBuyQuantity(value: number): void;
    calcSellToBuy(): void;

    meta: Model.TransformMetaType<this>;
}

export abstract class CalculationBase extends ModelBase {

    constructor(public readonly trancheBalance: PortfolioBalanceLookup) {
        super();

        this.instrument = trancheBalance.instrument;
        this.trackingInstrument = trancheBalance.trackingInstrument;
        this.trancheId = trancheBalance.trancheId;
    }

    // Can't use abstract properties because babel keeps them around, (instead of removing them at runtime like its supposed to).
    // So we have to assume children of this class will implement ITradeableBalance.
    // Then we can access this variable instead of the abstract properties.
    @Attributes.NoTracking()
    @Attributes.Observable(false)
    private impl = this as unknown as ICalculationBase;

    public trancheId: number = 0;

    public readonly instrument: InstrumentLookup;

    public readonly trackingInstrument: InstrumentLookup | undefined;

    public get hasQuantity() {
        return this.impl.sellQuantity > 0 || this.impl.buyQuantity > 0;
    }

    @Attributes.Float()
    public get currentValue() {
        return this.impl.availableBalance * this.trancheBalance.customInstrumentPrice / (this.trackingInstrument ? this.trancheBalance.awardPrice : 1);
    }

    @Attributes.Float()
    public get currentValueConverted() {
        return this.currencyConversion(this.currentValue);
    }

    @Attributes.Display("Award costs")
    @Attributes.Float()
    public get remainingAwardDebtPositive() {
        return -this.impl.remainingAwardDebt;
    }

    @Attributes.Float()
    public get profitLoss() {
        return this.currentValue + this.impl.remainingAwardDebt;
    }

    @Attributes.Float()
    public get tradeProfitLossGross() {
        return this.impl.tradeProceeds + this.impl.proportionalAwardDebt;
    }

    /**
     * Profit or loss converted to the participants selected currency.
     */
    @Attributes.Float()
    public get profitLossConverted() {
        return this.currencyConversion(this.profitLoss);
    }

    @Attributes.Float()
    public get profitLossConvertedLimited() {
        return this.limitLossToZero(c => c.profitLossConverted);
    }

    @Attributes.Float()
    public get remainingAwardDebtConverted() {
        return this.currencyConversion(this.impl.remainingAwardDebt);
    }

    public get unitsAfterTrade() {
        return this.impl.availableBalance - this.impl.sellQuantity - this.impl.buyQuantity;
    }

    public get saleBrokerage() {
        return this.getSaleBrokerage();
    }

    public get buyBrokerage() {
        return this.getBuyBrokerage();
    }

    public get tradingCosts() {
        return this.saleBrokerage + this.buyBrokerage + this.impl.calcTaxAmount;
    }

    public get netProceeds() {
        return this.tradeProfitLossGross + this.tradingCosts;
    }

    public get netProceedsConverted() {
        return this.currencyConversion(this.netProceeds);
    }

    public get buyNetValueConverted() {
        return this.currencyConversion(this.impl.buyQuantity * this.trancheBalance.instrumentPrice);
    }

    public get tradeTypeRules(): ITradeTypeRules {
        return {
            allowBuy: this.trancheBalance.incentiveScheme.allowBuy,
            allowSell: this.trancheBalance.incentiveScheme.allowSell,
            allowSellToCover: this.trancheBalance.incentiveScheme.allowSellToCover
        }
    }

    /** The last trade type which was selected by the user. */
    @Attributes.NoTracking()
    public lastTradeType: TradeType | null = null;

    public setTradeType(type: TradeType | null) {
        this.lastTradeType = type;

        if (type === TradeType.Sell) {
            if (this.trancheBalance.incentiveScheme.allowSell) {
                this.impl.setSellQuantity(this.impl.availableBalance);
            }
        } else if (type === TradeType.Buy) {
            if (this.trancheBalance.incentiveScheme.allowBuy) {
                this.impl.setBuyQuantity(this.impl.availableBalance);
            }
        } else if (type === TradeType.SellToCover) {
            if (this.trancheBalance.incentiveScheme.allowSellToCover) {
                this.impl.calcSellToBuy();
            }
        } else {
            this.lastTradeType = null;
            this.impl.sellQuantity = this.impl.buyQuantity = 0;
        }
    }

    public get tradeTypeText() {
        if (this.lastTradeType === TradeType.Sell) {
            return "Sell";
        } else if (this.lastTradeType === TradeType.Buy) {
            return "Transfer";
        } else {
            return "Trade";
        }
    }

    protected getSaleBrokerage() {
        if (this.impl.sellQuantity === 0 || this.trancheBalance.incentiveScheme.isCashSettled) {
            return 0;
        } else {
            return -this.impl.brokerAccount.getBrokingFeeTotal(this.impl.tradeProceeds, true);
        }
    }

    protected getBuyBrokerage() {
        if (this.impl.buyQuantity === 0) {
            return 0;
        } else {
            return -this.impl.brokerAccount.getBrokingFeeTotal(this.impl.buyQuantity * this.trancheBalance.instrumentPrice, false);
        }
    }

    protected currencyConversion(amount: number) {
        return this.instrument.rate === null ? amount : (amount * this.instrument.rate);
    }

    protected abstract getLinkedRecords(): ICalculationBase[];

    /**
     * Limits the value to zero, unless the scheme is set to show losses.
     * For linked awards, this will only limit if the total for the linked awards is less than zero.
     */
    public limitLossToZero<T extends this>(property: (item: T) => number, forTotal: boolean = false) {

        const value = property(this.impl as unknown as T),
            linkedRecords = this.getLinkedRecords() as unknown as T[];

        const isLoss = ((this.profitLoss * (this.trancheBalance.awardLinkExchangeRate ?? 1)) + linkedRecords.sum(c => c.profitLoss * (c.trancheBalance.awardLinkExchangeRate ?? 1))) < 0;

        const lossDisplayType = this.trancheBalance.incentiveScheme.lossDisplayType,
            shouldLimit = forTotal ? lossDisplayType !== Awards.LossDisplayType.ShowLoss : lossDisplayType === Awards.LossDisplayType.HideLoss;

        if (isLoss && shouldLimit) {
            return 0;
        } else {
            return value;
        }
    }
}