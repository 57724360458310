import React from 'react';
import { Neo, Views } from '@singularsystems/neo-react';
import ContactUsVM from './ContactUsVM';
import { observer } from 'mobx-react';
import { HtmlView } from '@singularsystems/neo-notifications';
import { TicketProviderType } from '../../Common/Models/TicketProviders/TicketProviderType.enum';
import { ModalUtils } from '@singularsystems/neo-core';

@observer
export default class ContactUsView extends Views.ViewBase<ContactUsVM> {

    constructor(props: unknown) {
        super("Contact Us", ContactUsVM, props);
    }

    private redirectToTicketingPlatform() {
        this.taskRunner.run(async () => {
            const result = await this.viewModel.clientsApiClient.getTicketProviderUrl(true);

            if (result.data.redirectMessage)
            {
                ModalUtils.showMessage("4Me Service Management Platform", <p>{result.data.redirectMessage}</p>)
            }

            setTimeout(function () {
                window.open(result.data.redirectUrl.toString(), "_blank");
            }, result.data.redirectMessage ? 5000 : 0);
        });
    }

    public render() {
        return (
            <div className="more-padding">
			    <h2>Contact Us</h2>

                <div className="my-4">
                    <HtmlView html={this.viewModel.contactDetailsHtml} />
                </div>

                {this.viewModel.tenant.ticketProviderType === TicketProviderType.Email &&
                    <div className="page-block page-block-alt reset-margin">
                        <Neo.FormGroup
                            label="Please provide a short description of your enquiry e.g. vested shares, tax certificate"
                            bind={this.viewModel.meta.queryBody}
                            input={{rows: 5}}
                            editorClassName="highlight-border"
                            footerText="Your name and contact details will be included in the enquiry" />

                            {!this.viewModel.hasSent &&
                                <div className="text-right">
                                    <Neo.Button className="btn-150" onClick={() => this.viewModel.sendQueryMessage()}>Send</Neo.Button>
                                </div>}
                    </div>
                }
                {this.viewModel.tenant.ticketProviderType !== TicketProviderType.Email &&
                    <div className="page-block page-block-alt reset-margin">
                        <Neo.Button
                            onClick={() => this.redirectToTicketingPlatform()}
                            icon="ticket-alt"
                            text={this.viewModel.tenant.ticketProviderType === TicketProviderType.FourMe ? "Go to 4Me" : "Go to External Ticket Platform"}>
                        </Neo.Button>
                    </div>
                }
            </div>
        );
    }
}