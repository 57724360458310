import { AppServices } from '@singularsystems/neo-core';
import AppTypes from '../../App/Types/AppTypes';
import { AppService, Types as CommonTypes } from '../Common/ClientsTypes';
import { IParticipantsMessageApiClient } from './ApiClients/ParticipantsMessageApiClient';

const CommonClientTypes = CommonTypes.Clients;

const Types = {
    ...AppTypes,
    Clients: {
        ApiClients: {
            ...CommonClientTypes.ApiClients,
            ParticipantsMessageApiClient: new AppServices.ServiceIdentifier<IParticipantsMessageApiClient>("Clients.ParticipantsMessageApiClient"),
        },
        Services: {
            ...CommonClientTypes.Services
        }
    }
}

export { AppService, Types }