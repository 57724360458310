import { NeoModel } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import { AppService, Types } from '../AllocationManagerTypes';
import AllocationParticipantHistoryLookup from '../Models/Queries/AllocationParticipantHistoryLookup';
import HistoryChartVM from './Components/HistoryChartVM';

@NeoModel
export default class AllocationHistoryComponentVM extends Views.ViewModelBase {

    constructor(
        public participantHistory: AllocationParticipantHistoryLookup,
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications)) {

        super(taskRunner);
    }

    public async initialise() {

    }

    public historyChartVM: HistoryChartVM | null = null;

    public getPriorHeader(yearsToSubtract: number) {
        return "FY " + (this.participantHistory?.financialYear! - yearsToSubtract) + "\n Awards"
    }

    public getEVHeader(yearsToAdd: number) {
        return "FY " + (this.participantHistory?.financialYear! + yearsToAdd) + "\n Vestings"
    }


    public async showParticipantHistoryGraph() {
        this.historyChartVM = new HistoryChartVM(this.participantHistory);
    }
}