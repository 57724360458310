import { Attributes, NeoModel } from '@singularsystems/neo-core';

import ParticipantAwardLookupBase from '../../Base/ParticipantAwardLookupBase';

@NeoModel
export default class ParticipantAwardReleaseLookup extends ParticipantAwardLookupBase {

    public awardApprovalId: number = 0;

    public participantCode: string = "";

    public participantName: string = "";

    public correspondenceEmail: string = "";

    @Attributes.Float()
    public originalOfferedUnits: number = 0;

    @Attributes.Nullable()
    @Attributes.Float()
    public newOfferedUnits: number | null = null;

    @Attributes.Date()
    public approvedOn: Date | null = null;

    @Attributes.Date()
    public declinedOn: Date | null = null;

    public declinedReason: string = "";

    @Attributes.Date()
    public submittedOn: Date | null = null;

    // Client only properties / methods

}