import { Attributes, LookupBase } from '@singularsystems/neo-core';

export default class SettlementExchangeRateLookup extends LookupBase {

    constructor() {
        super();
        this.makeBindable();
    }

    public fromCurrencyCode: string = "";

    public toCurrencyCode: string = "";

    @Attributes.Float()
    public settlementExchangeRate: number = 0;

    // Client only properties / methods
}