import { DateUtils } from "@singularsystems/neo-core";
import { injectable } from "inversify";
import { observable } from "mobx";
import ExchangeRateLookup from "../../Common/Models/ExchangeRateLookup";
import ExchangeRateLookupHistoryCriteria from "../../Common/Models/ExchangeRateLookupHistoryCriteria";
import { ExchangeRateLookupHistoryCriteriaDetail } from "../../Common/Models/ExchangeRateLookupHistoryCriteriaDetail";
import { AppService, Types } from '../TransactionsTypes';

export interface IExchangeRateService {
    loadExchangeRates: (rates: Array<{ fromCode: string, toCode: string, rateDate: Date }>) => Promise<ExchangeRateLookup[]>;
    getExchangeRate: (fromCode: string, toCode: string, rateDate: Date) => ExchangeRateLookup | undefined;
}

@injectable()
export default class ExchangeRateService implements IExchangeRateService {

    constructor(private pricingApiClient = AppService.get(Types.Transactions.ApiClients.PricingApiClient)) {

    }

    @observable
    private exchangeRates = new Map<string, ExchangeRateLookup>();

    public async loadExchangeRates(rateRequests: Array<{ fromCode: string, toCode: string, rateDate: Date }>): Promise<ExchangeRateLookup[]> {

        // work out all the combinations we need to fetch
        const requestedRates = new Array<{ fromCode: string, toCode: string, rateDate: Date, rateKey: string }>();
        for (const rateRequest of rateRequests) {
            if (rateRequest.fromCode !== rateRequest.toCode) {
                const existingRequestedRate = requestedRates.find(c => this.createRateKey(c.fromCode, c.toCode, c.rateDate) === this.createRateKey(rateRequest.fromCode, rateRequest.toCode, rateRequest.rateDate));
                if (!existingRequestedRate) {
                    requestedRates.push({ fromCode: rateRequest.fromCode, toCode: rateRequest.toCode, rateDate: rateRequest.rateDate, rateKey: this.createRateKey(rateRequest.fromCode, rateRequest.toCode, rateRequest.rateDate) });
                }
            }
        }

        // build the list of what we need to fetch
        const ratesToFetch = new Array<{ fromCode: string, toCode: string, rateDate: Date, rateKey: string }>();
        for (let i = requestedRates.length - 1; i >= 0; i--) {
            if (!this.exchangeRates.get(requestedRates[i].rateKey)) {
                // dont have this one so add the requirement
                ratesToFetch.push(requestedRates[i]);
            }
        }

        if (ratesToFetch.length > 0) {
            // need to fetch some
            const criteria = new ExchangeRateLookupHistoryCriteria();

            for (const rate of ratesToFetch) {
                const detail = new ExchangeRateLookupHistoryCriteriaDetail();
                detail.fromCode = rate.fromCode;
                detail.toCode = rate.toCode;
                detail.rateDate = rate.rateDate;
                criteria.rates.push(detail);
            }

            const result = await this.pricingApiClient.getHistoricalExchangeRates(criteria.toJSObject());

            for (const fetchedRate of result.data) {
                const exchangeRateLookup = new ExchangeRateLookup();
                exchangeRateLookup.set(fetchedRate);

                this.exchangeRates.set(this.createRateKey(exchangeRateLookup.fromCurrencyCode, exchangeRateLookup.toCurrencyCode, exchangeRateLookup.rateDate),
                    exchangeRateLookup);
            }
        }

        // now return the requested rates
        var rates = requestedRates.map(rateToFetch => this.exchangeRates[rateToFetch.rateKey])
        return rates;
    }

    public getExchangeRate(fromCode: string, toCode: string, rateDate: Date) {
        if (fromCode === toCode) {
            const sameCodeExchangeRate = new ExchangeRateLookup();
            sameCodeExchangeRate.fromCurrencyCode = fromCode;
            sameCodeExchangeRate.toCurrencyCode = toCode;
            sameCodeExchangeRate.rate = 1;
            sameCodeExchangeRate.rateDate = rateDate;
            return sameCodeExchangeRate;
        } else {
            return this.exchangeRates.get(this.createRateKey(fromCode, toCode, rateDate));
        }
    }

    private createRateKey(fromCode: string, toCode: string, rateDate: Date) {
        return `${fromCode}_${toCode}_${DateUtils.format(rateDate, "yyyyMMdd")}`;
    }
}