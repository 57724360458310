import React from 'react';
import { Neo, NeoGrid, Views } from '@singularsystems/neo-react';
import DocumentsVM from './DocumentsVM';
import { observer } from 'mobx-react';
import { HtmlView } from '@singularsystems/neo-notifications';
import AppTypes from '../../../App/Types/AppTypes';

class DocumentsViewParams {
    documentNo = {}
}

@observer
export default class DocumentsView extends Views.ViewBase<DocumentsVM, DocumentsViewParams> {
    static params = new DocumentsViewParams();

    constructor(props: unknown) {
        super("My Documents", DocumentsVM, props);
    }

    public get headerTitle() {
        return "My Profile";
    }

    protected viewParamsUpdated() {
        this.viewModel.findDocument(this.viewParams.documentNo);
    }

    public render() {
        const criteria = this.viewModel.criteria,
            filteredDocuments = this.viewModel.filteredDocuments;

        const termsAndConditionsService = this.viewModel.termsAndConditionsService;
        const displayTermsAndConditionsLink = !!termsAndConditionsService?.status?.companyHasTermsAndConditions && termsAndConditionsService.displayOptions.location === AppTypes.Identity.Types.TsAndCsDisplayLocation.MyDocumentsPageText;

        return (
            <div className="more-padding documents-view">
                <h2>My Documents</h2>

                {!this.viewModel.selectedDocument &&
                    <div>
                        <p className="my-4">
                            Please find the offer documents we have on file for you this includes: Award letters, scheme booklets and scheme rules.
                            We may also have personal documents which you provided during a specific trade. {displayTermsAndConditionsLink &&
                                <span>
                                    Or view the <a href="/" onClick={e => { termsAndConditionsService.navigateToTermsAndConditions(); e.preventDefault(); }} >{termsAndConditionsService.displayOptions.name}</a>.
                                </span>}
                        </p>

                        <div className="page-block page-block-alt my-4">
                            <Neo.GridLayout md={2} lg={4}>
                                <Neo.FormGroup bind={criteria.meta.documentType} select={{ items: this.viewModel.documentTypes, valueMember: "id" }} />
                                <Neo.FormGroup bind={criteria.meta.startDate} />
                                <Neo.FormGroup bind={criteria.meta.endDate} />
                                <Neo.FormGroup bind={criteria.meta.searchText} />
                            </Neo.GridLayout>
                        </div>

                        {filteredDocuments.length > 0 &&
                            <NeoGrid.Grid items={filteredDocuments}>
                                {(item, meta) => (
                                    <NeoGrid.Row>
                                        <NeoGrid.Column display={meta.documentName} />
                                        <NeoGrid.Column display={meta.documentType} />
                                        <NeoGrid.Column display={meta.documentDate} />
                                        <NeoGrid.ButtonColumn>
                                            <Neo.Button
                                                size="sm"
                                                icon={item.isLoading ? "circle-notch fa-spin" : ""}
                                                disabled={item.isLoading}
                                                className="btn-150"
                                                onClick={() => this.viewParams.documentNo.value = item.findId}>View</Neo.Button>
                                        </NeoGrid.ButtonColumn>
                                    </NeoGrid.Row>
                                )}
                            </NeoGrid.Grid>}
                        {filteredDocuments.length === 0 &&
                            <Neo.Alert variant="info">No documents found.</Neo.Alert>}
                    </div>}

                {this.viewModel.selectedDocument &&
                    <div>
                        <div className="page-block-alt my-4 mx-n3 p-3">
                            <Neo.GridLayout md={2}>
                                <h2>{this.viewModel.selectedDocument.documentName}</h2>

                                {this.viewModel.selectedDocument &&
                                    <div className="right-tools">
                                        <Neo.Button icon="download" isOutline data-tip={`Download a pdf version of this ${this.viewModel.selectedDocument.documentName}`}
                                            onClick={this.viewModel.downloadHtmlDocument.bind(this.viewModel)} />
                                    </div>}
                            </Neo.GridLayout>
                        </div>

                        <HtmlView html={this.viewModel.selectedDocument.html!} />

                        <Neo.Button icon="chevron-left" className="mt-3" variant="light" onClick={() => this.viewParams.documentNo.value = null}>Back</Neo.Button>

                    </div>}
            </div>
        );
    }
}