import { Attributes, LookupBase, NeoModel } from '@singularsystems/neo-core';

@NeoModel
export default class RatesLookup extends LookupBase {

    @Attributes.Float()
    public vatRate: number = 0;

    @Attributes.Float()
    public sttRate: number = 0;

    // Client only properties / methods

    public get vatRatePlus1() {
        return this.vatRate + 1;
    }
}