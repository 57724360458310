import { injectable } from "inversify";

import { AppService, Types } from '../CommonIdentityTypes';
import { ModelBase, NeoModel, Components } from '@singularsystems/neo-core';
import CurrentTermsAndConditionsLookup from "../Models/TsAndCs/Queries/CurrentTermsAndConditionsLookup";
import { NotificationDuration } from "../../../App/Models/Enums/NotificationDuration";
import TsAndCsDisplayOptions from "../Models/TsAndCs/Display/TsAndCsDisplayOptions";
import TermsAndConditionsStatusLookup from "../Models/TsAndCs/Queries/TermsAndConditionsStatusLookup";
import TermsAndConditionsStatusLookupCriteria from "../Models/TsAndCs/Queries/TermsAndConditionsStatusLookupCriteria";
import TermsAndConditionsView from "../Views/TermsAndConditionsView";

@injectable()
@NeoModel
export default class TermsAndConditionsService extends ModelBase {

    public constructor(
        public taskRunner = AppService.get(Types.Neo.TaskRunner),
        private navigation = AppService.get(Types.Neo.Routing.NavigationHelper),
        private acceptTermsAndConditionsApiClient = AppService.get(Types.Identity.ApiClients.AcceptTermsAndConditionsApiClient),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications)) {

        super();
    }

    private initialized = false;
    private initializing = false;
    private ensureLoaded = false;
    private isLoaded = false;
    public acceptingTermsAndConditions = false;
    public status: TermsAndConditionsStatusLookup | null = null;
    public termsAndConditions = new CurrentTermsAndConditionsLookup();
    public displayOptions = new TsAndCsDisplayOptions();

    public async initialize() {
        if (!this.initialized && !this.initializing) {
            this.initializing = true;

            const criteria = new TermsAndConditionsStatusLookupCriteria();
            criteria.alwaysFetchTermsAndConditions = this.ensureLoaded;
            const result = await this.taskRunner.waitFor(this.acceptTermsAndConditionsApiClient.getStatus(criteria.toQueryObject()), { errorHandling: Components.ErrorDisplayType.ThrowError });

            this.status = TermsAndConditionsStatusLookup.fromJSObject<TermsAndConditionsStatusLookup>(result.data);
            this.termsAndConditions = new CurrentTermsAndConditionsLookup();
            this.displayOptions = this.status.displayOptions;
            if (this.status.currentTermsAndConditions) {
                this.termsAndConditions = this.status.currentTermsAndConditions;
                this.isLoaded = true;
            }

            this.initialized = true;
        }
    }

    public ensureTermsAndConditionsLoaded() {
        this.ensureLoaded = true;
        
        if (!this.isLoaded) {
            this.initializing = false;
            this.initialized = false;
        }

        if (!this.initialized || !this.isLoaded) {
            this.initialize();
        }
    }

    public async acceptTermsAndConditions() {
        await this.taskRunner.run(async () => {
            await this.acceptTermsAndConditionsApiClient.accept(this.termsAndConditions!.termsAndConditionsId);
            this.status!.userHasAccepted = true;

            this.notifications.addSuccess("Terms & Conditions Accepted", null, NotificationDuration.Standard);
        });
    }

    public navigateToTermsAndConditions() {
        this.navigation.navigateToView(TermsAndConditionsView);
    }

    public get userHasAccepted() {
        if (this.status === null) {
            return true;
        } 
        else {
            return this.status && this.status.userHasAccepted;
        }
    }
}