import { ModelBase, NeoModel, Rules, Validation } from '@singularsystems/neo-core';

@NeoModel
export default class EndProxySessionCommand extends ModelBase {

    @Rules.Required()
    public applicationUserId: string = "";

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew || !this.applicationUserId) {
            return "New end proxy session command";
        } else {
            return this.applicationUserId;
        }
    }
}