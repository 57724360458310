import { AppServices } from '@singularsystems/neo-core';
import { Config } from '../Models/Config/Config';
import { AppDataService } from '../Services/AppDataService';
import { RouteService } from '../Services/RouteService';
import { IAppLayout } from '../Services/AppLayout';
import { ISTAuthenticationService } from '../Common/Services/Security/AuthenticationServiceBase';
import { CommonTypes } from '../Common/CommonTypes';

const SharedTypes = {
    Services: {
        AppDataCache: new AppServices.ServiceIdentifier<AppDataService>("Services.AppDataCache"),
        RouteService: new AppServices.ServiceIdentifier<RouteService>("Shared.Services.RouteService"),
        AppLayout: new AppServices.ServiceIdentifier<IAppLayout>("Shared.Services.AppLayout"),
        /** ST Typed pointer to neo auth service */
        STAuthenticationService: AppServices.NeoTypes.Security.AuthenticationService.asType<ISTAuthenticationService>(),
        ...CommonTypes.Services
    },
    Config: AppServices.NeoTypes.Config.ConfigModel.asType<Config>(),
};

export default SharedTypes;