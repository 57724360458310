import { ModelBase, NeoModel, Rules, Validation, Attributes } from '@singularsystems/neo-core';

@NeoModel
export default class DropDownItem extends ModelBase {

    public dropDownItemId: number = 0;

    public questionnaireQuestionId: number = 0;

    @Rules.StringLength(250)
    @Rules.Required()
    @Attributes.OnChanged<DropDownItem>(c => c.onItemDisplayChanged)
    public itemDisplay: string = "";

    @Rules.StringLength(250)
    @Rules.Required()
    public itemValue: string = "";

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew || !this.itemDisplay) {
            return "New drop down item";
        } else {
            return this.itemDisplay;
        }
    }

    @Attributes.NoTracking()
    private previousItemDisplay: string = "";

    public onDeserialiseCompleted() {
        this.previousItemDisplay = this.itemDisplay;
    }

    public async onItemDisplayChanged() {
        this.itemValue = this.itemValue === "" || this.previousItemDisplay === this.itemValue ?
            this.itemDisplay : this.itemValue;
        this.previousItemDisplay = this.itemDisplay;
    }
}