import { ModelBase, NeoModel } from '@singularsystems/neo-core';

import ParticipantAwardReleaseLookup from './ParticipantAwardReleaseLookup';

@NeoModel
export default class ParticipantAwardReleaseInfo extends ModelBase {

    constructor(public awards: ParticipantAwardReleaseLookup[]) {
        super();
    }

}