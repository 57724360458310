import { NeoModel } from '@singularsystems/neo-core';
import { NotificationServiceTypes } from '@singularsystems/neo-notifications';
import { Views } from '@singularsystems/neo-react';
import { NotificationDuration } from '../../../App/Models/Enums/NotificationDuration';
import { AppService, Types } from '../ClientsTypes';
import { TemplateTypes } from '../Models/TemplateTypes';
import TenantLookup from '../../Common/Models/TenantLookup';
import TicketProviderUrlLookup from '../../Common/Models/TicketProviders/TicketProviderUrlLookup';

@NeoModel
export default class ContactUsVM extends Views.ViewModelBase {

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        private templatesApiClient = AppService.get(NotificationServiceTypes.ApiClients.TemplateProcessing),
        private messageApiClient = AppService.get(Types.Clients.ApiClients.ParticipantsMessageApiClient),
        public clientsApiClient = AppService.get(Types.Clients.ApiClients.ClientsApiClient)) {

        super(taskRunner);
    }

    public queryBody: string = "";
    public contactDetailsHtml: string = "";
    public hasSent: boolean = false;
    public tenant: TenantLookup = new TenantLookup();
    public ticketProviderUrl = new TicketProviderUrlLookup();
    public showModal: boolean = false;

    public async initialise() {

        const [templateResult, tenantResult] = await this.taskRunner.waitFor(Promise.all([
            this.templatesApiClient.merge(TemplateTypes.ContactDetails, "", "", {}),
            this.taskRunner.waitFor(this.clientsApiClient.getTenantLookup())
        ]))

        this.tenant.set(tenantResult.data);
        this.contactDetailsHtml = templateResult.mergedText.body;
    }

    public sendQueryMessage() {
        if (this.queryBody) {
            this.taskRunner.run(async () => {
                await this.messageApiClient.sendQueryMessage(this.queryBody);

                this.hasSent = true;
                this.notifications.addSuccess("Enquiry sent", "You enquiry has been sent to an administrator.", NotificationDuration.Standard);
            });
        }
    }
}