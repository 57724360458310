import { LookupBase, NeoModel } from '@singularsystems/neo-core';
import { PreventionType } from '../../../Common/Models/PreventionType';

@NeoModel
export default class DocumentRequirementCheckResult extends LookupBase {

    public documentRequirementName: string = "";

    public tradingAction: PreventionType | null = null;

    public missingDocumentTypes: string[] = [];

    // Client only properties / methods
}