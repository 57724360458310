import { Attributes, LookupBase, NeoModel } from '@singularsystems/neo-core';

@NeoModel
export default class InstrumentPriceLookup extends LookupBase {

    public instrumentCode: string = "";

    @Attributes.Date()
    public priceDate: Date = new Date();

    @Attributes.Float()
    public price: number = 0;

    // Client only properties / methods
}