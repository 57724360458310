import { List, LookupBase } from '@singularsystems/neo-core';
import InterestAccrualLookup from '../../../Common/Models/InterestAccrualLookup';
import AwardCostCashTransactionLookup from '../Cash/Lookups/AwardCostCashTransactionLookup';

export default class AwardCostsLookup extends LookupBase {

    constructor() {
        super();
        this.makeBindable();
    }

    public readonly cashTransactions = new List(AwardCostCashTransactionLookup);

    public readonly interestAccruals = new List(InterestAccrualLookup);

    // Client only properties / methods
}