import { Attributes, List, LookupBase, NeoModel } from '@singularsystems/neo-core';
import { ITradeableBalance } from '../../Portfolio/Calculation/CalculationBase';
import { BrokingFeeChargeEvent } from '../../../../Common/Models/Brokers/BrokingFeeChargeEvent';

@NeoModel
export class BrokingFeeLookup extends LookupBase {

    public brokingFeeId: number = 0;

    public category: string = "";

    @Attributes.Float()
    public minimumFee: number = 0;

    @Attributes.Float()
    public feePercent: number = 0;

    public chargeVat: boolean = false;

    public chargedOn: BrokingFeeChargeEvent | null = null;

    public brackets = new List(BrokingFeeBracketLookup);

    // Client only properties / methods

    public isCharged(sellQuantity: number, buyQuantity: number) {
        return this.chargedOn === BrokingFeeChargeEvent.SaleAndTransfer ||
               (this.chargedOn === BrokingFeeChargeEvent.Sale && sellQuantity > 0) ||
               (this.chargedOn === BrokingFeeChargeEvent.Transfer && buyQuantity > 0)
    }

    public calcTradeFee(trade: ITradeableBalance) {
        let totalFee = 0;
        if (trade.sellQuantity > 0 && (this.chargedOn === BrokingFeeChargeEvent.Sale || this.chargedOn === BrokingFeeChargeEvent.SaleAndTransfer)) {
            totalFee += this.calcFee(trade.tradeProceeds);
        }
        if (trade.buyQuantity > 0 && (this.chargedOn === BrokingFeeChargeEvent.Transfer || this.chargedOn === BrokingFeeChargeEvent.SaleAndTransfer)) {
            totalFee += this.calcFee(trade.buyQuantity * trade.instrumentPrice);
        }
        return totalFee;
    }

    public calcFee(tradeValue: number) {
        let lastBracket: BrokingFeeBracketLookup | undefined = undefined;
        for (let bracket of this.brackets) {
            if (tradeValue < bracket.fromAmount) {
                break;
            }
            lastBracket = bracket;
        }
        if (lastBracket) {
            return ((tradeValue - lastBracket.fromAmount) * lastBracket.feePercent) + lastBracket.startFee;
        } else {
            return Math.max(this.minimumFee, tradeValue * this.feePercent);
        }
    }
}

@NeoModel
export class BrokingFeeBracketLookup extends LookupBase {

    @Attributes.Float()
    public fromAmount: number = 0;

    @Attributes.Float()
    public feePercent: number = 0;

    @Attributes.Float()
    public startFee: number = 0;

    // Client only properties / methods
}