import { ModelBase, NeoModel, Validation, Rules, Attributes, Misc } from "@singularsystems/neo-core";

@NeoModel
export default class ChangePasswordCommand extends ModelBase {

    @Rules.Required()
    public oldPassword: string = "";

    @Rules.Required()
    public newPassword: string = "";

    @Rules.Required()
    @Attributes.Serialisation(Misc.SerialiseType.Never)
    public confirmPassword: string = "";

    addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);

        rules.failWhen(c => !!c.newPassword && c.newPassword === c.oldPassword, "New password must not be the same as old password.").onProperties(c => [c.newPassword]);
        rules.failWhen(c => !!c.confirmPassword && c.newPassword !== c.confirmPassword, "Passwords do not match.").onProperties(c => [c.confirmPassword]);
    }
}