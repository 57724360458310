import React from 'react';
import { Views } from '@singularsystems/neo-react';
import TradeRequestVM from './TradeRequestVM';
import { observer } from 'mobx-react';
import { TradeDetailComponent } from './Components/TradeRequests/TradeDetailComponent';
import { IParticipantsAppView } from '../../../App/Components/Application/IParticipantsAppView';

class TradeRequestParams {
    public tradeRequestId = { required: true }
}

@observer
export default class TradeRequestView extends Views.ViewBase<TradeRequestVM, TradeRequestParams> implements IParticipantsAppView {
   public static params = new TradeRequestParams();

    constructor(props: unknown) {
        super("View Trade Request", TradeRequestVM, props);
    }

    protected viewParamsUpdated() {
        this.viewModel.loadTradeRequest(this.viewParams.tradeRequestId.value as number);
    }

    public showBreadcrumb() { 
        return true;
    }

    public get headerTitle() {
        return "My Portfolio";
    }

    public render() {
        return (
            <div>		
                {this.viewModel.tradeRequest && 
                <TradeDetailComponent tradeRequestLookup={this.viewModel.tradeRequest} taskRunner={this.taskRunner} />}
            </div>
        );
    }
}