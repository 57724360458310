import { NeoModel, Validation } from '@singularsystems/neo-core';

import ParticipantAwardLookupCriteriaBase from '../../Base/ParticipantAwardLookupCriteriaBase';

@NeoModel
export default class ParticipantAwardReleaseLookupCriteria extends ParticipantAwardLookupCriteriaBase {

    public filterString: string = "";

    public requiresApprovalOnly: boolean = true;

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        return "Participant Offer Lookup Criteria";
    }
}