import { Attributes, ModelBase, NeoModel } from '@singularsystems/neo-core';
import CurrentTermsAndConditionsLookup from './CurrentTermsAndConditionsLookup';
import TsAndCsDisplayOptions from '../Display/TsAndCsDisplayOptions';

@NeoModel
export default class TermsAndConditionsStatusLookup extends ModelBase {

    public companyHasTermsAndConditions: boolean = true;

    public userHasAccepted: boolean = false;

    @Attributes.ChildObject(CurrentTermsAndConditionsLookup)
    public currentTermsAndConditions: CurrentTermsAndConditionsLookup | null = null;

    @Attributes.ChildObject(TsAndCsDisplayOptions)
    public displayOptions = new TsAndCsDisplayOptions();

    // Client only properties / methods
}