import { Attributes, LookupBase, Misc, NeoModel, NumberUtils, Utils } from '@singularsystems/neo-core';

@NeoModel
export default class CashTransactionLookup extends LookupBase {

    public participantId: string = Utils.emptyGuid();

    public trancheId: number = 0;

    public cashTransactionId: number = 0;
    
    public cashTransactionTypeId: number = 0;

    public cashSubTransactionTypeId: number | null = null;

    @Attributes.Display("Date")
    @Attributes.Date()
    public effectiveDate: Date = new Date();

    @Attributes.Date()
    public postedDate: Date = new Date();

    @Attributes.Float()
    public amount: number = 0;

    public currencyId: number = 0;

    public detail: string = "";

    public transactionType: string = "";

    public group: string = "";

    public currencySymbol: string = "";

    public currencyCode: string = "";

    public isTrade: boolean = false;

    public isCurrencyConversion: boolean = false;

    @Attributes.Nullable()
    @Attributes.Float()
    public matchQuantity: number | null = null;

    @Attributes.Nullable()
    @Attributes.Float()
    public matchPrice: number | null = null;

    public instrumentCode: string | null = null;

    @Attributes.Nullable()
    public tradeRequestId: number | null = null;

    // Client only properties / methods

    @Attributes.Float()
    public amountCurrency1: number | null = null;
    
    @Attributes.Float()
    public amountCurrency2: number | null = null;

    public get transactionDetail() {
        if (this.detail) {
            return this.detail;
        } else if (this.isTrade && this.instrumentCode && this.matchPrice) {
            let matchPriceString = NumberUtils.format(this.matchPrice, Misc.NumberFormat.CurrencyDecimals, this.currencySymbol);

            if (this.matchQuantity) {
                return `Sold ${NumberUtils.format(this.matchQuantity, Misc.NumberFormat.NoDecimals)} ${this.instrumentCode} at ${matchPriceString}`;
            } else {
                return `Sold ${this.instrumentCode} for value ${NumberUtils.format(this.amount, Misc.NumberFormat.CurrencyDecimals, this.currencySymbol)} (${matchPriceString} per share)`;
            }  
        }
        return "";
    }
}