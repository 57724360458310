import { Attributes, LookupBase, NeoModel } from '@singularsystems/neo-core';
import { ITradeableBalance } from '../../Portfolio/Calculation/CalculationBase';
import { BrokingFeeLookup } from './BrokingFeeLookup';

@NeoModel
export default class BrokerAccountLookup extends LookupBase {

    public brokerAccountId: number = 0;

    public isDefault: boolean = false;

    public supportsForexCover: boolean = false;

    public brokingFeeIds: number[] = [];

    @Attributes.Date()
    public maxExpiryDate: Date = new Date();
    
    // Client only properties / methods

    @Attributes.NoTracking()
    public brokingFees: BrokingFeeLookup[] = [];

    @Attributes.NoTracking()
    public vatRate: number = 0;

    public get hasVat() {
        return this.brokingFees.some(c => c.chargeVat);
    }

    public setFeeObjects(fees: BrokingFeeLookup[]) {
        for (let feeId of this.brokingFeeIds) {
            const fee = fees.find(c => c.brokingFeeId === feeId);
            if (fee){
                this.brokingFees.push(fee);
            }
        }
    }

    /**
     * Gets the sum of all fees incl vat.
     */
    public getBrokingFeeTotal(tradeValue: number, isSale: boolean) {

        let totalFee = 0;

        if (tradeValue > 0) {
            for (let brokingFee of this.brokingFees) {
                if (brokingFee.isCharged(isSale ? 1 : 0, isSale ? 0 : 1)) {
                    let fee = brokingFee.calcFee(tradeValue);
                    if (brokingFee.chargeVat) {
                        fee *= (1 + this.vatRate);
                    }
                    totalFee += fee;
                }
            }
        }
        
        return totalFee;
    }

    public getTotalVat(trade: ITradeableBalance) {
        let totalVat = 0;
        for (let brokingFee of this.brokingFees) {
            if (brokingFee.chargeVat) {
               totalVat += (brokingFee.calcTradeFee(trade) * this.vatRate)
            }
        }
        return totalVat;
    }
}