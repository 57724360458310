import { injectable } from 'inversify';
import { makeObservable, observable } from 'mobx';

import { PlatformImageName } from '../Models/Enums/PlatformImageName';
import ImageLookup from '../Models/Styling/ImageLookup';
import { AppService, Types } from '../ClientsTypes';
import { App } from '../Models/Enums/App';

export enum LoadingState {
    loading = 0,
    ready = 1,
    failed = 2
}

@injectable()
class StyleService implements IStyleService {
    @observable.ref
    public loadingState = LoadingState.loading;

    private imageMap: { [index: string]: ImageLookup } = {};
    private styleValues: { [index: string]: string } = {};
    private hasInitialised = false;

    constructor(
        private config = AppService.get(Types.Shared.Config),
        private stylesApiClient = AppService.get(Types.Clients.ApiClients.AdminStylesQueryApiClient)) {

        makeObservable(this);
    }

    public async initialise(app: App) {
        if (!this.hasInitialised) {
            this.hasInitialised = true;

            try {
                const styleInfo = (await this.stylesApiClient.getStyleInfo(app)).data;
                
                this.setupImages(styleInfo.images);
                this.styleValues = styleInfo.styleValues;
                if (this.config.enableLoadServerCss && (!this.config.isDevelopment || this.config.alwaysLoadServerCss)) {
                    await this.loadCss(app, styleInfo.cssHash);
                }
                
                this.loadingState = LoadingState.ready;
            } catch (e) {
                this.loadingState = LoadingState.failed;
                throw e;
            }
        }
    }

    private async setupImages(imageList: ImageLookup[]) {
        this.imageMap = imageList.reduce((map, obj) => ((map[obj.imageName] = obj, map)), {});
    }

    private async loadCss(app: App, hash: string) {

        // Create a link using this hash. The browser may have this file in cache.
        const link = document.createElement("link");
        link.rel = "stylesheet";
        link.type = "text/css";
        link.href = this.stylesApiClient.getCssUrl(app, hash);
        link.media = "all";

        document.getElementsByTagName("head")[0].appendChild(link);

        return new Promise<void>((resolve, reject) => {

            link.addEventListener("load", () => resolve());
            link.addEventListener("error", e => reject(e.error));
        });
    }

    // import { baseClientsServicePath, baseClientsServiceApiPath } from '../Clients/Routes';
    public getImageUrl(image: PlatformImageName): string {
        const imageLookup = this.imageMap[PlatformImageName[image]];
        if (imageLookup) {
            return imageLookup.imagePath !== null && imageLookup.imagePath.includes("http") ? imageLookup.imagePath : `${this.config.ClientsApi.BasePath}/Images/${imageLookup.imagePath.replace(/\\/g, "/")}`;
        } else {
            return "";
        }
    }

    public getStyleValue(key: string): string {
        return this.styleValues[key] ?? "";
    }
}

export interface IStyleService {
    initialise(app: App): Promise<void>;
    loadingState: LoadingState;
    getImageUrl(image: PlatformImageName): string;
    getStyleValue(key: string): string;
}

export { StyleService as default, PlatformImageName as PlatformImage };