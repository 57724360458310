import React from "react";
import { observer } from "mobx-react";
import { Neo, Views } from "@singularsystems/neo-react";
import { NeoModel } from "@singularsystems/neo-core";
import { AppService, Types } from '../../AllocationManagerTypes';
import AllocationParticipantAddRequest from "../../Models/AllocationParticipantAddRequest";

interface IAddParticipantModalProps {
    onClose: () => void;
    sendRequest: (addRequest: AllocationParticipantAddRequest) => void;
    show: boolean;
}

@NeoModel
export class AddParticipantModalVM extends Views.ViewModelBase {
    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner)) {
        super(taskRunner);
    }
    public addRequest = new AllocationParticipantAddRequest();
}

@observer
export default class AddParticipantModal extends React.Component<IAddParticipantModalProps> {

    private viewModel = new AddParticipantModalVM();
    constructor(props: IAddParticipantModalProps) {
        super(props)
        this.viewModel = new AddParticipantModalVM();
    }

    render() {
        const request = this.viewModel.addRequest;
        return (
            <Neo.Modal title="Add additional team member" size="lg" className={"request-modal"}
                show={this.props.show}
                onClose={() => this.props.onClose()}
                closeButton={false}
                acceptButton={false}>
                <Neo.FormGroupInline suppressLabel placeholder="Name of new member" bind={request.meta.participantName} />
                <Neo.FormGroupInline suppressLabel placeholder="Additional notes" bind={request.meta.additionalNotes} input={{ rows: 3 }} />
                <Neo.Button style={{ float: 'right' }} text="Send request" onClick={() => this.props.sendRequest(this.viewModel.addRequest)} />
            </Neo.Modal >
        )
    }
}