import React from 'react';
import { Neo, NeoGrid } from '@singularsystems/neo-react';
import IncentiveGroup from '../../../Models/Portfolio/IncentiveGroup';
import { InlineFieldComponent } from '../../Components/InlineFieldComponent';
import { Misc, ModalUtils, Model, NumberUtils } from '@singularsystems/neo-core';
import { TradeType } from '../../../Models/Trading/TradeType';
import CalculationGroup from '../../../Models/Portfolio/Calculation/CalculationGroup';
import { observer } from 'mobx-react';
import { Awards } from '../../../../../App';
import InfoIcon from '../../../../../App/Components/Application/InfoIcon';

interface ICalculateGroupedGridProps {
    category: IncentiveGroup;
    showValidation: boolean;
    isAdvancedCalculatorMode: boolean;
    onGroupRemoved: () => void;
    onCustomPriceChange: (instrumentId: number) => void;
}

@observer
export class CalculateGroupedGrid extends React.Component<ICalculateGroupedGridProps> {

    public render() {
        const category = this.props.category;
        const onlyBuys = category.groupedList.every(c => c.sellQuantity === 0 && c.buyQuantity > 0);

        return (
            <div>
                <NeoGrid.Grid items={category.groupedList} className="editable compact-editors">
                    {(item, meta) => (
                        <NeoGrid.RowGroup>
                            {this.renderDetails(item, meta, false, onlyBuys, category.info.isCash)}
                            {item.linkedGroups.map(linked => this.renderDetails(linked, linked.meta, true, onlyBuys, category.info.isCash))}
                            <NeoGrid.Row showInHeader={false} className="group-footer">
                                <NeoGrid.Column colSpan={9}>
                                    <div className="ml-2 flex-container flex-v-center justify-content-between">
                                        <div>
                                            {this.renderTradeTypeInstructions(item.trancheBalance.incentiveScheme)}
                                        </div>
                                        <div className="text-right">
                                            {item.trancheBalance.incentiveScheme.allowSell &&
                                                <Neo.Button
                                                    size="sm"
                                                    icon={item.lastTradeType === TradeType.Sell ? (item.unitsAfterTrade === 0 ? "check-square" : "minus-square") : ""}
                                                    className={item.lastTradeType === TradeType.Sell ? "btn-toggled" : ""}
                                                    onClick={() => item.setTradeType(TradeType.Sell)}>
                                                    Sell and cash out
                                                </Neo.Button>}

                                            {item.trancheBalance.incentiveScheme.allowSellToCover &&
                                                <Neo.Button
                                                    size="sm"
                                                    icon={item.lastTradeType === TradeType.SellToCover ? (item.unitsAfterTrade === 0 ? "check-square" : "minus-square") : ""}
                                                    className={(item.lastTradeType === TradeType.SellToCover ? "btn-toggled" : "") + " ml-2"}
                                                    onClick={() => item.setTradeType(TradeType.SellToCover)}>
                                                    Transfer some units
                                                </Neo.Button>}

                                            {item.trancheBalance.incentiveScheme.allowBuy &&
                                                <Neo.Button
                                                    size="sm"
                                                    icon={item.lastTradeType === TradeType.Buy ? (item.unitsAfterTrade === 0 ? "check-square" : "minus-square") : ""}
                                                    className={(item.lastTradeType === TradeType.Buy ? "btn-toggled" : "") + " ml-2"}
                                                    onClick={() => item.setTradeType(TradeType.Buy)}>
                                                    Transfer units
                                                </Neo.Button>}
                                        </div>
                                    </div>
                                </NeoGrid.Column>
                            </NeoGrid.Row>
                        </NeoGrid.RowGroup>
                    )}
                </NeoGrid.Grid>
            </div>
        )
    }

    private renderDetails(item: CalculationGroup, meta: Model.TransformMetaType<CalculationGroup>, isLink: boolean, onlyBuys: boolean, isCashIncentiveGroup: boolean) {
        const availableQtyText = NumberUtils.format(item.availableBalance, Misc.NumberFormat.NoDecimals);
        const showSettlementInstrumentCustomPrice = (item.lastTradeType === TradeType.Buy || item.lastTradeType === TradeType.SellToCover) && this.props.isAdvancedCalculatorMode && item.trancheBalance.settlementInstrument !== undefined;
        const allowPriceEntry =
            item.trancheBalance.fixedPrice === null
            && ((item.trancheBalance.incentiveScheme.allowedSellPriceType === Awards.AllowedExercisePriceType.AllowLimitPrice && item.lastTradeType !== TradeType.Buy)
                || this.props.isAdvancedCalculatorMode);

        return (
            <>
                <NeoGrid.Row showInHeader={!isLink}>
                    <NeoGrid.Column
                        hideBelow="lg">
                        {!isLink && <i className="fa fa-trash-alt text-heading" style={{ cursor: "pointer" }} onClick={e => this.onRemoveClick(e, item)}></i>}
                    </NeoGrid.Column>

                    <NeoGrid.Column label="Scheme" bindContext={meta.incentiveSchemeName} className="text-heading" headerClassName="bold" cellClassName={item?.groupLink?.isHovered ? "bold" : ""}>
                        <span className={item.groupLink?.isHovered ? "bold" : ""}>
                            {item.groupLink && <i className="fa fa-link mr-2" data-tip={"Linked award: " + item.groupLink.awardLinkDescription} />}
                            {item.incentiveSchemeName}
                            {item.trancheBalance.hasClosePeriod && <i className="fa fa-ban ml-2" data-tip="Subject to ban on trade" />}
                        </span>
                    </NeoGrid.Column>

                    <NeoGrid.Column display={meta.awardDate} hideBelow="xl" />

                    <NeoGrid.Column
                        label={"Units to\ntrade"}
                        className="number-col column-150"
                        bind={meta.unitsToTrade}
                        prepend={item.unitsAfterTrade === 0 ? null :
                            <Neo.Button icon="undo-alt" className="btn-primary-grey" data-tip={`${item.tradeTypeText} all ${availableQtyText} units`} onClick={() => item.resetTradeValues()}></Neo.Button>} >
                    </NeoGrid.Column>

                    <NeoGrid.Column label={"Award\nprice"} display={meta.awardPrice} numProps={{ formatString: this.formatString(item.priceCurrencySymbol) }} hideBelow="lg" />

                    <NeoGrid.Column
                        label={"Current\nprice"}
                        bind={this.props.isAdvancedCalculatorMode ? meta.customPrice : meta.effectiveSellPrice}
                        numProps={{ formatString: this.formatString(item.priceCurrencySymbol) }}
                        className="column-150"
                        headerClassName="bold pr-3"
                        suppressDefaultContent={!allowPriceEntry}
                        headerTooltip="This is the current indicative price"
                        onBlur={() => {
                            if (this.props.isAdvancedCalculatorMode) {
                                this.props.onCustomPriceChange(item.instrument.instrumentId);
                            }
                        }}
                        sort={false}>
                        {() => !allowPriceEntry &&
                            <span className="pr-2">
                                {item.trancheBalance.fixedPrice !== null &&
                                    <i className="fa fa-tags mr-2" data-tip={"Price is fixed at " + NumberUtils.format(item.trancheBalance.fixedPrice, Misc.NumberFormat.CurrencyDecimals, item.priceCurrencySymbol)} />}
                                {NumberUtils.format(item.instrumentPrice, this.formatString(item.priceCurrencySymbol))}
                            </span>}


                    </NeoGrid.Column>

                    {onlyBuys &&
                        <NeoGrid.Column
                            label={"Current gross\nvalue"}
                            display={meta.buyValue}
                            numProps={{ currencySymbol: item.instrument.currencySymbol }}
                        />}

                    {!onlyBuys &&
                        <NeoGrid.Column
                            label={"Trade\nproceeds"}
                            display={meta.tradeProceeds}
                            numProps={{ currencySymbol: item.instrument.currencySymbol }}
                        />}

                    <NeoGrid.Column label={"Award\ncosts/income"} display={meta.proportionalAwardDebt} numProps={{ currencySymbol: item.instrument.currencySymbol }} />

                    <NeoGrid.Column
                        label={"Current potential\nprofit"}
                        display={meta.tradeProfitLossGross}
                        numProps={{ currencySymbol: item.instrument.currencySymbol }}
                        className={(item.tradeProfitLossGross < 0 ? "grid-loss" : "grid-highlight") + " bold calculator-profit-column"} />
                </NeoGrid.Row>

                <NeoGrid.Row showInHeader={false} className="second-row">
                    <NeoGrid.Column hideBelow="lg" />
                    <NeoGrid.Column>
                        {isLink && <small>Please note this is a linked award and has to be traded together with the main award.</small>}
                        {this.props.showValidation && item.hasQuantity && item.cannotTradeReason &&
                            <Neo.Alert variant="danger" className="mt-1 alert-tiny">
                                <i className="fa fa-exclamation-triangle" /> {item.cannotTradeReason}
                            </Neo.Alert>}
                    </NeoGrid.Column>
                    <NeoGrid.Column hideBelow="xl" />
                    <NeoGrid.Column>
                        {item.lastTradeType === TradeType.SellToCover && !item.trancheBalance.settlementInstrument &&
                            <>
                                <div className="text-right mr-2">
                                    <label className="mb-0">Sell</label><br /><strong>{NumberUtils.format(item.sellQuantity, Misc.NumberFormat.NoDecimals)}</strong>
                                </div>
                                <div className="text-right mr-2">
                                    <label className="mb-0">Transfer</label><br /><strong>{NumberUtils.format(item.buyQuantity, Misc.NumberFormat.NoDecimals)}</strong>
                                </div>
                            </>}
                    </NeoGrid.Column>
                    <NeoGrid.Column hideBelow="lg" />
                    <NeoGrid.Column>
                        {allowPriceEntry && !this.props.isAdvancedCalculatorMode &&
                            <Neo.RadioList bind={meta.isLimitPrice} radioList={{ items: [{ id: false, text: "At Market price" }, { id: true, text: "Limit price" }] }} />}
                        {showSettlementInstrumentCustomPrice &&
                            <Neo.FormGroup
                                label="Buy Price"
                                bind={item.trancheBalance.settlementInstrument!.meta.customOrDefaultPrice}
                                numProps={{ formatString: this.formatString(item.trancheBalance.settlementInstrument!.currencySymbol) }} />}
                    </NeoGrid.Column>
                    <NeoGrid.Column colSpan={3}>
                        <div className="table-field-container text-right" style={{ marginLeft: "auto" }}>
                            {!item.trancheBalance.incentiveScheme.isCashSettled &&
                                <div>
                                    {item.brokerAccount.brokingFees.map(fee => (
                                        fee.isCharged(item.sellQuantity, item.buyQuantity) &&
                                        <div key={fee.entityIdentifier}>
                                            <InlineFieldComponent xs={7} label={fee.category} display={NumberUtils.format(-fee.calcTradeFee(item), Misc.NumberFormat.CurrencyDecimals, item.instrument.currencySymbol)} className="sub" right />
                                        </div>
                                    ))}

                                    {item.brokerAccount.hasVat &&
                                        <InlineFieldComponent xs={7} label="VAT" display={NumberUtils.format(-item.brokerAccount.getTotalVat(item), Misc.NumberFormat.CurrencyDecimals, item.instrument.currencySymbol)} className="sub" right />}
                                </div>}
                            <InlineFieldComponent xs={7} label="Indicative tax" display={NumberUtils.format(item.calcTaxAmount, Misc.NumberFormat.CurrencyDecimals, item.instrument.currencySymbol)} className="sub" right />
                            <InlineFieldComponent
                                xs={7}
                                label={"Indicative " + (item.netProceeds < 0 ? "Shortfall" : "Net proceeds")}
                                display={NumberUtils.format(item.trancheBalance.settlementInstrumentCode && item.sellQuantity === 0 ? 
                                    item.tradingCosts : item.netProceeds, Misc.NumberFormat.CurrencyDecimals, item.instrument.currencySymbol)}
                                right />

                            {(item.buyQuantity > 0) &&
                                <InlineFieldComponent
                                    xs={7}
                                    label={"Units transferred " + (item.trancheBalance.settlementInstrumentCode ? `(${item.trancheBalance.settlementInstrumentCode})` : "")}
                                    display={NumberUtils.format(item.settlementUnitsTransferred, "#,##0.##")}
                                    right />}
                        </div>

                    </NeoGrid.Column>
                </NeoGrid.Row>
            </>
        )
    }

    private renderTradeTypeInstructions(scheme: Awards.IncentiveSchemeLookup) {
        const choiceCount = (scheme.allowSell ? 1 : 0) + (scheme.allowSellToCover ? 1 : 0) + (scheme.allowBuy ? 1 : 0);
        if (choiceCount > 1) {

            const choices = <>
                {scheme.allowSell && <p>If you sell and cash out units, you wil sell all units and receive cash after costs and taxes have been deducted.</p>}
                {scheme.allowSellToCover && <p>If you transfer some units to your broker account, you need to sell enough units to pay for costs and relevant taxes and you will take delivery of the balance of shares in your personal brokerage account.</p>}
                {scheme.allowBuy && <p>If you Transfer units, you choose to take ownership of all your shares and agree to pay for the costs and taxes with your own resources. Please refer to the calculations for the details on the indicative amount/s due, if any, and how many shares you can receive.</p>}
            </>

            return (
                <strong className="text-heading">
                    Would you like to sell and cash out vested units, or transfer units to your broker account? <InfoIcon text={choices} />
                </strong>)
        }
        return null;
    }

    private async onRemoveClick(e: React.MouseEvent, item: CalculationGroup) {
        if (item.cannotTradeReason || this.props.isAdvancedCalculatorMode) {
            this.removeGroup(item);
        } else {
            const choice = await ModalUtils.showYesNoDismissible("Remove award", "Are you sure you want to remove this award from your current trade selection?");
            if (choice === Misc.ModalResult.Yes) {
                this.removeGroup(item);
            } else {
                item.isSelected = false;
            }
        }
    }

    private removeGroup(item: CalculationGroup) {
        this.props.category.removeGroup(item);
        this.props.onGroupRemoved();
    }

    private formatString(currencySymbol: string) {
        return `${currencySymbol} #,##0.00######;(${currencySymbol}#,##0.00######)`;
    }
}