import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../AllocationManagerTypes';
import AllocationManager from '../Models/AllocationManager';
import { SelectLinkedManagersCommand } from '../Models/Commands/SelectLinkedManagersCommand';
import UpdateAllocationManagerCommand from '../Models/Commands/UpdateAllocationManagerCommand';

export interface IAllocationManagerApiClient {

    /**
     * The GET Allocation Manager End Point
     * @param allocationManagerId The Id of the Allocation Manager to be fetched.
     * @returns The Allocation Manager
     */
    getAllocationManager(allocationManagerId: number): AxiosPromise<Model.PlainTrackedObject<AllocationManager>>;

    /**
     * The UPDATE Allocation Manager End Point.
     * @param allocationManager The allocation manager being updated.
     * @returns The updated allocation manager.
     */
    updateAllocationManager(allocationManager: Model.PartialPlainObject<AllocationManager>): AxiosPromise<Model.PlainTrackedObject<AllocationManager>>;

    /**
     * Will add/remove linked managers as per the selection
     * @param command The select linked managers command
     * @returns An OK result if successful
     */
    selectLinkedManagers(command: Model.PartialPlainObject<SelectLinkedManagersCommand>): AxiosPromise<any>;

    /** 
     * Performs partial update on an existing Allocation Manager - used for the participant app
     * @param updateAllocationManagerCommand The Update Allocation Manager Command.
     * @returns The updated allocation manager.
     */
    updateAllocationManagerPartial(updateAllocationManagerCommand: Model.PartialPlainObject<UpdateAllocationManagerCommand>): AxiosPromise;

    // Client only properties / methods
}

@injectable()
export default class AllocationManagerApiClient extends Data.ApiClientBase implements IAllocationManagerApiClient {

    constructor (config = AppService.get(Types.Shared.Config)) {
        super(`${config.AwardsApi.ApiPath}/allocation-manager`);
    }

    public getAllocationManager(allocationManagerId: number): AxiosPromise<Model.PlainTrackedObject<AllocationManager>> {
        return this.axios.get(`${this.apiPath}/${allocationManagerId}`);
    }

    public updateAllocationManager(allocationManager: Model.PartialPlainObject<AllocationManager>): AxiosPromise<Model.PlainTrackedObject<AllocationManager>> {
        return this.axios.post(`${this.apiPath}/update`, allocationManager);
    }

    public selectLinkedManagers(command: Model.PartialPlainObject<SelectLinkedManagersCommand>): AxiosPromise<any> {
        return this.axios.post(`${this.apiPath}/linkedManagers/select`, command);
    }

    public updateAllocationManagerPartial(updateAllocationManagerCommand: Model.PartialPlainObject<UpdateAllocationManagerCommand>): AxiosPromise {
        return this.axios.patch(`${this.apiPath}/update`, updateAllocationManagerCommand);
    }

    // Client only properties / methods
}