import React from 'react';
import HistoryChartVM from './HistoryChartVM';
import { observer } from 'mobx-react';
import HighchartsReact from 'highcharts-react-official';
import * as Highcharts from 'highcharts';
import { StyleUtils } from '../../Services/StyleUtils';

interface IHistoryChartProps {
    viewModel: HistoryChartVM;
}

@observer
export default class HistoryChart extends React.Component<IHistoryChartProps> {

    public render() {
        return (
            <div className="mb-n3">
                <div>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={this.getChartOptions()} />
                </div>
            </div>
        )
    }

    private getChartOptions() {
        const data = this.props.viewModel.allocationParticipantHistory;
        const colors = StyleUtils.getGraphColors();
        const allocationCurrencySymbol = this.props.viewModel.allocationParticipantHistory?.currencySymbol;

        if (data) {
            return {
                chart: { type: "column", height: 300 },
                title: { text: undefined },
                xAxis: {
                    categories: [`${data.expectedValueLabel(0)}`, `${data.expectedValueLabel(1)}`,
                    `${data.expectedValueLabel(2)}`, `${data.expectedValueLabel(3)}`, `${data.expectedValueLabel(4)}`]
                },
                yAxis: { visible: false },
                series: [{
                    tooltip: { valueDecimals: 0, pointFormat: "<b>" + allocationCurrencySymbol + "{point.y}</b>" },
                    type: "column",
                    name: "Value",
                    data: [data.plus1ExpectedValueTotal, data.plus2ExpectedValueTotal,
                    data.plus3ExpectedValueTotal, data.plus4ExpectedValueTotal, data.plus5ExpectedValueTotal],
                    colors: [colors[0], colors[1], colors[1], colors[1], colors[1]],
                    showInLegend: false,
                    colorByPoint: true,
                    groupPadding: 0.1,
                    dataLabels: [{ enabled: true, format: allocationCurrencySymbol + "{y:,.0f}", style: { fontSize: "10px", textOutline: false } },]
                }],
                credits: { enabled: false }
            } as Highcharts.Options;
        }
        return null;
    }
}