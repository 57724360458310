import React from 'react';
import { observer } from 'mobx-react';
import { Neo } from '@singularsystems/neo-react';
import QuestionnaireQuestionAnswerLookup from '../../../../../../Models/Questionnaires/Queries/QuestionnaireQuestionAnswerLookup';
import QuestionnaireAnsweredLookup from '../../../../../../Models/Questionnaires/Queries/QuestionnaireAnsweredLookup';

interface IQuestionYesNoComponentProps {
    questionAnswered: QuestionnaireAnsweredLookup | null;
    questionNumber: number;
    question: QuestionnaireQuestionAnswerLookup;
    disabled: boolean;
}

@observer
export default class QuestionYesNoComponent extends React.Component<IQuestionYesNoComponentProps> {

    private radioButtonItems: any[] = [
        { id: "true", text: "Yes" },
        { id: "false", text: "No" }
    ]

    public render() {
        const question = this.props.question;
        const questionLabel = this.props.questionNumber + ". " + question.question;
        return (
            <div>
                {question &&
                    <Neo.FormGroup
                        bind={question.meta.questionAnswer}
                        label={questionLabel}
                        editorProps={{
                            "data-tip": question.questionInfoText,
                            disabled: this.props.disabled
                        } as any}
                        radioList={{
                            items: this.radioButtonItems,
                            valueMember: "id",
                            displayMember: "text",
                            inline: true,
                        }}
                    />
                }
            </div>
        )
    }
}