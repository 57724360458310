import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../../CommonIdentityTypes';
import TermsAndConditionsStatusLookup from '../../../Models/TsAndCs/Queries/TermsAndConditionsStatusLookup';
import TermsAndConditionsStatusLookupCriteria from '../../../Models/TsAndCs/Queries/TermsAndConditionsStatusLookupCriteria';


export interface IAcceptTermsAndConditionsApiClient {

    /** 
     * Gets the current terms and conditions. Returns null of no current terms to accept for user.
     * @param criteria Lookup criteria (if any)
     * @returns A page of terms and conditions.
     */
     getStatus(criteria?: Model.PartialPlainNonTrackedObject<TermsAndConditionsStatusLookupCriteria>): AxiosPromise<Model.PlainObject<TermsAndConditionsStatusLookup>>;

    /** 
     * Will accept the provided terms and conditions.
     * @param termsAndConditionsId The terms and conditions id
     * @returns A task awaiting the accept of the terms and conditions.
     */
    accept(termsAndConditionsId: number): AxiosPromise;

    // Client only properties / methods
}

@injectable()
export default class AcceptTermsAndConditionsApiClient extends Data.ApiClientBase implements IAcceptTermsAndConditionsApiClient {

    constructor (config = AppService.get(Types.Shared.Config)) {
        super(`${config.IdentityServerApi.ApiPath}/terms-and-conditions/accept`);
    }

    public getStatus(criteria?: Model.PartialPlainNonTrackedObject<TermsAndConditionsStatusLookupCriteria>): AxiosPromise<Model.PlainObject<TermsAndConditionsStatusLookup>> {
        const queryString = !criteria ? "" : `?criteria.alwaysFetchTermsAndConditions=${criteria.alwaysFetchTermsAndConditions}`;
        return this.axios.get(`${this.apiPath}/status${queryString}`);
    }

    public accept(termsAndConditionsId: number): AxiosPromise {
        return this.axios.post(`${this.apiPath}/${termsAndConditionsId}`);
    }

    // Client only properties / methods
}