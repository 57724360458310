import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../TransactionsTypes';
import RatesLookup from '../Models/RatesLookup';

export interface IFeesApiClient {

    /**
     * Gets the rates effective today.
     * @returns Rates object.
     */
    getCurrentRates(): AxiosPromise<Model.PlainObject<RatesLookup>>;

    // Client only properties / methods
}

@injectable()
export default class FeesApiClient extends Data.ApiClientBase implements IFeesApiClient {

    constructor (config = AppService.get(Types.Shared.Config)) {
        super(`${config.TransactionsApi.ApiPath}/Fees`);
    }

    public getCurrentRates(): AxiosPromise<Model.PlainObject<RatesLookup>> {
        return this.axios.get(`${this.apiPath}/CurrentRates`);
    }

    // Client only properties / methods
}