import { Attributes, ModelBase, NeoModel, Validation } from '@singularsystems/neo-core';
import { AwardDocumentAcceptanceType } from '../AwardDocumentAcceptanceType.enum';
import FileDescriptor from '../../Files/FileDescriptor';
import { AwardDocumentDeliveryType } from '../AwardDocumentDeliveryType.enum';

@NeoModel
export default class ParticipantOfferAwardDocumentLookup extends ModelBase {

    public participantOfferAwardDocumentId: number = 0;

    @Attributes.Nullable()
    public incentiveSchemeId: number | null = null;

    @Attributes.Nullable()
    public incentivePlanId: number | null = null;

    @Attributes.Nullable()
    public incentiveSchemeDocumentId: number | null = null;

    public participantOfferAwardDocumentSetId: number = 0;

    public participantOfferId: number = 0;

    public awardDocumentId: number = 0;

    @Attributes.Nullable()
    public awardDocumentSetId: number | null = 0;

    public documentName: string = "";

    public awardDocumentHTML: string = "";

    public acceptedAwardDocumentHTML: string = "";

    @Attributes.ChildObject(FileDescriptor)
    public downloadFileDescriptor: FileDescriptor | null = null;

    public awardDocumentType: string = "";

    public deliveryType: AwardDocumentDeliveryType | null = null;

    @Attributes.Integer()
    public orderNum: number = 0;

    public acceptanceType: AwardDocumentAcceptanceType | null = null;

    public acceptedTermsAndConditions: boolean | null = null;

    public uploadedFileName: string = "";

    @Attributes.Date()
    public respondedOn: Date | null = null;

    // Client only properties / methods

    public get isDownloadOnly () {
        return this.deliveryType === AwardDocumentDeliveryType.DocumentDownload && this.downloadFileDescriptor &&
            this.acceptanceType === AwardDocumentAcceptanceType.None;
    }

    public isCompleted() {
        return this.acceptanceType === AwardDocumentAcceptanceType.None || this.respondedOn !== null;
    }
    
    public hasResponded() {
        return this.respondedOn !== null;
    }

    public headerClass() {
        //base
        let className: string = "award-document-header";

        // has responded or not
        className += (this.hasResponded() ? " responded" : "");

        return className;
    }

    public getIcon() {
        return ParticipantOfferAwardDocumentLookup.getDocumentIcon(this.deliveryType) + " text-primary"
    }

    public static getDocumentIcon(deliveryType: AwardDocumentDeliveryType | null) {
        switch (deliveryType) {
            case AwardDocumentDeliveryType.HtmlTemplate: return "fa-file-alt";
            case AwardDocumentDeliveryType.DocumentDownload: return "fa-file-download";
        }

        return "file";
    }

    @Attributes.NoTracking()
    public isExpanded: boolean = false;

    public documentResponseString(): string {
        if (this.respondedOn === null) {
            return "No Response"
        } else if (this.acceptedTermsAndConditions) {
            return "Accepted"
        } else {
            return "Declined"
        }
    }

    public responseDetailsText() {
        return `You responded with the option "${this.documentResponseString()}"`;
    }

    public responseClass(): string {
        let className: string = "award-document-response";
        // what type of response
        switch (this.documentResponseString()) {
            case "Accepted":
                className += " accepted";
                break;
            case "Declined":
                className += " declined";
                break;
        };
        return className;
    }

    public responseIcon(): string {
        if (this.respondedOn === null) {
            return "fa fa-question"
        } else if (this.acceptedTermsAndConditions) {
            return "fa fa-check"
        } else {
            return "fa fa-times mr-2"
        }
    }

    public containerClicked(evt: any) {
        try {
            const contentElement = evt.currentTarget.querySelector('div.award-document-content');
            let expectedHeight: number = 0;
            this.isExpanded = !this.isExpanded;
            if (this.isExpanded) {
                expectedHeight = contentElement.scrollHeight;
            }
            contentElement.style.maxHeight = `${expectedHeight}px`;
        } catch (err) {
            console.log(err);
        }
    }

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew || !this.awardDocumentHTML) {
            return "New participant offer award document lookup";
        } else {
            return this.awardDocumentHTML;
        }
    }
}