import React from 'react';
import { AppService, Types } from '../../../TransactionsTypes';
import { HtmlView, ITemplateDefinition, NotificationServiceTypes } from '@singularsystems/neo-notifications';
import { observer } from 'mobx-react';
import { ModalUtils } from '@singularsystems/neo-core';

interface TemplateLinkProps {
    templateTypeKey: string;
    children: React.ReactNode;
}

@observer
export class TemplateLink extends React.Component<TemplateLinkProps> {

    private templateTask = AppService.get(Types.Neo.TaskRunner);
    private static templates = new Map<string, ITemplateDefinition>();

    public render() {
        return (
            <a href="/" onClick={(e) => { this.showTemplate(); e.preventDefault();}}>
                {this.templateTask.isBusy && <i className="fas fa-spinner fa-spin" />} {this.props.children}
            </a>
        )
    }

    private async showTemplate() {
        let template = TemplateLink.templates.get(this.props.templateTypeKey);
        if (!template) {
            const templatesApiClient = AppService.get(NotificationServiceTypes.ApiClients.TemplateProcessing);
            const result = await this.templateTask.waitFor(templatesApiClient.merge(this.props.templateTypeKey, "", "", {}), { allowPost: true });
            
            template = result.data.mergedText;
            TemplateLink.templates.set(this.props.templateTypeKey, template);
        }
        
        ModalUtils.showModal(template.subject, <HtmlView html={template.body} />, { size: "lg" });
    }
}