import React from 'react';

import { AppServices } from '@singularsystems/neo-core';

import { Types } from './IdentityTypes';

import ProxySessionApiClient from '../Common/ApiClients/Custom/ProxyUsers/ProxySessionApiClient';
import AcceptTermsAndConditionsApiClient from '../Common/ApiClients/Custom/TermsAndConditions/AcceptTermsAndConditionsApiClient';
import ProxyAsParticipantModal, { ProxyAsParticipantModalVM } from '../Common/Components/ProxyAsParticipantModal';
import ProxySessionQueryApiClient from '../Common/ApiClients/Custom/ProxyUsers/ProxySessionQueryApiClient';
import ParticipantsAppProxySessionService from './Services/ParticipantsAppProxySessionService';
import { IdentityCommonRoutes, IdentityPureRoutes } from '../Common/IdentityRoutes';
import AccountApiClient from '../Common/ApiClients/AccountApiClient';
import ChangePasswordView from './Views/ChangePasswordView';
import TermsAndConditionsService from '../Common/Services/TermsAndConditionsService';
import TermsAndConditionsDisplayOptionsApiClient from '../Common/ApiClients/Custom/TermsAndConditions/TermsAndConditionsDisplayOptionsApiClient';

// Modules
const identityAppModule = new AppServices.Module("Clients", container => {
    // Api Clients
    container.bind(Types.Identity.ApiClients.AccountApiClient).to(AccountApiClient).inSingletonScope();
    container.bind(Types.Identity.ApiClients.ProxySessionApiClient).to(ProxySessionApiClient).inSingletonScope();
    container.bind(Types.Identity.ApiClients.ProxySessionQueryApiClient).to(ProxySessionQueryApiClient).inSingletonScope();
    container.bind(Types.Identity.ApiClients.AcceptTermsAndConditionsApiClient).to(AcceptTermsAndConditionsApiClient).inSingletonScope();
    container.bind(Types.Identity.ApiClients.TermsAndConditionsDisplayOptionsApiClient).to(TermsAndConditionsDisplayOptionsApiClient).inSingletonScope();
    
    // Services
    container.bind(Types.Identity.Services.ProxySessionService).to(ParticipantsAppProxySessionService).inTransientScope();
    container.bind(Types.Identity.Services.TermsAndConditionsService).to(TermsAndConditionsService).inSingletonScope();
    
    // View Models
    container.bind(Types.Identity.ViewModels.ProxyAsParticipantModalVM).to(ProxyAsParticipantModalVM).inTransientScope();
    
    // Components
    container.bind(Types.Identity.Components.ProxyAsParticipantModalContainer).toDynamicValue((context) => {
        const modalVM = context.container.get<ProxyAsParticipantModalVM>(Types.Identity.ViewModels.ProxyAsParticipantModalVM.symbol);
        return {
            viewModel: modalVM,
            component: React.createElement(ProxyAsParticipantModal, { viewModel: modalVM }, null)
        };
    }).inTransientScope();
});

// Test
const identityTestModule = new AppServices.Module("Clients", context => {
    // bind test types
});

// Routing
const ChangePasswordRoute = { path: "/change-password", component: ChangePasswordView };

export { identityAppModule, 
         identityTestModule,
         IdentityPureRoutes,
         IdentityCommonRoutes,
         ChangePasswordRoute }
