import { Data, Model, Utils } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, AppTypes } from 'st-app/Services/AppService';
import IncentiveSchemesSearchCriteria from '../Models/IncentiveSchemes/IncentiveSchemesSearchCriteria';
import IncentiveSchemeLookup from '../Models/IncentiveSchemes/IncentiveSchemeLookup';
import IncentiveSchemeSeparationConditionLookup from '../Models/Adjustments/IncentiveSchemeSeparationConditionLookup';
import IncentiveSchemeDropDownLookup from '../Models/IncentiveSchemes/IncentiveSchemeDropDownLookup';
import IncentiveSchemeSeparationCondition from '../Models/Adjustments/IncentiveSchemeSeparationCondition';

export interface IIncentiveSchemesQueryApiClient {

    /**
     * Gets a lightweight list of IncentiveSchemes.
     * @param criteria Search criteria
     * @returns a lightweight list of IncentiveSchemes.
     */
    getLookup(criteria: Model.PartialPlainNonTrackedObject<IncentiveSchemesSearchCriteria>): AxiosPromise<Array<Model.PlainObject<IncentiveSchemeLookup>>>;

    /**
     * Gets a list of Incentive Scheme Separation Conditions.
     * @returns a list of Incentive Scheme Separation Conditions.
     */
    getSeparationConditionsLookupAll(): AxiosPromise<Array<Model.PlainObject<IncentiveSchemeSeparationConditionLookup>>>;

    // Client only properties / methods

    /**
     * Gets a lightweight list of all IncentiveSchemes for the current tenant.
     */
    getLookupAll(): AxiosPromise<Array<Model.PlainObject<IncentiveSchemeLookup>>>;

    /**
     * Gets a lightweight list of all IncentiveSchemes for the current tenant and current participant
     */
    getParticipantIncentiveSchemeLookup():  AxiosPromise<Array<Model.PlainObject<IncentiveSchemeDropDownLookup>>>;

    /** 
    /// Gets the participant incentive scheme separation conditions.
     * @param participantId The participant id.
     * @param separationReasonId The separation reason Id.
     * @returns a lightweight list of incentive scheme separation conditions.
     */
    getParticipantIncentiveSchemeSeparationCondition(participantId: string, separationReasonId: number): AxiosPromise<Array<Model.PlainTrackedObject<IncentiveSchemeSeparationCondition>>>;
    /** 
    * Gets an excel export of instrument options.
    * @returns An Excel File.
    */
    getInstrumentOptionExcelExtractStream(incentiveSchemeId: number): AxiosPromise<Blob>; 


}

@injectable()
export default class IncentiveSchemesQueryApiClient extends Data.ApiClientBase implements IIncentiveSchemesQueryApiClient {

    constructor(config = AppService.get(AppTypes.Shared.Config)) {
        super(`${config.AwardsApi.ApiPath}/IncentiveSchemesQuery`);
        }

    public getLookup(criteria: Model.PartialPlainNonTrackedObject<IncentiveSchemesSearchCriteria>): AxiosPromise<Array<Model.PlainObject<IncentiveSchemeLookup>>> {
        return this.axios.get(`${this.apiPath}?${Utils.getQueryString(criteria)}`);
    }

    public getSeparationConditionsLookupAll(): AxiosPromise<Array<Model.PlainObject<IncentiveSchemeSeparationConditionLookup>>> {
        return this.axios.get(`${this.apiPath}/separationConditionsLookupAll`);
    }

    // Client only properties / methods

    /**
     * Gets a lightweight list of all IncentiveSchemes for the current tenant.
     */
    public getLookupAll(): AxiosPromise<Array<Model.PlainObject<IncentiveSchemeLookup>>> {
        return this.getLookup({});
    }

    /**
     * Gets a lightweight list of all IncentiveSchemes for the current tenant and current participant
     */
    public getParticipantIncentiveSchemeLookup(): AxiosPromise<Array<Model.PlainObject<IncentiveSchemeDropDownLookup>>> {
        return this.axios.get(`${this.apiPath}/participantIncentiveSchemeLookup`);
    }

    public getParticipantIncentiveSchemeSeparationCondition(participantId: string, separationReasonId: number): AxiosPromise<Array<Model.PlainTrackedObject<IncentiveSchemeSeparationCondition>>> {
        return this.axios.get(`${this.apiPath}/participantIncentiveSchemeSeparationConditionTenant/${participantId}?participantId=${encodeURIComponent(participantId)}&separationReasonId=${separationReasonId}`);
    }

    public getInstrumentOptionExcelExtractStream(incentiveSchemeId: number): AxiosPromise<Blob> {
        return this.axios.get(`${this.apiPath}/instrument-options-export/${incentiveSchemeId}`,{responseType:"blob"});
    }
}