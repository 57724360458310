import { ModelBase, NeoModel, Rules, Utils, Validation } from '@singularsystems/neo-core';

@NeoModel
export default class StartProxySessionCommand extends ModelBase {

    @Rules.Required()
    public applicationUserId: string = "";

    @Rules.Required()
    public participantId: string = Utils.emptyGuid();

    @Rules.Required()
    public participantName: string = "";

    public returnToAdminUrl: string = "";

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew || !this.applicationUserId) {
            return "New start proxy session command";
        } else {
            return this.applicationUserId;
        }
    }
}