import { ModelBase, NeoModel } from '@singularsystems/neo-core'
import { injectable } from 'inversify';
import { AppService, Types } from '../AllocationManagerTypes'

@injectable()
@NeoModel
export default class AllocationManagerService extends ModelBase {
    
    public constructor(
       public taskRunner = AppService.get(Types.Neo.TaskRunner),
       private allocationManagerQueryAPIClient = AppService.get(Types.AllocationManager.ApiClients.AllocationManagerQueryApiClient)
    ) {
        super();        
    }

    public displayMenu = false;

    public async initialize() {
        const result = await this.taskRunner.waitFor(this.allocationManagerQueryAPIClient.displayAllocationManagerMenuItem());            
        this.displayMenu = result.data;        
    }
}