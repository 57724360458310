export class ApiConfig { 
    private _baseUrl: string = "";
    private _apiPath: string = "";
  
    public get BasePath(): string { return this._baseUrl; }
    public get ApiPath(): string { return this._apiPath; }
  
    constructor(private baseUrlTemplate: string, apiPath: string) {
      const domain = window.location.hostname;
  
      // Naive approach assuming subdomain is on the host, and no 2nd, 3rd etc level subdomains
      // (Improve later to support http://domain.com and http://second.first.domain.com patterns)
      const subdomain = domain.split('.')[0];
      
      // Embed the domain or subdomain for multi-tenancy (Only one of these should be present in the URL template)
      this._baseUrl = this.baseUrlTemplate.replace("{domain}", domain).replace("{subdomain}", subdomain) 
      this._apiPath = `${this._baseUrl}${apiPath}`;
    }
  }
