import { Components } from '@singularsystems/neo-core';
import ComponentBase from '@singularsystems/neo-react/dist/ReactComponents/ComponentBase';
import React from 'react';

export class Collapsable extends ComponentBase<{ show: boolean, children: React.ReactNode }> {

    protected containerRef = React.createRef<HTMLDivElement>();
    protected animationHelper?: Components.CollapseAnimationHelper;

    public render() {

        return <div className="collapsable" ref={this.containerRef}>
            {this.props.children}
        </div>
    }

    afterRender(isInitial: boolean) {

        if (this.containerRef.current) {
            if (!this.animationHelper) {
                this.animationHelper = new Components.CollapseAnimationHelper(this.containerRef.current, this.props.show);
            }

            if (!isInitial) {
                this.animationHelper.showHide(this.props.show, isInitial)
            }
        }
    }

    componentWillUnmount() {
        if (this.animationHelper) {
            this.animationHelper.destroy();
        }
    }
}