import React from 'react';
import { observer } from 'mobx-react';
import { Neo, NeoGrid, Views } from '@singularsystems/neo-react';
import TradeRequestAdminExtraDetailsLookup from '../Models/Trading/TradeRequestAdminExtraDetailsLookup';
import { ITaskRunner, NeoModel } from '@singularsystems/neo-core';
import TradeRequestDocumentLookup from '../Models/Documents/TradeRequestDocumentLookup';

interface ITradeRequestDocumentsComponent {
    taskRunner: ITaskRunner;
    extraDetails: TradeRequestAdminExtraDetailsLookup;
    getDocumentDownloadUrl: (tradeRequestDocumentId: number) => Promise<string>;
}

@NeoModel
class TradeRequestDocumentsComponentVM extends Views.ViewModelBase {

    constructor() {
        super();
    }

    public async downloadFile(item: TradeRequestDocumentLookup, getDocumentDownloadUrl: (tradeRequestDocumentId: number) => Promise<string>) {
        if (!item.downloadUrl) {
            // need to retrieve the download URL with SAS token
            item.preparingDownload = true;
            const result = await getDocumentDownloadUrl(item.tradeRequestDocumentId);

            item.downloadUrl = result;
            if (item.downloadUrl) {
                window.location.href = item.downloadUrl;
            }
            item.preparingDownload = false;
        } else {
            window.location.href = item.downloadUrl
        };
    }
}

@observer
export default class TradeRequestDocumentsComponent extends Views.ViewComponentBase<ITradeRequestDocumentsComponent, TradeRequestDocumentsComponentVM> {

    constructor(props: ITradeRequestDocumentsComponent) {
        super(props, TradeRequestDocumentsComponentVM);
    }

    public render() {
        const { extraDetails, getDocumentDownloadUrl } = this.props;

        return (
            <Neo.Card icon="file" title="Documents" className="participant-documents">
                <NeoGrid.Grid items={extraDetails.documents} >
                    {(item, meta) => (
                        <NeoGrid.Row>
                            <NeoGrid.Column display={meta.documentName} />
                            <NeoGrid.Column display={meta.documentType} />
                            <NeoGrid.Column display={meta.documentDate} />
                            <NeoGrid.ButtonColumn>
                                <Neo.Button
                                size="sm"
                                icon={item.isLoading ? "circle-notch fa-spin" : ""}
                                disabled={item.isLoading}
                                className="btn-150"
                                onClick={() => this.viewModel.downloadFile(item, getDocumentDownloadUrl)}
                                >View</Neo.Button>
                            </NeoGrid.ButtonColumn>
                        </NeoGrid.Row>
                    )}
                </NeoGrid.Grid>
            </Neo.Card>
        )
    }
}