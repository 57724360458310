import { Attributes, ModelBase, NeoModel, Validation } from '@singularsystems/neo-core';

@NeoModel
export default class TradeRequestTrancheCommand extends ModelBase {

    public trancheId: number = 0;

    @Attributes.Float()
    public sellQuantity: number = 0;

    @Attributes.Float()
    public buyQuantity: number = 0;

    // Client only properties / methods

    canSerialise(shouldSerialise: boolean) {
        return shouldSerialise && (this.sellQuantity > 0 || this.buyQuantity > 0);
    }

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew) {
            return "New trade request tranche";
        } else {
            return "Trade Request Tranche";
        }
    }
}