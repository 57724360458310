import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import ExchangeRateLookup from '../../Common/Models/ExchangeRateLookup';
import ExchangeRateLookupHistoryCriteria from '../../Common/Models/ExchangeRateLookupHistoryCriteria';
import { AppService, Types } from '../TransactionsTypes';
import InstrumentPriceLookup from '../../Common/Models/Instruments/InstrumentPriceLookup';

export interface IPricingApiClient {

    /**
     * Gets a list of exchange rates going to the provided currency.
     * @param viewCurrencyId Currency to convert to.
     * @returns List of exchange rates.
     */
    getExchangeRates(viewCurrencyId: number): AxiosPromise<Array<Model.PlainObject<ExchangeRateLookup>>>;

    /** 
     * Returns the exchange rate conversions from the From Currency codes to the To Currency codes for the date provided
     * @param criteria The lookup criteria
     * @returns Returns a list of exchange rates for the provided date, from the list of From Currency Codes provided to the list of To Currency Codes.
     */
    getHistoricalExchangeRates(criteria: Model.PartialPlainObject<ExchangeRateLookupHistoryCriteria>): AxiosPromise<Array<Model.PlainObject<ExchangeRateLookup>>>;

    /**
     * Gets a list of prices for the provided instrument codes.
     * @param codes List of instrument codes.
     * @returns List of instrument code and price.
     */
    getPrices(codes: string[]): AxiosPromise<Array<Model.PlainObject<InstrumentPriceLookup>>>;


    // Client only properties / methods
}

@injectable()
export default class PricingApiClient extends Data.ApiClientBase implements IPricingApiClient {

    constructor (config = AppService.get(Types.Shared.Config)) {
        super(`${config.TransactionsApi.ApiPath}/Pricing`);
    }

    public getExchangeRates(viewCurrencyId: number): AxiosPromise<Array<Model.PlainObject<ExchangeRateLookup>>> {
        return this.axios.get(`${this.apiPath}/exchange-rates?viewCurrencyId=${viewCurrencyId}`);
    }

    public getHistoricalExchangeRates(criteria: Model.PartialPlainObject<ExchangeRateLookupHistoryCriteria>): AxiosPromise<Array<Model.PlainObject<ExchangeRateLookup>>> {
        return this.axios.post(`${this.apiPath}/get-historical-exchange-rates`, criteria);
    }

    public getPrices(codes: string[]): AxiosPromise<Array<Model.PlainObject<InstrumentPriceLookup>>> {
        return this.axios.get(`${this.apiPath}/prices?codes=${codes.map(c => encodeURIComponent(c)).join("&codes=")}`);
    }

    // Client only properties / methods
}