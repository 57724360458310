import { Utils, Validation, ValueObject } from '@singularsystems/neo-core';

export default class ClosedPeriodCriteria extends ValueObject {

    constructor() {
        super();
        this.makeObservable();
    }

    public participantId: string = Utils.emptyGuid();

    public instrumentIds: number[] | null = null;

    public incentiveSchemeIds: number[] | null = null;

    // Client only properties / methods

    protected static addBusinessRules(rules: Validation.Rules<ClosedPeriodCriteria>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        return "Closed Period Criteria";
    }
}