
export enum GridColumn {
    TeamMember = 1,
    GradePerformance = 2,
    Salary = 3,
    CurrentValue = 4,
    ValuePercSalary = 5,
    LastYearAlloc = 6,
    MinAndMaxValueGuideline = 7,
    Allocation = 8,
    Scheme = 9,
    NewValuePercSalary = 10,
    Notes = 11,
}