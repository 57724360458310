import { NeoModel } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import { AppService, Types } from '../../AllocationManagerTypes';
import AllocationParticipantHistoryLookup from '../../Models/Queries/AllocationParticipantHistoryLookup';

@NeoModel
export default class HistoryChartVM extends Views.ViewModelBase {

    constructor(
        public participantHistory: AllocationParticipantHistoryLookup,
        taskRunner = AppService.get(Types.Neo.TaskRunner),) {

        super(taskRunner);
        this.allocationParticipantHistory = participantHistory;
    }

    readonly allocationParticipantHistory: AllocationParticipantHistoryLookup | null;
    
    public async initialise() {
    }
}