import { ModelBase, NeoModel } from '@singularsystems/neo-core';

@NeoModel
export default class IncentiveGroupLookup extends ModelBase {

    public incentiveGroupId: number = 0;

    public groupName: string = "";

    public userDescription: string = "";

    public isDefault: boolean = false;

    public isCash: boolean = false;

    // Client only properties / methods

    public static CreateDefault() : IncentiveGroupLookup {
        const group = new IncentiveGroupLookup();
        group.groupName = "Awards";
        group.isDefault = true;
        return group;
    }
}