import { Attributes, ModelBase, NeoModel, Rules, Validation } from '@singularsystems/neo-core';

@NeoModel
export default class UpdateSchemeSplitCommand extends ModelBase {

    public allocationSchemeSplitId: number = 0;

    @Rules.Required()    
    public incentiveSchemeId: number | null = null;
    
    public incentiveSchemeName: string = "";

    @Attributes.Float()
    public percentageSplit: number = 0;
    
    @Attributes.Float()
    public expectedValuePercentage: number = 0;

    public description: string = "";

    // Client only properties / methods
    
    public readableSchemeName: string = "";

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew) {
            return "New update scheme split command";
        } else {
            return "Update Scheme Split Command";
        }
    }
}