import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../AwardsTypes';
import QuestionnaireAnsweredLookup from '../../Models/Questionnaires/Queries/QuestionnaireAnsweredLookup';

export interface IAnswerQuestionnaireQueryApiClient {

    /**
     * Will get the approval summary for the current logged in participant
     * @returns The approval summary
     */
    getQuestionnairesAnsweredAsync(questionnaireAnsweredId: number | null): AxiosPromise<Model.PlainObject<QuestionnaireAnsweredLookup>>;
    
    // Client only properties / methods
}

@injectable()
export default class AnswerQuestionnaireQueryApiClient extends Data.ApiClientBase implements IAnswerQuestionnaireQueryApiClient {

    constructor(config = AppService.get(Types.Shared.Config)) {
        super(`${config.AwardsApi.ApiPath}/AnswerQuestionnaireQuery`);
    }
    
    public getQuestionnairesAnsweredAsync(questionnaireAnsweredId: number | null): AxiosPromise<Model.PlainObject<QuestionnaireAnsweredLookup>> {
        return this.axios.get(`${this.apiPath}/questionnairesAnswered/${questionnaireAnsweredId}`);
    }

    // Client only properties / methods
}