import { Neo } from '@singularsystems/neo-react';
import {  Model, ITaskRunner, Data } from '@singularsystems/neo-core';
import React from 'react';

type IPagerTextFilterProps = {
    filterProperty: Model.IPropertyInstance;
    pageManager: Data.IPageManager;
    taskRunner: ITaskRunner;
    label?: any;
}

export default class PagerTextFilter extends React.Component<IPagerTextFilterProps> {

    public render() {
        return (
            <Neo.FormGroupInline bind={this.props.filterProperty} label={this.props.label} className="pager-text-filter"
                editorProps={{
                    onKeyPress: key => {
                        if (key.key === "Enter") {
                            this.props.pageManager.refreshData(true, this.props.taskRunner);
                            key.preventDefault();
                        }
                    }
                }}
                append={() =>
                        <Neo.Button size="sm" tabIndex={-1} icon="search"
                            onClick={() => this.props.pageManager.refreshData(true, this.props.taskRunner)} />
                        } />
        );
    }
}