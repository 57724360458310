import { NeoModel, Validation, ValueObject } from '@singularsystems/neo-core';

@NeoModel
export default class AllocationManagerChangeControlConfig extends ValueObject {

    public changeControlWording: string = "";

    public changeControlURL: string = "";

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        return "Allocation Manager Change Control Config";
    }
}