import { List, ModelBase, NeoModel, Rules, Validation } from '@singularsystems/neo-core';
import { AllocationPlanSection } from '../Enums/AllocationPlanSection';
import { AllocationPlanType } from '../Enums/AllocationPlanType';
import AllocationPlanInformationSupportMedia from './AllocationPlanInformationSupportMedia';

@NeoModel
export default class AllocationPlanInformation extends ModelBase {

    public allocationPlanInformationId: number = 0;

    public allocationPlanSectionId: AllocationPlanSection | null = null;

    @Rules.StringLength(4000)
    public sectionText: string = "";

    public allocationPlanInformationSupportMedia = new List(AllocationPlanInformationSupportMedia);

    public allocationPlanTypeId: AllocationPlanType | null = null;

    // Client only properties / methods

    public allocationPlanInformationSupportMediaImages = new List(AllocationPlanInformationSupportMedia);
    
    public allocationPlanInformationSupportMediaDocuments = new List(AllocationPlanInformationSupportMedia);

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew || !this.sectionText) {
            return "New allocation plan information";
        } else {
            return this.sectionText;
        }
    }
}