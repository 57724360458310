import { Attributes, ModelBase, NeoModel } from '@singularsystems/neo-core';

@NeoModel
export default class MyAwardInfoLookup extends ModelBase {

    public myAwardsHeader: string = "My Awards";

    @Attributes.Date()
    public nextAcceptanceActionBy: Date | null = null;

    @Attributes.Integer()
    public nextAcceptanceNumberOfAwards: number = 0;

    @Attributes.Integer()
    public acceptTotalAwards: number = 0;

    @Attributes.Integer()
    public activeNumberOfAwards: number = 0;

    public pendingAwardsMessage: string = "";

    // Client only properties / methods
}