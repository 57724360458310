import { Attributes, Misc, ModelBase, NeoModel} from '@singularsystems/neo-core';

@NeoModel
export default class AllocationIncentiveGroupAllocationLookup extends ModelBase {

    public allocationParticipantId: number = 0;

    public allocationIncentiveGroupAllocationId: number = 0;

    @Attributes.Serialisation(Misc.SerialiseType.NotNull)
    public incentiveGroupId: number | null = null;

    public incentiveGroup: string = "";

    @Attributes.Integer()
    public proposedAllocationFaceValue: number = 0;

    @Attributes.Integer()
    public proposedAllocationExpectedValue: number = 0;

    @Attributes.Integer()
    public lastYearsAllocation: number = 0;

    public applyConversion: boolean = false;

    @Attributes.Integer()
    public readonly displayOrder: number = 0;

    @Attributes.Integer()
    @Attributes.Nullable()
    public assumedIncentiveSchemeId: number | null = 0;

    // Client only properties / methods
}