import { AppServices } from "@singularsystems/neo-core";
import { IParticipantsAppApiClient } from './ApiClients/ParticipantsAppApiClient';
import ParticipantsDataCache from './Services/ParticipantsDataCache';

export const ParticipantsSharedTypes = {    
    ApiClients: {        
        MainApiClient: new AppServices.ServiceIdentifier<IParticipantsAppApiClient>("Participants.ParticipantsAppApiClient"),        
    },
    Services: {
        DataCache: new AppServices.ServiceIdentifier<ParticipantsDataCache>("Participants.DataCache")
    }
}