import { ModelBase, NeoModel } from '@singularsystems/neo-core';

@NeoModel
export default class AllocationCommentLookup extends ModelBase {

    public allocationCommentId: number = 0;

    public allocationParticipantId: number = 0;

    public commentType: string = "";

    public comment: string = "";

    public allocationCommentTypeId: number = 0;

    public allocationCommentTypeOptionId: number = 0;

    public allocationCommentNotes: string = "";

    // Client only properties / methods

    // TODO: We definitely need a better way of identifying Over the Max comments between different clients. This isn't a static enum as comment types can differ between clients.
    // This assumes each Over The Maximum comment will have the letters "max" included - a fairly flimsy premise which needs to be addressed, but which we will have more context
    // on when more clients are wanting to use this functionality - will other clients even have the concept of Over The Max / Over Allocated?
    public get isOverTheMaxComment() {
        return this.commentType.toLowerCase().includes("max")
    }

    public toString() {
        return `${this.commentType}: ${this.comment}`;
    }
}