import { Attributes, List, ModelBase, NeoModel, Rules } from '@singularsystems/neo-core';
import AwardPrepLookup from '../../../../Common/Models/AwardPreparation/AwardPrepLookup';
import { variant } from '@singularsystems/neo-core/dist/Components/BootstrapTypes';

@NeoModel
export class ApproverApprovalSummaryLookup extends ModelBase {

    public awards = new List(ApproverAwardApprovalSummaryLookup);

    // Client only properties / methods
}

export class ApprovalCounts {
    public approved = 0;
    public declined = 0;
    public submitted = 0;
}

@NeoModel
export class ApproverAwardApprovalSummaryLookup extends ModelBase {

    @Attributes.ChildObject(AwardPrepLookup)
    public award: AwardPrepLookup = new AwardPrepLookup();

    @Attributes.Integer()
    @Attributes.Display("Total")
    public approvals: number = 0;

    @Attributes.Integer()
    public approved: number = 0;

    @Attributes.Integer()
    public declined: number = 0;

    @Rules.Required()
    @Attributes.Date()
    public actionBy: Date | null = null;

    @Attributes.Integer()
    public submitted: number = 0;

    public daysRemaining: number = 0;

    // Client only properties / methods

    @Attributes.NoTracking()
    private nonVisibleCounts = new ApprovalCounts();

    public setupCounts(pageCounts: ApprovalCounts) {
        // the non visible counts have not been setup yet
        this.nonVisibleCounts = new ApprovalCounts();
        this.nonVisibleCounts.approved = this.approved - pageCounts.approved;
        this.nonVisibleCounts.declined = this.declined - pageCounts.declined;
        this.nonVisibleCounts.submitted = this.submitted - pageCounts.submitted;
    }
    
    public updateCounts(pageCounts: ApprovalCounts) {
        this.approved = this.nonVisibleCounts.approved + pageCounts.approved;
        this.declined = this.nonVisibleCounts.declined + pageCounts.declined;
        this.submitted = this.nonVisibleCounts.submitted + pageCounts.submitted;
    }

    public updateCountsAllApproved() {
        this.approved = this.approvals;
        this.declined = 0;
    }

    public updateCountsAllDeclined() {
        this.declined = this.approvals;
        this.approved = 0;
    }

    public updateCountsAllCleared() {
        this.approved = 0;
        this.declined = 0;
    }

    public updateCountsAllSubmitted() {
        this.submitted = this.approved + this.declined;
    }

    public get approvedPercentage() {
        return this.approved / this.approvals;
    }

    public get declinedPercentage() {
        return this.declined / this.approvals;
    }

    public get completedPercentage() {
        return (this.approved + this.declined) / this.approvals;
    }

    public get submittedPercentage() {
        return this.submitted / this.approvals;
    }

    public get hasApprovalsToSubmit() {
        return ((this.approved + this.declined) > this.submitted);
    }

    public toString() {
        return this.award.toString();
    }

    public getAlertVariant(): variant {
        // TODO: Replace with config
        if (this.submittedPercentage === 1) {
            return "success";
        } else if (this.daysRemaining <= 3) {
            return "danger";
        } else if (this.daysRemaining <= 7) {
            return "warning";
        }

        return "info";
    }
}