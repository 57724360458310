import { Attributes, LookupBase, NeoModel } from '@singularsystems/neo-core';

@NeoModel
export default class AllocationLastEditedInfoLookup extends LookupBase {

    @Attributes.Date()
    @Attributes.Observable()
    public lastEditedOn: Date = new Date();

    public lastEditedBy: string = "";

    // Client only properties / methods
}