import { Misc, NumberUtils } from '@singularsystems/neo-core';
import { Neo } from '@singularsystems/neo-react';
import { observer } from 'mobx-react';
import React from 'react';
import { AppService, Types } from '../../../TransactionsTypes';
import { IncentiveGroupLookup } from '../../../../../Awards/Common/CommonSharedExports';
import { ITradeableBalance } from '../../../Models/Portfolio/Calculation/CalculationBase';

interface ICategory {
    entityIdentifier: string;
    info: IncentiveGroupLookup;
    flattened: ITradeableBalance[];
    hasDetail: boolean;
}

export interface IIncentiveGroupListProps<T extends ICategory> {
    categories: T[];
    renderBody: (category: T) => JSX.Element;
}

@observer
export class IncentiveGroupList<T extends ICategory> extends React.Component<IIncentiveGroupListProps<T>> {

    private portfolioService = AppService.get(Types.Transactions.Services.PortfolioService)

    public render() {
        return this.props.categories.filter(c => c.hasDetail).map(category => this.renderCategory(category));
    }

    private renderCategory(category: T) {
        const flattened = category.flattened;
        const totals = flattened.groupBy(c => c.instrument.displayCurrencySymbol, (k, i, d) => ({ 
            symbol: k,
            conversionText: i.instrument.getConversionText(),
            netProceeds: d.sum(c => c.netProceedsConverted),
            buyValue: d.sum(c => c.buyNetValueConverted)
        }));
        const buyValues = totals.filter(c => !!c.buyValue);

        const conversions = flattened
            .filter(c => (c.instrument.rate ?? 1) !== 1)
            .groupBy(c => c.instrument.currencyCode, (k, i, d) => i.instrument.getConversionText());
        
        return (
            <div key={category.entityIdentifier} className="portfolio-group-container">
                        <div className="portfolio-group-header">
                            <div className="portfolio-group-title">
                                {category.info.groupName}
                            </div>
                        </div>
                        <div className="portfolio-group-body">
                            {this.props.renderBody(category)}
                        </div>
                        <div className="portfolio-group-footer">
                            <div className="flex-container flex-v-center" style={{ flexDirection: "row-reverse"}}>
                                <div>
                                    <div className="table-field-container text-right" style={{marginLeft: "auto"}}>
                                        <div className="row">
                                            <div className="col-6">
                                                Total cash
                                            </div>
                                            <div className="col-6">
                                                {totals.map(t => (
                                                    <div>{NumberUtils.format(t.netProceeds, Misc.NumberFormat.CurrencyDecimals, t.symbol)}</div>
                                                ))}
                                            </div>
                                        </div> 
                                        {buyValues.length > 0 &&
                                        <div className="row">
                                            <div className="col-6">
                                                Transfer value
                                            </div>
                                            <div className="col-6">
                                                {buyValues.map(t => (
                                                    <div>{NumberUtils.format(t.buyValue, Misc.NumberFormat.CurrencyDecimals, t.symbol)}</div>
                                                ))}
                                            </div>
                                        </div>}
                                        {conversions.length > 0 &&
                                        <div className="conversion-details mt-2">
                                            Calculated at
                                            {conversions.map(c => <div>{c}</div>)}   
                                        </div>}
                                    </div>
                                </div>
                                <div>
                                    <Neo.DropDown 
                                        className="highlight" 
                                        style={{ width: this.portfolioService.currencyId ? 100 : 200, margin: "-5px 0" }} 
                                        bind={this.portfolioService.meta.currencyId} 
                                        select={{ items: this.portfolioService.appData!.mainCurrencies, allowNulls: true, nullText: "Show in Unit currency" }} />
                                </div>
                            </div>
                        </div>
                    </div>
        )
    }
}