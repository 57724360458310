import { EnumHelper } from '@singularsystems/neo-core';
import PortfolioBalanceLookup from '../PortfolioBalanceLookup';
import CalculationGroup from './CalculationGroup';

export enum PortfolioGroupType {
    ByInstrument,
    ByScheme,
    ByAward,
    Details
}

EnumHelper.decorateEnum(PortfolioGroupType, d => {
    d.describe(PortfolioGroupType.ByInstrument, "Instrument", "By Instrument");
    d.describe(PortfolioGroupType.ByScheme, "Scheme", "By Scheme");
    d.describe(PortfolioGroupType.ByAward, "Award", "By Award");
    d.describe(PortfolioGroupType.Details, "Details", "Details");
});

export interface IPortfolioGrouping {
    getId(item : PortfolioBalanceLookup): any;
    getName(item : PortfolioBalanceLookup): string;
    sort(list: CalculationGroup[]): CalculationGroup[];
}
export const portfolioGroupings = { 
    [PortfolioGroupType.ByInstrument]: { 
        getId: ((item: PortfolioBalanceLookup) => item.instrumentId),
        getName: ((item: PortfolioBalanceLookup) => item.instrument.instrumentName),
        sort: ((list: CalculationGroup[]) => list.sortBy(c => c.totalValue, "desc"))
    },
    [PortfolioGroupType.ByScheme]: { 
        getId: ((item: PortfolioBalanceLookup) => item.instrumentId + "." + item.incentiveSchemeId),
        getName: ((item: PortfolioBalanceLookup) => item.incentiveScheme.incentiveSchemeName),
        sort: ((list: CalculationGroup[]) => list.sortBy(c => c.groupName))
    },
    [PortfolioGroupType.ByAward]: { 
        getId: ((item: PortfolioBalanceLookup) => item.awardId),
        getName: ((item: PortfolioBalanceLookup) => item.awardName),
        sort: ((list: CalculationGroup[]) => list.sortBy(c => c.details[0].trancheBalance.awardDate.getTime()))
    }
}

