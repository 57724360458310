import { ModelBase, NeoModel, Rules, Validation } from '@singularsystems/neo-core';

@NeoModel
export default class AllocationCommentTypeOption extends ModelBase {

    public allocationCommentTypeOptionId: number = 0;

    public allocationCommentTypeId: number = 0;

    @Rules.Required()
    @Rules.StringLength(100)
    public comment: string = "";

    public isActive: boolean = true;

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew || !this.comment) {
            return "New allocation comment type option";
        } else {
            return this.comment;
        }
    }
}