import { Data, Model, NeoModel } from '@singularsystems/neo-core';
import ParticipantAwardReleaseLookupCriteria from '../../../Common/Models/Approvals/Queries/ParticipantAwardReleaseLookupCriteria';
import { AppService, Types } from '../../AwardsTypes';
import ParticipantAwardReleaseLookup from '../../../Common/Models/Approvals/Queries/ParticipantAwardReleaseLookup';
import ParticipantAwardReleaseInfo from '../../../Common/Models/Approvals/Queries/ParticipantAwardReleaseInfo';
import AwardPrepApprovalConfig from '../../../Common/Models/Config/AwardPrepApprovalConfig';
import ChangeApprovalsCommandBase from '../../Models/Approvals/Commands/ChangeApprovalsCommandBase';
import { AxiosPromise } from 'axios';
import ApproveApprovalsCommand from '../../Models/Approvals/Commands/ApproveApprovalsCommand';
import DeclineApprovalsCommand from '../../Models/Approvals/Commands/DeclineApprovalsCommand';
import SubmitApprovalsCommand from '../../Models/Approvals/Commands/SubmitApprovalsCommand';
import { NotificationDuration } from '../../../../App/Models/Enums/NotificationDuration';

import AwardsVMBase from '../Base/AwardsVMBase';
import ParticipantAwardLookupBase from '../../../Common/Models/Base/ParticipantAwardLookupBase';
import { AwardSelector } from '../Base/AwardSelector';

@NeoModel
export default class ReleaseVM extends AwardsVMBase<ParticipantAwardReleaseLookup> {
    
    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private queryApiClient = AppService.get(Types.Awards.ApiClients.ApprovalsQueryApiClient),
        private commandApiClient = AppService.get(Types.Awards.ApiClients.ApprovalsCommandApiClient),
        public notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        public pluralizer = AppService.get(Types.Shared.Services.Pluralizer)) {

        super(taskRunner);

        this.getEligibleForApproval = this.getEligibleForApproval.bind(this);
        this.getEligibleForDecline = this.getEligibleForDecline.bind(this);
        this.getEligibleForRelease = this.getEligibleForRelease.bind(this);
        this.getEligibleForCancel = this.getEligibleForCancel.bind(this);
    }

    public hasData = false;
    public awardCount: number = 0;
    public criteria = new ParticipantAwardReleaseLookupCriteria()

    public pageManager = new Data.PageManager(this.criteria, ParticipantAwardReleaseLookup, this.getParticipantAwardReleaseLookup.bind(this), {
        pageSize: 10,
        initialTaskRunner: this.taskRunner,
        sortBy: "participantName",
        afterFetch: (data) => this.setupViewPageData(data)
    });

    private async getParticipantAwardReleaseLookup(pageRequest: Model.PartialPlainNonTrackedObject<Data.PageRequest<ParticipantAwardReleaseLookupCriteria>>) {
        var result = await this.queryApiClient.getParticipantAwardReleaseLookup(pageRequest);
        this.awardCount = result.data.awardCount;
        // TODO: Once PageManager allows normal Promise<> then can remove the `as any`
        return result as any;
    }

    private selectedParticipantCriteria = new ParticipantAwardReleaseLookupCriteria()

    public selectedParticipantPageManager = new Data.PageManager(this.selectedParticipantCriteria, ParticipantAwardReleaseLookup, this.queryApiClient.getParticipantAwardReleaseLookup, {
        pageSize: 10,
        initialTaskRunner: this.taskRunner,
        sortBy: "participantName",
        afterFetch: (data) => this.setupSelectedParticipantPageData(data)
    });

    public approvalConfigs = new Map<number, AwardPrepApprovalConfig>();

    public participantAwardSelector = new AwardSelector<ParticipantAwardReleaseLookup>();

    public get activeAwardSelector() {
        if (this.selectedParticipant) {
            return this.participantAwardSelector;
        } else {
            return this.pageAwardSelector;
        }
    }

    private _selectedParticipant: ParticipantAwardReleaseInfo | null = null
    public get selectedParticipant() {
        return this._selectedParticipant;
    }
    public set selectedParticipant(value: ParticipantAwardReleaseInfo | null) {
        if (this.selectedParticipant && value === null) {
            // make sure the same entries in the page are updated
            for (const participantAward of this.selectedParticipantPageManager.data) {
                const pageAward = this.pageManager.data.find(c => c.awardOptionInstrumentPriceId === participantAward.awardOptionInstrumentPriceId);
                if (pageAward) {
                    pageAward.mapFrom({ declinedOn: participantAward.declinedOn, approvedOn: participantAward.approvedOn });
                }
            }
        }

        this._selectedParticipant = value;
    }

    public get activeData() {
        if (this.selectedParticipant) {
            return this.selectedParticipantPageManager.data;
        } else {
            return this.pageManager.data;
        }
    }

    public get activeTaskRunner() {
        if (this.selectedParticipant) {
            return this.selectedParticipantPageManager.taskRunner;
        } else {
            return this.pageManager.taskRunner;
        }
    }

    public async initialise() {
        await this.taskRunner.waitForAll({
            superLoad: super.initialise(),
            _: this.pageManager.refreshData()
        });

        this.hasData = this.pageManager.data.length > 0;
        await this.setupData(this.pageManager.data);
    }

    private setupViewPageData(data: ParticipantAwardReleaseLookup[]) {
        this.setupData(data);
    }

    private setupSelectedParticipantPageData(data: ParticipantAwardReleaseLookup[]) {
        this.setupData(data);
    }

    public async showParticipantInfo(item: ParticipantAwardReleaseLookup) {
        // load all the awards for this participant
        this.selectedParticipantCriteria.participantId = item.participantId;

        await this.selectedParticipantPageManager.refreshData()

        const newParticipantInfo = new ParticipantAwardReleaseInfo([...this.selectedParticipantPageManager.data]);
        this.participantAwardSelector.resetToSelected(newParticipantInfo.awards);

        this.selectedParticipant = newParticipantInfo;
    }

    protected async loadAdditionalData(data: ParticipantAwardLookupBase[]) {
        await this.loadApprovalConfigs(data);
    }

    private async loadApprovalConfigs(data: ParticipantAwardLookupBase[]) {
        const awardPrepIdsToFetch = Array.from(new Set(data.map(c => c.awardPrepId)));
        for (let i = awardPrepIdsToFetch.length - 1; i >= 0; i--) {
            if (this.approvalConfigs.get(awardPrepIdsToFetch[i])) {
                awardPrepIdsToFetch.remove(awardPrepIdsToFetch[i]);
            }
        }

        if (awardPrepIdsToFetch.length > 0) {
            const configs = await this.queryApiClient.getApprovalsConfigs(awardPrepIdsToFetch);
            for (const awardPrepId of awardPrepIdsToFetch) {
                this.approvalConfigs.set(awardPrepId, configs.get(awardPrepId)!);
            }
        }
    }

    public getSelected(filterPredicate?: (item: ParticipantAwardReleaseLookup) => boolean) {
        return this.activeAwardSelector.getSelected(c => c.isSelected && (!filterPredicate || filterPredicate(c)));
    }

    public get selectedCount() {
        return this.activeAwardSelector.selectedCount(this.awardCount);
    }

    public getEligibleForDecline() {
        return this.getSelected(c => !c.declinedOn && !!this.approvalConfigs.get(c.awardPrepId) && this.approvalConfigs.get(c.awardPrepId)!.approversCanChangeStatus);
    }

    public get declineAwardCount() {
        return (new Set(this.getEligibleForDecline().map(a => a.awardApprovalId))).size;
    }

    public getEligibleForApproval() {
        return this.getSelected(c => !c.approvedOn && !!this.approvalConfigs.get(c.awardPrepId) && this.approvalConfigs.get(c.awardPrepId)!.approversCanChangeStatus);
    }

    public get approveAwardCount() {
        return (new Set(this.getEligibleForApproval().map(a => a.awardApprovalId))).size;
    }

    public getEligibleForRelease() {
        return this.getSelected(c => !!c.approvedOn);
    }

    public get releaseAwardCount() {
        return (new Set(this.getEligibleForRelease().map(a => a.awardApprovalId))).size;
    }

    public getEligibleForCancel() {
        return this.getSelected(c => !!c.declinedOn);
    }

    public get cancelAwardCount() {
        return (new Set(this.getEligibleForCancel().map(a => a.awardApprovalId))).size;
    }

    public get declineAwardsText() {
        return this.getAwardCommandText("Decline", this.declineAwardCount);
    }

    public get approveAwardsText() {
        return this.getAwardCommandText("Approve", this.approveAwardCount);
    }

    public get releaseAwardsText() {
        return this.getAwardCommandText("Release", this.releaseAwardCount);
    }

    public get cancelAwardsText() {
        return this.getAwardCommandText("Cancel", this.cancelAwardCount);
    }

    protected getAwardCommandText(action: string, noOfAwards: number) {
        if (!this.selectedParticipant && this.pageAwardSelector.inAllSelectedMode && this.pageManager.totalPages > 1) {
            return `${action} applicable awards`;
        } else {
            return super.getAwardCommandText(action, noOfAwards);
        }
    }

    public approveAwards() {
        this.runActionCommand(
            this.getEligibleForApproval,
            new ApproveApprovalsCommand(),
            this.commandApiClient.approveApprovals,
            false,
            item => this.updateItems(item, c => {
                item.approvedOn = new Date();
                item.declinedOn = null;
            }));
    }

    public declineReasonRequired() {
        const declineItems = this.getEligibleForDecline();
        const reasonRequiredItem = declineItems.find(c => this.approvalConfigs.get(c.awardPrepId)?.reasonRequiredForDecline);
        return (reasonRequiredItem !== undefined);
    }

    public declineAwards(declineCommand: DeclineApprovalsCommand) {
        this.runActionCommand(
            this.getEligibleForDecline,
            declineCommand,
            this.commandApiClient.declineApprovals,
            false,
            item => this.updateItems(item, c => {
                c.declinedOn = new Date();
                c.approvedOn = null;
            }));
    }

    private updateItems(item: ParticipantAwardReleaseLookup, updateItemAction: (item: ParticipantAwardReleaseLookup) => void) {
        const items: ParticipantAwardReleaseLookup[] = [];
        items.push(item);

        if (this.selectedParticipant) {
            // find the base page item as well
            const otherItem = this.pageManager.data.find(c => c.awardApprovalId === item.awardApprovalId);
            if (otherItem) {
                items.push(otherItem);
            }
        }

        for (const c of items) {
            updateItemAction(c);
        }
    }

    public async releaseAwards() {
        const command = new SubmitApprovalsCommand();
        command.isRelease = true;
        await this.runActionCommand(
            this.getEligibleForRelease,
            command,
            this.commandApiClient.submitApprovals,
            true,
            item => this.activeAwardSelector.selectedAwards.remove(item));

        if (this.selectedParticipant) {
            this.selectedParticipant = null;
        }
    }

    public async cancelAwards() {
        const command = new SubmitApprovalsCommand();
        command.isRelease = false;
        await this.runActionCommand(
            this.getEligibleForCancel,
            command,
            this.commandApiClient.submitApprovals,
            true,
            item => this.activeAwardSelector.selectedAwards.remove(item));

        if (this.selectedParticipant) {
            this.selectedParticipant = null;
        }
    }

    private async runActionCommand(
        getEligibleHandler: () => ParticipantAwardReleaseLookup[],
        command: ChangeApprovalsCommandBase,
        clientAction: (command: Model.PartialPlainObject<ChangeApprovalsCommandBase>) => AxiosPromise<number>,
        reloadPage: boolean,
        postRunAction?: (item: ParticipantAwardReleaseLookup) => void) {

        this.prepareCommand(command);

        this.activeTaskRunner.run(async () => {
            this.setupChangeCommand(getEligibleHandler, command);

            await clientAction(command.toJSObject());

            this.notifications.addSuccess("Changes Saved", null, NotificationDuration.Standard);

            if (reloadPage) {
                await this.pageManager.refreshData();
            }
            if (postRunAction) {
                for (const item of getEligibleHandler()) {
                    postRunAction(item);
                }
            }
        });
    }

    private prepareCommand(command: ChangeApprovalsCommandBase) {
        if (!this.selectedParticipant) {
            command.filterString = this.criteria.filterString;
        }
    }

    private setupChangeCommand(getEligibleHandler: () => ParticipantAwardReleaseLookup[], command: ChangeApprovalsCommandBase) {
        command.allEligibleAwards = true;
        if (!this.selectedParticipant && this.pageAwardSelector.inAllSelectedMode) {
            command.allApproverApprovals = true;
            let excludeAwardApprovalIds = this.pageAwardSelector.unSelectedAwards.map(a => (a as ParticipantAwardReleaseLookup).awardApprovalId);
            excludeAwardApprovalIds = excludeAwardApprovalIds.filter((x, y) => excludeAwardApprovalIds.indexOf(x) === y);
            command.excludeAwardApprovalIds = excludeAwardApprovalIds;
        }
        else {
            for (const item of getEligibleHandler()) {
                command.awardApprovalIds.push(item.awardApprovalId);
            }
        }
    }
}