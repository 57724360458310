import { ModelBase, NeoModel, Validation } from '@singularsystems/neo-core';

@NeoModel
export default class DeclineAwardsCommand extends ModelBase {

    public participantOfferIds: number[] | null = null;

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew) {
            return "New decline awards command";
        } else {
            return "Decline Awards Command";
        }
    }
}