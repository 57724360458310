import React from 'react';
import { Neo, Views } from '@singularsystems/neo-react';

import MyAwardsVM from './MyAwardsVM';
import { observer } from 'mobx-react';
import { PageBlock } from "../../../../App/Components/Application/PageBlock";
import MyAwardsGrid from './Components/MyAwardsGrid';

import AcceptAwardsView from './AcceptAwardsView';

@observer
export default class MyAwardsView extends Views.ViewBase<MyAwardsVM> {

    constructor(props: unknown) {
        super("My Awards", MyAwardsVM, props);
    }

    public render() {
        return (
            <div className="my-awards">
                <div className="pt-3">
                    <div className="row ml-2">
                        <div className="col">
                            <h2>{this.viewModel.awardModuleConfig.myAwards.header}</h2>
                            <p>
                                {this.viewModel.awardModuleConfig.myAwards.subHeader}
                            </p>
                        </div>
                    </div>
                </div>

                {/* PENDING AWARDS */}
                <Neo.Loader task={this.taskRunner}>
                    <div className="pt-3 page-block-alt">
                        <div>
                            <Neo.Loader task={this.viewModel.myAwardInfoTaskRunner} size="small">
                                <div className="row mb-3 ml-2 align-items-end">
                                    <div className="col-sm-6">
                                        <h2>{this.viewModel.awardModuleConfig.myAwards.pendingAwardsTitle}</h2>
                                        {this.viewModel.hasPendingAwards &&
                                            <p>
                                                {this.viewModel.myAwardsInfoLookup.pendingAwardsMessage}
                                            </p>}
                                    </div>
                                    <div className="col-sm-6">
                                        {this.viewModel.pendingAwardsPageManager.totalPages > 1 && this.viewModel.pendingAwardsPageManager.data.length > 0 &&
                                            <div className="col pager-holder right-tools">
                                                <Neo.PagerControlsBasic pageManager={this.viewModel.pendingAwardsPageManager} noOfButtons={this.viewModel.getPagerNoOfButtons()} />
                                            </div>}
                                    </div>
                                </div>
                            </Neo.Loader>

                            {this.viewModel.hasPendingAwards && !this.viewModel.initialising &&
                                <div>
                                    <MyAwardsGrid
                                        pageManager={this.viewModel.pendingAwardsPageManager}
                                        viewModel={this.viewModel}
                                        showSelection={false}
                                        showAwardDate
                                        config={this.viewModel.awardModuleConfig.myAwards.grid} />

                                    <div className="mt-3 right-tools pr-3">
                                        <Neo.Button variant="primary" onClick={() => this.navigation.navigateToView(AcceptAwardsView)}>View pending awards</Neo.Button>
                                    </div>
                                </div>}

                            {!this.viewModel.hasPendingAwards && !this.viewModel.initialising && !this.viewModel.pendingAwardsPageManager.taskRunner.isBusy &&
                                <Neo.Alert variant="info">
                                    <p>
                                        You have no pending awards.
                                    </p>
                                </Neo.Alert>}

                            {/* <div className="footer-banner header-main-background">
                                <div className="message-holder">
                                    <div className="message">The exchange rate of the grant date was used for calculations:</div>
                                    {this.viewModel.ratesCurrentlyUsed.map(rate =>
                                        <div className="message" key={rate.entityIdentifier}>
                                            - {DateUtils.format(rate.rateDate, "dd/MM/yy")} - {NumberUtils.format(1 / rate.rate, "#,##0.######")} {rate.fromCurrencyCode} <strong>=</strong> 1 {rate.toCurrencyCode}
                                        </div>)}
                                </div>
                            </div> */}
                        </div>
                    </div>
                </Neo.Loader>

                {/* GAP */}
                <PageBlock className="pt-3" />

                {/* ACCEPTED AWARDS */}
                <Neo.Loader task={this.taskRunner}>
                    <div className="pt-3 page-block-alt">
                        <div className="row ml-2">
                            <div className="col-sm-6">
                                <h2>{this.viewModel.awardModuleConfig.myAwards.acceptedAwardsTitle}</h2>
                                {this.viewModel.hasAcceptedAwards &&
                                    <p>
                                        {this.viewModel.awardModuleConfig.myAwards.acceptedAwardsSubHeader}
                                    </p>}
                            </div>
                            <div className="col-sm-6">
                                {this.viewModel.acceptedAwardsPageManager.totalPages > 1 && this.viewModel.acceptedAwardsPageManager.data.length > 0 &&
                                    <div className="col pager-holder right-tools">
                                        <Neo.PagerControlsBasic pageManager={this.viewModel.acceptedAwardsPageManager} noOfButtons={this.viewModel.getPagerNoOfButtons()} />
                                    </div>}
                            </div>
                        </div>

                        {this.viewModel.hasAcceptedAwards && !this.viewModel.initialising &&
                            <>
                                <MyAwardsGrid
                                    pageManager={this.viewModel.acceptedAwardsPageManager}
                                    viewModel={this.viewModel}
                                    showSelection={false}
                                    showAwardDate 
                                    config={this.viewModel.awardModuleConfig.myAwards.grid} />

                                <div className="row ml-2 pt-3">
                                    <div className="col">
                                        <p>
                                            <small>{this.viewModel.awardModuleConfig.myAwards.acceptedAwardsFooter}</small>
                                        </p>
                                    </div>
                                </div>
                            </>}
                        {!this.viewModel.hasAcceptedAwards && !this.viewModel.initialising && !this.viewModel.acceptedAwardsPageManager.taskRunner.isBusy &&
                            <Neo.Alert variant="info">
                                <p>
                                    You have no accepted awards.
                                </p>
                            </Neo.Alert>}
                    </div>
                </Neo.Loader>
            </div>
        );
    }
}