import React from 'react';
import { observer } from 'mobx-react';
import { Validation, Model } from '@singularsystems/neo-core';
import { Neo } from '@singularsystems/neo-react';
import { AppService, AppTypes } from '../../../Services/AppService';

@observer
export default class ValidationDisplay extends React.Component<{ rule: Validation.IRule | null, model: Model.IModelBase }> {
    private iconFactory = AppService.get(AppTypes.Neo.Components.IconFactory);

    public render() {

        const result = this.props.model.validator.getRuleResult(this.props.rule);
        const displayInfo = result && result.getDisplayInfo(true);

        return (
            displayInfo && result!.hasDetail && <div>
                <Neo.Alert variant={displayInfo.variant}>{this.iconFactory.getIconComponent(displayInfo.icon)} {displayInfo.displayText}</Neo.Alert>
            </div>
        )
    }
}