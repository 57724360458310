import { AppServices } from '@singularsystems/neo-core';
import { IPluralizer } from './Services/Pluralizer';
import { ISTUtils } from './Services/STUtils';

// Symbols to expose outside of this module
export const CommonTypes = {
    Services: {
        STUtils: new AppServices.ServiceIdentifier<ISTUtils>("Shared.Services.Utils"),
        Pluralizer: new AppServices.ServiceIdentifier<IPluralizer>("Shared.Services.Pluralizer")
    },
}