import { NeoModel } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import ChangePasswordCommand from '../../Common/Models/ChangePasswordCommand';
import { AppService, Types } from '../IdentityTypes'

@NeoModel
export default class ChangePasswordVM extends Views.ViewModelBase {

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        private apiClient = AppService.get(Types.Identity.ApiClients.AccountApiClient),
        authService = AppService.get(Types.Shared.Services.STAuthenticationService)) {

        super(taskRunner);

        this.isIDSUser = authService.user!.isIDSUser;
    }

    public changePasswordCommand = new ChangePasswordCommand();
    public isIDSUser: boolean;

    public async initialise() {

    }

    public changePassword() {
        this.taskRunner.run(async () => {
            await this.apiClient.changePassword(this.changePasswordCommand.toJSObject());

            this.changePasswordCommand = new ChangePasswordCommand();
            this.notifications.addSuccess("Change password", "Your password has been changed.");
        });
    }
}