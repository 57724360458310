import { NeoModel, Rules, ValueObject } from '@singularsystems/neo-core';
import MyAwardsGridConfig from './MyAwardsGridConfig';

@NeoModel
export default class MyAwardsConfig extends ValueObject {

    @Rules.StringLength(50)
    public header: string = "";

    @Rules.StringLength(500)
    public subHeader: string = "";

    @Rules.StringLength(50)
    public pendingAwardsTitle: string = "";

    @Rules.StringLength(250)
    public pendingAwardsDateMessageAll: string = "";

    @Rules.StringLength(250)
    public pendingAwardsDateMessageSome: string = "";

    @Rules.StringLength(50)
    public acceptedAwardsTitle: string = "";

    @Rules.StringLength(250)
    public acceptedAwardsSubHeader: string = "";

    @Rules.StringLength(500)
    public acceptedAwardsFooter: string = "";

    public grid: MyAwardsGridConfig = new MyAwardsGridConfig();

    // Client only properties / methods
}