export enum TradeAuthorisationStatus {
    AwaitingAuthorisation = 1,
    AutoAuthorised = 100,
    ManuallyAuthorised = 110,
}

export enum TradeSellStatus {
    Default = 1,
    AwaitingInternalPrice = 10,
    AwaitingBatching = 20,
    Batched = 30,
    SentToExchange = 40,
    PendingCancellation = 99,
    Settled = 100,
    Cancelled = 110,
    Expired = 111,
    NotApplicable = 200,
}

export enum TradeBuyStatus {
    Pending = 1,
    Processed = 99,
    Authorised = 100,
    SentToBroker = 101,
    Confirmed = 105,
    Cancelled = 110,
    NotApplicable = 200,
}