import { EnumHelper } from "@singularsystems/neo-core";

export enum ChangeRequestReason {
    Remove = 1,
    ReAssignManager = 2,
    Other = 3,
}

EnumHelper.decorateEnum(ChangeRequestReason, decorator => {
    decorator.describe(ChangeRequestReason.ReAssignManager, "Re-assign to another manager");
});