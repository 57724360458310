import { Attributes, NeoModel, Validation, ValueObject } from '@singularsystems/neo-core';

@NeoModel
export default class AllocationParticipantLookupCriteria extends ValueObject {

    public allocationPlanId: number = 0;

    public allocationManagerParticipantId: string = "";

    public allocationManagerId: number = 0;

    public participantName: string = "";

    @Attributes.Nullable()
    public participantGradeId: number | null = null;

    public jobTitle: string = "";

    public performanceRating: string = "";

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        return "Allocation Participant Lookup Criteria";
    }
}