import { Attributes, DateUtils, Misc, ModelBase, Rules, Utils } from '@singularsystems/neo-core';

import { Transactions } from '../../../../App';
import { RoundingType } from '../Enums/RoundingType';
import { InstrumentType } from '../Enums/InstrumentType';

export enum CalcDependency {
    ExpectedPrice,
    MarketPriceAtAwardDate,
    OfferInstrumentPrice,
    OptionInstrumentPrice,
    OptionToOfferedExchangeRate,
    OfferedToDefaultExchangeRate,
}

export default class ParticipantAwardLookupBase extends ModelBase {
    public awardPrepId: number = 0;

    @Rules.Required()
    @Attributes.Date()
    public awardDate = new Date();

    public participantOfferId: number = 0;

    public participantId: string = Utils.emptyGuid();

    public incentiveSchemeName: string = "";

    public incentiveSchemeId: number = 0;

    @Attributes.Float()
    public awardedBaseCurrency: number = 0;

    @Attributes.Float()
    public expectedValueBaseCurrency: number = 0;

    @Attributes.Float()
    public offeredUnits: number = 0;

    public offeredCurrencyCode: string = "";

    public offeredSymbol: string = "";

    public offeredInstrumentId: number = 0;

    public offeredInstrumentCode: string = "";

    public optionInstrumentType: InstrumentType | null = null;

    public hasAcceptedAwardSummary: boolean = false;

    @Attributes.Float()
    public optionDefaultPercentage: number = 0;

    @Attributes.Float()
    public instrumentOptionDefaultPercentage: number = 0;

    public useMarketPriceForUnitConversion: boolean = false;

    @Attributes.Float()
    public optionPercentage: number = 0;

    public offeredInstrumentType: InstrumentType | null = null;

    public vestingSchedule: string = "";

    public vestingRounding: RoundingType | null = null;

    public awardOptionInstrumentPriceId: number = 0;

    @Attributes.Float()
    public optionAwardPrice: number = 0;

    @Attributes.Nullable()
    @Attributes.Float()
    public optionMarketPriceAtAwardDate: number | null = null;

    public useExpectedValue: boolean = false;

    @Attributes.Nullable()
    @Attributes.Float()
    public optionExpectedPrice: number | null = null;

    public optionCurrencyCode: string = "";

    public optionSymbol: string = "";

    public optionInstrumentCode: string = "";

    public optionInstrumentName: string = "";

    // Client only properties / methods

    public get emptyPlaceholder() {
        return "-";
    }

    @Attributes.NoTracking()
    protected previousItem: ParticipantAwardLookupBase | null = null;


    public get sameParticipant() {
        return this.previousItem?.participantId === this.participantId;
    }

    public get sameAward() {
        return this.previousItem?.participantOfferId === this.participantOfferId;
    }

    @Attributes.NoTracking()
    public sameParticipantRowCount = 1;

    @Attributes.NoTracking()
    public sameAwardRowCount = 1;

    protected setRootItemSameParticipantRowCounts(count: number) {
        if (this.sameParticipant) {
            // same as parent, set me to 0 and
            this.sameParticipantRowCount = 0;
            this.previousItem!.setRootItemSameParticipantRowCounts(++count);
        } else {
            // set me
            this.sameParticipantRowCount = count;
        }
    }

    protected setRootItemSameAwardRowCounts(count: number) {
        if (this.sameAward) {
            // same as parent, set me to 0 and
            this.sameAwardRowCount = 0;
            this.previousItem!.setRootItemSameAwardRowCounts(++count);
        } else {
            // set me
            this.sameAwardRowCount = count;
        }
    }

    @Attributes.NoTracking()
    public isSelected = false;

    @Attributes.NoTracking()
    private _offeredToDefaultExchangeRate: Transactions.ExchangeRateLookup | undefined;

    @Attributes.NoTracking()
    private _optionToOfferedExchangeRate: Transactions.ExchangeRateLookup | undefined;

    @Attributes.NoTracking()
    private _optionInstrumentCurrentPrice: Transactions.InstrumentPriceLookup | undefined;

    @Attributes.NoTracking()
    private _offeredInstrumentCurrentPrice: Transactions.InstrumentPriceLookup | undefined;

    @Attributes.NoTracking()
    public defaultCurrencyCode = "";

    public get offeredToDefaultExchangeRateObject() {
        return this._offeredToDefaultExchangeRate;
    }

    public get offeredToDefaultExchangeRate() {
        return this._offeredToDefaultExchangeRate?.rate ?? 0;
    }

    public get optionToOfferedExchangeRateObject() {
        return this._optionToOfferedExchangeRate;
    }

    public get optionToOfferedExchangeRate() {
        return this._optionToOfferedExchangeRate?.rate ?? 0;
    }

    public get optionInstrumentCurrentPrice() {
        if (this.optionInstrumentType === InstrumentType.Cash) {
            return 1;
        } else {
            return this._optionInstrumentCurrentPrice?.price ?? 0;
        }
    }

    public get offeredInstrumentCurrentPrice() {
        if (this.offeredInstrumentType === InstrumentType.Cash) {
            return 1;
        } else {
            return this._offeredInstrumentCurrentPrice?.price ?? 0;
        }
    }

    public get totalOfferedValue() {
        return this.offeredUnits * this.offeredInstrumentCurrentPrice;
    }

    @Attributes.Display("Offered Value")
    public get optionOfferedValue() {
        return this.totalOfferedValue * this.optionPercentage;
    }

    public get optionDefaultUnits() {
        if (this.offeredInstrumentCode === this.optionInstrumentCode) {
            return this.offeredUnits * this.optionPercentage;
        }
        else {
            const calculatedValue = this.optionOfferedValue / (this.getOfferedAwardPrice() * this.optionToOfferedExchangeRate);
            switch (this.vestingRounding) {
                case RoundingType.NoRounding:
                    return calculatedValue;
                case RoundingType.Round:
                    return Math.round(calculatedValue);
                case RoundingType.Floor:
                    return Math.floor(calculatedValue);
                case RoundingType.Ceiling:
                    return Math.ceil(calculatedValue);
                default:
                    return Math.floor(calculatedValue);
            }          
        }
    }

    private getOfferedAwardPrice() {
        return this.optionAwardPrice === 0 ? (this.optionMarketPriceAtAwardDate ?? 0) : this.optionAwardPrice;
    }

    public get awardDefaultCurrencyExpectedValue() {
        return this.optionDefaultUnits * (this.optionExpectedPrice ?? 0) * this.optionToOfferedExchangeRate * this.offeredToDefaultExchangeRate;
    }

    public get awardDefaultCurrencyFaceValue() {
        return this.offeredInstrumentType === InstrumentType.Cash ?
            this.offeredUnits :
            this.optionDefaultUnits * (this.optionMarketPriceAtAwardDate ?? 0) * this.optionToOfferedExchangeRate * this.offeredToDefaultExchangeRate;
    }

    public get awardCost() {
        return this.optionDefaultUnits * this.optionAwardPrice;
    }

    public get currentGrossValue() {
        return this.optionDefaultUnits * this.optionInstrumentCurrentPrice;
    }

    @Attributes.NoTracking()
    public hasSetup = false;

    public setup(
        previousItem: ParticipantAwardLookupBase | null,
        defaultCurrencyCode: string,
        offeredToDefaultExchangeRate: Transactions.ExchangeRateLookup | undefined,
        optionToAwardedExchangeRate: Transactions.ExchangeRateLookup | undefined,
        optionInstrumentCurrentPrice: Transactions.InstrumentPriceLookup | undefined,
        offeredInstrumentCurrentPrice: Transactions.InstrumentPriceLookup | undefined) {
        this.previousItem = previousItem;

        this.setRootItemSameAwardRowCounts(1);
        this.setRootItemSameParticipantRowCounts(1);

        this.defaultCurrencyCode = defaultCurrencyCode;
        this._offeredToDefaultExchangeRate = offeredToDefaultExchangeRate;
        this._optionToOfferedExchangeRate = optionToAwardedExchangeRate;
        this._optionInstrumentCurrentPrice = optionInstrumentCurrentPrice;
        this._offeredInstrumentCurrentPrice = offeredInstrumentCurrentPrice;

        this.hasSetup = true;
    }

    public usesRate(rate: Transactions.ExchangeRateLookup) {
        return ((this.optionCurrencyCode === rate.fromCurrencyCode && this.offeredCurrencyCode === rate.toCurrencyCode)
            || (this.offeredCurrencyCode === rate.fromCurrencyCode && this.defaultCurrencyCode === rate.toCurrencyCode))
            && this.awardDate.toDateString() === rate.rateDate.toDateString()
    }

    public hasMissingDependencies(column: (keyof ParticipantAwardLookupBase)) {
        return !this.hasDependencies(this.getDependencies(column));
    }

    public getMissingDependencyTip(column: (keyof ParticipantAwardLookupBase), altValueName?: string) {
        const deps = this.getDependencies(column);
        const missingDeps = deps.filter(d => !this.hasDependency(d));

        if (missingDeps.length === 0) {
            return undefined;
        } else {
            let message = `Missing ${missingDeps.map(d => this.getDependencyDescription(d)).join(", ")}`

            if (altValueName) {
                message += `. Showing ${altValueName} instead.`
            }

            return message;
        }
    }

    private getDependencies(column: (keyof ParticipantAwardLookupBase)) {
        switch (column) {
            case "awardDefaultCurrencyExpectedValue":
                return [CalcDependency.ExpectedPrice, CalcDependency.OfferInstrumentPrice, CalcDependency.OptionToOfferedExchangeRate, CalcDependency.OfferedToDefaultExchangeRate];
            case "awardDefaultCurrencyFaceValue":
                return [CalcDependency.MarketPriceAtAwardDate, CalcDependency.OptionToOfferedExchangeRate, CalcDependency.OfferedToDefaultExchangeRate];
            case "optionDefaultUnits":
                return [CalcDependency.OfferInstrumentPrice, CalcDependency.OptionToOfferedExchangeRate];
            case "currentGrossValue":
                return [CalcDependency.OfferInstrumentPrice, CalcDependency.OptionInstrumentPrice, CalcDependency.OptionToOfferedExchangeRate];
            case "awardCost":
                return [CalcDependency.OptionInstrumentPrice, CalcDependency.OptionToOfferedExchangeRate];
            case "offeredUnits":
                return [];
        }

        throw new Error(`Add support for ${column} in ParticipantAwardLookupBase.getDependencies(column)`);
    }

    private getDependencyDescription(dep: CalcDependency): any {
        switch (dep) {
            case CalcDependency.ExpectedPrice:
                return `Expected price`;
            case CalcDependency.MarketPriceAtAwardDate:
                return "Market price at award date";
            case CalcDependency.OfferInstrumentPrice:
                return `Offered ${this.offeredInstrumentCode} price`;
            case CalcDependency.OptionInstrumentPrice:
                return `Option ${this.optionInstrumentCode} price: ${this._optionInstrumentCurrentPrice}`;
            case CalcDependency.OfferedToDefaultExchangeRate:
                return `${this.offeredCurrencyCode} to ${this.defaultCurrencyCode} exchange rate for ${DateUtils.format(this.awardDate, "dd MMM yy")}`;
            case CalcDependency.OptionToOfferedExchangeRate:
                return `${this.optionCurrencyCode} to ${this.offeredCurrencyCode} exchange rate for ${DateUtils.format(this.awardDate, "dd MMM yy")}`;
        }
    }

    private hasDependencies(dependencies: CalcDependency[]) {
        for (var dep of dependencies) {
            if (!this.hasDependency(dep)) {
                return false;
            }
        }
        return true;
    }

    private hasDependency(dependency: CalcDependency) {
        switch (dependency) {
            case CalcDependency.ExpectedPrice:
                return (this.optionExpectedPrice ?? 0) > 0;
            case CalcDependency.MarketPriceAtAwardDate:
                return (this.optionMarketPriceAtAwardDate ?? 0) > 0;
            case CalcDependency.OfferInstrumentPrice:
                return !!this._offeredInstrumentCurrentPrice || this.offeredInstrumentType === InstrumentType.Cash;
            case CalcDependency.OptionInstrumentPrice:
                return !!this._optionInstrumentCurrentPrice || this.optionInstrumentType === InstrumentType.Cash;
            case CalcDependency.OptionToOfferedExchangeRate:
                return !!this._optionToOfferedExchangeRate;
            case CalcDependency.OfferedToDefaultExchangeRate:
                return !!this._offeredToDefaultExchangeRate;
        }
    }
}