import { EnumHelper } from '@singularsystems/neo-core';

export enum QuestionType {
    YesOrNo = 1,
    DropDown = 2,
    ShortText = 3,
    LongText = 4,
}

EnumHelper.decorateEnum(QuestionType, e => {
    e.describe(QuestionType.YesOrNo, "Yes/No");
    e.describe(QuestionType.DropDown, "Drop Down");
    e.describe(QuestionType.ShortText, "Short Text");
    e.describe(QuestionType.LongText, "Long Text");
});