import { Attributes, NeoModel, Utils, Validation, ValueObject } from '@singularsystems/neo-core';

@NeoModel
export default class ApprovalLookupCriteria extends ValueObject {

    public awardPrepId: number = 0;

    public approverParticipantId: string = Utils.emptyGuid();

    @Attributes.Display("Name")
    public participantName: string = "";

    public excludeApproved: boolean = false;

    public excludeDeclined: boolean = false;

    public excludePending: boolean = false;

    public includeSubmitted: boolean = false;

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        return "Approval Lookup Criteria";
    }
}