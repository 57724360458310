import { Attributes, ModelBase } from '@singularsystems/neo-core';

export default class AuditLookup extends ModelBase {

    public id: number = 0;
    
    @Attributes.Display("Type")
    public changeAction: string = "";
    
    @Attributes.Display("By")
    public changedBy: string = "";

    @Attributes.Display("On")
    @Attributes.Date()
    public changedOn!: Date;
}