import { Data, Model, Utils } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../AwardsTypes';
import MyAwardLookup from '../../Models/ParticipantOffers/Queries/MyAwards/MyAwardLookup';
import MyAwardInfoLookup from '../../Models/ParticipantOffers/Queries/MyAwards/MyAwardInfoLookup';
import MyAwardLookupCriteria from '../../Models/ParticipantOffers/Queries/MyAwards/MyAwardLookupCriteria';
import MyAwardLookupIncludeOptions from '../../Models/ParticipantOffers/Queries/MyAwards/MyAwardLookupIncludeOptions';
import MyAwardDocumentLookup from '../../Models/ParticipantOffers/Queries/MyAwards/MyAwardDocumentLookup';

export interface IMyAwardsApiClient {

    /**
     * Will get the approval summary for the current logged in participant
     * @param pageRequest The page request.
     * @returns The approval summary
     */
    getAcceptedAwards(pageRequest: Model.PartialPlainNonTrackedObject<Data.PageRequest<MyAwardLookupCriteria>>, includeOptions: MyAwardLookupIncludeOptions | null): AxiosPromise<Data.PageResult<Model.PlainObject<MyAwardLookup>>>;

    /** 
     * Gets a list of document sets for the current participant.
     * @returns List of document sets.
     */
    participantDocumentLookupList(): AxiosPromise<Array<Model.PlainObject<MyAwardDocumentLookup>>>;

    /**
     * Will get the user's list of pending awards.
     * @returns The approval summary
     */
    getPendingAwardInfo(): AxiosPromise<Model.PlainObject<MyAwardInfoLookup>>;

    /**
     * Will get the user's list of pending awards.
     * @param pageRequest The page request.
     * @param includeDocuments A value indicating whether document info should be included
     * @param includeQuestionnaires A value indicating whether questionnaire info should be included
     * @returns The approval summary
     */
    getPendingAwards(pageRequest: Model.PartialPlainNonTrackedObject<Data.PageRequest<MyAwardLookupCriteria>>, includeOptions: MyAwardLookupIncludeOptions | null): AxiosPromise<Data.PageResult<Model.PlainObject<MyAwardLookup>>>;

    // Client only properties / methods
}

@injectable()
export default class MyAwardsApiClient extends Data.ApiClientBase implements IMyAwardsApiClient {

    constructor (config = AppService.get(Types.Shared.Config)) {
        super(`${config.AwardsApi.ApiPath}/my-awards`);
    }

    public getAcceptedAwards(pageRequest: Model.PartialPlainNonTrackedObject<Data.PageRequest<MyAwardLookupCriteria>>, includeOptions: MyAwardLookupIncludeOptions | null): AxiosPromise<Data.PageResult<Model.PlainObject<MyAwardLookup>>> {
        const combinedObjects = { pageRequest: pageRequest, includeOptions: includeOptions };
        return this.axios.get(`${this.apiPath}/accepted?${Utils.getQueryString(combinedObjects)}`);
    }

    public participantDocumentLookupList(): AxiosPromise<Array<Model.PlainObject<MyAwardDocumentLookup>>> {
        return this.axios.get(`${this.apiPath}/participant-documents`);
    }

    public getPendingAwardInfo(): AxiosPromise<Model.PlainObject<MyAwardInfoLookup>> {
        return this.axios.get(`${this.apiPath}/pending/info`);
    }

    public getPendingAwards(pageRequest: Model.PartialPlainNonTrackedObject<Data.PageRequest<MyAwardLookupCriteria>>, includeOptions: MyAwardLookupIncludeOptions | null = null): AxiosPromise<Data.PageResult<Model.PlainObject<MyAwardLookup>>> {
        const combinedObjects = { pageRequest: pageRequest, includeOptions: includeOptions };
        return this.axios.get(`${this.apiPath}/pending?${Utils.getQueryString(combinedObjects)}`);
    }

    // Client only properties / methods
}