import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../../CommonIdentityTypes';
import StartProxySessionCommand from '../../../Models/ProxyUsers/Commands/StartProxySessionCommand';
import EndProxySessionCommand from '../../../Models/ProxyUsers/Commands/EndProxySessionCommand';

export interface IProxySessionApiClient {

    /**
     * Will create/configure a proxy user
     * @param command The configure proxy user command
     * @returns A task awaiting the configure proxy user operation
     */
    start(command: Model.PartialPlainObject<StartProxySessionCommand>): AxiosPromise<any>;

    /** 
     * Will create/configure a proxy user
     * @param command The configure proxy user command
     * @returns A task awaiting the configure proxy user operation
     */
    end(command: Model.PartialPlainObject<EndProxySessionCommand>): AxiosPromise<string>;

    // Client only properties / methods
}

@injectable()
export default class ProxySessionApiClient extends Data.ApiClientBase implements IProxySessionApiClient {

    constructor (config = AppService.get(Types.Shared.Config)) {
        super(`${config.IdentityServerApi.ApiPath}/ProxySession`);
    }

    public start(command: Model.PartialPlainObject<StartProxySessionCommand>): AxiosPromise<any> {
        return this.axios.post(`${this.apiPath}/start`, command);
    }

    public end(command: Model.PartialPlainObject<EndProxySessionCommand>): AxiosPromise<string> {
        return this.axios.post(`${this.apiPath}/end`, command);
    }

    // Client only properties / methods
}