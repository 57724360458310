import { Data } from "@singularsystems/neo-core";

import { AppService, Types } from '../../AwardsTypes';
import MyAwardLookup from "../../Models/ParticipantOffers/Queries/MyAwards/MyAwardLookup";
import MyAwardLookupCriteria from "../../Models/ParticipantOffers/Queries/MyAwards/MyAwardLookupCriteria";
import MyAwardLookupIncludeOptions from "../../Models/ParticipantOffers/Queries/MyAwards/MyAwardLookupIncludeOptions";
import AwardsVMBase from "../Base/AwardsVMBase";

export default abstract class MyAwardsVMBase extends AwardsVMBase<MyAwardLookup> {

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        public myAwardInfoTaskRunner = AppService.get(Types.Neo.TaskRunner),
        protected myAwardsApiClient = AppService.get(Types.Awards.ApiClients.MyAwardsApiClient)) {

        super(taskRunner);
    }

    protected pendingAwardsCriteria = new MyAwardLookupCriteria()

    protected get pendingAwardsIncludes() {
        return { includeDocuments: false, includeQuestionnaires: false, includeAwardConfig: false } as MyAwardLookupIncludeOptions;
    }

    public pendingAwardsPageManager = new Data.PageManager(this.pendingAwardsCriteria, MyAwardLookup, (request) => this.myAwardsApiClient.getPendingAwards(request, this.pendingAwardsIncludes), {
        pageSize: 6,
        initialTaskRunner: this.taskRunner,
        sortBy: "awardDate",
        sortAscending: false,
        afterFetch: (data) => this.setupPendingAwardsData(data)
    });

    protected pageLoaded = false;

    protected async setupPendingAwardsData(data: MyAwardLookup[]) {
        await this.setupData(data);
        this.pageLoaded = true;
    }
}
