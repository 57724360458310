import { Attributes, LookupBase } from "@singularsystems/neo-core";
import CalculationGroup from "../Portfolio/Calculation/CalculationGroup";
import PortfolioBalanceLookup from "../Portfolio/PortfolioBalanceLookup";
import LinkedTradeRequestsCommand from "./Commands/LinkedTradeRequestsCommand";
import TradeRequestCommand from "./Commands/TradeRequestCommand";
import TradeRequestCommandBase from "./Commands/TradeRequestCommandBase";

export default class BindableTradeInfo extends LookupBase {

    constructor(trade: TradeRequestCommand | LinkedTradeRequestsCommand) {
        super();
        this.makeObservable();

        if ((trade as TradeRequestCommand).calcObject) {
            this.trade = trade as TradeRequestCommand;
            this.tradeBase = this.trade;
        }
        else {
            const linkedTrade = trade as LinkedTradeRequestsCommand;
            this.trade = linkedTrade.tradeRequests[0];
            this.tradeBase = linkedTrade;

            this.linkedTrades = linkedTrade.tradeRequests.slice(1).map(tr => new BindableTradeInfo(tr));
        }

        this.calcObject = this.trade.calcObject;
        this.trancheBalance = this.calcObject.trancheBalance;
    }

    public readonly trade: TradeRequestCommand;
    public readonly tradeBase: TradeRequestCommandBase;

    public readonly linkedTrades: BindableTradeInfo[] = [];

    @Attributes.NoTracking()
    public readonly calcObject: CalculationGroup;

    @Attributes.NoTracking()
    public readonly trancheBalance: PortfolioBalanceLookup;

    public get incentiveSchemeName() {
        return this.trancheBalance.incentiveScheme.incentiveSchemeName;
    }

    @Attributes.Float()
    public get buyValue() {
        return this.buyQuantity * this.trancheBalance.instrumentPrice;
    }

    @Attributes.Integer()
    public get sellQuantity() {
        return this.trade.sellQuantity;
    }

    @Attributes.Integer()
    public get buyQuantity() {
        return this.trade.buyQuantity;
    }

    @Attributes.Float()
    public get sellPrice() {
        return this.calcObject.effectiveSellPrice;
    }

    @Attributes.Float()
    public get tradeProceeds() {
        return this.calcObject.tradeProceeds
    }

    @Attributes.Float()
    public get proportionalAwardDebt() {
        return this.calcObject.proportionalAwardDebt;
    }

    @Attributes.Float()
    public get tradingCosts() {
        return this.calcObject.tradingCosts;
    }

    @Attributes.Float()
    public get netProceeds() {
        return this.calcObject.netProceeds;
    }
}