import * as React from "react";
import { AppService, AppTypes } from '../../Services/AppService';

class OidcLoginRedirect extends React.Component {

    public async componentDidMount() {

        const result = await AppService.get(AppTypes.Neo.Security.AuthenticationService).tryCompleteSignIn();
        if (result.success) {

            await AppService.get(AppTypes.Neo.Security.AuthorisationService).loadRoles();

            const navigation = AppService.get(AppTypes.Neo.Routing.NavigationHelper);

            if (result.redirectPath) {
                navigation.navigateInternal(result.redirectPath, true);
            } else {
                navigation.navigateInternal("/", true);
            }
        } 
    }

    public render() {
        return <div>Signing in...</div>
    }
}

export default OidcLoginRedirect;