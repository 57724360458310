import { AppService, AppTypes } from '../../App/Services/AppService';

import { AppServices } from '@singularsystems/neo-core';
import { IApprovalsCommandApiClient } from './ApiClients/Approvals/ApprovalsCommandApiClient';
import { IParticipantAcceptanceCommandApiClient } from './ApiClients/ParticipantOffers/ParticipantAcceptanceCommandApiClient';
import { IParticipantOffersQueryApiClient } from './ApiClients/ParticipantOffers/ParticipantOffersQueryApiClient';
import { Types as CommonTypes } from '../Common/CommonAwardsTypes';
import { IAnswerQuestionnaireQueryApiClient } from './ApiClients/Questionnaires/AnswerQuestionnaireQueryApiClient';
import { IAnswerQuestionnaireCommandApiClient } from './ApiClients/Questionnaires/AnswerQuestionnaireCommandApiClient';
import { IAwardDocumentsCommandApiClient } from '../Common/ApiClients/AwardDocuments/AwardDocumentsCommandApiClient';
import { AwardsSharedTypes } from './AwardsSharedTypes';

// Merge the shared type symbols with this module's type symbols
const Types = {
    ...AppTypes,
    Awards: {
        ApiClients: {
            ...CommonTypes.ApiClients,
            ...AwardsSharedTypes.ApiClients,
            ApprovalsCommandApiClient: new AppServices.ServiceIdentifier<IApprovalsCommandApiClient>("Awards.ApiClients.ApprovalsCommandApiClient"),
            ParticipantOffersQueryApiClient: new AppServices.ServiceIdentifier<IParticipantOffersQueryApiClient>("Awards.ApiClients.ParticipantOffersQueryApiClient"),
            ParticipantAcceptanceCommandApiClient: new AppServices.ServiceIdentifier<IParticipantAcceptanceCommandApiClient>("Awards.ApiClients.ParticipantAcceptanceCommandApiClient"),
            AnswerQuestionnaireQueryApiClient: new AppServices.ServiceIdentifier<IAnswerQuestionnaireQueryApiClient>("Awards.ApiClients.AnswerQuestionnaireQueryApiClient"),
            AnswerQuestionnaireCommandApiClient: new AppServices.ServiceIdentifier<IAnswerQuestionnaireCommandApiClient>("Awards.ApiClients.AnswerQuestionnaireCommandApiClient"),
            AwardDocumentCommandApiClient: new AppServices.ServiceIdentifier<IAwardDocumentsCommandApiClient>("Awards.ApiClients.AwardDocumentCommandApiClient")              
        },
        Services : {
            ...AwardsSharedTypes.Services,
            ...CommonTypes.Services
        }
    }
};

export { AppService, Types }