import { Data, Model, Utils } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../AllocationManagerTypes';
import AllocationPlanLookupCriteria from '../Models/Queries/AllocationPlanLookupCriteria';
import AllocationPlanLookup from '../Models/Queries/AllocationPlanLookup';

export interface IAllocationPlanQueryApiClient {

    /**
     * Gets a list of Allocation Plans based on criteria.
     * @param pageRequest The page request used with Paging functionality.
     * @returns An Allocation Plan Lookup List.
     */
    getAllocationPlanLookups(pageRequest: Model.PartialPlainNonTrackedObject<Data.PageRequest<AllocationPlanLookupCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<AllocationPlanLookup>>>;

    /**
     * Gets a list of allocation plans active in the last 2 years.
     * @returns List of allocation plan.
     */
    getAllocationPlanList(): AxiosPromise<Array<Model.PlainObject<AllocationPlanLookup>>>;
    // Client only properties / methods
}

@injectable()
export default class AllocationPlanQueryApiClient extends Data.ApiClientBase implements IAllocationPlanQueryApiClient {

    constructor (config = AppService.get(Types.Shared.Config)) {
        super(`${config.AwardsApi.ApiPath}/allocation-plan-query`);
    }

    public getAllocationPlanLookups(pageRequest: Model.PartialPlainNonTrackedObject<Data.PageRequest<AllocationPlanLookupCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<AllocationPlanLookup>>> {
        return this.axios.get(`${this.apiPath}/plans-paged?${Utils.getQueryString(pageRequest)}`);
    }

    public getAllocationPlanList(): AxiosPromise<Array<Model.PlainObject<AllocationPlanLookup>>> {
        return this.axios.get(`${this.apiPath}`);
    }
    // Client only properties / methods
}