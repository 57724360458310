import { AppService, AppTypes } from '../../App/Services/AppService';
import { ClientsSharedTypes } from './ClientsSharedTypes';

// Merge the shared type symbols with this module's type symbols
const Types = { 
    ...AppTypes,
    Clients: {
        ApiClients: {
            ...ClientsSharedTypes.ApiClients,
        },
        Services: {
            ...ClientsSharedTypes.Services
        }
    }
};

export { AppService, Types }