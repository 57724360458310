import { Attributes, LookupBase,  NeoModel } from '@singularsystems/neo-core';
import IncentiveSchemeSeparationConfig from './IncentiveSchemeSeparationConfig';

@NeoModel
export default class IncentiveSchemeSeparationConditionLookup extends LookupBase {

    public incentiveSchemeId: number = 0;

    public separationReasonId: number = 0;

    @Attributes.ChildObject(IncentiveSchemeSeparationConfig)
    public config: IncentiveSchemeSeparationConfig = new IncentiveSchemeSeparationConfig();

    // Client only properties / methods
}