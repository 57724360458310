import React from 'react';
import { observer } from 'mobx-react';
import { Model } from '@singularsystems/neo-core';

@observer
export class LargeCheckBox extends React.Component<{ checked: Model.IPropertyInstance; readOnly?: boolean; }> {

    private getIconName() {
        if (this.props.checked.value === null) {
            return "far fa-minus-square fa-lg fa-fw text-success";
        }
        else if (this.props.checked.value) {
            return "far fa-check-square fa-lg fa-fw text-success";
        }
        else {
            return "far fa-square fa-lg fa-fw text-dark text-success";
        }
    }

    private setNextValue() {
        if (!this.props.readOnly) {
            if (!this.props.checked.value) {
                this.props.checked.value = true;
            }
            else {
                this.props.checked.value = false;
            }
        }
    }

    render() {
        return <div className={`large-check-box${this.props.readOnly ? "" : " selectable"}`} onClick={() => this.setNextValue()}>
            <i className={this.getIconName()} />
        </div>;
    }
}
