import { Data, Model, Utils } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../AllocationManagerTypes';
import AllocationParticipantLookupCriteria from '../Models/Queries/AllocationParticipantLookupCriteria';
import AllocationParticipantLookup from '../Models/Queries/AllocationParticipantLookup';
import AllocationParticipantHistoryLookup from '../Models/Queries/AllocationParticipantHistoryLookup';
import AMIncentiveGroupUsedBudgetLookup from '../Models/Queries/AMIncentiveGroupUsedBudgetLookup';

export interface IAllocationParticipantQueryApiClient {

  /**
   * Gets a list of Allocation Participants for the currently logged in Allocation Manager
   * @param pageRequest The Page Request
   * @returns A  representing the result of the asynchronous operation.
   */
  getAllocationParticipantsPaged(pageRequest: Model.PartialPlainNonTrackedObject<Data.PageRequest<AllocationParticipantLookupCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<AllocationParticipantLookup>>>;

  /**
   * Gets an Allocation Participant History Object containing all previous allocation data for a particular Participant
   * @param allocationParticipantId The Allocation Participant Id used to fetch the History.
   * @returns An Allocation Participant History Lookup
   */
  getAllocationParticipantHistory(allocationParticipantId: number): AxiosPromise<Model.PlainObject<AllocationParticipantHistoryLookup>>;

  /**
   * Returns all the GradeIds for participants since they only want to see the GradeIds present in the dataset.
   * @param allocationParticipantId The Allocation Participant Id used to fetch the GradeIds.
   * @returns List of GradeIds.
   */
  getParticipantGradeIds(allocationParticipantId: string): AxiosPromise<number[]>;

  /** 
   * Gets a list of the remaining budget amounts per Incentive Group for the currently logged Allocation Manager
   * @param participantId The Page Request
   * @returns List of AM Incentive Group Remaining Budget Lookups.
   */
  getAMIncentiveGroupUsedBudget(participantId: string): AxiosPromise<Array<Model.PlainObject<AMIncentiveGroupUsedBudgetLookup>>>;

  // Client only properties / methods
}

@injectable()
export default class AllocationParticipantQueryApiClient extends Data.ApiClientBase implements IAllocationParticipantQueryApiClient {

  constructor(config = AppService.get(Types.Shared.Config)) {
    super(`${config.AwardsApi.ApiPath}/allocation-participant-query`);
  }

  public getAllocationParticipantsPaged(pageRequest: Model.PartialPlainNonTrackedObject<Data.PageRequest<AllocationParticipantLookupCriteria>>): AxiosPromise<Data.PageResult<Model.PlainObject<AllocationParticipantLookup>>> {
    return this.axios.get(`${this.apiPath}/participants-paged?${Utils.getQueryString(pageRequest)}`);
  }

  public getAllocationParticipantHistory(allocationParticipantId: number): AxiosPromise<Model.PlainObject<AllocationParticipantHistoryLookup>> {
    return this.axios.get(`${this.apiPath}/history?allocationParticipantId=${allocationParticipantId}`);
  }

  public getParticipantGradeIds(allocationParticipantId: string): AxiosPromise<number[]> {
    return this.axios.get(`${this.apiPath}/grades?allocationParticipantId=${encodeURIComponent(allocationParticipantId)}`);
  }

  public getAMIncentiveGroupUsedBudget(participantId: string): AxiosPromise<Array<Model.PlainObject<AMIncentiveGroupUsedBudgetLookup>>> {
    return this.axios.get(`${this.apiPath}/participants-budgets?participantId=${encodeURIComponent(participantId)}`);
  }

  // Client only properties / methods
}