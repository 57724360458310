import { NeoModel, Validation, ValueObject } from '@singularsystems/neo-core';
import { InitialApprovalStatus } from '../Enums/InitialApprovalStatus';

@NeoModel
export default class AwardPrepApprovalConfig extends ValueObject {

    public reasonRequiredForDecline: boolean = true;

    public approversCanEditOfferedUnits: boolean = true;

    public approversCanChangeStatus: boolean = true;

    public initialApprovalStatus: InitialApprovalStatus = InitialApprovalStatus.Pending;

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);

        rules.failWhen(c => c.initialApprovalStatus !== InitialApprovalStatus.Approved && !c.approversCanChangeStatus , "Initial status must be approved if approver's cannot change the status");
    }

    public toString(): string {
        return "Approvals Config";
    }
}