import { Attributes, LookupBase } from '@singularsystems/neo-core';

export default class AwardCostCashTransactionLookup extends LookupBase {

    constructor() {
        super();
        this.makeBindable();
    }

    public readonly transactionType: string = "";

    @Attributes.Date()
    public readonly effectiveDate: Date = new Date();

    @Attributes.Float()
    public amount: number = 0;

    // Client only properties / methods
}