import { AppServices } from '@singularsystems/neo-core';

import { Types as CommonTypes } from '../Common/CommonAwardsTypes';
import { IMyAwardsApiClient } from './ApiClients/ParticipantOffers/MyAwardsApiClient';
import { IAwardDocumentsQueryApiClient } from '../Common/ApiClients/AwardDocuments/AwardDocumentsQueryApiClient';
import AwardsDataCache from './Services/AwardsDataCache';
import { ICatalogueQueryApiClient } from '../Common/ApiClients/CatalogueQueryApiClient';

// Symbols to expose outside of this module
export const AwardsSharedTypes = {
    ApiClients: {
        ApprovalsQueryApiClient: CommonTypes.ApiClients.ApprovalsQueryApiClient,
        MyAwardsApiClient: new AppServices.ServiceIdentifier<IMyAwardsApiClient>("Awards.ApiClients.MyAwardsApiClient"),
        AwardDocumentsQueryApiClient: new AppServices.ServiceIdentifier<IAwardDocumentsQueryApiClient>("Awards.ApiClients.AwardDocumentsQueryApiClient"),
        CatalogueQueryApiClient: new AppServices.ServiceIdentifier<ICatalogueQueryApiClient>("Awards.ApiClients.CatalogueQueryApiClient"),
    },
    Services: {
        AwardsDataCache: new AppServices.ServiceIdentifier<AwardsDataCache>("Awards.Services.AwardsDataCache"),        
    }
}