import { injectable } from "inversify";

import { AppService, Types } from '../CommonIdentityTypes';
import StartProxySessionCommand from "../Models/ProxyUsers/Commands/StartProxySessionCommand";
import { NotificationDuration } from '../../../App/Models/Enums/NotificationDuration';
import EndProxySessionCommand from '../Models/ProxyUsers/Commands/EndProxySessionCommand';
import { StringUtils } from '@singularsystems/neo-core';
import { ProxyRefreshRoute } from "../IdentityRoutes";

@injectable()
export default class ProxySessionService {
    
    public failMessage = "";

    public constructor(
        private authenticationService = AppService.get(Types.Shared.Services.STAuthenticationService),
        private sessionApiClient = AppService.get(Types.Identity.ApiClients.ProxySessionApiClient),
        protected navigation = AppService.get(Types.Neo.Routing.NavigationHelper),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications), ) {

    }

    public get user() {
        return this.authenticationService.user;
    }

    public async startProxySession(participantId: string, participantName: string) {
        if (this.user) {
            var cmd = new StartProxySessionCommand();
            cmd.applicationUserId = this.user.id;
            cmd.participantId = participantId;
            cmd.participantName = participantName;
            cmd.returnToAdminUrl = window.location.href;

            this.failMessage = "";
            try {
                await this.sessionApiClient.start(cmd.toJSObject());
            }
            catch (ex) {
                // worth doing a silent sign in and refresh anyway
                this.failMessage = (ex as any).toString();
            }

            await this.authenticationService.signinSilent();

            if (this.failMessage !== "") {
                this.notifications.addDanger("Proxy Session Failed", `Reloading page...`, NotificationDuration.Short);
            } else {
                this.notifications.addSuccess("Proxy Session Started", `You are now performing actions on behalf of ${participantName}`, NotificationDuration.Short);
            }
            
        } else {
            this.notifications.addDanger("Error", `No user found...`, NotificationDuration.Short);
        }
    }

    public async endProxySession() {
        if (this.user) {
            var cmd = new EndProxySessionCommand();
            cmd.applicationUserId = this.user.id;

            const result = await this.sessionApiClient.end(cmd.toJSObject());
            const returnUrl = result.data;
            await this.authenticationService.signinSilent();

            this.notifications.addSuccess("Proxy Session Ended", "You are now back to yourself.", NotificationDuration.Standard);

            this.reloadView(returnUrl);

            return returnUrl;
        } else {
            this.notifications.addDanger("Error", `No user found...`, NotificationDuration.Short);
            return "";
        }
    }

    public async silentSignIn() {
        await this.authenticationService.signinSilent();
    }

    public reloadView(url = "") {
        if (StringUtils.isNullOrWhitespace(url)) {
            const currentPath = this.navigation.getCurrentViewPath();
            this.navigation.navigateInternal(ProxyRefreshRoute.path!);
            this.navigation.navigateInternal(currentPath); 
           
        } else {
            window.location.href = url;
        }
    }
}