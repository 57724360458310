import AwardParticipantOfferTotalLookup from "../Models/ParticipantOffers/Queries/AwardParticipantOfferTotalLookup";
import { NeoModel, ModelBase, List, Model } from "@singularsystems/neo-core";
import { injectable } from 'inversify';

export interface IOfferRecord {
    offeredInstrumentId: number;
    offeredInstrumentDisplay: string;
    offeredUnits: number;
}

@injectable()
@NeoModel
export default class OfferTotalsService extends ModelBase {
    
    private offerTotals = new List(AwardParticipantOfferTotalLookup);
    private pageTotals = new List(AwardParticipantOfferTotalLookup);
    private baseTotals = new List(AwardParticipantOfferTotalLookup);

    private offerTotalsLoaded = false;
    private calculatePageOnLoad: IOfferRecord[] | null = null;

    public includeInTotalPredicate?: (item: IOfferRecord) => boolean;

    public setOfferTotals(list: Array<Model.PlainObject<AwardParticipantOfferTotalLookup>>) {
        this.offerTotals.set(list);
        this.offerTotalsLoaded = true;

        if (this.calculatePageOnLoad) {
            this.recalculatePageTotals(this.calculatePageOnLoad);
            this.calculatePageOnLoad = null;
        }
    }

    public reset() {
        this.offerTotalsLoaded = false;
        this.calculatePageOnLoad = null;
        this.offerTotals.set([]);
        this.pageTotals.set([]);
        this.baseTotals.set([]);
    }

    public getOfferTotals() {
        return this.offerTotals;
    }
    
    public recalculatePageTotals(items: IOfferRecord[]) {
        if (!this.offerTotalsLoaded) {
            this.calculatePageOnLoad = items;
        } else {
            this.calculatePageTotals(items);
            this.calculateBaseTotals();
        }
    }
    
    public recalculateOfferTotals(items: IOfferRecord[]) {
        // recalculate the offer totals based on the base totals and current page totals
        // 1st recalculate the page totals
        this.calculatePageTotals(items);

        // re-add page totals to base totals, then replace the offer totals
        const newTotals = this.copyTotals(this.baseTotals);
        for (const pageTotal of this.pageTotals) {
            const findTotal = pageTotal;
            let offerTotal = newTotals.find(total => total.instrumentId === findTotal.instrumentId);
            if (!offerTotal) {
                // must be a new instrument, so add it
                offerTotal = newTotals.addNew();
                offerTotal.mapFrom(pageTotal);
            }
            offerTotal.offerTotal += pageTotal.offerTotal;
        }

        var totalsToRemove = newTotals.filter(total => total.offerTotal === 0);
        for (const offerTotal of totalsToRemove) {
            newTotals.removeWithoutTracking(offerTotal);
        }

        this.offerTotals = newTotals;
    }

    private calculatePageTotals(items: IOfferRecord[]) {
        this.pageTotals = new List(AwardParticipantOfferTotalLookup);
        for (var item of items) {
            const findItem = item;
            let total = this.pageTotals.find(total => total.instrumentId === findItem.offeredInstrumentId);
            if (!total) {
                total = this.pageTotals.addNew();
                total.instrumentId = item.offeredInstrumentId;
                total.instrumentDisplay = item.offeredInstrumentDisplay;
            }
            if (!this.includeInTotalPredicate || this.includeInTotalPredicate(item)) {
                total.offerTotal += item.offeredUnits;
            }
        }
    }

    private calculateBaseTotals() {
        // set from offer
        this.baseTotals = this.copyTotals(this.offerTotals);
        // now subtract the page totals
        for (var pageTotal of this.pageTotals) {
            for (var baseTotal of this.baseTotals) {
                if (pageTotal.instrumentId === baseTotal.instrumentId) {
                    baseTotal.offerTotal -= pageTotal.offerTotal;
                }
            }
        }
    }

    private copyTotals(items: List<AwardParticipantOfferTotalLookup>) {
        var newTotals = new List(AwardParticipantOfferTotalLookup);
        for (const item of items) {
            const newTotal = newTotals.addNew();
            newTotal.mapFrom(item);
        }
        return newTotals;
    }
}