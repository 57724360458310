import { Neo } from '@singularsystems/neo-react';
import React from 'react';

interface IInfoIconProps {
    text?: any;
}

export default class InfoIcon extends React.Component<IInfoIconProps> {

    public render() {
        return (
            <Neo.Tooltip content={this.props.text} position="right" className="background-secondary" inline>
                <span className="info-icon">
                    <i className="fa fa-info" />
                </span>
            </Neo.Tooltip>
        )
    }
}