import { ModelBase, NeoModel } from '@singularsystems/neo-core';
import { injectable } from 'inversify';

@injectable()
@NeoModel
export default class AuthorisationService extends ModelBase {

    public hasRole(roleName: string) : boolean {
        return true;
    }

    public loadRoles() : Promise<void> {
        return Promise.resolve();
    }
}