import { injectable } from 'inversify';
import { Routing as NeoRouting } from '@singularsystems/neo-core';
import { Routing } from '@singularsystems/neo-react';

import { AppService, AppTypes } from './AppService';

import NotFound from '../Components/Application/404NotFound';
import LoggedOut from '../Views/Security/LoggedOut';
import OidcLoginRedirect from '../Views/Security/OidcLoginRedirect';
import RegisterView from '../Views/Security/RegisterView';
import SilentSignInView from '../Common/Views/SilentSignInView';

import { ChangePasswordRoute, IdentityPureRoutes, IdentityCommonRoutes } from '../../Identity/ParticipantsApp/IdentityModule';
import { AwardsPureRoutes, MyAwardsRoute, ReleaseRoute } from '../../Awards/ParticipantsApp/AwardsModule';
import { PortfolioViewRoute, TradeHistoryRoute, BrokerDetailsRoute, PureRoutes as TransactionsPureRoutes } from '../../Transactions/ParticipantsApp/TransactionsModule';
import { ContactDetailsRoute, SecondmentsRoute, PureRoutes as ParticipantsPureRoutes, DocumentsRoute } from '../../Participants/ParticipantsApp/ParticipantsModule';
import { ContactUsRoute, PureRoutes as ClientsPureRoutes } from '../../Clients/Participants/ClientsModule';
import { AllocationManagerRoute } from '../../Awards/AllocationManager/ParticipantsApp/AllocationManagerModule';
import Home from '../Views/Home';
import AllocationInformationView from '../../Awards/AllocationManager/ParticipantsApp/Views/AllocationInformationView';
import { Config } from '../Models/Config/Config';

interface IRouteExtensions {
    requiresParticipant?: boolean;
}

export interface IAppRoute extends NeoRouting.IRoute, IRouteExtensions {
    path: string;
    component: any;
}

export interface IAppMenuItem extends NeoRouting.IMenuRoute, IRouteExtensions {
    showMenuItem?: () => boolean;
}

export const SilentSignInRoute = {
    path: "/silentsignin", component: SilentSignInView, allowAnonymous: false
};

@injectable()
export class RouteService {
    private routeTree: NeoRouting.IMenuRoute[] = [];
    private pureRoutes: NeoRouting.IRoute[] = [];
    private routeProvider: Routing.RouteProvider;

    constructor(private config = AppService.get(AppTypes.Shared.Config)) {
        this.createRouteTree();
        this.createPureRoutes();
        this.routeProvider = new Routing.RouteProvider(this.routeTree, this.pureRoutes, NotFound);
    }

    public readonly registerRoute = { path: "/register", component: RegisterView, allowAnonymous: true };

    /**
     * Gets the routes provider
     */
    public get routes(): Routing.RouteProvider {
        return this.routeProvider;
    }

    /**
     * Create the menu routes tree
     */
    private createRouteTree() {
        this.routeTree = [
            {
                path: "/", name: "Home", icon: "home", component: Home
            },
            {
                name: "Awards", icon: "award", children: [
                    ReleaseRoute,
                    MyAwardsRoute,
                    AllocationManagerRoute
                ]
            },
            new Routing.MenuRoute(PortfolioViewRoute, {
                name: "Portfolio", icon: "chart-line", children: [
                    new Routing.MenuRoute(PortfolioViewRoute, { name: "Portfolio Details" }),
                    new Routing.MenuRoute(TradeHistoryRoute, { name: "Trade History" }),
                    MyAwardsRoute,
                ]
            }),
            new Routing.MenuRoute(ContactDetailsRoute, {
                name: "Profile", icon: "user", children: [
                    new Routing.MenuRoute(DocumentsRoute, { name: "My Documents" }),
                    new Routing.MenuRoute(ContactDetailsRoute, { name: "Contact details" }),
                    new Routing.MenuRoute(BrokerDetailsRoute, { name: "Broker account details" }),
                    new Routing.MenuRoute(SecondmentsRoute, { name: "Global locations" }),
                    new Routing.MenuRoute(ChangePasswordRoute, { name: "Change password" }),
                    IdentityCommonRoutes.TermsAndConditionsRoute
                ]
            }),
            new Routing.MenuRoute(ContactUsRoute, { name: "Contact us", icon: "far-address-book" })
        ];        
    }

    /**
     * Create the pure routes list
     */
    private createPureRoutes() {
        this.pureRoutes = [
            {
                path: Config.loginRedirectRoute, component: OidcLoginRedirect, allowAnonymous: true
            },
            {
                path: "/loggedout", component: LoggedOut, allowAnonymous: true
            },
            {
                path: "/supportandtraining", component: AllocationInformationView, allowAnonymous: false
            },
            SilentSignInRoute,
            ...AwardsPureRoutes,
            ...TransactionsPureRoutes,
            ...AwardsPureRoutes,
            ...IdentityPureRoutes,
            ...ParticipantsPureRoutes,
            ...ClientsPureRoutes
        ]
    };
}