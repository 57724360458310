import { Attributes, LookupBase, Misc, NeoModel } from '@singularsystems/neo-core';

@NeoModel
export default class AwardParticipantOfferTotalLookup extends LookupBase {

    public instrumentId: number = 0;

    @Attributes.Integer()
    public allowedDecimals: number = 0;

    @Attributes.Float()
    public offerTotal: number = 0;

    @Attributes.Float()
    public declinedTotal: number = 0;

    public instrumentDisplay: string = "";

    // Client only properties / methods

    public getNumberFormat(): Misc.NumberFormat {
        if (this.allowedDecimals > 0) {
            return Misc.NumberFormat.Decimals;
        } else {
            return Misc.NumberFormat.NoDecimals;
        }
    }
}