import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, AppTypes } from 'st-app/Services/AppService';

import CreateAwardDocumentSetCommand from '../../Models/AwardDocuments/Commands/CreateAwardDocumentSetCommand';
import UpdateAwardDocumentSetCommand from '../../Models/AwardDocuments/Commands/UpdateAwardDocumentSetCommand';
import CreateAwardDocumentCommand from '../../Models/AwardDocuments/Commands/CreateAwardDocumentCommand';
import UpdateAwardDocumentCommand from '../../Models/AwardDocuments/Commands/UpdateAwardDocumentCommand';
import DeleteAwardDocumentCommand from '../../Models/AwardDocuments/Commands/DeleteAwardDocumentCommand';
import RespondToParticipantOfferAwardDocumentCommand from '../../Models/AwardDocuments/Commands/RespondToParticipantOfferAwardDocumentCommand';
import DeleteAwardDocumentSetCommand from '../../Models/AwardDocuments/Commands/DeleteAwardDocumentSetCommand';

export interface IAwardDocumentsCommandApiClient {

    /**
     * Will create a new award document with the information provided in the command.
     * @param command The create award command
     * @returns The key of the newly created command
     */
    createAwardDocumentSet(command: Model.PartialPlainObject<CreateAwardDocumentSetCommand>): AxiosPromise<number>;

    /**
     * Will update an existing award document with the information provided in the command.
     * @param command The update award command
     * @returns The key of the existing command
     */
    updateAwardDocumentSet(command: Model.PartialPlainObject<UpdateAwardDocumentSetCommand>): AxiosPromise<any>;

    /**
     * Will delete an existing award document with the information provided in the command.
     * @param command The delete award command
     * @returns Returns true if the document set is deleted, false is the document set was archived
     */
    deleteAwardDocumentSet(command: Model.PartialPlainObject<DeleteAwardDocumentSetCommand>): AxiosPromise<boolean>;

    /**
     * Will create a new award document with the information provided in the command.
     * @param command The create award command
     * @returns The key of the newly created command
     */
    createAwardDocument(command: Model.PartialPlainObject<CreateAwardDocumentCommand>): AxiosPromise<number>;

    /**
     * Will update an existing award document with the information provided in the command.
     * @param command The update award command
     * @returns The key of the existing command
     */
    updateAwardDocument(command: Model.PartialPlainObject<UpdateAwardDocumentCommand>): AxiosPromise<any>;

    /**
     * Will delete an existing award document based on the id provided in the command.
     * @param command The delete award document command
     * @returns The key of the existing command
     */
    deleteAwardDocument(command: Model.PartialPlainObject<DeleteAwardDocumentCommand>): AxiosPromise<any>;

    /**
     * Will update a participant offer award document with the participants response.
     * @param command The respond command
     * @returns The key of the existing command
     */
    respondToParticipantOfferAwardDocument(command: Model.PartialPlainObject<RespondToParticipantOfferAwardDocumentCommand>): AxiosPromise<any>;

    /**
     * Uploads a document for the participant (should include a participantId query parameter).
     * Note: This method returns the url of the api endpoint. It performs no logic.
     * @returns Status.
     */
    getDocumentUploadUrl(awardDocumentSetId: number): string;

    /** 
     * Uploads a document for the participant (should include a participantId query parameter).
     * Note: This method returns the url of the api endpoint. It performs no logic.
     * @returns Status.
     */
    getParticipantDocumentUploadUrl(participantOfferAwardDocumentId: number): string;

    // Client only properties / methods
}

@injectable()
export default class AwardDocumentsCommandApiClient extends Data.ApiClientBase implements IAwardDocumentsCommandApiClient {

    constructor (config = AppService.get(AppTypes.Shared.Config)) {
        super(`${config.AwardsApi.ApiPath}/AwardDocumentsCommand`);
    }

    public createAwardDocumentSet(command: Model.PartialPlainObject<CreateAwardDocumentSetCommand>): AxiosPromise<number> {
        return this.axios.post(`${this.apiPath}/documentSets/create`, command);
    }

    public updateAwardDocumentSet(command: Model.PartialPlainObject<UpdateAwardDocumentSetCommand>): AxiosPromise<any> {
        return this.axios.post(`${this.apiPath}/documentSets/update`, command);
    }

    public deleteAwardDocumentSet(command: Model.PartialPlainObject<DeleteAwardDocumentSetCommand>): AxiosPromise<boolean> {
        return this.axios.post(`${this.apiPath}/documentSets/delete`, command);
    }

    public createAwardDocument(command: Model.PartialPlainObject<CreateAwardDocumentCommand>): AxiosPromise<number> {
        return this.axios.post(`${this.apiPath}/documents/create`, command);
    }

    public updateAwardDocument(command: Model.PartialPlainObject<UpdateAwardDocumentCommand>): AxiosPromise<any> {
        return this.axios.post(`${this.apiPath}/documents/update`, command);
    }

    public deleteAwardDocument(command: Model.PartialPlainObject<DeleteAwardDocumentCommand>): AxiosPromise<any> {
        return this.axios.post(`${this.apiPath}/documents/delete`, command);
    }

    public respondToParticipantOfferAwardDocument(command: Model.PartialPlainObject<RespondToParticipantOfferAwardDocumentCommand>): AxiosPromise<any> {
        return this.axios.post(`${this.apiPath}/participantDocuments/respond`, command);
    }

    public getDocumentUploadUrl(awardDocumentSetId: number): string {
        return `${this.apiPath}/awardDocumentUpload?awardDocumentSetId=${awardDocumentSetId}`;
    }

    public getParticipantDocumentUploadUrl(participantOfferAwardDocumentId: number): string {
        return `${this.apiPath}/participantAwardDocumentUpload?participantOfferAwardDocumentId=${participantOfferAwardDocumentId}`;
    }

    // Client only properties / methods
}