import { Attributes, DateUtils, LookupBase, Misc, NeoModel, NumberUtils } from '@singularsystems/neo-core';
import InstrumentLookup from '../../../Common/Models/InstrumentLookup';
import { Awards } from '../../../../App';
import PortfolioSettingsLookup from './PortfolioSettingsLookup';

export enum VestingStatus {
    Unvested = 10,
    Tradeable = 20,
    Vested = 30,
}

export enum GroupMode {
    portfolio,
    portfolioCalculator,
    tradeCalculator,
    trade
}

@NeoModel
export default class PortfolioBalanceLookup extends LookupBase {

    public incentiveSchemeId: number = 0;

    public awardId: number = 0;

    public awardNumber: number = 0;

    @Attributes.Display("Award")
    public awardName: string = "";

    public awardLinkId: number | null = null;

    public awardLinkDescription: string = "";

    @Attributes.Nullable()
    public awardLinkCurrencyId: number | null = null;

    @Attributes.Nullable()
    @Attributes.Float()
    public awardLinkExchangeRate: number | null = null;

    public instrumentId: number = 0;

    public instrumentCode: string = "";

    public trackingInstrumentCode: string = "";

    public settlementInstrumentCode: string = "";

    public settlementInstrumentCurrencyCode: string = "";

    public settlementInstrumentCurrencySymbol: string = "";

    @Attributes.Float()
    public settlementExchangeRate: number | null = null;

    public currencyId: number = 0;

    public currencyCode: string = "";

    public currencySymbol: string = "";

    @Attributes.Date()
    public awardDate: Date = new Date();

    @Attributes.Float()
    public awardPrice: number = 0;

    public trancheId: number = 0;

    @Attributes.Date()
    public vestingDate: Date = new Date();

    @Attributes.Date()
    public releaseDate: Date = new Date();

    @Attributes.Date()
    public tradeInstructionOpenDate: Date | null = null;

    public readonly excludeFromChargeOuts: boolean = false;

    @Attributes.Date()
    public acceleratedVestingDate: Date | null = null;

    @Attributes.Display("Units")
    @Attributes.Float()
    public unitBalance: number = 0;

    @Attributes.Float()
    public pendingTradeQty: number = 0;

    @Attributes.Float()
    public cashBalance: number = 0;

    @Attributes.Display("Award costs")
    @Attributes.Float()
    public awardCosts: number = 0;

    @Attributes.Float()
    public awardCash: number = 0;

    @Attributes.Float()
    @Attributes.Nullable()
    public awardTax: number | null = null;

    @Attributes.Float()
    public currentInstrumentPrice: number = 0;

    @Attributes.Float()
    public trackingInstrumentPrice: number = 0;

    @Attributes.Float()
    public settlementInstrumentPrice: number = 0;

    @Attributes.Float()
    public awardedUnits: number = 0;

    @Attributes.Float()
    public adjustedUnits: number = 0;

    @Attributes.Float()
    public tradedUnits: number = 0;

    public hasUnprocessedAwardCondition: boolean = false;

    public isConditional: boolean = false;

    public readonly hasAwardCondition: boolean = false;

    @Attributes.Nullable()
    @Attributes.Integer()
    public readonly certificateNumber: number | null = null;

    public readonly hasInterest: boolean = false;

    @Attributes.Integer()
    public readonly awardVestingOrdinal: number = 0;

    @Attributes.Nullable()
    public readonly participantEntityId: number | null = null;

    public participantEntityName: string = "";

    @Attributes.Date()
    public readonly acceleratedOn: Date | null = null;

    @Attributes.Nullable()
    @Attributes.Float()
    public readonly marketPriceAtAwardDate: number | null = null;

    public get hasParticipantEntity() {
        return !!this.participantEntityName;
    }

    public hasClosePeriod: boolean = false;

    /** Expiry date of the tranche. */
    @Attributes.Date()
    public expiryDate: Date = new Date();

    /** Expiry date as a result of employee separation. */
    @Attributes.Date()
    public separationExpiryDate: Date | null = null;

    public get effectiveExpiryDate() {
        if (this.separationExpiryDate !== null && this.separationExpiryDate < this.expiryDate) {
            return this.separationExpiryDate;
        } else {
            return this.expiryDate;
        }
    }

    @Attributes.Float()
    @Attributes.Nullable()
    public fixedPrice: number | null = null;

    @Attributes.Float()
    @Attributes.Nullable()
    public expectedPrice: number | null = null;

    @Attributes.Float()
    public allowedExerciseDays: number | null = null;

    public useMarketPriceForUnitConversion: boolean = false;

    public readonly hasUpcomingExpiry: boolean = false;

    // Client only properties / methods

    @Attributes.Float()
    public customTaxRate: number | null = null;

    /**
     * If this is a linked tranche, this will contain references to all tranches in all of the linked awards.
     * Only the first tranche in the linked awards will have items in this list, the rest will have an empty list.
     */
    @Attributes.NoTracking()
    public linkedTranches: PortfolioBalanceLookup[] | null = null;

    @Attributes.Display("Units")
    @Attributes.Float()
    public get availableBalance() {
        return this.unitBalance + this.pendingTradeQty;
    }

    public get awardDebt() {
        return this.awardCosts + this.awardCash;
    }

    public get costPerUnit() {
        return this.unitBalance === 0 ? 0 : (-this.awardCosts / this.unitBalance);
    }

    public get debtPerUnit() {
        return this.unitBalance === 0 ? 0 : (-this.awardDebt / this.unitBalance);
    }

    public get remainingAwardDebt() {
        return -this.debtPerUnit * this.availableBalance;
    }

    /** Note: do not use instrumentId from this object, it is from the awards module. */
    @Attributes.NoTracking()
    public instrument!: InstrumentLookup;

    @Attributes.NoTracking()
    public trackingInstrument: InstrumentLookup | undefined;

    @Attributes.NoTracking()
    public settlementInstrument: InstrumentLookup | undefined;

    @Attributes.NoTracking()
    public incentiveScheme!: Awards.IncentiveSchemeLookup;

    @Attributes.NoTracking()
    public settings = new PortfolioSettingsLookup();

    @Attributes.NoTracking()
    public taxRate: number = 0;

    /**
     * Gets the price of the instrument, or the fixed instrument price if the tranches price is fixed.
     */
    @Attributes.Float()
    public get instrumentPrice() {
        return this.fixedPrice ?? this.currentInstrumentPrice;
    }

    public get customInstrumentPrice() {
        return this.fixedPrice ??
            (this.trackingInstrument ?
                this.trackingInstrument.customPrice ?? this.trackingInstrumentPrice :
                this.instrument.customPrice ?? this.currentInstrumentPrice);
    }
    public set customInstrumentPrice(value: number) {
        if (this.trackingInstrument) {
            this.trackingInstrument.customPrice = value;
        } else {
            this.instrument.customPrice = value;
        }
    }

    public get priceDifference() {
        return this.customInstrumentPrice - this.awardPrice;
    }

    public get awardPriceForGain() {
        return this.useMarketPriceForUnitConversion ? (this.marketPriceAtAwardDate ?? this.awardPrice) : this.awardPrice
    }

    public get priceGainRatio() {
        return this.customInstrumentPrice / this.awardPriceForGain;
    }

    public get pricePercent() {
        return this.priceGainRatio - 1;
    }

    public get effectiveVestingDate() {
        return this.acceleratedVestingDate ?? this.releaseDate;
    }

    public get canTradeDate() {
        let canTradeDate = this.effectiveVestingDate;
        if (this.tradeInstructionOpenDate) {
            if (this.tradeInstructionOpenDate.getTime() < this.effectiveVestingDate.getTime()) {
                canTradeDate = this.tradeInstructionOpenDate;
            }
        }
        return canTradeDate;
    }

    @Attributes.Float()
    public get currentValue() {
        return this.availableBalance * this.instrumentPrice * (this.trackingInstrument ? this.priceGainRatio : 1);
    }

    @Attributes.Float()
    public get expectedValue() {
        return this.availableBalance * (this.expectedPrice ?? 0);
    }

    @Attributes.Float()
    public get profitLoss() {
        return this.currentValue + this.remainingAwardDebt;
    }

    /**
     * Profit or loss converted to the participants selected currency.
     */
    public get profitLossConverted() {
        return this.profitLoss * (this.instrument.rate ?? 1);
    }

    public get expectedValueConverted() {
        return this.expectedValue * (this.instrument.rate ?? 1);
    }

    /** Gets the award name with the scheme name included (if the award doesn't already contain the scheme name) */
    @Attributes.Display("Award")
    public get awardNameWithScheme() {
        if (this.awardName.toLowerCase().indexOf(this.incentiveScheme.incentiveSchemeName.toLowerCase()) < 0) {
            return this.incentiveScheme.incentiveSchemeName + " - " + this.awardName;
        } else {
            return this.awardName;
        }
    }

    public get status(): VestingStatus {
        if (Date.now() >= this.effectiveVestingDate.getTime()) {
            return VestingStatus.Vested;
        } else if (this.tradeInstructionOpenDate && Date.now() >= this.tradeInstructionOpenDate.getTime()) {
            return VestingStatus.Tradeable;
        } else {
            return VestingStatus.Unvested;
        }
    }

    /**
     * Is the tranche vested, or past the trade open date.
     */
    public get isVested(): boolean {
        return Date.now() >= this.canTradeDate.getTime() && !this.isConditional;
    }

    public get cannotTradeReason() {

        if (this.hasClosePeriod) {
            return "Cannot trade due to a trading closed period.";
        }
        if (this.effectiveExpiryDate && DateUtils.today() > this.effectiveExpiryDate) {
            return "Tranche has expired, and cannot be traded.";
        }
        if (this.hasUnprocessedAwardCondition) {
            return "This tranche has unprocessed award conditions";
        }
        if (this.isConditional) {
            return "This tranche is restricted";
        }
        if (this.awardLinkCurrencyId && this.awardLinkCurrencyId !== this.currencyId && !this.awardLinkExchangeRate) {
            return "Exchange rate missing.";
        }
        return "";
    }

    /**
     * Limits the value to zero, unless the scheme is set to show losses.
     * For linked awards, this will only limit if the total for the linked awards is less than zero.
     */
    public limitLossForTotal(property: (item: PortfolioBalanceLookup) => number) {
        let value = 0,
            isLoss = false;

        if (this.linkedTranches === null) {
            value = property(this);
            isLoss = value < 0;
        } else if (this.linkedTranches.length > 0) {
            value = this.linkedTranches.sum(property);
            isLoss = this.linkedTranches.sum(c => c.profitLoss * (c.awardLinkExchangeRate ?? 1)) < 0;
        }

        if (isLoss && this.incentiveScheme.lossDisplayType !== Awards.LossDisplayType.ShowLoss) {
            return 0;
        } else {
            return value;
        }
    }

    public getGroupSeparator(mode: GroupMode) {
        let groupId = "";

        if (!(mode === GroupMode.portfolio || mode === GroupMode.portfolioCalculator)) {
            if (this.incentiveScheme.openTradeInstructionBeforeVesting?.hasValue() && this.acceleratedVestingDate === null) {
                // If the schemes awards trade on vesting date, don't allow different award vestings to be grouped together.
                groupId += "." + this.trancheId.toString();
            }
            if (this.awardLinkId) {
                groupId += "." + this.awardLinkId;
                if (mode === GroupMode.tradeCalculator) {
                    // Must force group by award if in calculator mode for linked awards.
                    // Data entry wont make sense at a scheme level.
                    groupId += "." + this.awardId;
                }
            }
            if (DateUtils.today() > this.effectiveExpiryDate) {
                groupId += ".expired";
            }
        }

        if (this.fixedPrice) {
            groupId += "." + NumberUtils.format(this.fixedPrice, Misc.NumberFormat.Decimals);
        }

        return groupId;
    }
}