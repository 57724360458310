import { List, NeoModel } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import SecondmentLookup from '../Models/Lookups/SecondmentLookup';
import { AppService, Types } from '../ParticipantsTypes';

@NeoModel
export default class SecondmentsVM extends Views.ViewModelBase {

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        private apiClient = AppService.get(Types.Participants.ApiClients.MainApiClient)) {

        super(taskRunner);
    }

    public secondments = new List(SecondmentLookup);

    public async initialise() {
        const result = await this.taskRunner.waitFor(this.apiClient.getSecondments());
        this.secondments.set(result.data);
    }
}