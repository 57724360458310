import { Data, Misc, ModalUtils, NeoModel, List } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import { AppService, Types } from '../AllocationManagerTypes';
import UpdateParticipantAllocationCommand from '../Models/Commands/UpdateParticipantAllocationCommand';
import AllocationParticipantChangeRequest from '../Models/AllocationParticipantChangeRequest';
import UpdateIncentiveGroupAllocationCommand from '../Models/Commands/UpdateIncentiveGroupAllocationCommand';
import AllocationParticipantHistoryLookup from '../Models/Queries/AllocationParticipantHistoryLookup';
import AllocationParticipantLookup from '../Models/Queries/AllocationParticipantLookup';
import AllocationHistoryComponentVM from './AllocationHistoryComponentVM';
import { NotificationDuration } from '../../../../App/Models/Enums/NotificationDuration';
import AllocationParticipantAddRequest from '../Models/AllocationParticipantAddRequest';
import UpdateSchemeSplitCommand from '../Models/Commands/UpdateSchemeSplitCommand';
import AllocationParticipantLookupCriteria from '../Models/Queries/AllocationParticipantLookupCriteria';
import UpdateParticipantCommentCommand from '../Models/Commands/UpdateParticipantCommentCommand';
import UpdateParticipantAllocationCommentCommand from '../Models/Commands/UpdateParticipantAllocationCommentCommand';
import AllocationManagerLookup from '../Models/Queries/AllocationManagerLookup';
import IncentiveGroup from '../IncentiveGroup';
import { AllocationPlanType } from '../Models/Enums/AllocationPlanType';
import AllocationPlanIncentiveSchemeInfo from '../Models/AllocationPlanIncentiveSchemeInfo';
import AllocationManagerConfig from '../../Common/Models/AllocationManagerConfig';
import GridColumnConfig from '../../Common/Models/GridColumnConfig';
import TenantLookup from '../../../../Clients/Common/Models/TenantLookup';
import AMIncentiveGroupUsedBudgetLookup from '../Models/Queries/AMIncentiveGroupUsedBudgetLookup';
import AllocationCommentTypeOption from '../Models/AllocationCommentTypeOption';
import CurrencyLookup from '../../../../Transactions/Common/Models/CurrencyLookup';

@NeoModel
export default class AllocationGridComponentVM extends Views.ViewModelBase {

  constructor(
    taskRunner = AppService.get(Types.Neo.TaskRunner),
    pageManager: Data.PageManager<AllocationParticipantLookupCriteria, AllocationParticipantLookup>,
    currentlyLoggedInManagerDetails: AllocationManagerLookup,
    allocationGridColumnConfig: List<GridColumnConfig>,
    allocationCurrency: CurrencyLookup,
    private allocationParticipantQueryApiClient = AppService.get(Types.AllocationManager.ApiClients.AllocationParticipantQueryApiClient),
    private allocationParticipantApiClient = AppService.get(Types.AllocationManager.ApiClients.AllocationParticipantApiClient),
    public awardsCache = AppService.get(Types.Awards.Services.AwardsDataCache),
    public participantsCache = AppService.get(Types.Participants.Services.DataCache),
    private commentTypeApiClient = AppService.get(Types.AllocationManager.ApiClients.AllocationCommentTypeApiClient),
    private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
    private allocationManagerQueryApiClient = AppService.get(Types.AllocationManager.ApiClients.AllocationManagerQueryApiClient),
    public clientsApiClient = AppService.get(Types.Clients.ApiClients.ClientsApiClient)) {
    super(taskRunner);
    this.pageManager = pageManager;
    this.currentlyLoggedInManagerDetails = currentlyLoggedInManagerDetails;
    this.allocationGridColumnConfig = allocationGridColumnConfig;
    this.allocationCurrency = allocationCurrency;
  }

  public participantName: string = ""; // Used for Title on History Modal && Change Request Modal
  public participantJobTitle: string = ""; // Used for Job Title on History Modal && Change Request Modal
  public allocationHistoryVM: AllocationHistoryComponentVM | null = null;
  public showChangeRequestModal = false;
  public showAddRequestModal = false;
  public showPostRequestModal = false;
  public updateCommand: UpdateParticipantAllocationCommand | null = null;
  public updateCommentCommand: UpdateParticipantAllocationCommentCommand | null = null;
  public pageManager: Data.PageManager<AllocationParticipantLookupCriteria, AllocationParticipantLookup> | null = null;
  public selectedParticipant = new AllocationParticipantLookup();
  public commentTypeOptions = new List(AllocationCommentTypeOption);

  //Drop downs
  public commentTypes = new Data.ApiClientDataSource(this.commentTypeApiClient.getActiveAllocationCommentTypes);
  public participantGrades = this.participantsCache.participantGrades.get();
  public managerIncentiveSchemes = new List(AllocationPlanIncentiveSchemeInfo);

  // TO DO: this is a placeholder that will be added in a later ticket.
  public totals: string = "";
  public currentlyLoggedInManagerDetails: AllocationManagerLookup;
  public managerCanEdit = false;
  public allocationGridColumnConfig = new List(GridColumnConfig);
  public participantGradeIds: number[] = [];
  public incentiveGroupList = new List(IncentiveGroup);
  public allocationManagerConfig = new AllocationManagerConfig();
  public tenant: TenantLookup = new TenantLookup();
  public incentiveGroupBudgetUsedList = new List(AMIncentiveGroupUsedBudgetLookup);
  public allocationCurrency = new CurrencyLookup();

  public async initialise() {
    this.commentTypes.fetchInitial();
    this.participantGrades.fetchInitial();
    this.populateIncentiveGroupList();
    this.managerCanEdit = this.currentlyLoggedInManagerDetails.canEdit;
    const managerSchemeList = await this.allocationManagerQueryApiClient.getAMIncentiveSchemesInfoLookups(this.currentlyLoggedInManagerDetails.allocationManagerId);
    this.managerIncentiveSchemes.set(managerSchemeList.data);
    const config = await this.allocationManagerQueryApiClient.getAMChangeControlConfig();
    this.allocationManagerConfig.set(config.data);
    const theTenant = await this.clientsApiClient.getTenantLookup();
    this.tenant.set(theTenant.data);
    const incentiveGroupBudgetsUsed = await this.taskRunner.waitFor(this.allocationParticipantQueryApiClient.getAMIncentiveGroupUsedBudget(this.currentlyLoggedInManagerDetails.participantId));
    this.incentiveGroupBudgetUsedList.set(incentiveGroupBudgetsUsed.data);
  }

  public async showParticipantHistory(participant: AllocationParticipantLookup) {
    const result = await this.taskRunner.waitFor(this.allocationParticipantQueryApiClient.getAllocationParticipantHistory(participant.allocationParticipantId));
    const participantHistory = AllocationParticipantHistoryLookup.fromJSObject<AllocationParticipantHistoryLookup>(result.data);
    participantHistory.currencySymbol = this.allocationCurrency.symbol;
    participantHistory.annualTCTC = participant.annualTCTC!;
    this.participantName = participant.participantName;
    this.participantJobTitle = participant.jobTitle;
    this.allocationHistoryVM = new AllocationHistoryComponentVM(participantHistory);
  }

  public async updateProposeAllocations(allocationParticipant: AllocationParticipantLookup) {
    if (allocationParticipant.incentiveGroupAllocations.isDirty) {
      var updateParticipantAllocationCommand = new UpdateParticipantAllocationCommand();
      updateParticipantAllocationCommand.allocationParticipantId = allocationParticipant.allocationParticipantId;
      allocationParticipant.incentiveGroupAllocations.forEach(allocation => {
        var updIncentiveGroupAllocation = new UpdateIncentiveGroupAllocationCommand();
        updIncentiveGroupAllocation.mapFrom(allocation);
        updateParticipantAllocationCommand.incentiveGroupAllocations.push(updIncentiveGroupAllocation);
      });

      if (allocationParticipant.isOverAllocated && allocationParticipant.hasOverTheMaxComment() || !allocationParticipant.isOverAllocated) {
        await this.allocationParticipantApiClient.updateParticipantIncentiveGroupAllocation(updateParticipantAllocationCommand.toJSObject());
        const response = await this.taskRunner.waitForData(this.allocationManagerQueryApiClient.getLastEditByManagerInformation(this.currentlyLoggedInManagerDetails.participantId))
        this.currentlyLoggedInManagerDetails.lastEditedInfoLookup.set(response);
        this.selectedParticipant = new AllocationParticipantLookup();
      } else {
        this.selectedParticipant = allocationParticipant;
        await this.checkComments(allocationParticipant);
      }
      this.refreshBudgetData();
    }
  }

  public async checkComments(allocationParticipant: AllocationParticipantLookup) {
    let allocationAmount = allocationParticipant.incentiveGroupAllocations.sum(a => a.proposedAllocationExpectedValue + a.proposedAllocationFaceValue);
    if (allocationAmount > 0 && allocationParticipant.isOverAllocated && !allocationParticipant.hasOverTheMaxComment()) {
      this.editComments(allocationParticipant, false, allocationParticipant.isOverAllocated);
    }
    else if (this.currentlyLoggedInManagerDetails.allocationPlanTypeId === AllocationPlanType.ShortRoundAllocation) {
      if (allocationAmount > 0 && allocationParticipant.allocationComments.length === 0) {
        this.editComments(allocationParticipant, true, allocationParticipant.isOverAllocated);
      }
    } else {
      if (this.pageManager) {
        const response = await this.taskRunner.waitForData(this.allocationManagerQueryApiClient.getLastEditByManagerInformation(this.currentlyLoggedInManagerDetails.participantId))
        this.currentlyLoggedInManagerDetails.lastEditedInfoLookup.set(response);
        this.pageManager.refreshData();
      }
    }
  }

  public async showChangeRequest(participant: AllocationParticipantLookup) {
    this.participantName = participant.participantName;
    this.showChangeRequestModal = true;
  }

  public async sendChangeRequest(changeRequest: AllocationParticipantChangeRequest) {
    this.showChangeRequestModal = false;
    await this.taskRunner.run(async () => {
      const response = await this.allocationParticipantApiClient.sendParticipantChangeRequest(changeRequest.toJSObject());
      if (response.data.errorEncountered) {
        this.notifications.addDanger("Error Encountered", response.data.errorMessage, NotificationDuration.Standard);
      }
      else {
        this.notifications.addSuccess("Change requested", null, NotificationDuration.Standard);
        this.showPostRequestModal = true;
      }
    });
  }

  public async sendAddRequest(addRequest: AllocationParticipantAddRequest) {
    this.showAddRequestModal = false;
    await this.taskRunner.run(async () => {
      const response = await this.allocationParticipantApiClient.sendParticipantAddRequest(addRequest.toJSObject());
      if (response.data.errorEncountered) {
        this.notifications.addDanger("Error Encountered", response.data.errorMessage, NotificationDuration.Standard);
      }
      else {
        this.notifications.addSuccess("Add requested", null, NotificationDuration.Standard);
        this.showPostRequestModal = true;
      }
    });
  }

  public editSchemeSplit(participant: AllocationParticipantLookup) {
    var newCommand = new UpdateParticipantAllocationCommand();
    this.selectedParticipant = new AllocationParticipantLookup();
    newCommand.allocationParticipantId = participant.allocationParticipantId;
    participant.incentiveGroupAllocations.forEach(allocation => {
      var updIncentiveGroupAllocation = new UpdateIncentiveGroupAllocationCommand();
      updIncentiveGroupAllocation.mapFrom(allocation);
      newCommand.incentiveGroupAllocations.push(updIncentiveGroupAllocation);
    });
    participant.schemeSplits.forEach(split => {
      var updSchemeSplitAllocation = new UpdateSchemeSplitCommand();
      updSchemeSplitAllocation.mapFrom(split);
      newCommand.schemeSplits.push(updSchemeSplitAllocation);
    });
    this.selectedParticipant = participant;
    this.updateCommand = newCommand;
  }

  public async updateAllocationSchemeSplits() {
    if (this.updateCommand) {
      await this.taskRunner.run(async () => {
        if (this.selectedParticipant.isParticipantException) {
          await this.calculateExpectedValueFromSplit(this.updateCommand?.schemeSplits as any, this.updateCommand?.incentiveGroupAllocations as any);
        } else {
          await this.calculateFaceValueFromSplit(this.updateCommand?.schemeSplits as any, this.updateCommand?.incentiveGroupAllocations as any);
        }
      })
        .then(() => this.allocationParticipantApiClient.updateParticipantIncentiveGroupAllocation(this.updateCommand!.toJSObject({ includeClean: true })));

      this.refreshBudgetData();
      this.updateCommand = null;

      if (this.pageManager) {
        const response = await this.taskRunner.waitForData(this.allocationManagerQueryApiClient.getLastEditByManagerInformation(this.currentlyLoggedInManagerDetails.participantId))
        this.currentlyLoggedInManagerDetails.lastEditedInfoLookup.set(response);
        this.pageManager.refreshData();
      }
    }
  }

  public async calculateExpectedValueFromSplit(schemeSplits: List<UpdateSchemeSplitCommand>, incentiveGroupAllocations: List<UpdateIncentiveGroupAllocationCommand>) {
    incentiveGroupAllocations.forEach(alloc => {
      if (!alloc.applyConversion) {
        alloc.proposedAllocationExpectedValue = alloc.proposedAllocationFaceValue;
      } else {
        alloc.proposedAllocationExpectedValue = 0;
        for (let index = 0; index < schemeSplits.length; index++) {
          alloc.proposedAllocationExpectedValue += Math.round(alloc.proposedAllocationFaceValue * schemeSplits[index].percentageSplit * schemeSplits[index].expectedValuePercentage);
        }
      }
    });
  }

  public async calculateFaceValueFromSplit(schemeSplits: List<UpdateSchemeSplitCommand>, incentiveGroupAllocations: List<UpdateIncentiveGroupAllocationCommand>) {
    incentiveGroupAllocations.forEach(alloc => {
      if (!alloc.applyConversion) {
        alloc.proposedAllocationFaceValue = alloc.proposedAllocationExpectedValue;
      } else {
        alloc.proposedAllocationFaceValue = 0;
        for (let index = 0; index < schemeSplits.length; index++) {
          alloc.proposedAllocationFaceValue += Math.round(alloc.proposedAllocationExpectedValue * schemeSplits[index].percentageSplit / schemeSplits[index].expectedValuePercentage);
        }
      }
    });
  }

  public async calculateExpectedValue(participant: AllocationParticipantLookup, allocationIncentiveGroupAllocationId: number) {
    if (this.managerCanEdit) {
      if (allocationIncentiveGroupAllocationId !== undefined) {
        var alloc = participant.incentiveGroupAllocations.find(c => c.allocationIncentiveGroupAllocationId === allocationIncentiveGroupAllocationId)!;
        if (!alloc.applyConversion) {
          alloc.proposedAllocationExpectedValue = alloc.proposedAllocationFaceValue;
        } else {
          alloc.proposedAllocationExpectedValue = 0;
          if (alloc.applyConversion && participant.schemeSplits.length === 0) {
            this.notifications.showMessage("No scheme split selected", "Please select a scheme split before entering an allocation");
            alloc.proposedAllocationFaceValue = 0;
          } else {
            for (let index = 0; index < participant.schemeSplits.length; index++) {
              alloc.proposedAllocationExpectedValue += Math.round(alloc.proposedAllocationFaceValue * participant.schemeSplits[index].percentageSplit * participant.schemeSplits[index].expectedValuePercentage);
            }
          }
        }
      }

      await this.taskRunner.run(() => this.updateProposeAllocations(participant));
    }
  }

  public async calculateFaceValue(participant: AllocationParticipantLookup, allocationIncentiveGroupAllocationId: number) {
    if (this.managerCanEdit) {
      var alloc = participant.incentiveGroupAllocations.find(c => c.allocationIncentiveGroupAllocationId === allocationIncentiveGroupAllocationId)!;
      if (!alloc.applyConversion) {
        alloc.proposedAllocationFaceValue = alloc.proposedAllocationExpectedValue;
      }
      else {
        alloc.proposedAllocationFaceValue = 0;
        if (alloc.applyConversion && participant.schemeSplits.length === 0) {
          this.notifications.showMessage("No scheme split selected", "Please select a scheme split before entering an allocation");
          alloc.proposedAllocationExpectedValue = 0;
        } else {
          for (let index = 0; index < participant.schemeSplits.length; index++) {
            alloc.proposedAllocationFaceValue += Math.round(alloc.proposedAllocationExpectedValue * participant.schemeSplits[index].percentageSplit / participant.schemeSplits[index].expectedValuePercentage);
          }
        }
      }

      await this.taskRunner.run(() => this.updateProposeAllocations(participant));
    }
  }

  public async editComments(participant: AllocationParticipantLookup, required: boolean, requireOverTheMaxComment: boolean) {
    var updParticipantCommand = new UpdateParticipantAllocationCommentCommand();
    updParticipantCommand.commentRequired = required;
    let allocationAmount = participant.incentiveGroupAllocations.sum(a => a.proposedAllocationExpectedValue + a.proposedAllocationFaceValue);
    if (requireOverTheMaxComment && allocationAmount > 0) {
      updParticipantCommand.overTheMaxCommentRequired = requireOverTheMaxComment
      updParticipantCommand.overTheMaxCommentTypeId = this.getOverTheMaxCommentTypeId(); // OverMaxCommentTypeId required for validation on comments modal
    }
    await this.commentTypes.promise;
    updParticipantCommand.allocationParticipantId = participant.allocationParticipantId;
    participant.allocationComments.forEach(comment => {
      var updParticipantComments = new UpdateParticipantCommentCommand();
      updParticipantComments.mapFrom(comment);
      updParticipantComments.commentTypeOptions.set(this.commentTypes.data.find(c => c.allocationCommentTypeId === comment.allocationCommentTypeId)?.options as any);
      updParticipantCommand.participantComments.push(updParticipantComments);
    });
    this.updateCommentCommand = updParticipantCommand;
  }

  public async updateParticipantComments() {
    if (this.updateCommentCommand) {
      await this.taskRunner.run(async () => {
        await this.allocationParticipantApiClient.updateParticipantComment(this.updateCommentCommand!.toJSObject({ includeClean: true }));
        await this.updateProposeAllocations(this.selectedParticipant);
      });
      this.updateCommentCommand = null;
      if (this.pageManager) {
        const response = await this.taskRunner.waitForData(this.allocationManagerQueryApiClient.getLastEditByManagerInformation(this.currentlyLoggedInManagerDetails.participantId))
        this.currentlyLoggedInManagerDetails.lastEditedInfoLookup.set(response);
        this.pageManager.refreshData();
      }
    }
  }

  public async loadCommentOptionsListByType(updateParticipantCommentCommand: UpdateParticipantCommentCommand) {
    var options = this.commentTypes.data.find(c => c.allocationCommentTypeId === updateParticipantCommentCommand.allocationCommentTypeId)?.options;
    updateParticipantCommentCommand.commentTypeOptions.set(options as any);
  }

  public async setDefaultCommentType(updateParticipantCommentCommand: UpdateParticipantCommentCommand, overTheMaxCommentRequired: boolean) {
    if (overTheMaxCommentRequired) {
      updateParticipantCommentCommand.allocationCommentTypeId = this.getOverTheMaxCommentTypeId()
    }
    this.loadCommentOptionsListByType(updateParticipantCommentCommand);
  }

  public removeComment(comment: UpdateParticipantCommentCommand) {
    this.updateCommentCommand?.participantComments.remove(comment);
  }

  public async removeSchemeSplit(schemeSplit: UpdateSchemeSplitCommand) {
    if (await ModalUtils.showYesNo("Delete", "Are you sure you want to delete this item?") === Misc.ModalResult.Yes) {
      this.updateCommand?.schemeSplits.remove(schemeSplit);
    }
  }

  public populateIncentiveGroupList() {
    this.incentiveGroupList.set([]);
    this.currentlyLoggedInManagerDetails.remainingIncentiveGroupBudgets.forEach(budgetInfo => {
      let newIncentiveGroup = this.incentiveGroupList.addNew();
      newIncentiveGroup.incentiveGroupId = budgetInfo.incentiveGroupId;
      newIncentiveGroup.incentiveGroupName = budgetInfo.incentiveGroup;
    });
  }

  // TODO: We definitely need a better way of identifying Over the Max comments between different clients. This isn't a static enum as comment types can differ between clients.
  // This assumes each Over The Maximum comment will have the letters "max" included - a fairly flimsy premise which needs to be addressed, but which we will have more context
  // on when more clients are wanting to use this functionality - will other clients even have the concept of Over The Max / Over Allocated?
  public getOverTheMaxCommentTypeId() {
    let commentTypeId = 0
    this.commentTypes.data.forEach(ct => {
      if (ct.commentType.toLowerCase().includes("max")) {
        commentTypeId = ct.allocationCommentTypeId
      }
    });
    return commentTypeId;
  }

  public async refreshBudgetData() {
    let incentiveGroupBudget = await this.allocationParticipantQueryApiClient.getAMIncentiveGroupUsedBudget(this.currentlyLoggedInManagerDetails.participantId);
    this.incentiveGroupBudgetUsedList.set(incentiveGroupBudget.data);
  }

  public getRemainingBudgets(incentiveGroupId: number) {
    let refreshedBudget = this.incentiveGroupBudgetUsedList;
    let budgetUsed = 0, remainingBudget = 0;

    this.currentlyLoggedInManagerDetails.remainingIncentiveGroupBudgets.forEach(budgetInfo => {
      if (budgetInfo.incentiveGroupId === incentiveGroupId && this.pageManager) {
        refreshedBudget.forEach(element => {
          if (element.incentiveGroupId === incentiveGroupId) {
            budgetUsed = element.budgetUsedFaceValue;
          }
        });
        remainingBudget = budgetInfo.budgetAmount - budgetUsed;
      }
    });
    return remainingBudget;
  }

  public async getParticipantGrades(participantGradeIds: number[]) {
    let participantGrades = await this.participantGrades.promise;
    return participantGrades!.filter(c => participantGradeIds!.includes(c.participantGradeId));
  }
}