import { NeoModel, ValueObject } from '@singularsystems/neo-core';
import { AwardedUnitsDisplayType } from './AwardedUnitsDisplayType';

@NeoModel
export default class MyAwardsGridConfig extends ValueObject {

    public hideExpectedValue: boolean = false;

    public hideFaceValue: boolean = false;

    public awardedUnitsDisplayType: AwardedUnitsDisplayType = AwardedUnitsDisplayType.CurrencyValue;

    // Client only properties / methods
}