import React from 'react';
import { observer } from 'mobx-react';

import AwardsGridBase, { IAwardsGridBaseProps } from '../../Components/AwardsGridBase';
import { Components, ValueObject } from '@singularsystems/neo-core';
import MyAwardLookup from '../../../Models/ParticipantOffers/Queries/MyAwards/MyAwardLookup';
import MyAwardsVMBase from '../MyAwardsVMBase';
import { Neo, NeoGrid } from '@singularsystems/neo-react';
import { ScreenSize } from '../../../../../App/Services/AppLayout';
import ParticipantOfferAwardDocumentLookup from '../../../../Common/Models/AwardDocuments/Queries/ParticipantOfferAwardDocumentLookup';
import MyAwardDocumentLookup from '../../../Models/ParticipantOffers/Queries/MyAwards/MyAwardDocumentLookup';

interface IMyAwardsGridProps extends IAwardsGridBaseProps<MyAwardsVMBase, ValueObject, MyAwardLookup> {
    viewQuestionnaireHandler?: (item: MyAwardLookup) => void;
    viewSelectionHandler?: (item: MyAwardLookup) => void;
    viewDocumentHandler?: (award: MyAwardLookup, doc: MyAwardDocumentLookup) => void;
    viewEntityHandler?: (item: MyAwardLookup) => void;
    showAllDocuments?: boolean;
    showQuestionnaireButton?: boolean;
    showEntityButton?: boolean;
    currentlySelectedEntity?: string;
}

@observer
export default class MyAwardsGrid extends AwardsGridBase<IMyAwardsGridProps, MyAwardsVMBase, ValueObject, MyAwardLookup> {

    protected getSelectorCellColorClassName(item: MyAwardLookup) {
        return item.readyForAcceptance ? `text-primary` : 'text-danger';
    }

    protected leftColumns(item: MyAwardLookup): JSX.Element | undefined {
        return undefined;
    }

    protected rightColumns(item: MyAwardLookup): JSX.Element | undefined {
        const screenSize = this.appLayout.currentScreenSize;
        return <>
            {(this.props.viewDocumentHandler || this.props.viewQuestionnaireHandler || this.props.viewEntityHandler) &&
                <NeoGrid.Column className="document-buttons"
                    label={<i className="fa fa-minus fa-lg" data-tip="Award Documentation" />}
                    alignment="center"
                    rowSpan={item.sameAwardRowCount}
                    cellClassName={item.sameAward ? "hidden" : ""}>
                    {!item.sameAward && this.getButtons(item, screenSize)}
                </NeoGrid.Column>}

            {/* // DEBUG COLUMNS
                <NeoGrid.Column display={item.meta.participantOfferId} />
                <NeoGrid.Column label="documents">{item.documentSet?.documents.length}</NeoGrid.Column>
                <NeoGrid.Column label="questionnaire">{item.questionnaire?.answersCount}</NeoGrid.Column>


                
                <NeoGrid.Column>
                    <p>
                        AwardPrepId: {item.awardOptionId}
                    </p>
                    <p>
                        SelectionType: {item.selectionType}
                    </p>
                </NeoGrid.Column>
                <NeoGrid.Column display={item.meta.awardOptionId} />
                <NeoGrid.Column display={item.meta.totalSelectedPercent} />
                <NeoGrid.Column display={item.meta.optionInstrumentPriceId} /> 
                <NeoGrid.Column display={item.meta.awardPrepId} /> */}
        </>;
    }

    private getButtons(item: MyAwardLookup, screenSize: ScreenSize) {
        return this.getButtonOptions(item, screenSize).map((options, index) => <Neo.Button key={`document-button-${index}-${options.variant}`} {...options} />)
    }

    private getButtonOptions(item: MyAwardLookup, screenSize: ScreenSize): Components.IButtonOptions[] {
        let buttons: Components.IButtonOptions[] = [],
            className = "btn-action " + (screenSize <= ScreenSize.Small ? "small" : "large");

        if (this.props.viewDocumentHandler && item.documents.length > 0) {
            // show the rest of the documents that dont require completion
            item.documents.filter(d => this.props.showAllDocuments || !d.requiresCompletion).forEach(doc => {
                buttons.push(
                    {
                        onClick: () => this.props.viewDocumentHandler!(item, doc),
                        className,
                        iconAlignment: "right",
                        icon: screenSize <= ScreenSize.Small ? ParticipantOfferAwardDocumentLookup.getDocumentIcon(doc.deliveryType) + " fa-fw" : "",
                        text: screenSize <= ScreenSize.Small ? "" : `${(screenSize > ScreenSize.Medium ? doc.documentViewAction : "")} ${doc.documentName}`,
                        tooltip: screenSize <= ScreenSize.Small ? doc.documentName : undefined,
                    });
            });
        }

        if (this.props.showQuestionnaireButton && this.props.viewQuestionnaireHandler && item.questionnaire) {
            buttons.push(
                {
                    variant: "secondary",
                    onClick: () => this.props.viewQuestionnaireHandler!(item),
                    className,
                    iconAlignment: "right",
                    icon: (screenSize <= ScreenSize.Small ? "fa-question-circle fa-fw" : ""),
                    text: screenSize <= ScreenSize.Small ? "" : (screenSize > ScreenSize.Medium ? "View Questionnaire" : ""),
                    tooltip: screenSize <= ScreenSize.Small ? "View Questionnaire" : undefined,
                })
        }

        if (this.props.showEntityButton && this.props.viewEntityHandler) {
            buttons.push(
                {
                    variant: "secondary",
                    onClick: () => this.props.viewEntityHandler!(item),
                    className,
                    iconAlignment: "right",
                    icon: (screenSize <= ScreenSize.Small ? "fa-question-circle fa-fw" : ""),
                    text: screenSize <= ScreenSize.Small ? "" : (screenSize > ScreenSize.Medium ? (item.participantEntityId ? "Change Selected Entity" : "Select Entity") : ""),
                    tooltip: item.participantEntityId ? "Current Selection: " + item.selectedParticipantEntityName : undefined,
                });
        }

        return buttons;
    }
}