import { Data } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../ClientsTypes';

export interface IParticipantsMessageApiClient {

    /** 
     * Sends a message to the tenant .
     * @param message Message to send.
     * @returns Task.
     */
    sendQueryMessage(message: string): AxiosPromise;

    // Client only properties / methods
}

@injectable()
export default class ParticipantsMessageApiClient extends Data.ApiClientBase implements IParticipantsMessageApiClient {

    constructor (config = AppService.get(Types.Shared.Config)) {
        super(`${config.ClientsApi.ApiPath}/ParticipantsMessage`);
    }

    public sendQueryMessage(message: string): AxiosPromise {
        return this.axios.post(`${this.apiPath}/query-message`, JSON.stringify(message), { headers: {'Content-Type': 'application/json'}});
    }

    // Client only properties / methods
}