import { NeoModel } from '@singularsystems/neo-core';
import { injectable } from 'inversify';
import { OidcAuthenticationServiceBase } from '../Common/Services/Security/AuthenticationServiceBase';
import { AppService, AppTypes } from './AppService';

@injectable()
@NeoModel
export class OidcAuthenticationService extends OidcAuthenticationServiceBase {

    protected async afterUserLoaded(): Promise<void> {
        await super.afterUserLoaded();
        await AppService.get(AppTypes.AllocationManager.Services.AllocationManagerService).initialize();
    }
}