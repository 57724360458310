import { Attributes, List, NeoModel } from '@singularsystems/neo-core';
import { Awards } from '../../../App';
import BrokerAccountLookup from './Brokers/Lookups/BrokerAccountLookup';
import { IncentiveSchemeLookupBase } from './IncentiveSchemeLookup';
import DocumentRequirementCheckResult from './Compliance/DocumentRequirementCheckResult';
import { PreventionType } from '../../Common/Models/PreventionType';

@NeoModel
export default class IncentiveSchemeParticipantLookup extends IncentiveSchemeLookupBase {

    public failedDocumentChecks = new List(DocumentRequirementCheckResult);

    // Client only

    /** The full awards scheme. */
    @Attributes.NoTracking()
    public awardsScheme!: Awards.IncentiveSchemeLookup;

    @Attributes.NoTracking()
    public brokerAccount: BrokerAccountLookup | null = null;

    public get mustPreventTrade() {
        return this.failedDocumentChecks.find(c => c.tradingAction === PreventionType.Prevent) !== undefined;
    }

    public get missingDocumentText() {
        const firstPreventionCheck = this.failedDocumentChecks.find(c => c.tradingAction === PreventionType.Prevent);
        if (firstPreventionCheck) {
            return `${firstPreventionCheck.documentRequirementName} (Document types: ${firstPreventionCheck.missingDocumentTypes.join(", ")})`;
        } else {
            return "";
        }
    }
}