import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../../../CommonIdentityTypes';
import TsAndCsDisplayOptions from '../../../Models/TsAndCs/Display/TsAndCsDisplayOptions';

export interface ITermsAndConditionsDisplayOptionsApiClient {

    /** 
     * Gets a page of terms and conditions display options.
     * @returns A page of terms and conditions display options.
     */
    get(): AxiosPromise<Model.PlainTrackedObject<TsAndCsDisplayOptions>>;

    // Client only properties / methods
}

@injectable()
export default class TermsAndConditionsDisplayOptionsApiClient extends Data.ApiClientBase implements ITermsAndConditionsDisplayOptionsApiClient {

    constructor (config = AppService.get(Types.Shared.Config)) {
        super(`${config.IdentityServerApi.ApiPath}/terms-and-conditions/display-options`);
    }

    public get(): AxiosPromise<Model.PlainTrackedObject<TsAndCsDisplayOptions>> {
        return this.axios.get(`${this.apiPath}`);
    }

    // Client only properties / methods
}