import { AppService, AppTypes } from '../../App/Services/AppService';

import { IdentitySharedTypes } from './IdentitySharedTypes';

// Symbols to expose outside of this module
const CommonIdentityTypes = {
    ApiClients: {
        ...IdentitySharedTypes.ApiClients
    },
    Services: {
        ...IdentitySharedTypes.Services
    },
    ViewModels: {
        ...IdentitySharedTypes.ViewModels
    },
    Components: {
        ...IdentitySharedTypes.Components
    }
}

export { AppService, AppTypes as Types, CommonIdentityTypes }