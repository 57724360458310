export default class FutureAmounts {

    constructor(
        public yearPlus1Label: string,
        public yearPlus2Label: string,
        public yearPlus3Label: string
    ) {

    }
    
    public vestedValue: number = 0;
    public yearPlus1Value: number = 0;
    public yearPlus2Value: number = 0;
    public yearPlus3Value: number = 0;
    public allTimeValue: number = 0;
}