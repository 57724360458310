import { Attributes, LookupBase, NeoModel } from '@singularsystems/neo-core';

@NeoModel
export class IncentiveSchemeTypeGradeRangeLookup extends LookupBase {

    @Attributes.Nullable()
    public incentiveSchemeTypeId: number | null = null;

    @Attributes.Float()
    public minPercentage: number = 0;

    @Attributes.Float()
    public maxPercentage: number = 0;

    // Client only properties / methods
}