import React from 'react';
import { Neo, NeoGrid } from '@singularsystems/neo-react';
import { observer } from 'mobx-react';

import { AppService, Types } from '../../AwardsTypes';

import { Components, Data, Misc, Model, ValueObject } from '@singularsystems/neo-core';
import ParticipantAwardLookupBase from '../../../Common/Models/Base/ParticipantAwardLookupBase';
import AwardsVMBase from '../Base/AwardsVMBase';
import { ScreenSize } from '../../../../App/Services/AppLayout';
import { TransformMetaType } from '@singularsystems/neo-core/dist/Model';
import MyAwardsGridConfig from '../../../Common/Models/Config/Module/MyAwardsGridConfig';
import { AwardedUnitsDisplayType } from '../../../Common/Models/Config/Module/AwardedUnitsDisplayType';

export interface IAwardsGridBaseProps<TViewModel extends AwardsVMBase<TLookup>, TCriteria extends ValueObject, TLookup extends ParticipantAwardLookupBase> {
    viewModel: TViewModel;
    pageManager: Data.PageManager<TCriteria, TLookup>;
    showAwardDate?: boolean;
    showSelection?: boolean;
    hideFaceValue?: boolean;
    allowSort?: boolean;
    config: MyAwardsGridConfig;
}

interface IValueColumn {
    label: any;
    sort: false | Model.IPropertyInstance<any> | undefined;
    targetProperty: Model.IPropertyInstance<any>;
    altValue: Model.IPropertyInstance<any> | undefined;
    // calcFailure?: boolean;
    numberFormat: Misc.NumberFormat | undefined;
    currencySymbol: string | undefined;
    hideBelow?: Components.breakpoint;
    // dataTip: any;
    suppressField?: boolean;
}

export default class AwardsGridBase<TProps extends IAwardsGridBaseProps<TViewModel, TCriteria, TLookup>,
    TViewModel extends AwardsVMBase<TLookup>,
    TCriteria extends ValueObject,
    TLookup extends ParticipantAwardLookupBase>
    extends React.Component<TProps> {

    protected appLayout = AppService.get(Types.Shared.Services.AppLayout);

    protected getMultiLevelLabel(mainLabelText: string, subLabelText: string) {
        return <div>
            <strong>{mainLabelText}</strong>
            <br />
            <small>{subLabelText}</small>
        </div>
    }

    protected getPagerNoOfButtons(): number {
        return this.appLayout.currentScreenSize <= ScreenSize.Large ? 5 : 9;
    }

    protected getGridClassName() {
        return "awards-grid";
    }

    protected leftColumns(item: TLookup): JSX.Element | undefined {
        return undefined;
    }

    protected rightColumns(item: TLookup): JSX.Element | undefined {
        return undefined;
    }

    private getValueColumn(column: IValueColumn, item: TLookup, meta: TransformMetaType<ParticipantAwardLookupBase>, tooltip?: string) {

        const columnName = column.targetProperty.propertyInfo.propertyName as (keyof ParticipantAwardLookupBase);

        let calcFailure = false;
        if (item.awardPrepId !== 0 && item.hasSetup) {
            calcFailure = item.hasMissingDependencies(columnName);
            if (calcFailure) {
                const altValueName = (column.altValue ? column.altValue.propertyInfo.readableName : undefined);
                const missingDependenciesMessage = item.getMissingDependencyTip(columnName, altValueName);
                if (missingDependenciesMessage) {
                    console.log(`Missing Dependencies for ${columnName}: ${missingDependenciesMessage}`);
                }
            }
        }

        return <NeoGrid.Column
            label={column.label}
            sort={column.sort}
            cellClassName={!calcFailure ? "" : "calc-failure"}
            display={(column.suppressField ?? false) ? undefined :
                (!calcFailure ? column.targetProperty :
                    (!!column.altValue ? column.altValue : meta.emptyPlaceholder))}
            numProps={{ format: column.numberFormat, currencySymbol: column.currencySymbol }}
            hideBelow={column.hideBelow}
            headerTooltip={column.suppressField ? undefined : tooltip} />
    }

    private hasMultipleInstruments() {
        for (var award of this.props.pageManager.data) {
            var incentiveScheme = this.props.viewModel.incentiveSchemes.find(x => x.incentiveSchemeId === award.incentiveSchemeId);
            if (incentiveScheme && incentiveScheme.instruments.length > 1) {
                return true;
            }
        }
        return false;
    }

    private hasMultipleAwards() {
        return this.props.viewModel.myAwardsInfoLookup.acceptTotalAwards > 1;
    }

    private hasAwardsThatUseMarketPriceForUnitConversion() {
        return this.props.pageManager.data.some(c => c.useMarketPriceForUnitConversion);
    }

    public render() {
        const viewModel = this.props.viewModel;
        viewModel.awardModuleConfig
        const showExpectedValue = !this.props.config.hideExpectedValue && this.props.pageManager.data.some(c => c.useExpectedValue);
        const screenSize = this.appLayout.currentScreenSize;
        const showInstrumentColumn = this.hasMultipleInstruments();
        const hasMultipleAwards = this.hasMultipleAwards();
        const showMarketPriceAtAwardDateColumn = this.hasAwardsThatUseMarketPriceForUnitConversion();

        return (
            <Neo.Pager pageManager={this.props.pageManager} pageControls="none" pageControlProps={{ noOfButtons: this.getPagerNoOfButtons() }} >
                <NeoGrid.Grid<ParticipantAwardLookupBase> className={this.getGridClassName()} allowSort={this.props.allowSort}>
                    {(item, meta) => (<NeoGrid.Row className={item.sameParticipant ? (item.sameAward ? "no-border" : "dashed-border") : ""}>

                        {this.leftColumns(item as TLookup)}

                        {(this.props.showSelection ?? true) && hasMultipleAwards &&
                            <NeoGrid.Column
                                alignment="center"
                                sort={false}
                                headerClassName="is-selected"
                                cellClassName={`is-selected selectable text-client-secondary icon-container-client-secondary ${item.sameAward ? "hidden" : ""}`}
                                rowSpan={item.sameAwardRowCount}
                                label={<Neo.Button variant="link" onClick={() => viewModel.selectAll(this.props.pageManager.data)}>
                                    {this.props.viewModel.activeAwardSelector.inAllSelectedMode ?
                                        (screenSize <= ScreenSize.Small ? "None" : "Deselect\nall") :
                                        (screenSize <= ScreenSize.Small ? "All" : "Select\nall")}
                                </Neo.Button>}
                                onClick={() => viewModel.itemSelected(item as TLookup, this.props.pageManager.data)}
                                display={item.meta.isSelected} >
                            </NeoGrid.Column>}

                        {this.props.showAwardDate &&
                            <NeoGrid.Column display={item.meta.awardDate}
                                hideBelow="lg" />}

                        <NeoGrid.Column display={meta.incentiveSchemeName} label="Scheme"
                            cellClassName="max-100" />

                        {showInstrumentColumn &&
                            <NeoGrid.Column
                                display={meta.optionInstrumentCode}
                                label="Instrument"
                                data-tip={item.optionInstrumentName}
                                data-tip-align="start"
                            />}

                        {showExpectedValue &&
                            this.getValueColumn({
                                label: this.getMultiLevelLabel(`Award in ${viewModel.defaultCurrency.currencyCode}`, `in expected value`),
                                sort: false,
                                targetProperty: meta.awardDefaultCurrencyExpectedValue,
                                altValue: meta.optionOfferedValue,
                                numberFormat: Misc.NumberFormat.CurrencyNoDecimals,
                                currencySymbol: !item.hasMissingDependencies("awardDefaultCurrencyExpectedValue") ? viewModel.defaultCurrency.symbol : item.offeredSymbol,
                                hideBelow: "sm",
                                suppressField: (item.awardPrepId !== 0) && !item.useExpectedValue
                            }, item as TLookup, meta, "Expected value of the Long Term Incentive award (LTI) at the award date is the value that, at the time of grant, can be reasonably expected over the full plan period. It's never a guarantee and the final value depends on how the business performs over time.")
                        }

                        {!this.props.hideFaceValue && !this.props.config.hideFaceValue &&
                            this.getValueColumn({
                                label: this.getMultiLevelLabel(`Award in ${viewModel.defaultCurrency.currencyCode}`, `in face value`),
                                sort: false,
                                targetProperty: meta.awardDefaultCurrencyFaceValue,
                                altValue: meta.optionOfferedValue,
                                numberFormat: Misc.NumberFormat.CurrencyNoDecimals,
                                currencySymbol: !item.hasMissingDependencies("awardDefaultCurrencyFaceValue") ? viewModel.defaultCurrency.symbol : item.offeredSymbol,
                                suppressField: (item.awardPrepId !== 0) && !item.optionMarketPriceAtAwardDate
                            }, item as TLookup, meta, "Face value of the Long Term Incentive award (LTI) is the number of units granted, multiplied by the share price on grant date")
                        }

                        {this.getValueColumn({
                            label: "Total\nunits",
                            sort: false,
                            targetProperty: this.props.config.awardedUnitsDisplayType === AwardedUnitsDisplayType.UnitValue ? meta.offeredUnits : meta.optionDefaultUnits,
                            altValue: this.props.config.awardedUnitsDisplayType === AwardedUnitsDisplayType.UnitValue ? meta.offeredUnits : meta.optionOfferedValue,
                            numberFormat: this.props.config.awardedUnitsDisplayType === AwardedUnitsDisplayType.UnitValue ? Misc.NumberFormat.NoDecimals : !item.hasMissingDependencies("optionDefaultUnits") ? Misc.NumberFormat.NoDecimals : Misc.NumberFormat.CurrencyNoDecimals,
                            currencySymbol: this.props.config.awardedUnitsDisplayType === AwardedUnitsDisplayType.UnitValue ? "" : !item.hasMissingDependencies("optionDefaultUnits") ? viewModel.defaultCurrency.symbol : item.offeredSymbol,
                            hideBelow: "md"
                        }, item as TLookup, meta)}

                        <NeoGrid.Column display={meta.optionAwardPrice}
                            label={"Award\nprice"}
                            numProps={{ formatString: `${item.optionSymbol} #,##0.00######;(${item.optionSymbol}#,##0.00######)` }} />

                        {showMarketPriceAtAwardDateColumn &&
                            <NeoGrid.Column display={meta.optionMarketPriceAtAwardDate}
                                label={"Market Price at\nAward Date"}
                                numProps={{ formatString: `${item.optionSymbol} #,##0.00######;(${item.optionSymbol}#,##0.00######)` }} />}

                        {this.getValueColumn({
                            label: "Current\ngross value",
                            sort: false,
                            targetProperty: meta.currentGrossValue,
                            altValue: meta.optionOfferedValue,
                            numberFormat: Misc.NumberFormat.CurrencyNoDecimals,
                            currencySymbol: !item.hasMissingDependencies("currentGrossValue") ? item.optionSymbol : item.offeredSymbol,
                            hideBelow: "lg"
                        }, item as TLookup, meta, "Number of units x current share price (before any award costs, taxes or other costs eg. brokerage")}

                        {this.getValueColumn({
                            label: "Award\ncosts/income",
                            sort: false,
                            targetProperty: meta.awardCost,
                            altValue: meta.optionOfferedValue,
                            numberFormat: Misc.NumberFormat.CurrencyNoDecimals,
                            currencySymbol: !item.hasMissingDependencies("awardCost") ? item.optionSymbol : item.offeredSymbol,
                        }, item as TLookup, meta)}

                        <NeoGrid.Column display={meta.vestingSchedule}
                            label={"Vesting period"}
                            cellClassName="max-100"
                            hideBelow="xl" />

                        {this.rightColumns(item as TLookup)}

                        {/*     DEBUGGING COLUMNS
                                    <NeoGrid.Column display={meta.awardApprovalId} />
                                    <NeoGrid.Column display={meta.awardPrepId} />
                                    <NeoGrid.Column display={meta.approvedOn} />
                                    <NeoGrid.Column display={meta.declinedOn} /> */}
                    </NeoGrid.Row>)
                    }
                </NeoGrid.Grid>
            </Neo.Pager>
        )
    }
}