import { Attributes, ModelBase, NeoModel, Validation } from '@singularsystems/neo-core';

@NeoModel
export default class UpdateIncentiveGroupAllocationCommand extends ModelBase {

    public allocationIncentiveGroupAllocationId: number = 0;

    @Attributes.Integer()
    public proposedAllocationFaceValue: number = 0;

    @Attributes.Integer()
    public proposedAllocationExpectedValue: number = 0;

    public applyConversion: boolean = false;
    
    @Attributes.Integer()
    public assumedIncentiveSchemeId: number | null = null;

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew) {
            return "New update incentive group allocation command";
        } else {
            return "Update Incentive Group Allocation Command";
        }
    }
}