
export enum TaxDirectiveStatus {
    None = 10,
    Pending = 20,
    Complete = 100,
    NotApplicable = 200,
}

export default class TaxDirectiveStatusHelper {

    // Client only properties / methods
}