import { NeoModel } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import { NotificationDuration } from '../../../App/Models/Enums/NotificationDuration';
import UpdateContactDetailsCommand from '../Models/Commands/UpdateContactDetailsCommand';
import ParticipantContactDetailsLookup from '../Models/Lookups/ParticipantContactDetailsLookup';
import { AppService, Types } from '../ParticipantsTypes';

@NeoModel
export default class ContactDetailsVM extends Views.ViewModelBase {

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        public apiClient = AppService.get(Types.Participants.ApiClients.MainApiClient)) {

        super(taskRunner);
    }

    public contactDetails = new ParticipantContactDetailsLookup();

    public editCommand: UpdateContactDetailsCommand | null = null;

    public async initialise() {
        const result = await this.taskRunner.waitFor(this.apiClient.getContactDetails());
        this.contactDetails = ParticipantContactDetailsLookup.fromJSObject<ParticipantContactDetailsLookup>(result.data);
    }

    public beginEditContactDetails() {
        this.editCommand = UpdateContactDetailsCommand.mapFrom<UpdateContactDetailsCommand>(this.contactDetails);
    }

    public saveContactDetails() {
        this.taskRunner.run(async () => {
            await this.apiClient.updateContactDetails(this.editCommand!.toJSObject());
            
            this.contactDetails.correspondenceEmail = this.editCommand!.correspondenceEmail;
            this.contactDetails.correspondenceMobileNumber = this.editCommand!.correspondenceMobileNumber;
            this.editCommand = null;
            this.notifications.addSuccess("Contact details updated successfully", "", NotificationDuration.Standard);
        })
    }
}