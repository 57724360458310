import React from 'react';
import { observer } from 'mobx-react';
import { Neo } from '@singularsystems/neo-react';
import QuestionnaireQuestionAnswerLookup from '../../../../../../Models/Questionnaires/Queries/QuestionnaireQuestionAnswerLookup';
import QuestionnaireAnsweredLookup from '../../../../../../Models/Questionnaires/Queries/QuestionnaireAnsweredLookup';

interface IQuestionDropDownComponentProps {
    questionAnswered: QuestionnaireAnsweredLookup | null;
    questionNumber: number;
    question: QuestionnaireQuestionAnswerLookup;
    disabled: boolean;
}

@observer
export default class QuestionDropDownComponent extends React.Component<IQuestionDropDownComponentProps> {

    public render() {
        const question = this.props.question;
        const questionLabel = this.props.questionNumber + ". " + question.question;
        return (
            <div>
                {question &&
                    <Neo.FormGroup bind={question.meta.questionAnswerDropDownValue}
                        label={questionLabel}
                        placeholder={question.answerPlaceholder}
                        editorProps={{
                            "data-tip": question.questionInfoText,
                            disabled: this.props.disabled
                        } as any}
                        select={{ items: question.dropDownItems, valueMember: "itemValue", displayMember: "itemDisplay" }} />
                }
            </div>
        )
    }
}