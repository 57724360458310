import React from 'react';
import { observer } from 'mobx-react';

import QuestionYesNoComponent from './Type/QuestionYesNoComponent';
import QuestionTextComponent from './Type/QuestionTextComponent';
import QuestionDropDownComponent from './Type/QuestionDropDownComponent';
import QuestionnaireQuestionAnswerLookup from '../../../../../Models/Questionnaires/Queries/QuestionnaireQuestionAnswerLookup';
import { QuestionType } from '../../../../../../Common/Models/Enums/QuestionType';
import QuestionnaireAnsweredLookup from '../../../../../Models/Questionnaires/Queries/QuestionnaireAnsweredLookup';

interface IQuestionComponentProps {
    questionAnswered: QuestionnaireAnsweredLookup | null;
    questionNumber: number;
    questionAnswer: QuestionnaireQuestionAnswerLookup;
    disabled: boolean;
}

@observer
export default class QuestionComponent extends React.Component<IQuestionComponentProps> {
    public render() {
        const answer = this.props.questionAnswer;
        return (
            <div>
                {(answer.questionType === QuestionType.ShortText ||
                    answer.questionType === QuestionType.LongText) &&
                    <QuestionTextComponent
                        questionNumber={this.props.questionNumber}
                        question={answer}
                        questionAnswered={this.props.questionAnswered}
                        disabled={this.props.disabled} />}
                {answer.questionType === QuestionType.DropDown &&
                    <QuestionDropDownComponent
                        questionNumber={this.props.questionNumber}
                        question={answer}
                        questionAnswered={this.props.questionAnswered}
                        disabled={this.props.disabled} />}
                {answer.questionType === QuestionType.YesOrNo &&
                    <QuestionYesNoComponent
                        questionNumber={this.props.questionNumber}
                        question={answer}
                        questionAnswered={this.props.questionAnswered}
                        disabled={this.props.disabled} />}
            </ div >
        )
    }
}
