import { Attributes, Misc, NeoModel, Validation, ValueObject } from '@singularsystems/neo-core';

@NeoModel
export default class TradeRequestCriteria extends ValueObject {

    @Attributes.Date(Misc.TimeZoneFormat.None)
    public fromDate: Date | null = null;

    @Attributes.Date(Misc.TimeZoneFormat.None)
    public toDate: Date | null = null;

    public activeTrades: boolean | null = null;

    @Attributes.Nullable()
    public tradeRequestId: number | null = null;

    @Attributes.Nullable()
    public incentiveSchemeId: number | null = null;

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        return "Trade Request Criteria";
    }
}