import { injectable } from 'inversify';

export interface ISTUtils {
    getInitials(fullName: string): string; 
}

@injectable()
export default class STUtils implements ISTUtils {

    public getInitials(fullName: string) {
        const splitName = fullName.split(" ");
        // First and Last capitalised, others only capitalised if more than 3 chars
        return splitName.map((name, index) => name[0] ? 
                                                (splitName.length > 3 || index === 0 || index === splitName.length - 1 ? 
                                                    name[0].toUpperCase() : 
                                                    name[0]) :
                                                "").join("");
    }
}