import { Attributes, NeoModel, Utils, Validation, ValueObject } from '@singularsystems/neo-core';
import { ParticipantAwardSelectionOption } from '../../../../Common/Models/Enums/ParticipantAwardSelectionOption';

@NeoModel
export default class UpdateParticipantSelectionCommand extends ValueObject {

    public participantOfferId: number = 0;

    public awardOptionInstrumentPriceId: number = 0;

    public participantId: string = Utils.emptyGuid();

    public selectedOption: ParticipantAwardSelectionOption | null = null;

    @Attributes.Float()
    public percent: number = 0;

    // Client only properties / methods

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        return "Update Participant Selection Command";
    }
}