import React from "react";
import { observer } from "mobx-react";
import { Neo, Views } from "@singularsystems/neo-react";
import { Data, NeoModel } from "@singularsystems/neo-core";
import { AppService, Types } from '../../AllocationManagerTypes';
import AllocationParticipantChangeRequest from "../../Models/AllocationParticipantChangeRequest";
import { ChangeRequestReason } from "../../Models/Enums/ChangeRequestReason";

interface IChangeRequestModalProps {
    onClose: () => void;
    sendRequest: (changeRequest: AllocationParticipantChangeRequest) => void;
    show: boolean;
    participantName: string;
}

@NeoModel
export class ChangeRequestModalVM extends Views.ViewModelBase {
    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner)) {
        super(taskRunner);
    }
    public changeRequest = new AllocationParticipantChangeRequest();
}

@observer
export default class ChangeRequestModal extends React.Component<IChangeRequestModalProps> {

    private viewModel = new ChangeRequestModalVM();
    constructor(props: IChangeRequestModalProps) {
        super(props)
        this.viewModel = new ChangeRequestModalVM();
    }

    public async sendChangeRequest() {
        this.viewModel.changeRequest.participantFullName = this.props.participantName;
        this.props.sendRequest(this.viewModel.changeRequest)
    }

    render() {
        const request = this.viewModel.changeRequest;
        return (
            <>
                <Neo.Modal title={"Request change for " + this.props.participantName} size="lg" className={"request-modal"}
                    show={this.props.show}
                    onClose={() => this.props.onClose()}
                    closeButton={false}
                    acceptButton={false}>
                    <Neo.Loader task={this.viewModel.taskRunner}>
                        <div>
                            <Neo.FormGroupInline suppressLabel bind={request.meta.changeRequestReasonId}
                                select={{
                                    itemSource: Data.StaticDataSource.fromEnum(ChangeRequestReason),
                                    nullText: "Select change request option"
                                }}
                            />
                            <Neo.FormGroupInline suppressLabel placeholder="Name of new people or allocation manager (if known)" bind={request.meta.managerName} hidden={request.meta.changeRequestReasonId !== null && request.meta.changeRequestReasonId.value !== ChangeRequestReason.ReAssignManager} />
                            <Neo.FormGroupInline suppressLabel placeholder="Additional notes" bind={request.meta.additionalNotes} input={{ rows: 3 }} hidden={request.meta.changeRequestReasonId !== null && (request.meta.changeRequestReasonId.value !== ChangeRequestReason.Other && request.meta.changeRequestReasonId.value !== ChangeRequestReason.ReAssignManager)} />
                            <Neo.FormGroupInline suppressLabel placeholder="Reason for removal" bind={request.meta.removalReason} input={{ rows: 3 }} hidden={request.meta.changeRequestReasonId !== null && request.meta.changeRequestReasonId.value !== ChangeRequestReason.Remove} />
                            <Neo.Button style={{ float: 'right' }} text="Send request" onClick={() => this.sendChangeRequest()} />
                        </div>
                    </Neo.Loader>
                </Neo.Modal >
            </>
        )
    }
}