import { Attributes, LookupBase, Misc, NeoModel } from '@singularsystems/neo-core';

@NeoModel
export default class ExchangeRateLookup extends LookupBase {

    public fromCurrencyId: number = 0;

    public fromCurrencyCode: string = "";

    public toCurrencyId: number = 0;

    public toCurrencyCode: string = "";

    @Attributes.Float()
    public rate: number = 0;

    @Attributes.Date(Misc.TimeZoneFormat.None)
    public rateDate: Date = new Date();

    // Client only properties / methods
}