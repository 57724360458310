import { Attributes, LookupBase, NeoModel } from '@singularsystems/neo-core';
import { InstrumentType } from '../Enums/InstrumentType';

export class InstrumentLookupBase extends LookupBase {

    public instrumentId: number = 0;

    public instrumentCode: string = "";

    public instrumentName: string = "";

    public instrumentType: InstrumentType | null = null;

    public currencyCode: string = "";

    public currencySymbol: string = "";

    @Attributes.Integer()
    public allowedDecimals: number = 0;

    // Client only properties / methods
}

@NeoModel
export default class InstrumentLookup extends InstrumentLookupBase {

    // Client only properties / methods
}