import React from 'react';
import { Neo, Views } from '@singularsystems/neo-react';
import { observer } from 'mobx-react';

import { AppService, AppTypes } from '../../Services/AppService';
import Home from '../../Views/Home';

@observer
export default class SilentSignInView extends Views.ViewBase  {

    private authenticationService = AppService.get(AppTypes.Shared.Services.STAuthenticationService);

    constructor(props: unknown) {
        super("", Views.EmptyViewModel, props);
    }

    public initialise() {
        this.taskRunner.run(async () => {
            await this.authenticationService.signinSilent();

            if (window.location.search) {
                const search = window.location.search;
                if (search.indexOf("=") > -1) {
                    const url = search.substring(search.indexOf("=") + 1);
                    window.location.href = url;
                } else {
                    this.navigation.navigateToView(Home);
                }
            } else {
                this.navigation.navigateToView(Home);
            }
            
        });
    }

    public render() {
        return (
            <div>
			    <h5>Signing in...</h5>
                <Neo.Loader task={this.taskRunner} />
            </div>
        );
    }
}