import { Data, List, Misc, ModalUtils, NeoModel } from '@singularsystems/neo-core';
import { Views } from '@singularsystems/neo-react';
import { AllocationPlanSection } from '../Models/Enums/AllocationPlanSection';
import AllocationManagerLinkedManagerLookup from '../Models/Queries/AllocationManagerLinkedManagerLookup';
import AllocationManagerLinkedManagerLookupCriteria from '../Models/Queries/AllocationManagerLinkedManagerLookupCriteria';
import AllocationParticipantLookup from '../Models/Queries/AllocationParticipantLookup';
import AllocationParticipantLookupCriteria from '../Models/Queries/AllocationParticipantLookupCriteria';
import AllocationPlanInformation from '../Models/Queries/AllocationPlanInformation';
import { AppService, Types } from '../AllocationManagerTypes';
import FusionImportLookup from '../../../../Participants/ParticipantsApp/Models/Import/Queries/FusionImportLookup';
import AllocationManagerLookup from '../Models/Queries/AllocationManagerLookup';
import AllocationManagerLookupCriteria from '../Models/Queries/AllocationManagerLookupCriteria';
import IncentiveGroup from '../IncentiveGroup';
import AllocationManagerStartupData from '../Models/Queries/AllocationManagerStartupData';
import UpdateAllocationManagerCommand from '../Models/Commands/UpdateAllocationManagerCommand';
import { NotificationDuration } from '../../../../App/Models/Enums/NotificationDuration';
import GridColumnConfig from '../../Common/Models/GridColumnConfig';
import CurrencyLookup from '../../../../Transactions/Common/Models/CurrencyLookup';

@NeoModel
export default class AllocationManagerVM extends Views.ViewModelBase {

  constructor(
    taskRunner = AppService.get(Types.Neo.TaskRunner),
    private allocationParticipantQueryApiClient = AppService.get(Types.AllocationManager.ApiClients.AllocationParticipantQueryApiClient),
    private allocationManagerQueryApiClient = AppService.get(Types.AllocationManager.ApiClients.AllocationManagerQueryApiClient),
    private allocationManagerApiClient = AppService.get(Types.AllocationManager.ApiClients.AllocationManagerApiClient),
    private catalogueQueryApiClient = AppService.get(Types.Awards.ApiClients.CatalogueQueryApiClient),
    private participantsApiClient = AppService.get(Types.Participants.ApiClients.MainApiClient),
    private allocationParticipantApiClient = AppService.get(Types.AllocationManager.ApiClients.AllocationParticipantApiClient),
    private allocationReportingApiClient = AppService.get(Types.AllocationManager.ApiClients.AllocationReportingApiClient),
    private notifications = AppService.get(Types.Neo.UI.GlobalNotifications)) {

    super(taskRunner);

    this.autoDispose(this.directReportsCriteria.onAnyPropertyChanged(() => this.directReportsPageManager.refreshData()));
  }

  public welcomeSection = new AllocationPlanInformation();
  public hRAssistSection = new AllocationPlanInformation();
  public proposeAllocationsSection = new AllocationPlanInformation();
  public approveAllocationsSection = new AllocationPlanInformation();
  public allocationPlanInformationList = new List(AllocationPlanInformation);
  public hasLinkedManagers = false;
  public fusionInformation = new FusionImportLookup();
  public showAddRequestModal = false;
  public proposeAllocationExpanded = false;
  public approveAllocationExpanded = false;

  //Gets currently logged in managers details
  public currentlyLoggedInManagerDetails = new AllocationManagerLookup();

  public incentiveGroupList = new List(IncentiveGroup);
  public allocationGridColumnConfig = new List(GridColumnConfig);
  public participantGradeIds: number[] = [];
  public allocationCurrency = new CurrencyLookup();

  public async initialise() {
    const result = await this.taskRunner.waitFor(this.allocationManagerQueryApiClient.getStartupData());
    const startupData = AllocationManagerStartupData.fromJSObject<AllocationManagerStartupData>(result.data);
    const allocationCurrencyResult = await this.taskRunner.waitFor(this.catalogueQueryApiClient.getCurrency(startupData.allocationCurrencyCode));
    this.allocationCurrency = CurrencyLookup.fromJSObject<CurrencyLookup>(allocationCurrencyResult.data);

    const fusionResponse = await this.participantsApiClient.getLastFusionImport();
    if (fusionResponse.data) {
      this.fusionInformation.set(fusionResponse.data);
    }

    this.currentlyLoggedInManagerDetails = startupData.allocationManager;
    this.currentlyLoggedInManagerDetails.canEdit = this.currentManagerCanEdit();
    this.allocationGridColumnConfig = startupData.gridColumnConfig;
    this.participantGradeIds = startupData.participantGradeIds;

    this.approveAllocationExpanded = this.currentlyLoggedInManagerDetails.isHrAssist;

    this.setupAllocationPlanInformation(startupData.allocationPlanInformation);
    this.hasDirectReports = startupData.hasDirectReports;

    this.populateIncentiveGroupList();

    this.linkedManagerPageManager.refreshData(); // required for showing / hiding Linked Manager grid. 
  }

  public async setupAllocationPlanInformation(infoList: List<AllocationPlanInformation>) {
    this.allocationPlanInformationList = infoList;
    this.welcomeSection = this.allocationPlanInformationList.find(c => c.allocationPlanSectionId === AllocationPlanSection.Welcome) ?? new AllocationPlanInformation();
    this.hRAssistSection = this.allocationPlanInformationList.find(c => c.allocationPlanSectionId === AllocationPlanSection.HRAssist) ?? new AllocationPlanInformation();
    this.proposeAllocationsSection = this.allocationPlanInformationList.find(c => c.allocationPlanSectionId === AllocationPlanSection.ProposeAllocations) ?? new AllocationPlanInformation();
    this.approveAllocationsSection = this.allocationPlanInformationList.find(c => c.allocationPlanSectionId === AllocationPlanSection.ApproveAllocations) ?? new AllocationPlanInformation();
  }

  public hasDirectReports = false;
  public directReportsCriteria = new AllocationParticipantLookupCriteria();
  public directReportsPageManager = new Data.PageManager(
    this.directReportsCriteria,
    AllocationParticipantLookup,
    this.allocationParticipantQueryApiClient.getAllocationParticipantsPaged,
    {
      sortBy: "participantName",
      fetchInitial: true,
      initialTaskRunner: this.taskRunner,
      pageSizeOptions: [1, 5, 10, 15, 20, 50, 100],
      pageSize: 10,
    }
  );

  public amLinkedManagerLookupCriteria = new AllocationManagerLinkedManagerLookupCriteria()
  public linkedManagerPageManager = new Data.PageManager(
    this.amLinkedManagerLookupCriteria,
    AllocationManagerLinkedManagerLookup,
    this.allocationManagerQueryApiClient.getAllocationManagerLinkedManagerLookups,
    {
      sortBy: "participantName",
      pageSizeOptions: [1, 5, 10, 15, 20, 50, 100],
      pageSize: 5,
      fetchInitial: false,
    }
  );

  public allocationManagerCriteria = new AllocationManagerLookupCriteria();

  public getTotalBudgets(incentiveGroupId: number) {
    let totalBudget = 0;
    this.linkedManagerPageManager.data.forEach(linkedManager => {
      let managerBudget = linkedManager.remainingIncentiveGroupBudgets.find(c => c.incentiveGroupId === incentiveGroupId)?.budgetAmount
      if (managerBudget) {
        totalBudget += managerBudget;
      }
    })
    return totalBudget;
  }

  public getRemainingBudgets(incentiveGroupId: number) {
    let remainingBudget = 0
    this.linkedManagerPageManager.data.forEach(linkedManager => {
      let managerBudget = linkedManager.remainingIncentiveGroupBudgets.find(c => c.incentiveGroupId === incentiveGroupId)
      if (managerBudget) {
        let budgetUsed = 0;
        // check if it has fetched the linked managers individual data yet, 
        // if it has, calculate the totals client side
        // if not, use the data from server side
        if (linkedManager.directReportsPageManager.data.length > 0) {
          linkedManager.directReportsPageManager.data.forEach(directManager => {
            directManager.incentiveGroupAllocations.forEach(allocation => {
              if (allocation.incentiveGroupId === incentiveGroupId) {
                budgetUsed += allocation.proposedAllocationFaceValue;
              }
            });
          })
          managerBudget.budgetRemaining = managerBudget.budgetAmount - budgetUsed;
          managerBudget.budgetUsed = budgetUsed;
          remainingBudget += managerBudget.budgetRemaining;
        }
        else {
          remainingBudget += managerBudget.budgetRemaining;
        }
      }
    })
    return remainingBudget;
  }


  public openProposeAllocationAccordian() {
    this.proposeAllocationExpanded = !this.proposeAllocationExpanded;
    this.approveAllocationExpanded = false;
  }

  public openApproveAllocationAccordian() {
    this.approveAllocationExpanded = !this.approveAllocationExpanded;
    this.proposeAllocationExpanded = false;
  }

  public populateIncentiveGroupList() {
    this.currentlyLoggedInManagerDetails.remainingIncentiveGroupBudgets.forEach(budgetInfo => {
      let newIncentiveGroup = this.incentiveGroupList.addNew();
      newIncentiveGroup.incentiveGroupId = budgetInfo.incentiveGroupId;
      newIncentiveGroup.incentiveGroupName = budgetInfo.incentiveGroup;
    });
  }

  public refreshAllocationsLabels(allocationParticipant: AllocationParticipantLookup) {
    const allocationsChange = allocationParticipant.getAllocationCompletedChangeValue();
    this.currentlyLoggedInManagerDetails.allocationTotals.allocationsComplete += allocationsChange;
    this.currentlyLoggedInManagerDetails.allocationTotals.allocationsOutstanding -= allocationsChange;
  }

  public refreshManagersLabels(allocationParticipant: AllocationParticipantLookup, manager: AllocationManagerLinkedManagerLookup) {
    const allocationsChange = allocationParticipant.getAllocationCompletedChangeValue();
    manager.allocationsComplete += allocationsChange;
    manager.allocationsOutstanding -= allocationsChange;
    if (allocationsChange !== 0) {
      const managersCompletedChange = manager.getHasCompletedChangedValue()
      this.currentlyLoggedInManagerDetails.allocationTotals.linkedManagersComplete += managersCompletedChange;
      this.currentlyLoggedInManagerDetails.allocationTotals.linkedManagersStillToComplete -= managersCompletedChange;
    }
  }

  public async approveTeam(manager: AllocationManagerLinkedManagerLookup) {
    var updateManager = new UpdateAllocationManagerCommand();
    updateManager.mapFrom(manager);
    updateManager.approved = true;
    await this.taskRunner.run(() => this.allocationManagerApiClient.updateAllocationManagerPartial(updateManager.toJSObject()));
    this.notifications.addSuccess("Team approved", null, NotificationDuration.Standard);
    const response = await this.taskRunner.waitForData(this.allocationManagerQueryApiClient.getLastEditByManagerInformation(this.currentlyLoggedInManagerDetails.participantId))
    this.currentlyLoggedInManagerDetails.lastEditedInfoLookup.set(response);
    this.linkedManagerPageManager.refreshData();
  }

  public async approveAllocationsQuestion() {
    if (await ModalUtils.showYesNo("Approve Allocations", "Are you sure you want to Approve all Allocations?") === Misc.ModalResult.Yes) {
      this.approveAllocations();
    }
  }

  public async approveAllocations() {
    await this.taskRunner.run(() => this.allocationParticipantApiClient.approveAllocations());
    this.notifications.addSuccess("Allocations approved", null, NotificationDuration.Standard);
    const response = await this.taskRunner.waitForData(this.allocationManagerQueryApiClient.getLastEditByManagerInformation(this.currentlyLoggedInManagerDetails.participantId))
    this.currentlyLoggedInManagerDetails.lastEditedInfoLookup.set(response);
    this.linkedManagerPageManager.refreshData();
  }

  public async submitForReview() {
    var updateManager = new UpdateAllocationManagerCommand();
    updateManager.participantId = this.currentlyLoggedInManagerDetails.participantId;
    updateManager.readyForReview = true;
    await this.taskRunner.run(() => this.allocationManagerApiClient.updateAllocationManagerPartial(updateManager.toJSObject()));
    this.notifications.addSuccess("Submitted", null, NotificationDuration.Standard);
    this.currentlyLoggedInManagerDetails.checkedDate = new Date();
    const response = await this.taskRunner.waitForData(this.allocationManagerQueryApiClient.getLastEditByManagerInformation(this.currentlyLoggedInManagerDetails.participantId))
    this.currentlyLoggedInManagerDetails.lastEditedInfoLookup.set(response);
    this.directReportsPageManager.refreshData();
    this.openApproveAllocationAccordian();
  }

  public currentManagerCanEdit() {
    var currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    var startDate = new Date(this.currentlyLoggedInManagerDetails.startDate);
    var endDate = new Date(this.currentlyLoggedInManagerDetails.endDate);
    return (startDate <= currentDate && endDate >= currentDate) || this.currentlyLoggedInManagerDetails.isUnlocked;
  }

  public async getAllocationParticipantExtract() {
    let criteria = this.directReportsCriteria;
    criteria.allocationManagerParticipantId = this.currentlyLoggedInManagerDetails.participantId;
    criteria.allocationManagerId = this.currentlyLoggedInManagerDetails.allocationManagerId;
    const response = await this.taskRunner.waitFor(
      this.allocationReportingApiClient.getAllocationParticipantsExcelExportFile(criteria.toJSObject()),
      { allowPost: true });

    return response;
  }
}