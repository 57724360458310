import React from 'react';
import { Neo, NeoGrid } from '@singularsystems/neo-react';
import AllocationGridComponentVM from './AllocationGridComponentVM';
import { observer } from 'mobx-react';
import { Data, FileUtils, ITaskRunner, List, Misc, ModalUtils, NumberUtils } from '@singularsystems/neo-core';
import AllocationParticipantLookup from '../Models/Queries/AllocationParticipantLookup';
import AllocationParticipantLookupCriteria from '../Models/Queries/AllocationParticipantLookupCriteria';
import AllocationHistoryComponent from './AllocationHistoryComponent';
import ChangeRequestModal from './Components/ChangeRequestModal';
import AddParticipantModal from './Components/AddParticipantModal';
import UpdateParticipantAllocationCommand from '../Models/Commands/UpdateParticipantAllocationCommand';
import ValidationDisplay from '../../../../App/Common/Components/Application/ValidationDisplay';
import UpdateParticipantAllocationCommentCommand from '../Models/Commands/UpdateParticipantAllocationCommentCommand';
import AllocationManagerLookup from '../Models/Queries/AllocationManagerLookup';
import AllocationManagerVM from './AllocationManagerVM';
import AllocationManagerLinkedManagerLookup from '../Models/Queries/AllocationManagerLinkedManagerLookup';
import GridColumnConfig from '../../Common/Models/GridColumnConfig';
import { GridColumn } from '../../Common/Models/Enums/GridColumn';
import UpdateSchemeSplitCommand from '../Models/Commands/UpdateSchemeSplitCommand';
import { TicketProviderType } from '../../../../Clients/Common/Models/TicketProviders/TicketProviderType.enum';
import CurrencyLookup from '../../../../Transactions/Common/Models/CurrencyLookup';
import UpdateParticipantCommentCommand from '../Models/Commands/UpdateParticipantCommentCommand';
import AllocationCommentLookup from '../Models/Queries/AllocationCommentLookup';


interface IAllocationGridProps {
    taskRunner: ITaskRunner;
    pageManager: Data.PageManager<AllocationParticipantLookupCriteria, AllocationParticipantLookup>
    currentAllocationManager: AllocationManagerLookup;
    allocationManagerViewModel: AllocationManagerVM;
    linkedManager?: AllocationManagerLinkedManagerLookup;
    allocationGridColumnConfig: List<GridColumnConfig>;
    participantGradeIds?: number[];
    allocationCurrency: CurrencyLookup;
}

@observer
export default class AllocationGridComponent extends React.Component<IAllocationGridProps> {

    private viewModel = new AllocationGridComponentVM(this.props.taskRunner, this.props.pageManager, this.props.currentAllocationManager, this.props.allocationGridColumnConfig, this.props.allocationCurrency);
    private allocationManagerViewModel = this.props.allocationManagerViewModel;

    private async showUnsavedChanges() {
        ModalUtils.showYesNoDismissible(
            `Unsaved changes?`,
            `Any unsaved changes will be lost. Are you sure you want to cancel?`,
            () => this.resetUpdateCommandAndSchemeSplitList()
        );
    }

    private cancelAllocation() {
        ModalUtils.showYesNoDismissible(
            `Cancel allocation?`,
            `Cancellation means you will need to re-enter the proposal. Are you sure you want to cancel?`,
            () => {
                this.viewModel.updateCommentCommand = null;
                this.viewModel.selectedParticipant = new AllocationParticipantLookup();
                this.viewModel.pageManager?.refreshData();
            }
        );
    }

    private addComment(overTheMaxCommentRequired: Boolean, command: UpdateParticipantAllocationCommentCommand) {
        const participantComment = new UpdateParticipantCommentCommand();
        if (overTheMaxCommentRequired) {
            participantComment.allocationCommentTypeId = this.viewModel.getOverTheMaxCommentTypeId();
        };

        const commentType = this.viewModel.commentTypes.data.find(c => c.allocationCommentTypeId === participantComment.allocationCommentTypeId);
        const options = commentType ? commentType.options : [];
        participantComment.commentTypeOptions.set(options as any);
        command.participantComments.push(participantComment);
        this.viewModel.updateCommentCommand = command;

        const allocationComment = new AllocationCommentLookup();
        allocationComment.commentType = commentType?.commentType as string;
        allocationComment.allocationCommentTypeId = participantComment.allocationCommentTypeId as number;
        this.viewModel.selectedParticipant.allocationComments.push(allocationComment);
    }

    private redirectToTicketingPlatform() {
        this.viewModel.taskRunner.run(async () => {
            const result = await this.viewModel.clientsApiClient.getTicketProviderUrl(false);

            if (result.data.redirectMessage) {
                ModalUtils.showMessage("4Me Service Management Platform", <p>{result.data.redirectMessage}</p>)
            }

            setTimeout(function () {
                window.open(result.data.redirectUrl.toString(), "_blank");
            }, result.data.redirectMessage ? 5000 : 0);
        });
    }

    public componentDidMount() {
        this.viewModel.initialise();
    }

    private async saveDirectReportsExcelFile() {
        const blobData = await this.allocationManagerViewModel.getAllocationParticipantExtract();
        if (blobData) {
            FileUtils.showSaveFile(blobData);
        }
    }

    private resetUpdateCommandAndSchemeSplitList() {
        this.viewModel.updateCommand!.schemeSplits = new List(UpdateSchemeSplitCommand);
        setTimeout(() => this.viewModel.updateCommand = null, 400);
    }

    public render() {
        const allocationCurrency = this.viewModel.allocationCurrency;
        const criteria = this.props.pageManager.criteria!;
        const changeControlConfig = this.viewModel.allocationManagerConfig.changeControlConfig;
        const gridColumnConfig = this.viewModel.allocationGridColumnConfig;
        const miscConfig = this.viewModel.allocationManagerConfig.miscConfig;
        const gradePerformanceConfig = gridColumnConfig.find(c => c.gridColumnId === GridColumn.GradePerformance);
        const teamMemberConfig = gridColumnConfig.find(c => c.gridColumnId === GridColumn.TeamMember);
        const salaryConfig = gridColumnConfig.find(c => c.gridColumnId === GridColumn.Salary);
        const currentValueConfig = gridColumnConfig.find(c => c.gridColumnId === GridColumn.CurrentValue);
        const valuePercSalaryConfig = gridColumnConfig.find(c => c.gridColumnId === GridColumn.ValuePercSalary);
        const lastYearAllocConfig = gridColumnConfig.find(c => c.gridColumnId === GridColumn.LastYearAlloc);
        const schemeConfig = gridColumnConfig.find(c => c.gridColumnId === GridColumn.Scheme);
        const minAndMaxValueGuidelineConfig = gridColumnConfig.find(c => c.gridColumnId === GridColumn.MinAndMaxValueGuideline);
        const allocationConfig = gridColumnConfig.find(c => c.gridColumnId === GridColumn.Allocation);
        const newValuePercSalaryConfig = gridColumnConfig.find(c => c.gridColumnId === GridColumn.NewValuePercSalary);
        const notesConfig = gridColumnConfig.find(c => c.gridColumnId === GridColumn.Notes);

        return (
            <>
                {this.renderModals()}

                <Neo.Pager className="am-table" pageManager={this.props.pageManager}
                    pageControlProps={{
                        firstText: "",
                        prevText: "Prev",
                        nextText: "Next",
                        lastText: "",
                        alignment: "center",
                        autoHide: false,
                        pageSizeLabel: "Show"
                    }}>
                    <>
                        <div className="background-highlight-1 px-3 pt-2" data-tip={"Filter the grid by entering in search criteria"} data-tip-class="grid-tooltip">
                            <span className="text-heading">Filters</span>
                            <Neo.GridLayout md={2} xl={4}>
                                <Neo.FormGroup bind={criteria.meta.participantName} suppressLabel placeholder="Team Member" />
                                <Neo.FormGroup bind={criteria.meta.participantGradeId} suppressLabel select={{ items: this.viewModel.getParticipantGrades(this.props.participantGradeIds ?? []), nullText: "Grade", deSelectText: "All Grades" }} />
                                <Neo.FormGroup bind={criteria.meta.jobTitle} suppressLabel placeholder />
                                <Neo.FormGroup bind={criteria.meta.performanceRating} suppressLabel placeholder />
                            </Neo.GridLayout>
                        </div>

                        <div className="mx-3 mt-1">
                            <NeoGrid.Grid<AllocationParticipantLookup> className="allocation-participants">
                                {(allocationParticipant, meta) => (
                                    <NeoGrid.Row>
                                        <NeoGrid.Column
                                            bindContext={meta.participantName}
                                            label={teamMemberConfig!.gridColumnName}
                                            headerTooltip={teamMemberConfig?.gridColumnTooltip}
                                            data-tip-class="grid-tooltip"
                                            hidden={teamMemberConfig?.isHidden}>
                                            <span className="text-heading no-wrap-xl">
                                                {allocationParticipant.participantName}
                                                {allocationParticipant.isPreviousAllocation &&
                                                    <span
                                                        className="badge badge-info"
                                                        style={{ marginLeft: 5 }}
                                                        data-tip-class="grid-tooltip"
                                                        data-tip={miscConfig.previousAllocationToolTip || "Participant had an allocation within the last 6 months"}>
                                                        Prev
                                                    </span>}
                                            </span>
                                            <br />
                                            <span className="text-smaller text-muted">
                                                {allocationParticipant.jobTitle}
                                            </span>
                                        </NeoGrid.Column>
                                        <NeoGrid.Column label={gradePerformanceConfig?.gridColumnName} alignment="center"
                                            cellClassName="text-smaller" bindContext={meta.participantGrade}
                                            headerTooltip={gradePerformanceConfig?.gridColumnTooltip}
                                            data-tip-class="grid-tooltip"
                                            hidden={gradePerformanceConfig?.isHidden}>
                                            {allocationParticipant.participantGradeLabel}
                                            <br />
                                            <div className="mt-1">{allocationParticipant.performanceRating}</div>
                                        </NeoGrid.Column>
                                        <NeoGrid.Column label={salaryConfig?.gridColumnName.replace("{Currency}", allocationCurrency!.symbol)}
                                            display={meta.annualTCTC}
                                            numProps={{ formatString: allocationCurrency!.symbol + "#,##0", autoRound: true }}
                                            headerTooltip={salaryConfig?.gridColumnTooltip}
                                            data-tip-class="grid-tooltip"
                                            alignment="center"
                                            hidden={salaryConfig?.isHidden} />
                                        <NeoGrid.Column
                                            label={minAndMaxValueGuidelineConfig?.gridColumnName}
                                            alignment="center"
                                            className="cell-highlight-1 header-highlight-1"
                                            headerTooltip={minAndMaxValueGuidelineConfig?.gridColumnTooltip}
                                            data-tip-class="grid-tooltip"
                                            hidden={minAndMaxValueGuidelineConfig?.isHidden}>
                                            {allocationParticipant.allowedValueRange(allocationCurrency!.symbol)}
                                            <br />
                                            <span className="text-smaller">{allocationParticipant.gradeRangeText}</span>
                                        </NeoGrid.Column>
                                        <NeoGrid.Column label={currentValueConfig?.gridColumnName} display={meta.unvestedCostNow}
                                            numProps={{ formatString: allocationCurrency!.symbol + "#,##0", autoRound: true }} cellClassName="text-primary"
                                            headerTooltip={currentValueConfig?.gridColumnTooltip}
                                            data-tip-class="grid-tooltip"
                                            hidden={currentValueConfig?.isHidden} />
                                        <NeoGrid.Column label={valuePercSalaryConfig?.gridColumnName} display={meta.annualValueAsPercSalaryAmount}
                                            numProps={{ format: Misc.NumberFormat.PercentDecimals }} alignment="center"
                                            headerTooltip={valuePercSalaryConfig?.gridColumnTooltip}
                                            data-tip-class="grid-tooltip"
                                            hidden={valuePercSalaryConfig?.isHidden} />
                                        <NeoGrid.Column label={lastYearAllocConfig?.gridColumnName}
                                            headerTooltip={lastYearAllocConfig?.gridColumnTooltip}
                                            data-tip-class="grid-tooltip"
                                            hidden={lastYearAllocConfig?.isHidden}>
                                            <div>
                                                <table className="layout-table allocation-scheme-values">
                                                    {allocationParticipant.incentiveGroupAllocations.map(allocation => (
                                                        <tr>
                                                            <td>
                                                                {allocation.incentiveGroup}
                                                            </td>
                                                            <td className="text-right pl-2">
                                                                {NumberUtils.format(allocation.lastYearsAllocation, Misc.NumberFormat.CurrencyNoDecimals, allocationCurrency!.symbol)}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </table>
                                            </div>
                                        </NeoGrid.Column>

                                        <NeoGrid.Column label={schemeConfig?.gridColumnName}
                                            display={meta.schemeSplitLabelText}
                                            onClick={() => this.viewModel.editSchemeSplit(allocationParticipant)}
                                            className="am-editable-column cell-highlight"
                                            cellClassName="text-smaller"
                                            headerTooltip={schemeConfig?.gridColumnTooltip}
                                            data-tip-class="grid-tooltip"
                                            cellTooltip="Click here to add Schemes"
                                            hidden={schemeConfig?.isHidden}>
                                        </NeoGrid.Column>

                                        <NeoGrid.Column
                                            label={<table className="layout-table-padded">
                                                <tr><td></td><td colSpan={2}>{allocationConfig?.gridColumnName.replace("{Currency}", allocationCurrency!.symbol)}</td></tr>
                                                <tr>
                                                    <td style={{ width: 30 }}></td>
                                                    {/*NB!!! If you are changing the order of these columns, ensure that you change the order of their related cells below*/}
                                                    <td style={{ width: 80 }}>{"Face\nValue"}</td>
                                                    <td style={{ width: 80 }}>{"Indicative Fair\nValue"}</td>
                                                </tr></table>}
                                            alignment="center"
                                            className="cell-highlight"
                                            headerTooltip={allocationConfig?.gridColumnTooltip}
                                            data-tip-class="grid-tooltip"
                                            hidden={allocationConfig?.isHidden}>

                                            <table className="layout-table allocation-scheme-values mb-n1">
                                                {allocationParticipant.incentiveGroupAllocations.map(allocation => (
                                                    <tr>
                                                        <td>
                                                            {allocation.incentiveGroup}:
                                                        </td>
                                                        <td>
                                                            <Neo.FormGroup key={allocation.allocationIncentiveGroupAllocationId}
                                                                suppressLabel
                                                                bind={allocation.meta.proposedAllocationFaceValue}
                                                                numProps={{ currencySymbol: allocationCurrency!.symbol }}
                                                                readOnly={!(this.viewModel.managerCanEdit && allocationParticipant.isParticipantException)}
                                                                className={this.viewModel.managerCanEdit && allocationParticipant.isParticipantException ? "highlight bordered-value" : "ml-2"}
                                                                style={{ width: 120 }}
                                                                onBlur={() => {
                                                                    // Only edit the other value if the enabled field is edited
                                                                    if (!(this.viewModel.managerCanEdit && allocationParticipant.isParticipantException)) return;

                                                                    this.viewModel.calculateExpectedValue(allocationParticipant, allocation.allocationIncentiveGroupAllocationId);
                                                                    if (this.props.linkedManager) {
                                                                        this.allocationManagerViewModel.refreshManagersLabels(allocationParticipant, this.props.linkedManager);
                                                                    } else {
                                                                        this.allocationManagerViewModel.refreshAllocationsLabels(allocationParticipant);
                                                                    }
                                                                }} />
                                                        </td>
                                                        <td>
                                                            <Neo.FormGroup key={allocation.allocationIncentiveGroupAllocationId}
                                                                suppressLabel
                                                                bind={allocation.meta.proposedAllocationExpectedValue}
                                                                numProps={{ currencySymbol: allocationCurrency!.symbol }}
                                                                readOnly={!(this.viewModel.managerCanEdit && !allocationParticipant.isParticipantException)}
                                                                className={this.viewModel.managerCanEdit && !allocationParticipant.isParticipantException ? "highlight bordered-value" : "ml-2"}
                                                                style={{ width: 120 }}
                                                                onBlur={() => {
                                                                    // Only edit the other value if the enabled field is edited
                                                                    if (!(this.viewModel.managerCanEdit && !allocationParticipant.isParticipantException)) return;

                                                                    this.viewModel.calculateFaceValue(allocationParticipant, allocation.allocationIncentiveGroupAllocationId);
                                                                    if (this.props.linkedManager) {
                                                                        this.allocationManagerViewModel.refreshManagersLabels(allocationParticipant, this.props.linkedManager)
                                                                    } else {
                                                                        this.allocationManagerViewModel.refreshAllocationsLabels(allocationParticipant);
                                                                    }
                                                                }}
                                                            />
                                                        </td>
                                                    </tr>
                                                ))}
                                            </table>
                                        </NeoGrid.Column>

                                        <NeoGrid.Column
                                            label={newValuePercSalaryConfig?.gridColumnName}
                                            alignment="center"
                                            headerClassName="cell-highlight"
                                            headerTooltip={newValuePercSalaryConfig?.gridColumnTooltip}
                                            data-tip-class="grid-tooltip"
                                            hidden={newValuePercSalaryConfig?.isHidden}>

                                            <strong className={allocationParticipant.isOverAllocated ? "text-color-negative" : "text-color-positive"}>
                                                {NumberUtils.format(allocationParticipant.newAnnualValueAsPercSalaryAmount, Misc.NumberFormat.PercentDecimals)}
                                            </strong>
                                        </NeoGrid.Column>

                                        <NeoGrid.Column
                                            label={notesConfig?.gridColumnName}
                                            onClick={() => this.viewModel.editComments(allocationParticipant, false, allocationParticipant.isOverAllocated)}
                                            className='am-editable-column text-smaller'
                                            headerClassName="cell-highlight"
                                            headerTooltip={notesConfig?.gridColumnTooltip}
                                            data-tip-class="grid-tooltip"
                                            cellTooltip={"Click here to add " + notesConfig?.gridColumnName}
                                            hidden={notesConfig?.isHidden}>
                                            <span className="text-primary" data-tip="Last changed by" data-tip-class="grid-tooltip">{allocationParticipant.lastChangedDetails}</span>
                                            <div>
                                                {allocationParticipant.allocationComments.map(note => (
                                                    <div className="mt-1">{note.toString()}</div>
                                                ))}
                                                {(allocationParticipant.allocationComments.length === 0 && allocationParticipant.lastChangedDetails === "") &&
                                                    <div className="mt-1">{"Add " + notesConfig?.gridColumnName}</div>
                                                }
                                            </div>
                                        </NeoGrid.Column>

                                        <NeoGrid.ButtonColumn>
                                            <Neo.Button size="sm" variant="info" icon="ellipsis-v" menuItems={miscConfig.hideHistory
                                                ? [{ text: "Request change", onClick: () => this.redirectToTicketingPlatform() }]
                                                : [
                                                    { text: "View History", onClick: () => this.viewModel.showParticipantHistory(allocationParticipant) },
                                                    { text: "Request change", onClick: () => this.redirectToTicketingPlatform() }
                                                ]} menuAlignment="right"></Neo.Button>
                                        </NeoGrid.ButtonColumn>
                                    </NeoGrid.Row>
                                )}
                            </NeoGrid.Grid>
                        </div>

                        <Neo.Button size="sm" variant="link" text="Add another" icon="plus" onClick={() => this.viewModel.showAddRequestModal = true} />
                        {!this.props.linkedManager &&
                            <div>
                                <div className="container">
                                    <div className="row budget-table-row">
                                        <div className="col-9 budget-table-header">
                                            Your remaining face value budget is:
                                        </div>
                                        <div className="col-3">
                                            <table className="layout-table" style={{ width: "auto" }}>
                                                {this.viewModel.incentiveGroupList.map(incentiveGroup => (
                                                    <tr>
                                                        <td>{incentiveGroup.incentiveGroupName}</td>
                                                        <td className="text-right pl-2">
                                                            {NumberUtils.format(this.viewModel.getRemainingBudgets(incentiveGroup.incentiveGroupId), Misc.NumberFormat.CurrencyNoDecimals, allocationCurrency!.symbol)}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-right mt-3 mx-3">
                                    <Neo.Button className="btn-150 mr-3"
                                        text={this.viewModel.currentlyLoggedInManagerDetails.meta.checkedDate.value !== null ? "Submitted" : "Ready for Review"}
                                        onClick={() => this.allocationManagerViewModel.submitForReview()}
                                    />
                                </div>
                            </div>
                        }
                    </>
                </Neo.Pager>

                <Neo.Modal title={"Allocation history for " + this.viewModel.participantName + " - " + this.viewModel.participantJobTitle} size="xl" bindModel={this.viewModel.meta.allocationHistoryVM}>
                    {() => (
                        this.viewModel.allocationHistoryVM && <AllocationHistoryComponent viewModel={this.viewModel.allocationHistoryVM} />
                    )}
                </Neo.Modal>
                {
                    this.viewModel.showChangeRequestModal &&
                    <ChangeRequestModal show={this.viewModel.showChangeRequestModal} participantName={this.viewModel.participantName}
                        onClose={() => this.viewModel.showChangeRequestModal = false}
                        sendRequest={(changeRequest) => this.viewModel.sendChangeRequest(changeRequest)} />
                }

                {
                    this.viewModel.showAddRequestModal &&
                    <AddParticipantModal show={this.viewModel.showAddRequestModal}
                        onClose={() => this.viewModel.showAddRequestModal = false}
                        sendRequest={(addRequest) => this.viewModel.sendAddRequest(addRequest)} />
                }
                <Neo.Modal title="Change requested" size="lg" className={"request-modal"}
                    show={this.viewModel.showPostRequestModal}
                    onClose={() => this.viewModel.showPostRequestModal = false}
                    closeButton={false}
                    acceptButton={false}>
                    <Neo.Loader task={this.viewModel.taskRunner}>
                        <div>
                            <p className="show-white-space">{changeControlConfig.changeControlWording !== "" ? changeControlConfig.changeControlWording : "Thank you. Your request has been sent."}</p>
                            {this.viewModel.tenant.ticketProviderType === TicketProviderType.Email &&
                                <p>{changeControlConfig.changeControlURL !== "" ? <a href={changeControlConfig.changeControlURL}>Track changes by clicking on this link</a> : ""}</p>
                            }
                            {this.viewModel.tenant.ticketProviderType !== TicketProviderType.Email &&
                                <div className="page-block page-block-alt reset-margin">
                                    <Neo.Button
                                        onClick={() => this.redirectToTicketingPlatform()}
                                        icon="ticket-alt"
                                        text={this.viewModel.tenant.ticketProviderType === TicketProviderType.FourMe ? "Go to 4Me" : "Go to External Ticket Platform"}>
                                    </Neo.Button>
                                </div>
                            }
                        </div>
                    </Neo.Loader>
                </Neo.Modal >
            </>
        )
    }

    public renderModals() {
        return (
            <>
                <Neo.Modal title="Confirm the SARs/SO schemes and % split" size="xl"
                    bindModel={this.viewModel.meta.updateCommand}
                    footer={false}
                    footerContent={false}
                    className={"request-modal"}
                    formProps={{ showSummaryModal: true }}
                    closeButton={{
                        text: "Cancel",
                        onClick: () => this.showUnsavedChanges(),
                        className: "btn-150 mx-3"
                    }}
                    acceptButton={{
                        text: "Save Schemes",
                        onClick: () => this.viewModel.updateAllocationSchemeSplits(),
                        disabled: !this.viewModel.managerCanEdit,
                        className: "btn-150"
                    }}
                >
                    {(command: UpdateParticipantAllocationCommand) => (
                        <>
                            You can make awards in the schemes below. Select the scheme(s) of your allocation and enter a % split if you are using more than one.
                            <NeoGrid.Grid items={command.schemeSplits}
                                addButton={{
                                    onClick: () => command.schemeSplits.addNew(),
                                    disabled: !this.viewModel.managerCanEdit
                                }}
                                className={"am-table allocation-participants table neo-grid"}>
                                {(ss) => (
                                    <NeoGrid.Row >
                                        <NeoGrid.Column style={{ width: "30%" }} label={"Scheme Name"} bindContext={ss.meta.incentiveSchemeId}>
                                            <Neo.AutoCompleteDropDown bind={ss.meta.incentiveSchemeId}
                                                items={this.viewModel.managerIncentiveSchemes}
                                                valueMember="incentiveSchemeId"
                                                bindDisplay={ss.meta.readableSchemeName}
                                                delayMs={0}
                                                minCharacters={0}
                                                onItemSelected={item => {
                                                    ss.description = item !== null ? item!.description : '';
                                                    ss.expectedValuePercentage = item !== null ? item!.expectedValuePercentage : 0;
                                                }}
                                            />
                                        </NeoGrid.Column>
                                        <NeoGrid.Column style={{ width: "30%" }} label={"Description"}
                                            display={ss.meta.description}
                                        />
                                        <NeoGrid.Column label={"Indicative Fair Value % per scheme"}
                                            display={ss.meta.expectedValuePercentage}
                                            numProps={{ format: Misc.NumberFormat.PercentDecimals }}
                                            alignment="center" />
                                        <NeoGrid.Column label={"Default Split?"}
                                            bind={ss.meta.percentageSplit}
                                            numProps={{ formatString: "#,##0.00######%;(#,##0.00######)%" }}
                                            alignment="center" />
                                        <NeoGrid.ButtonColumn deleteButton={{ onClick: () => this.viewModel.removeSchemeSplit(ss), disabled: !this.viewModel.managerCanEdit }} />
                                    </NeoGrid.Row>
                                )}
                            </NeoGrid.Grid>

                            <div className="pt-4">
                                <ValidationDisplay model={command} rule={UpdateParticipantAllocationCommand.incentiveSchemesUniqueRule} />
                                <ValidationDisplay model={command} rule={UpdateParticipantAllocationCommand.splitPercentagesTotalRule} />
                            </div>
                        </>
                    )}
                </Neo.Modal>

                <Neo.Modal title="Notes" size="lg"
                    bindModel={this.viewModel.meta.updateCommentCommand}
                    buttons={
                        [{
                            text: "Ok",
                            variant: "primary",
                            onClick: () => this.viewModel.updateParticipantComments(),
                            disabled: !this.viewModel.meta.updateCommentCommand.value?.isValid
                        },
                        {
                            text: "Cancel",
                            variant: "secondary",
                            onClick: () => {
                                if (this.viewModel.selectedParticipant.allocationPlanId > 0) {
                                    this.cancelAllocation();
                                } else {
                                    this.viewModel.updateCommentCommand = null;
                                }
                            }
                        }]
                    }
                    showCancelButton={false}
                    formProps={{ showSummaryModal: true }}>
                    {(command: UpdateParticipantAllocationCommentCommand) => (
                        <>
                            <NeoGrid.Grid items={command.participantComments}
                                addButton={{ onClick: () => this.addComment(this.viewModel.meta.updateCommentCommand.value?.overTheMaxCommentRequired!, command), disabled: !this.viewModel.managerCanEdit }}>
                                {(comment) => (
                                    <NeoGrid.Row>
                                        <NeoGrid.Column style={{ width: "30%" }} label={"Comment type"} bind={comment.meta.allocationCommentTypeId}
                                            select={{
                                                itemSource: this.viewModel.commentTypes,
                                                allowNulls: false,
                                                displayMember: "commentType",
                                                onItemSelected: (item) => {
                                                    this.viewModel.loadCommentOptionsListByType(comment)
                                                }
                                            }} />
                                        <NeoGrid.Column style={{ width: "30%" }} label={"Comment"} bind={comment.meta.allocationCommentTypeOptionId}
                                            select={{
                                                items: comment.commentTypeOptions,
                                                displayMember: "comment",
                                                allowNulls: true,
                                            }} />
                                        <NeoGrid.Column style={{ width: "60%" }} label={"Notes - max 250 characters"} bind={comment.meta.allocationCommentNotes}
                                            editorProps={{ maxLength: 250 }} input={{ rows: 2 }} editorTooltip={comment.allocationCommentNotesCharsRemaining} />
                                        <NeoGrid.ButtonColumn deleteButton={{ onClick: () => this.viewModel.removeComment(comment), disabled: !this.viewModel.managerCanEdit }} />
                                    </NeoGrid.Row>
                                )}
                            </NeoGrid.Grid>

                            <div className="pt-4">
                                <ValidationDisplay model={command} rule={UpdateParticipantAllocationCommentCommand.commentRequiredRule} />
                                <ValidationDisplay model={command} rule={UpdateParticipantAllocationCommentCommand.overTheMaxCommentRequiredRule} />
                            </div>
                        </>
                    )}
                </Neo.Modal>
            </>
        )
    }
}